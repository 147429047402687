import { empty } from "../fable-library.3.2.9/List.js";
import { uncurry } from "../fable-library.3.2.9/Util.js";
import { toFail, printf, toText } from "../fable-library.3.2.9/String.js";
import { RemoteBuilderOptions } from "./Types.fs.js";
import { Reader_$ctor_6C95DA22, Reader__Read_24524716 } from "../Fable.Remoting.MsgPack.1.13.0/Read.fs.js";
import { fullName, makeRecord, getRecordElements, name as name_1, class_type } from "../fable-library.3.2.9/Reflection.js";
import { value } from "../fable-library.3.2.9/Option.js";
import { createTypeInfo } from "../Fable.SimpleJson.3.21.0/TypeInfo.Converter.fs.js";
import { pick, map } from "../fable-library.3.2.9/Array.js";
import { singleton, collect, delay, toArray } from "../fable-library.3.2.9/Seq.js";
import { Proxy_proxyFetch } from "./Proxy.fs.js";

export function RemotingModule_createApi() {
    let clo1;
    return new RemoteBuilderOptions(empty(), void 0, void 0, false, uncurry(2, (clo1 = toText(printf("/%s/%s")), (arg10) => {
        const clo2 = clo1(arg10);
        return (arg20) => clo2(arg20);
    })), uncurry(2, void 0));
}

export function RemotingModule_withRouteBuilder(builder, options) {
    return new RemoteBuilderOptions(options.CustomHeaders, options.BaseUrl, options.Authorization, options.WithCredentials, builder, options.CustomResponseSerialization);
}

export function RemotingModule_withBaseUrl(url, options) {
    return new RemoteBuilderOptions(options.CustomHeaders, url, options.Authorization, options.WithCredentials, options.RouteBuilder, options.CustomResponseSerialization);
}

export function RemotingModule_withCustomHeader(headers, options) {
    return new RemoteBuilderOptions(headers, options.BaseUrl, options.Authorization, options.WithCredentials, options.RouteBuilder, options.CustomResponseSerialization);
}

export function RemotingModule_withAuthorizationHeader(token, options) {
    return new RemoteBuilderOptions(options.CustomHeaders, options.BaseUrl, token, options.WithCredentials, options.RouteBuilder, options.CustomResponseSerialization);
}

export function RemotingModule_withCredentials(withCredentials, options) {
    return new RemoteBuilderOptions(options.CustomHeaders, options.BaseUrl, options.Authorization, withCredentials, options.RouteBuilder, options.CustomResponseSerialization);
}

export function RemotingModule_withBinarySerialization(options) {
    return new RemoteBuilderOptions(options.CustomHeaders, options.BaseUrl, options.Authorization, options.WithCredentials, options.RouteBuilder, (response, returnType) => Reader__Read_24524716(Reader_$ctor_6C95DA22(response), returnType));
}

export class Remoting {
    constructor() {
    }
}

export function Remoting$reflection() {
    return class_type("Fable.Remoting.Client.Remoting", void 0, Remoting);
}

export function Remoting_$ctor() {
    return new Remoting();
}

export function Remoting_buildProxy_Z15584635(options, resolver) {
    const resolvedType = value(resolver).ResolveType();
    const schemaType = createTypeInfo(resolvedType);
    if (schemaType.tag === 36) {
        const patternInput = schemaType.fields[0]();
        const recordType = patternInput[1];
        const fieldTypes = map((prop) => [name_1(prop), prop[1]], getRecordElements(recordType));
        return makeRecord(recordType, toArray(delay(() => collect((field) => {
            let n, matchValue, fieldType, fn;
            return singleton((n = (((matchValue = field.FieldType, (matchValue.tag === 22) ? 0 : ((matchValue.tag === 23) ? 0 : ((matchValue.tag === 34) ? (matchValue.fields[0]().length - 1) : 0)))) | 0), (fieldType = pick((tupledArg) => {
                if (tupledArg[0] === field.FieldName) {
                    return tupledArg[1];
                }
                else {
                    return void 0;
                }
            }, fieldTypes), (fn = Proxy_proxyFetch(options, name_1(recordType), field, fieldType), (n === 0) ? fn(null)(null)(null)(null)(null)(null)(null)(null) : ((n === 1) ? ((a) => fn(a)(null)(null)(null)(null)(null)(null)(null)) : ((n === 2) ? ((delegateArg0, delegateArg1) => fn(delegateArg0)(delegateArg1)(null)(null)(null)(null)(null)(null)) : ((n === 3) ? ((delegateArg0_1, delegateArg1_1, delegateArg2) => fn(delegateArg0_1)(delegateArg1_1)(delegateArg2)(null)(null)(null)(null)(null)) : ((n === 4) ? ((delegateArg0_2, delegateArg1_2, delegateArg2_1, delegateArg3) => fn(delegateArg0_2)(delegateArg1_2)(delegateArg2_1)(delegateArg3)(null)(null)(null)(null)) : ((n === 5) ? ((delegateArg0_3, delegateArg1_3, delegateArg2_2, delegateArg3_1, delegateArg4) => fn(delegateArg0_3)(delegateArg1_3)(delegateArg2_2)(delegateArg3_1)(delegateArg4)(null)(null)(null)) : ((n === 6) ? ((delegateArg0_4, delegateArg1_4, delegateArg2_3, delegateArg3_2, delegateArg4_1, delegateArg5) => fn(delegateArg0_4)(delegateArg1_4)(delegateArg2_3)(delegateArg3_2)(delegateArg4_1)(delegateArg5)(null)(null)) : ((n === 7) ? ((delegateArg0_5, delegateArg1_5, delegateArg2_4, delegateArg3_3, delegateArg4_2, delegateArg5_1, delegateArg6) => fn(delegateArg0_5)(delegateArg1_5)(delegateArg2_4)(delegateArg3_3)(delegateArg4_2)(delegateArg5_1)(delegateArg6)(null)) : ((n === 8) ? ((delegateArg0_6, delegateArg1_6, delegateArg2_5, delegateArg3_4, delegateArg4_3, delegateArg5_2, delegateArg6_1, delegateArg7) => fn(delegateArg0_6)(delegateArg1_6)(delegateArg2_5)(delegateArg3_4)(delegateArg4_3)(delegateArg5_2)(delegateArg6_1)(delegateArg7)) : toFail(printf("Cannot generate proxy function for %s. Only up to 8 arguments are supported. Consider using a record type as input"))(field.FieldName)))))))))))));
        }, patternInput[0]))));
    }
    else {
        const arg10_1 = fullName(resolvedType);
        return toFail(printf("Cannot build proxy. Exepected type %s to be a valid protocol definition which is a record of functions"))(arg10_1);
    }
}

