import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { union_type, record_type, string_type } from "../../.fable/fable-library.3.2.9/Reflection.js";

export class State extends Record {
    constructor(Failed) {
        super();
        this.Failed = Failed;
    }
}

export function State$reflection() {
    return record_type("Pages.Tools.Model.State", [], State, () => [["Failed", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Failed"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.Tools.Model.Msg", [], Msg, () => [[], [["Item", string_type]]]);
}

