import { printf, toText } from "./.fable/fable-library.3.2.9/String.js";

export function saveToken(token) {
    localStorage.setItem("token", token);
}

export function removeToken() {
    localStorage.removeItem("token");
}

export function getToken() {
    const token = localStorage.getItem("token");
    if (token == null) {
        return "";
    }
    else {
        return token;
    }
}

export function getBearerToken() {
    const arg10 = getToken();
    return toText(printf("Bearer %s"))(arg10);
}

