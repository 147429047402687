import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { Invoice, InvoiceState, SortDirection, InvoiceSortFields, InvoiceRequest } from "../../Shared/Shared.js";
import { api } from "../../Api.js";
import { State as State_1, Msg } from "./Model.js";
import { Cmd_map, Cmd_OfFunc_result, Cmd_none, Cmd_batch, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { State_update as State_update_1, Types_State$1 } from "../Common/Sorting.js";
import { bounce, update, create } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { Cmd_fromAsync, Deferred$1 } from "../Common/Extensions.js";
import { singleton as singleton_1, filter as filter_1, map, ofArray, empty } from "../../.fable/fable-library.3.2.9/List.js";
import { value } from "../../.fable/fable-library.3.2.9/Option.js";
import { State_update, State_init } from "../Common/Paging.js";
import { equals } from "../../.fable/fable-library.3.2.9/Util.js";
import { fromSeconds } from "../../.fable/fable-library.3.2.9/TimeSpan.js";

export function Api_loadInvoicesCmd(field, direction, page, pageSize, filter) {
    return singleton.Delay(() => {
        const request = new InvoiceRequest(field, direction, page, pageSize, filter);
        return singleton.Bind(api().getInvoices(request), (_arg1) => singleton.Return(new Msg(12, _arg1)));
    });
}

export function Api_changeInvoiceStateCmd(invoiceId, state) {
    return singleton.Delay(() => singleton.Bind(api().changeInvoiceState(invoiceId, state), () => {
        let tupledArg;
        return singleton.Return((tupledArg = [invoiceId, state], new Msg(10, tupledArg[0], tupledArg[1])));
    }));
}

export function Api_deleteInvoiceCmd(invoiceId) {
    return singleton.Delay(() => singleton.Bind(api().deleteInvoice(invoiceId), () => singleton.Return(new Msg(11, invoiceId))));
}

export const Api_loadDraftsSummaryCmd = singleton.Delay(() => singleton.Bind(api().getDraftsSummary(), (_arg1) => singleton.Return(new Msg(13, _arg1))));

export function Api_changeDraftsStateCmd(nextState) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, api().changeDraftsState, nextState, () => (new Msg(17)));
}

export const Update_DEFAULT_SORT_FIELD = new InvoiceSortFields(0);

export const Update_DEFAULT_SORT_DIRECTION = new SortDirection(1);

export function Update_init() {
    const defaultSorting = new Types_State$1(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION);
    return [new State_1("", create(), void 0, defaultSorting, new Deferred$1(1), -1, empty(), void 0), Cmd_batch(ofArray([Cmd_fromAsync(Api_loadInvoicesCmd(defaultSorting.Field, defaultSorting.Direction, 1, 20, "")), Cmd_fromAsync(Api_loadDraftsSummaryCmd)]))];
}

export function Update_update(msg, prevState) {
    switch (msg.tag) {
        case 0: {
            const patternInput_1 = update(msg.fields[0], prevState.Debouncer);
            return [new State_1(prevState.Filter, patternInput_1[0], prevState.Paging, prevState.Sorting, prevState.Invoices, prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), patternInput_1[1]];
        }
        case 1: {
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, new Deferred$1(1), prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), Cmd_fromAsync(Api_loadInvoicesCmd(prevState.Sorting.Field, prevState.Sorting.Direction, value(prevState.Paging).CurrentPage, 20, prevState.Filter))];
        }
        case 12: {
            const x = msg.fields[0];
            return [new State_1(prevState.Filter, prevState.Debouncer, State_init(x.Page, x.PageSize, x.Total), prevState.Sorting, new Deferred$1(2, x.Items), prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), Cmd_none()];
        }
        case 3: {
            const matchValue = prevState.Paging;
            if (matchValue != null) {
                const patternInput_2 = State_update(matchValue, msg.fields[0]);
                const extCmd = patternInput_2[1];
                return [new State_1(prevState.Filter, prevState.Debouncer, patternInput_2[0], prevState.Sorting, new Deferred$1(1), prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), (extCmd == null) ? Cmd_none() : Cmd_fromAsync(Api_loadInvoicesCmd(prevState.Sorting.Field, prevState.Sorting.Direction, extCmd.fields[0], 20, prevState.Filter))];
            }
            else {
                return [prevState, Cmd_none()];
            }
        }
        case 4: {
            const nextState_2 = State_update_1(prevState.Sorting, msg.fields[0])[0];
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, nextState_2, new Deferred$1(1), prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), Cmd_fromAsync(Api_loadInvoicesCmd(nextState_2.Field, nextState_2.Direction, value(prevState.Paging).CurrentPage, 20, prevState.Filter))];
        }
        case 5: {
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, prevState.Invoices, msg.fields[0], prevState.InvoiceDetails, prevState.DraftSummary), Cmd_none()];
        }
        case 6: {
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, prevState.Invoices, msg.fields[0], prevState.InvoiceDetails, prevState.DraftSummary), Cmd_none()];
        }
        case 9: {
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, prevState.Invoices, prevState.ExpandedRow, msg.fields[0], prevState.DraftSummary), Cmd_none()];
        }
        case 7: {
            return [prevState, Cmd_fromAsync(Api_changeInvoiceStateCmd(msg.fields[0], new InvoiceState(1)))];
        }
        case 8: {
            return [prevState, Cmd_fromAsync(Api_deleteInvoiceCmd(msg.fields[0]))];
        }
        case 10: {
            const prevInvoices = prevState.Invoices;
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, (prevInvoices.tag === 2) ? (new Deferred$1(2, map((invoice) => {
                if (invoice.Id === msg.fields[0]) {
                    return new Invoice(invoice.Id, invoice.CompanyId, invoice.P1Id, invoice.CompanyName, invoice.DateFrom, invoice.DateTo, msg.fields[1], invoice.SmsCount, invoice.Tax, invoice.TotalAmount, invoice.Details, invoice.InvoiceRowTitle);
                }
                else {
                    return invoice;
                }
            }, prevInvoices.fields[0]))) : prevInvoices, prevState.ExpandedRow, prevState.InvoiceDetails, void 0), Cmd_fromAsync(Api_loadDraftsSummaryCmd)];
        }
        case 11: {
            const prevInvoices_1 = prevState.Invoices;
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, (prevInvoices_1.tag === 2) ? (new Deferred$1(2, filter_1((invoice_1) => (invoice_1.Id !== msg.fields[0]), prevInvoices_1.fields[0]))) : prevInvoices_1, prevState.ExpandedRow, prevState.InvoiceDetails, void 0), Cmd_fromAsync(Api_loadDraftsSummaryCmd)];
        }
        case 14: {
            return [prevState, Cmd_none()];
        }
        case 13: {
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, prevState.Invoices, prevState.ExpandedRow, prevState.InvoiceDetails, msg.fields[0]), Cmd_none()];
        }
        case 15: {
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, prevState.Invoices, prevState.ExpandedRow, prevState.InvoiceDetails, void 0), Cmd_fromAsync(Api_loadDraftsSummaryCmd)];
        }
        case 16: {
            return [prevState, Api_changeDraftsStateCmd(new InvoiceState(1))];
        }
        case 17: {
            const prevInvoices_2 = prevState.Invoices;
            return [new State_1(prevState.Filter, prevState.Debouncer, prevState.Paging, prevState.Sorting, (prevInvoices_2.tag === 2) ? (new Deferred$1(2, map((invoice_2) => {
                if (equals(invoice_2.State, new InvoiceState(0))) {
                    return new Invoice(invoice_2.Id, invoice_2.CompanyId, invoice_2.P1Id, invoice_2.CompanyName, invoice_2.DateFrom, invoice_2.DateTo, new InvoiceState(1), invoice_2.SmsCount, invoice_2.Tax, invoice_2.TotalAmount, invoice_2.Details, invoice_2.InvoiceRowTitle);
                }
                else {
                    return invoice_2;
                }
            }, prevInvoices_2.fields[0]))) : prevInvoices_2, prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), Cmd_OfFunc_result(new Msg(15))];
        }
        default: {
            const patternInput = bounce(fromSeconds(1), "contacts_search", new Msg(1), prevState.Debouncer);
            return [new State_1(msg.fields[0], patternInput[0], prevState.Paging, prevState.Sorting, prevState.Invoices, prevState.ExpandedRow, prevState.InvoiceDetails, prevState.DraftSummary), Cmd_batch(singleton_1(Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])))];
        }
    }
}

