import { useFeliz_React__React_useState_Static_1505 } from "../../.fable/Feliz.1.49.0/React.fs.js";
import { today } from "../../.fable/fable-library.3.2.9/Date.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Api_makeInvoicesByDateCmd } from "./Update.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { singleton, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { ClassName, OnChange, Value, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";

export function makeInvoicesByDateComponent() {
    let children, props_12, props_4, props_10, props_8, props_6;
    const patternInput = useFeliz_React__React_useState_Static_1505(today());
    const date = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const makeInvoices = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => Api_makeInvoicesByDateCmd(date), patternInput_1[1]);
    const props_15 = ofArray([["style", {
        minWidth: 320,
    }], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-5"], ["children", "Make invoices by date"]])))), (children = singleton((props_12 = ofArray([["className", "is-horizontal"], ["style", {
        verticalAlign: "middle",
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Date"]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_4)))), (props_10 = singleton(["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([Value(date), OnChange(patternInput[1]), ClassName("input")])), (props_8 = ofArray([["style", {
        paddingLeft: 15,
    }], ["children", Interop_reactApi.Children.toArray([(props_6 = toList(delay(() => append(equals(patternInput_1[0], new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", "Make"]), delay(() => singleton_1(["onClick", (_arg1) => {
        makeInvoices();
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))), createElement("fieldset", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_15)));
}

export function makeDiscountByDateComponent() {
    let children, props_12, props_4, props_10, props_8, props_6;
    const patternInput = useFeliz_React__React_useState_Static_1505(today());
    const date = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const makeInvoices = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => Api_makeInvoicesByDateCmd(date), patternInput_1[1]);
    const props_15 = ofArray([["style", {
        minWidth: 320,
    }], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-5"], ["children", "Make discount by date"]])))), (children = singleton((props_12 = ofArray([["className", "is-horizontal"], ["style", {
        verticalAlign: "middle",
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Date"]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_4)))), (props_10 = singleton(["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([Value(date), OnChange(patternInput[1]), ClassName("input")])), (props_8 = ofArray([["style", {
        paddingLeft: 15,
    }], ["children", Interop_reactApi.Children.toArray([(props_6 = toList(delay(() => append(equals(patternInput_1[0], new Deferred$1(1)) ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", "Make"]), delay(() => singleton_1(["onClick", (_arg1) => {
        makeInvoices();
    }]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_8))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))), createElement("fieldset", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_15)));
}

export function render(state, dispatch) {
    let props;
    const props_2 = singleton(["children", Interop_reactApi.Children.toArray([(props = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([createElement(makeInvoicesByDateComponent, null), createElement(makeDiscountByDateComponent, null)])]]), createElement("div", createObj(Helpers_combineClasses("column", props))))])]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_2)));
}

