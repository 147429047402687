import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { Sender$reflection, Country$reflection, PhoneValidationForm$reflection } from "../../Shared/Shared.js";
import { string_type, option_type, union_type, record_type, list_type, bool_type } from "../../.fable/fable-library.3.2.9/Reflection.js";

export class EditPhoneState extends Record {
    constructor(Form, IsEmptyPhone, IsNotSelectCountry, CountryList) {
        super();
        this.Form = Form;
        this.IsEmptyPhone = IsEmptyPhone;
        this.IsNotSelectCountry = IsNotSelectCountry;
        this.CountryList = CountryList;
    }
}

export function EditPhoneState$reflection() {
    return record_type("Pages.ValidationExceptions.Model.EditPhoneState", [], EditPhoneState, () => [["Form", PhoneValidationForm$reflection()], ["IsEmptyPhone", bool_type], ["IsNotSelectCountry", bool_type], ["CountryList", list_type(Country$reflection())]]);
}

export class EditSenderState extends Record {
    constructor(Form, IsEmptySender) {
        super();
        this.Form = Form;
        this.IsEmptySender = IsEmptySender;
    }
}

export function EditSenderState$reflection() {
    return record_type("Pages.ValidationExceptions.Model.EditSenderState", [], EditSenderState, () => [["Form", Sender$reflection()], ["IsEmptySender", bool_type]]);
}

export class ActiveTab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Phones", "Sender"];
    }
}

export function ActiveTab$reflection() {
    return union_type("Pages.ValidationExceptions.Model.ActiveTab", [], ActiveTab, () => [[], []]);
}

export class State extends Record {
    constructor(EditPhoneState, EditSenderState, Phones, Senders, IsSaving, Loading, ActiveTab) {
        super();
        this.EditPhoneState = EditPhoneState;
        this.EditSenderState = EditSenderState;
        this.Phones = Phones;
        this.Senders = Senders;
        this.IsSaving = IsSaving;
        this.Loading = Loading;
        this.ActiveTab = ActiveTab;
    }
}

export function State$reflection() {
    return record_type("Pages.ValidationExceptions.Model.State", [], State, () => [["EditPhoneState", option_type(EditPhoneState$reflection())], ["EditSenderState", option_type(EditSenderState$reflection())], ["Phones", list_type(PhoneValidationForm$reflection())], ["Senders", list_type(Sender$reflection())], ["IsSaving", bool_type], ["Loading", bool_type], ["ActiveTab", ActiveTab$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SwitchToPhones", "SwitchToSender", "OpenPhoneModal", "ClosePhoneModal", "SavePhone", "EditPhone", "UpdatePhone", "DeletePhone", "CountryChange", "ModifyPhone", "SaveSuccessfully", "UpdateSuccessfully", "OpenSenderModal", "CloseSenderModal", "SaveSender", "EditSender", "UpdateSender", "ModifySender", "DeleteSender", "Deleted", "CountryListLoaded", "PhonesLoaded", "SendersLoaded", "Failed"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.ValidationExceptions.Model.Msg", [], Msg, () => [[], [], [], [], [["Item", PhoneValidationForm$reflection()]], [["Item", PhoneValidationForm$reflection()]], [["Item", PhoneValidationForm$reflection()]], [["Item", PhoneValidationForm$reflection()]], [["Item", string_type]], [["Item", string_type]], [], [], [], [], [["Item", Sender$reflection()]], [["Item", Sender$reflection()]], [["Item", Sender$reflection()]], [["Item", string_type]], [["Item", Sender$reflection()]], [], [["Item", list_type(Country$reflection())]], [["Item", list_type(PhoneValidationForm$reflection())]], [["Item", list_type(Sender$reflection())]], []]);
}

