import { Record, Union } from "./.fable/fable-library.3.2.9/Types.js";
import { State as State_1, Msg$reflection, State$reflection as State$reflection_1 } from "./Pages/SignIn/Model.js";
import { Msg$reflection as Msg$reflection_1, State$reflection as State$reflection_2 } from "./Pages/Customers/Model.js";
import { View_view as View_view_1, State_update, State_init, Types_Msg$reflection, Types_State$reflection } from "./Pages/Customer/CustomerDetails.js";
import { Msg$reflection as Msg$reflection_2, State$reflection as State$reflection_3 } from "./Pages/Invoices/Model.js";
import { Msg$reflection as Msg$reflection_3, State$reflection as State$reflection_4 } from "./Pages/Tools/Model.js";
import { Msg$reflection as Msg$reflection_4, State$reflection as State$reflection_5 } from "./Pages/VirtualNumbers/Types.js";
import { Msg$reflection as Msg$reflection_5, State$reflection as State$reflection_6 } from "./Pages/Settings/Model.js";
import { Msg$reflection as Msg$reflection_6, State$reflection as State$reflection_7 } from "./Pages/Perfomance/Model.js";
import { Msg$reflection as Msg$reflection_7, State$reflection as State$reflection_8 } from "./Pages/OptedOut/Model.js";
import { Msg$reflection as Msg$reflection_8, State$reflection as State$reflection_9 } from "./Pages/SmsSearch/Model.js";
import { Msg$reflection as Msg$reflection_9, State$reflection as State$reflection_10 } from "./Pages/ValidationExceptions/Model.js";
import { option_type, record_type, string_type, union_type } from "./.fable/fable-library.3.2.9/Reflection.js";
import { Update_update, Update_init } from "./Pages/SignIn/Update.js";
import { Cmd_none, Cmd_map } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Update_update as Update_update_1, Update_init as Update_init_1 } from "./Pages/Customers/Update.js";
import { Update_update as Update_update_2, Update_init as Update_init_2 } from "./Pages/Invoices/Update.js";
import { Update_update as Update_update_3, Update_init as Update_init_3 } from "./Pages/Tools/Update.js";
import { Model_update, Model_init } from "./Pages/VirtualNumbers/Model.js";
import { update as update_1, init as init_1 } from "./Pages/Settings/Update.js";
import { Update_update as Update_update_4, Update_init as Update_init_4 } from "./Pages/Perfomance/Update.js";
import { Update_update as Update_update_5, Update_init as Update_init_5 } from "./Pages/OptedOut/Update.js";
import { Update_update as Update_update_6, Update_init as Update_init_6 } from "./Pages/SmsSearch/Update.js";
import { update as update_2, init as init_2 } from "./Pages/ValidationExceptions/Update.js";
import { removeToken } from "./LocalStorage.js";
import { routeParser, toPath, Route, navigateTo } from "./Router.js";
import { useFeliz_React__React_useState_Static_1505 } from "./.fable/Feliz.1.49.0/React.fs.js";
import { Interop_reactApi } from "./.fable/Feliz.1.49.0/Interop.fs.js";
import { empty, singleton, append, delay, toList } from "./.fable/fable-library.3.2.9/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { createObj } from "./.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { empty as empty_1, ofArray, singleton as singleton_1 } from "./.fable/fable-library.3.2.9/List.js";
import { container } from "./.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { view } from "./Pages/Customers/View.js";
import { view as view_1 } from "./Pages/Invoices/View.js";
import { render as render_1 } from "./Pages/Tools/View.js";
import { View_render } from "./Pages/VirtualNumbers/View.js";
import { render as render_2 } from "./Pages/Settings/View.js";
import { render as render_3 } from "./Pages/Perfomance/View.js";
import { view as view_2 } from "./Pages/OptedOut/View.js";
import { view as view_3 } from "./Pages/SmsSearch/View.js";
import { render as render_4 } from "./Pages/ValidationExceptions/View.js";
import { view as view_4 } from "./Pages/SignIn/View.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";
import { Program_withToast } from "./.fable/Thoth.Elmish.Toast.3.0.0/Toast.fs.js";
import { renderToastWithFulma } from "./Pages/Components/Toast.js";
import { ProgramModule_toNavigable } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parseHash } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";

export class PageModel extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SignIn", "Accounts", "CustomerDetails", "Invoices", "Tools", "VirtualNumbers", "Settings", "Perfomance", "OptedOut", "SmsSearch", "ValidationExceptions"];
    }
}

export function PageModel$reflection() {
    return union_type("Client.PageModel", [], PageModel, () => [[["Item", State$reflection_1()]], [["Item", State$reflection_2()]], [["Item", Types_State$reflection()]], [["Item", State$reflection_3()]], [["Item", State$reflection_4()]], [["Item", State$reflection_5()]], [["Item", State$reflection_6()]], [["Item", State$reflection_7()]], [["Item", State$reflection_8()]], [["Item", State$reflection_9()]], [["Item", State$reflection_10()]]]);
}

export class MainMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Logout", "SignInMsg", "AccountsMsg", "CustomerDetailsMsg", "InvoicesMsg", "ToolsMsg", "VirtualNumbersMsg", "SettingsMsg", "PerfomanceMsg", "OptedOutMsg", "SmsSearchMsg", "ValidationExceptionsMsg"];
    }
}

export function MainMsg$reflection() {
    return union_type("Client.MainMsg", [], MainMsg, () => [[], [["Item", Msg$reflection()]], [["Item", Msg$reflection_1()]], [["Item", Types_Msg$reflection()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [["Item", Msg$reflection_7()]], [["Item", Msg$reflection_8()]], [["Item", Msg$reflection_9()]]]);
}

export class CurrentUser extends Record {
    constructor(UserName) {
        super();
        this.UserName = UserName;
    }
}

export function CurrentUser$reflection() {
    return record_type("Client.CurrentUser", [], CurrentUser, () => [["UserName", string_type]]);
}

export class State extends Record {
    constructor(CurrentPage, User) {
        super();
        this.CurrentPage = CurrentPage;
        this.User = User;
    }
}

export function State$reflection() {
    return record_type("Client.State", [], State, () => [["CurrentPage", PageModel$reflection()], ["User", option_type(CurrentUser$reflection())]]);
}

export function urlUpdate(nextPage, model) {
    let pattern_matching_result, customerId;
    if (nextPage != null) {
        if (nextPage.tag === 1) {
            pattern_matching_result = 1;
        }
        else if (nextPage.tag === 4) {
            pattern_matching_result = 2;
            customerId = nextPage.fields[0];
        }
        else if (nextPage.tag === 2) {
            pattern_matching_result = 3;
        }
        else if (nextPage.tag === 3) {
            pattern_matching_result = 4;
        }
        else if (nextPage.tag === 5) {
            pattern_matching_result = 5;
        }
        else if (nextPage.tag === 6) {
            pattern_matching_result = 6;
        }
        else if (nextPage.tag === 7) {
            pattern_matching_result = 7;
        }
        else if (nextPage.tag === 8) {
            pattern_matching_result = 8;
        }
        else if (nextPage.tag === 9) {
            pattern_matching_result = 9;
        }
        else if (nextPage.tag === 10) {
            pattern_matching_result = 10;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = Update_init();
            return [new State(new PageModel(0, patternInput[0]), model.User), Cmd_map((arg0) => (new MainMsg(1, arg0)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = Update_init_1();
            return [new State(new PageModel(1, patternInput_1[0]), model.User), Cmd_map((arg0_1) => (new MainMsg(2, arg0_1)), patternInput_1[1])];
        }
        case 2: {
            const patternInput_2 = State_init(customerId);
            return [new State(new PageModel(2, patternInput_2[0]), model.User), Cmd_map((arg0_2) => (new MainMsg(3, arg0_2)), patternInput_2[1])];
        }
        case 3: {
            const patternInput_3 = Update_init_2();
            return [new State(new PageModel(3, patternInput_3[0]), model.User), Cmd_map((arg0_3) => (new MainMsg(4, arg0_3)), patternInput_3[1])];
        }
        case 4: {
            const patternInput_4 = Update_init_3();
            return [new State(new PageModel(4, patternInput_4[0]), model.User), Cmd_map((arg0_4) => (new MainMsg(5, arg0_4)), patternInput_4[1])];
        }
        case 5: {
            const patternInput_5 = Model_init();
            return [new State(new PageModel(5, patternInput_5[0]), model.User), Cmd_map((arg0_5) => (new MainMsg(6, arg0_5)), patternInput_5[1])];
        }
        case 6: {
            const patternInput_6 = init_1();
            return [new State(new PageModel(6, patternInput_6[0]), model.User), Cmd_map((arg0_6) => (new MainMsg(7, arg0_6)), patternInput_6[1])];
        }
        case 7: {
            const patternInput_7 = Update_init_4();
            return [new State(new PageModel(7, patternInput_7[0]), model.User), Cmd_map((arg0_7) => (new MainMsg(8, arg0_7)), patternInput_7[1])];
        }
        case 8: {
            const patternInput_8 = Update_init_5();
            return [new State(new PageModel(8, patternInput_8[0]), model.User), Cmd_map((arg0_8) => (new MainMsg(9, arg0_8)), patternInput_8[1])];
        }
        case 9: {
            const patternInput_9 = Update_init_6();
            return [new State(new PageModel(9, patternInput_9[0]), model.User), Cmd_map((arg0_9) => (new MainMsg(10, arg0_9)), patternInput_9[1])];
        }
        case 10: {
            const patternInput_10 = init_2();
            return [new State(new PageModel(10, patternInput_10[0]), model.User), Cmd_map((arg0_10) => (new MainMsg(11, arg0_10)), patternInput_10[1])];
        }
    }
}

export function init(page) {
    return urlUpdate(page, new State(new PageModel(0, new State_1("", "", false, "")), void 0));
}

export function update(msg, state) {
    const matchValue = [msg, state.CurrentPage];
    let pattern_matching_result, signInMsg, signInState, dMsg, dashboardState, dMsg_1, pageState, dMsg_2, dashboardState_1, dMsg_3, dashboardState_2, dMsg_4, dashboardState_3, model, msg_1, model_2, msg_2, model_4, msg_3, model_6, msg_4, model_8, msg_5;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 0) {
            pattern_matching_result = 1;
            signInMsg = matchValue[0].fields[0];
            signInState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 2;
            dMsg = matchValue[0].fields[0];
            dashboardState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 3;
            dMsg_1 = matchValue[0].fields[0];
            pageState = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 4;
            dMsg_2 = matchValue[0].fields[0];
            dashboardState_1 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].tag === 4) {
            pattern_matching_result = 5;
            dMsg_3 = matchValue[0].fields[0];
            dashboardState_2 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].tag === 5) {
            pattern_matching_result = 6;
            dMsg_4 = matchValue[0].fields[0];
            dashboardState_3 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 7;
            model = matchValue[1].fields[0];
            msg_1 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[1].tag === 7) {
            pattern_matching_result = 8;
            model_2 = matchValue[1].fields[0];
            msg_2 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 9) {
        if (matchValue[1].tag === 8) {
            pattern_matching_result = 9;
            model_4 = matchValue[1].fields[0];
            msg_3 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 10) {
        if (matchValue[1].tag === 9) {
            pattern_matching_result = 10;
            model_6 = matchValue[1].fields[0];
            msg_4 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (matchValue[0].tag === 11) {
        if (matchValue[1].tag === 10) {
            pattern_matching_result = 11;
            model_8 = matchValue[1].fields[0];
            msg_5 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            removeToken();
            return [state, navigateTo(new Route(0))];
        }
        case 1: {
            const patternInput = Update_update(signInMsg, signInState);
            return [new State(new PageModel(0, patternInput[0]), state.User), Cmd_map((arg0) => (new MainMsg(1, arg0)), patternInput[1])];
        }
        case 2: {
            const patternInput_1 = Update_update_1(dMsg, dashboardState);
            return [new State(new PageModel(1, patternInput_1[0]), state.User), Cmd_map((arg0_1) => (new MainMsg(2, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = State_update(dMsg_1, pageState);
            return [new State(new PageModel(2, patternInput_2[0]), state.User), Cmd_map((arg0_2) => (new MainMsg(3, arg0_2)), patternInput_2[1])];
        }
        case 4: {
            const patternInput_3 = Update_update_2(dMsg_2, dashboardState_1);
            return [new State(new PageModel(3, patternInput_3[0]), state.User), Cmd_map((arg0_3) => (new MainMsg(4, arg0_3)), patternInput_3[1])];
        }
        case 5: {
            const patternInput_4 = Update_update_3(dMsg_3, dashboardState_2);
            return [new State(new PageModel(4, patternInput_4[0]), state.User), Cmd_map((arg0_4) => (new MainMsg(5, arg0_4)), patternInput_4[1])];
        }
        case 6: {
            const patternInput_5 = Model_update(dMsg_4, dashboardState_3);
            return [new State(new PageModel(5, patternInput_5[0]), state.User), Cmd_map((arg0_5) => (new MainMsg(6, arg0_5)), patternInput_5[1])];
        }
        case 7: {
            const patternInput_6 = update_1(msg_1, model);
            return [new State(new PageModel(6, patternInput_6[0]), state.User), Cmd_map((arg0_6) => (new MainMsg(7, arg0_6)), patternInput_6[1])];
        }
        case 8: {
            const patternInput_7 = Update_update_4(msg_2, model_2);
            return [new State(new PageModel(7, patternInput_7[0]), state.User), Cmd_map((arg0_7) => (new MainMsg(8, arg0_7)), patternInput_7[1])];
        }
        case 9: {
            const patternInput_8 = Update_update_5(msg_3, model_4);
            return [new State(new PageModel(8, patternInput_8[0]), state.User), Cmd_map((arg0_8) => (new MainMsg(9, arg0_8)), patternInput_8[1])];
        }
        case 10: {
            const patternInput_9 = Update_update_6(msg_4, model_6);
            return [new State(new PageModel(9, patternInput_9[0]), state.User), Cmd_map((arg0_9) => (new MainMsg(10, arg0_9)), patternInput_9[1])];
        }
        case 11: {
            const patternInput_10 = update_2(msg_5, model_8);
            return [new State(new PageModel(10, patternInput_10[0]), state.User), Cmd_map((arg0_10) => (new MainMsg(11, arg0_10)), patternInput_10[1])];
        }
        case 12: {
            return [state, Cmd_none()];
        }
    }
}

export function View_renderNavBar(view_renderNavBarInputProps) {
    let props_29, elms, props_3, props_27;
    const unitVar2 = view_renderNavBarInputProps.unitVar2;
    const dispatch = view_renderNavBarInputProps.dispatch;
    const _state = view_renderNavBarInputProps._state;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMenuActive = patternInput[1];
    const menuActive = patternInput[0];
    const props_31 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_29 = ofArray([["role", "navigation"], ["aria-label", "main-navigation"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1((props_3 = toList(delay(() => append(singleton(["onClick", (x) => {
        x.preventDefault();
        setMenuActive(!menuActive);
    }]), delay(() => append(menuActive ? singleton(["className", "is-active"]) : empty(), delay(() => singleton(["children", Interop_reactApi.Children.toArray([createElement("span", {}), createElement("span", {}), createElement("span", {})])]))))))), createElement("a", createObj(Helpers_combineClasses("navbar-burger", props_3))))), createElement("div", {
        className: "navbar-brand",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_27 = toList(delay(() => append(menuActive ? singleton(["className", "is-active"]) : empty(), delay(() => append(singleton(["style", {
        padding: (0 + "px ") + (1.5 + "rem"),
    }]), delay(() => append(singleton(["onClick", (_arg1) => {
        setMenuActive(!menuActive);
    }]), delay(() => {
        let elms_1, props_6, props_8, props_10, props_12, props_14, props_16, props_18, props_20, elms_3, elms_2;
        return singleton(["children", Interop_reactApi.Children.toArray([(elms_1 = ofArray([(props_6 = ofArray([["href", toPath(new Route(1))], ["children", "Customers"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_6)))), (props_8 = ofArray([["href", toPath(new Route(2))], ["children", "Invoices"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_8)))), (props_10 = ofArray([["href", toPath(new Route(5))], ["children", "Virtual Numbers"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_10)))), (props_12 = ofArray([["href", toPath(new Route(6))], ["children", "Settings"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_12)))), (props_14 = ofArray([["href", toPath(new Route(7))], ["children", "Perfomance"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_14)))), (props_16 = ofArray([["href", toPath(new Route(8))], ["children", "Opted-out"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_16)))), (props_18 = ofArray([["href", toPath(new Route(9))], ["children", "SMS search"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_18)))), (props_20 = ofArray([["href", toPath(new Route(10))], ["children", "Validation exceptions"]]), createElement("a", createObj(Helpers_combineClasses("navbar-item", props_20))))]), createElement("div", {
            className: "navbar-start",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        })), (elms_3 = singleton_1((elms_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("button", ofArray([["onClick", (x_1) => {
            dispatch(new MainMsg(0));
            x_1.preventDefault();
        }], ["children", "Logout"], ["className", "is-primary"]]))))), createElement("a", {
            className: "navbar-item",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "navbar-end",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]);
    })))))))), createElement("div", createObj(Helpers_combineClasses("navbar-menu", props_27))))])]]), createElement("nav", createObj(Helpers_combineClasses("navbar", props_29))))])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_31)));
}

export function View_view(state, dispatch) {
    let dispatch_1, matchValue;
    const authenticated = (child) => react.createElement("div", {
        style: {
            paddingBottom: "1rem",
        },
    }, createElement(View_renderNavBar, {
        _state: state,
        dispatch: dispatch,
    }), child);
    return react.createElement("div", {}, container(empty_1(), singleton_1((dispatch_1 = dispatch, (matchValue = state.CurrentPage, (matchValue.tag === 1) ? authenticated(view(matchValue.fields[0], (arg_1) => {
        dispatch_1(new MainMsg(2, arg_1));
    })) : ((matchValue.tag === 2) ? authenticated(View_view_1(matchValue.fields[0], (arg_2) => {
        dispatch_1(new MainMsg(3, arg_2));
    })) : ((matchValue.tag === 3) ? authenticated(view_1(matchValue.fields[0], (arg_3) => {
        dispatch_1(new MainMsg(4, arg_3));
    })) : ((matchValue.tag === 4) ? authenticated(render_1(matchValue.fields[0], (arg_4) => {
        dispatch_1(new MainMsg(5, arg_4));
    })) : ((matchValue.tag === 5) ? authenticated(View_render(matchValue.fields[0], (arg_5) => {
        dispatch_1(new MainMsg(6, arg_5));
    })) : ((matchValue.tag === 6) ? authenticated(render_2(matchValue.fields[0], (arg_6) => {
        dispatch_1(new MainMsg(7, arg_6));
    })) : ((matchValue.tag === 7) ? authenticated(render_3(matchValue.fields[0], (arg_7) => {
        dispatch_1(new MainMsg(8, arg_7));
    })) : ((matchValue.tag === 8) ? authenticated(view_2(matchValue.fields[0], (arg_8) => {
        dispatch_1(new MainMsg(9, arg_8));
    })) : ((matchValue.tag === 9) ? authenticated(view_3(matchValue.fields[0], (arg_9) => {
        dispatch_1(new MainMsg(10, arg_9));
    })) : ((matchValue.tag === 10) ? authenticated(render_4(matchValue.fields[0], (arg_10) => {
        dispatch_1(new MainMsg(11, arg_10));
    })) : view_4(matchValue.fields[0], (arg) => {
        dispatch_1(new MainMsg(1, arg));
    })))))))))))))));
}

ProgramModule_run(Program_withReactSynchronous("elmish-app", Program_withToast(renderToastWithFulma, ProgramModule_toNavigable((location) => parseHash(routeParser, location), (nextPage, model) => urlUpdate(nextPage, model), ProgramModule_mkProgram((page) => init(page), (msg, state) => update(msg, state), (state_1, dispatch) => View_view(state_1, dispatch))))));

