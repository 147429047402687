import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { CampaignStat$reflection } from "../../Shared/Shared.js";
import { union_type, record_type, class_type, bool_type, int32_type, list_type } from "../../.fable/fable-library.3.2.9/Reflection.js";

export class State extends Record {
    constructor(CampaignStats, BusyCampaign, IsLoading) {
        super();
        this.CampaignStats = CampaignStats;
        this.BusyCampaign = BusyCampaign;
        this.IsLoading = IsLoading;
    }
}

export function State$reflection() {
    return record_type("Pages.Perfomance.Types.State", [], State, () => [["CampaignStats", list_type(CampaignStat$reflection())], ["BusyCampaign", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [int32_type, bool_type])], ["IsLoading", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CampaignStatsLoaded", "PauseCampaign", "UnPauseCampaign", "CampaignPaused", "CampaignUnPaused", "Refresh", "Failed"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.Perfomance.Types.Msg", [], Msg, () => [[["Item", list_type(CampaignStat$reflection())]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [], [["Item", class_type("System.Exception")]]]);
}

