import { Cmd_map, Cmd_none, Cmd_OfFunc_result, Cmd_batch, Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { customerStore, referenceApi, customerApi } from "../../Api.js";
import { Country, Customer as Customer_1, NewCustomerFormState, SortDirection, CustomerSortFields, CustomerStatus, CustomersRequest } from "../../Shared/Shared.js";
import { Field, CustomerData, State, changeUserStatus as changeUserStatus_1, Msg } from "./Model.js";
import { comparePrimitives, numberHash, partialApply } from "../../.fable/fable-library.3.2.9/Util.js";
import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { filter as filter_1, isEmpty, ofArray, map, singleton as singleton_1, empty, append, sort } from "../../.fable/fable-library.3.2.9/List.js";
import { List_distinct } from "../../.fable/fable-library.3.2.9/Seq2.js";
import { FormState_Form_withState, FormState_Form_isValid, FormState_Form_validate, Validation_validateEmail, Validation_requiredStringField, FormState_Validation_withField, FormState_Form_enableValiadateAll, FormState_Form_withNoErrors, FormState_Form_withFormErrors, FormState_Form_create } from "../Common/FormState.js";
import { State_update as State_update_1, Types_State$1 } from "../Common/Sorting.js";
import { update, bounce, create } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { State_init, State_update } from "../Common/Paging.js";
import { value as value_10 } from "../../.fable/fable-library.3.2.9/Option.js";
import { fromSeconds } from "../../.fable/fable-library.3.2.9/TimeSpan.js";
import { toText, printf, toFail } from "../../.fable/fable-library.3.2.9/String.js";
import { Cmd_fromAsync } from "../Common/Extensions.js";
import { ByteArrayExtensions_SaveFileAs_72B26259 } from "../../.fable/Fable.Remoting.Client.7.16.0/Extensions.fs.js";
import { toString, now } from "../../.fable/fable-library.3.2.9/Date.js";
import { toastError } from "../Components/Toast.js";

export function Api_loadCustomerCmd(field, direction, page, pageSize, filter, statusFilter, withDeleted) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (page_1) => customerApi().getCustomers(new CustomersRequest(field, direction, page_1, pageSize, filter, statusFilter, withDeleted)), page, (arg0) => (new Msg(0, arg0)));
}

export function Api_getOverdue(field, direction, page, pageSize, filter, statusFilter, withDeleted) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (page_1) => customerApi().getOverdue(new CustomersRequest(field, direction, page_1, pageSize, filter, statusFilter, withDeleted)), page, (arg0) => (new Msg(0, arg0)));
}

export function Api_acceptCustomerCmd(customer) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, customerApi().acceptCustomer, customer.Id, () => (new Msg(7, customer, new CustomerStatus(2))));
}

export function Api_acceptCustomerSilentlyCmd(customer) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, customerApi().acceptCustomerSilently, customer.Id, () => (new Msg(7, customer, new CustomerStatus(2))));
}

export function Api_declineCustomerCmd(customer) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, customerApi().declineCustomer, customer.Id, () => (new Msg(7, customer, new CustomerStatus(4))));
}

export function Api_declineCustomerSilentlyCmd(customer) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, customerApi().declineCustomerSilently, customer.Id, () => (new Msg(7, customer, new CustomerStatus(4))));
}

export function Api_changeUserStatusCmd(userId, userStatus) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, partialApply(1, customerApi().changeUserStatus, [userId]), userStatus, () => (new Msg(33)));
}

export const Api_exportCmd = Cmd_OfAsyncWith_either((x) => {
    Cmd_OfAsync_start(x);
}, () => customerApi().export(), void 0, (arg0) => (new Msg(41, arg0)), (arg0_1) => (new Msg(42, arg0_1)));

export function Api_getCustomersByVATOrCNCmd(customerData, acceptCustomerType) {
    return Cmd_OfAsyncWith_perform((x_3) => {
        Cmd_OfAsync_start(x_3);
    }, (customerId) => singleton.Delay(() => singleton.Bind(customerApi().getCustomersWithTheSameVAT(customerId), (_arg1) => singleton.Bind(customerApi().getCustomersWithTheSameCN(customerId), (_arg2) => singleton.Return(sort(List_distinct(append(_arg1, _arg2), {
        Equals: (x, y) => (x === y),
        GetHashCode: (x) => numberHash(x),
    }), {
        Compare: (x_1, y_1) => comparePrimitives(x_1, y_1),
    }))))), customerData.Id, (x_2) => (new Msg(2, customerData, x_2, acceptCustomerType)));
}

export const Api_getCountryList = singleton.Delay(() => singleton.Bind(referenceApi().getCountries(), (_arg1) => {
    const r = _arg1;
    return (r.tag === 1) ? singleton.Return(new Msg(37)) : singleton.Return(new Msg(34, r.fields[0]));
}));

export function Api_addCustomer(form) {
    return singleton.Delay(() => singleton.Bind(customerStore().add(form), (_arg1) => {
        const r = _arg1;
        return (r.tag === 1) ? singleton.Return(new Msg(36)) : singleton.Return(new Msg(35, r.fields[0]));
    }));
}

export const Update_DEFAULT_SORT_FIELD = new CustomerSortFields(4);

export const Update_DEFAULT_SORT_DIRECTION = new SortDirection(1);

export const Update_createNewCustomerForm = FormState_Form_create(new NewCustomerFormState("", "", "", "", "", "", "", "", "", "", "", ""));

export function Update_init() {
    return [new State(empty(), void 0, new Types_State$1(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION), true, "", "", void 0, create(), Update_createNewCustomerForm, new changeUserStatus_1(1), false, empty(), false, false, void 0, false), Cmd_batch(singleton_1(Api_loadCustomerCmd(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION, 1, 20, "", void 0, false)))];
}

export function Update_withServerError(error, state) {
    return new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_withFormErrors(singleton_1(error), state.NewCustomerForm), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting);
}

export function Update_withNoErrors(state) {
    return new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_withNoErrors(state.NewCustomerForm), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting);
}

export function Update_withEnableValidateAll(state) {
    return new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_enableValiadateAll(state.NewCustomerForm), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting);
}

export function Update_withForm(form, state) {
    return new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, form, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting);
}

export function Update_withLoading(isLoading, x) {
    return new CustomerData(x.Id, x.Customer, isLoading);
}

export function Update_withCustomer(customer, x) {
    return new State(map((c) => ((c.Id === customer.Id) ? customer : c), x.Customers), x.Paging, x.Sorting, x.Loading, x.Error, x.Filter, x.StatusFilter, x.Debouncer, x.NewCustomerForm, x.ConfirmChangeUserStatus, x.ShowNewCustomerForm, x.CountryList, x.ShowDeleted, x.ShowOverdue, x.ShowAcceptingConfirmation, x.Exporting);
}

export function Update_withStatus(status, x) {
    let inputRecord;
    return new CustomerData(x.Id, (inputRecord = x.Customer, new Customer_1(inputRecord.Id, inputRecord.PixieLogin, inputRecord.FirstName, inputRecord.LastName, status, inputRecord.UserStatus, inputRecord.CompanyInformation, inputRecord.HasVirtualNumbers, inputRecord.HasOverDueInvoice, inputRecord.Created)), x.Loading);
}

export function Update_validate(form) {
    return ofArray([FormState_Validation_withField(new Field(0))(Validation_requiredStringField("First name", 2, 200, form.State.FirstName)), FormState_Validation_withField(new Field(1))(Validation_requiredStringField("Last name", 2, 200, form.State.LastName)), FormState_Validation_withField(new Field(2))(Validation_requiredStringField("Company name", 2, 255, form.State.CompanyName)), FormState_Validation_withField(new Field(3))(Validation_validateEmail(form.State.Email)), FormState_Validation_withField(new Field(4))(Validation_requiredStringField("Phone number", 2, 50, form.State.PhoneNumber)), FormState_Validation_withField(new Field(5))(Validation_requiredStringField("Country", 1, 2, form.State.Country)), FormState_Validation_withField(new Field(6))(Validation_requiredStringField("City", 2, 255, form.State.City)), FormState_Validation_withField(new Field(7))(Validation_requiredStringField("Post code", 2, 100, form.State.PostCode)), FormState_Validation_withField(new Field(8))(Validation_requiredStringField("Address line 1", 2, 500, form.State.AddressLine1)), FormState_Validation_withField(new Field(11))(Validation_requiredStringField("Password", 8, 255, form.State.Password))]);
}

export function Update_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, arg10_1, copyOfStruct;
    switch (msg.tag) {
        case 1: {
            return [state, Api_getCustomersByVATOrCNCmd(msg.fields[0], msg.fields[1])];
        }
        case 2: {
            const customersWithTheSameVATorCN = msg.fields[1];
            const customerData_1 = msg.fields[0];
            const acceptCustomerType_1 = msg.fields[2];
            if (isEmpty(customersWithTheSameVATorCN)) {
                if (acceptCustomerType_1.tag === 1) {
                    return [state, Cmd_OfFunc_result(new Msg(4, customerData_1))];
                }
                else {
                    return [state, Cmd_OfFunc_result(new Msg(3, customerData_1))];
                }
            }
            else {
                return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, [customerData_1, customersWithTheSameVATorCN, acceptCustomerType_1], state.Exporting), empty()];
            }
        }
        case 31: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, void 0, state.Exporting), empty()];
        }
        case 3: {
            const customer = msg.fields[0];
            const updatedState_2 = new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, void 0, state.Exporting);
            return [Update_withCustomer(Update_withLoading(true, customer), updatedState_2), Api_acceptCustomerCmd(customer)];
        }
        case 4: {
            const customer_2 = msg.fields[0];
            const updatedState_3 = new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, void 0, state.Exporting);
            return [Update_withCustomer(Update_withLoading(true, customer_2), updatedState_3), Api_acceptCustomerSilentlyCmd(customer_2)];
        }
        case 5: {
            const customer_4 = msg.fields[0];
            return [Update_withCustomer(Update_withLoading(true, customer_4), state), Api_declineCustomerCmd(customer_4)];
        }
        case 6: {
            const customer_6 = msg.fields[0];
            return [Update_withCustomer(Update_withLoading(true, customer_6), state), Api_declineCustomerSilentlyCmd(customer_6)];
        }
        case 7: {
            return [Update_withCustomer(Update_withLoading(false, Update_withStatus(msg.fields[1], msg.fields[0])), state), Cmd_none()];
        }
        case 8: {
            const matchValue = state.Paging;
            if (matchValue != null) {
                const patternInput = State_update(matchValue, msg.fields[0]);
                const extCmd = patternInput[1];
                return [new State(state.Customers, patternInput[0], state.Sorting, true, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), (extCmd == null) ? Cmd_none() : Api_loadCustomerCmd(state.Sorting.Field, state.Sorting.Direction, extCmd.fields[0], 20, state.Filter, state.StatusFilter, state.ShowDeleted)];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 9: {
            const nextState_1 = State_update_1(state.Sorting, msg.fields[0])[0];
            return [new State(state.Customers, state.Paging, nextState_1, true, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), state.ShowOverdue ? Api_getOverdue(nextState_1.Field, nextState_1.Direction, value_10(state.Paging).CurrentPage, 20, state.Filter, state.StatusFilter, state.ShowDeleted) : Api_loadCustomerCmd(nextState_1.Field, nextState_1.Direction, value_10(state.Paging).CurrentPage, 20, state.Filter, state.StatusFilter, state.ShowDeleted)];
        }
        case 10: {
            const patternInput_2 = bounce(fromSeconds(1), "customer_search", new Msg(13), state.Debouncer);
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, msg.fields[0], state.StatusFilter, patternInput_2[0], state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_batch(singleton_1(Cmd_map((arg0) => (new Msg(12, arg0)), patternInput_2[1])))];
        }
        case 11: {
            const patternInput_3 = bounce(fromSeconds(1), "customer_filter", new Msg(13), state.Debouncer);
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, msg.fields[0], patternInput_3[0], state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_batch(singleton_1(Cmd_map((arg0_1) => (new Msg(12, arg0_1)), patternInput_3[1])))];
        }
        case 12: {
            const patternInput_4 = update(msg.fields[0], state.Debouncer);
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, patternInput_4[0], state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), patternInput_4[1]];
        }
        case 13: {
            return [new State(state.Customers, state.Paging, state.Sorting, true, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_batch(singleton_1(Api_loadCustomerCmd(state.Sorting.Field, state.Sorting.Direction, value_10(state.Paging).CurrentPage, 20, state.Filter, state.StatusFilter, state.ShowDeleted)))];
        }
        case 29: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, new changeUserStatus_1(0, msg.fields[0], msg.fields[1]), state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 30: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, new changeUserStatus_1(1), state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 32: {
            const changeParams = msg.fields[0];
            if (changeParams.tag === 1) {
                return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, new changeUserStatus_1(1), state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
            }
            else {
                const userStatus_1 = changeParams.fields[1] | 0;
                const customer_11 = changeParams.fields[0];
                return [state, (userStatus_1 === 0) ? Api_changeUserStatusCmd(customer_11.Id, 1) : ((userStatus_1 === 1) ? Api_changeUserStatusCmd(customer_11.Id, 1) : ((userStatus_1 === 2) ? Api_changeUserStatusCmd(customer_11.Id, 2) : ((userStatus_1 === 3) ? Api_changeUserStatusCmd(customer_11.Id, 3) : toFail(printf("Unexpected UserStatus = \u0027%A\u0027"))(userStatus_1))))];
            }
        }
        case 33: {
            const matchValue_1 = state.ConfirmChangeUserStatus;
            if (matchValue_1.tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                const id_2 = matchValue_1.fields[0].Id | 0;
                return [new State(((matchValue_1.fields[1] === 3) ? state.ShowDeleted : false) ? map((customer_13) => {
                    let inputRecord;
                    if (customer_13.Id === id_2) {
                        return new CustomerData(customer_13.Id, (inputRecord = customer_13.Customer, new Customer_1(inputRecord.Id, inputRecord.PixieLogin, inputRecord.FirstName, inputRecord.LastName, inputRecord.Status, 3, inputRecord.CompanyInformation, inputRecord.HasVirtualNumbers, inputRecord.HasOverDueInvoice, inputRecord.Created)), customer_13.Loading);
                    }
                    else {
                        return customer_13;
                    }
                }, state.Customers) : filter_1((customer_14) => (customer_14.Id !== id_2), state.Customers), state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, new changeUserStatus_1(1), state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
            }
        }
        case 26: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, Update_createNewCustomerForm, state.ConfirmChangeUserStatus, true, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_fromAsync(Api_getCountryList)];
        }
        case 27: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, false, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 28: {
            const form_4 = FormState_Form_validate((form_3) => Update_validate(form_3), FormState_Form_withNoErrors(FormState_Form_enableValiadateAll(state.NewCustomerForm)));
            const nextState_2 = Update_withForm(form_4, state);
            if (FormState_Form_isValid(form_4)) {
                return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, false, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_fromAsync(Api_addCustomer(state.NewCustomerForm.State))];
            }
            else {
                return [nextState_2, Cmd_none()];
            }
        }
        case 14: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_8) => Update_validate(form_8), FormState_Form_withState((inputRecord_1 = state.NewCustomerForm.State, new NewCustomerFormState(msg.fields[0], inputRecord_1.LastName, inputRecord_1.CompanyName, inputRecord_1.Email, inputRecord_1.PhoneNumber, inputRecord_1.Country, inputRecord_1.City, inputRecord_1.PostCode, inputRecord_1.AddressLine1, inputRecord_1.AddressLine2, inputRecord_1.Vat, inputRecord_1.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 15: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_12) => Update_validate(form_12), FormState_Form_withState((inputRecord_2 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_2.FirstName, msg.fields[0], inputRecord_2.CompanyName, inputRecord_2.Email, inputRecord_2.PhoneNumber, inputRecord_2.Country, inputRecord_2.City, inputRecord_2.PostCode, inputRecord_2.AddressLine1, inputRecord_2.AddressLine2, inputRecord_2.Vat, inputRecord_2.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 16: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_16) => Update_validate(form_16), FormState_Form_withState((inputRecord_3 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_3.FirstName, inputRecord_3.LastName, msg.fields[0], inputRecord_3.Email, inputRecord_3.PhoneNumber, inputRecord_3.Country, inputRecord_3.City, inputRecord_3.PostCode, inputRecord_3.AddressLine1, inputRecord_3.AddressLine2, inputRecord_3.Vat, inputRecord_3.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 17: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_20) => Update_validate(form_20), FormState_Form_withState((inputRecord_4 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_4.FirstName, inputRecord_4.LastName, inputRecord_4.CompanyName, msg.fields[0], inputRecord_4.PhoneNumber, inputRecord_4.Country, inputRecord_4.City, inputRecord_4.PostCode, inputRecord_4.AddressLine1, inputRecord_4.AddressLine2, inputRecord_4.Vat, inputRecord_4.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 18: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_24) => Update_validate(form_24), FormState_Form_withState((inputRecord_5 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_5.FirstName, inputRecord_5.LastName, inputRecord_5.CompanyName, inputRecord_5.Email, msg.fields[0], inputRecord_5.Country, inputRecord_5.City, inputRecord_5.PostCode, inputRecord_5.AddressLine1, inputRecord_5.AddressLine2, inputRecord_5.Vat, inputRecord_5.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 19: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_28) => Update_validate(form_28), FormState_Form_withState((inputRecord_6 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_6.FirstName, inputRecord_6.LastName, inputRecord_6.CompanyName, inputRecord_6.Email, inputRecord_6.PhoneNumber, msg.fields[0], inputRecord_6.City, inputRecord_6.PostCode, inputRecord_6.AddressLine1, inputRecord_6.AddressLine2, inputRecord_6.Vat, inputRecord_6.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 20: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_32) => Update_validate(form_32), FormState_Form_withState((inputRecord_7 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_7.FirstName, inputRecord_7.LastName, inputRecord_7.CompanyName, inputRecord_7.Email, inputRecord_7.PhoneNumber, inputRecord_7.Country, msg.fields[0], inputRecord_7.PostCode, inputRecord_7.AddressLine1, inputRecord_7.AddressLine2, inputRecord_7.Vat, inputRecord_7.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 21: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_36) => Update_validate(form_36), FormState_Form_withState((inputRecord_8 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_8.FirstName, inputRecord_8.LastName, inputRecord_8.CompanyName, inputRecord_8.Email, inputRecord_8.PhoneNumber, inputRecord_8.Country, inputRecord_8.City, msg.fields[0], inputRecord_8.AddressLine1, inputRecord_8.AddressLine2, inputRecord_8.Vat, inputRecord_8.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 22: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_40) => Update_validate(form_40), FormState_Form_withState((inputRecord_9 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_9.FirstName, inputRecord_9.LastName, inputRecord_9.CompanyName, inputRecord_9.Email, inputRecord_9.PhoneNumber, inputRecord_9.Country, inputRecord_9.City, inputRecord_9.PostCode, msg.fields[0], inputRecord_9.AddressLine2, inputRecord_9.Vat, inputRecord_9.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 23: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_44) => Update_validate(form_44), FormState_Form_withState((inputRecord_10 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_10.FirstName, inputRecord_10.LastName, inputRecord_10.CompanyName, inputRecord_10.Email, inputRecord_10.PhoneNumber, inputRecord_10.Country, inputRecord_10.City, inputRecord_10.PostCode, inputRecord_10.AddressLine1, msg.fields[0], inputRecord_10.Vat, inputRecord_10.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 24: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_48) => Update_validate(form_48), FormState_Form_withState((inputRecord_11 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_11.FirstName, inputRecord_11.LastName, inputRecord_11.CompanyName, inputRecord_11.Email, inputRecord_11.PhoneNumber, inputRecord_11.Country, inputRecord_11.City, inputRecord_11.PostCode, inputRecord_11.AddressLine1, inputRecord_11.AddressLine2, msg.fields[0], inputRecord_11.Password)), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 25: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, FormState_Form_validate((form_52) => Update_validate(form_52), FormState_Form_withState((inputRecord_12 = state.NewCustomerForm.State, new NewCustomerFormState(inputRecord_12.FirstName, inputRecord_12.LastName, inputRecord_12.CompanyName, inputRecord_12.Email, inputRecord_12.PhoneNumber, inputRecord_12.Country, inputRecord_12.City, inputRecord_12.PostCode, inputRecord_12.AddressLine1, inputRecord_12.AddressLine2, inputRecord_12.Vat, msg.fields[0])), state.NewCustomerForm)), state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 34: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, append(singleton_1(new Country(-1, "", "Select country")), msg.fields[0]), state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 35: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, Update_createNewCustomerForm, state.ConfirmChangeUserStatus, false, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_batch(singleton_1(Api_loadCustomerCmd(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION, 1, 20, "", void 0, state.ShowDeleted)))];
        }
        case 36: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, Update_createNewCustomerForm, state.ConfirmChangeUserStatus, false, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
        case 37: {
            return [state, Cmd_none()];
        }
        case 38: {
            const updatedState_4 = new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, msg.fields[0], false, state.ShowAcceptingConfirmation, state.Exporting);
            return [updatedState_4, Api_loadCustomerCmd(state.Sorting.Field, state.Sorting.Direction, value_10(state.Paging).CurrentPage, 20, state.Filter, state.StatusFilter, updatedState_4.ShowDeleted)];
        }
        case 39: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, "", void 0, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, false, true, state.ShowAcceptingConfirmation, state.Exporting), Api_getOverdue(state.Sorting.Field, state.Sorting.Direction, value_10(state.Paging).CurrentPage, 20, state.Filter, state.StatusFilter, false)];
        }
        case 40: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, true), Api_exportCmd];
        }
        case 41: {
            ByteArrayExtensions_SaveFileAs_72B26259(msg.fields[0], (arg10_1 = ((copyOfStruct = now(), toString(copyOfStruct, "yyyy-MM-dd_HH-mm-ss"))), toText(printf("Customers export %s.csv"))(arg10_1)));
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, false), Cmd_none()];
        }
        case 42: {
            return [new State(state.Customers, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, false), Cmd_batch(singleton_1(toastError("Something went wrong")))];
        }
        default: {
            const x = msg.fields[0];
            const s = State_init(x.Page, x.PageSize, x.Total);
            return [new State(map((x_1) => (new CustomerData(x_1.Id, x_1, false)), x.Items), s, state.Sorting, false, state.Error, state.Filter, state.StatusFilter, state.Debouncer, state.NewCustomerForm, state.ConfirmChangeUserStatus, state.ShowNewCustomerForm, state.CountryList, state.ShowDeleted, state.ShowOverdue, state.ShowAcceptingConfirmation, state.Exporting), Cmd_none()];
        }
    }
}

