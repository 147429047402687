import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { map, empty, singleton, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { createElement } from "react";
import { comparePrimitives, createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { isEmpty, filter, sortBy, singleton as singleton_1, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { Msg } from "./Model.js";
import { toString, compare } from "../../.fable/fable-library.3.2.9/Date.js";
import { defaultArg } from "../../.fable/fable-library.3.2.9/Option.js";
import { tryFind } from "../../.fable/fable-library.3.2.9/Map.js";

export function virtualNumbers(virtualNumbersInputProps) {
    const dispatch = virtualNumbersInputProps.dispatch;
    const model = virtualNumbersInputProps.model;
    const props_55 = ofArray([["style", {
        maxWidth: 800,
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let props_10, elms, elms_1, props_7, props_5, props_3;
        return append(singleton((props_10 = ofArray([["className", "is-marginless"], ["className", "is-paddingless"], ["children", Interop_reactApi.Children.toArray([(elms = singleton_1(createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-6"], ["children", "Campaigns in progress"]]))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (elms_1 = singleton_1((props_7 = ofArray([["className", "is-marginless"], ["className", "is-paddingless"], ["children", Interop_reactApi.Children.toArray([(props_5 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_3 = toList(delay(() => append(singleton(["className", "is-pulled-right"]), delay(() => append(singleton(["className", "is-success"]), delay(() => append(singleton(["className", "is-small"]), delay(() => append(singleton(["className", "is-outlined"]), delay(() => append(singleton(["className", "is-rounded"]), delay(() => append(model.IsLoading ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["children", "Refresh"]), delay(() => singleton(["onClick", (_arg1) => {
            dispatch(new Msg(5));
        }]))))))))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_3))))])]), createElement("span", createObj(Helpers_combineClasses("", props_5))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_7))))), createElement("div", {
            className: "column",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_10))))), delay(() => {
            let props_14, elms_2, props_33;
            const onGoingCampaigns = sortBy((x_3) => x_3.CustomerId, filter((x_1) => {
                if ((x_1.State === 1) ? true : (x_1.State === 20)) {
                    return x_1.Queue !== x_1.Sent;
                }
                else {
                    return false;
                }
            }, model.CampaignStats), {
                Compare: (x_4, y) => comparePrimitives(x_4, y),
            });
            const scheduledCampaigns = sortBy((x_5) => x_5.ScheduledAtUtcTime, filter((x_2) => (x_2.State === 0), model.CampaignStats), {
                Compare: (x_6, y_1) => compare(x_6, y_1),
            });
            return append(isEmpty(onGoingCampaigns) ? singleton((props_14 = ofArray([["style", {
                paddingTop: 0,
            }], ["children", "No campaign in progress"]]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))) : singleton((elms_2 = singleton_1((props_33 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
                children: Interop_reactApi.Children.toArray([createElement("th", {
                    children: "CustomerId",
                }), createElement("th", {
                    children: "CampaignId",
                }), createElement("th", {
                    children: "State",
                }), createElement("th", {
                    children: "Queue",
                }), createElement("th", {
                    children: "Sent",
                }), createElement("th", {
                    children: "Remaining",
                }), createElement("th", {
                    children: "",
                })]),
            }), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((item) => {
                    let children_2, stat, text, message, isBusy, props_12;
                    const children_4 = ofArray([createElement("td", {
                        style: {
                            textAlign: "center",
                            maxWidth: 600,
                        },
                        children: item.CustomerId,
                    }), createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: item.CampaignId,
                    }), createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: (item.State === 20) ? "Paused" : "Working",
                    }), createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: item.Queue,
                    }), createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: item.Sent,
                    }), createElement("td", {
                        style: {
                            textAlign: "center",
                        },
                        children: item.Queue - item.Sent,
                    }), (children_2 = singleton_1((stat = item, (text = ((stat.State === 20) ? "Unpause" : "Pause"), (message = ((stat.State === 20) ? (new Msg(2, stat.CampaignId)) : (new Msg(1, stat.CampaignId))), (isBusy = defaultArg(tryFind(stat.CampaignId, model.BusyCampaign), false), (props_12 = toList(delay(() => append(singleton(["className", "is-primary"]), delay(() => append(singleton(["className", "is-outlined"]), delay(() => append(isBusy ? singleton(["className", "is-loading"]) : empty(), delay(() => append(singleton(["children", text]), delay(() => singleton(["onClick", (x) => {
                        x.preventDefault();
                        dispatch(message);
                    }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_12))))))))), createElement("td", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    }))]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                    });
                }, onGoingCampaigns))))),
            })])]]), createElement("table", createObj(Helpers_combineClasses("table", props_33))))), createElement("div", {
                className: "column",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), delay(() => {
                let props_39, elms_3;
                return append(singleton((props_39 = ofArray([["className", "is-marginless"], ["className", "is-paddingless"], ["children", Interop_reactApi.Children.toArray([(elms_3 = singleton_1(createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-6"], ["children", "Scheduled campaigns"]]))))), createElement("div", {
                    className: "column",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_39))))), delay(() => {
                    let elms_4, props_52;
                    return isEmpty(scheduledCampaigns) ? singleton(createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["children", "No scheduled campaigns"]))))) : singleton((elms_4 = singleton_1((props_52 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
                        children: Interop_reactApi.Children.toArray([createElement("th", {
                            children: "Scheduled at (UTC)",
                        }), createElement("th", {
                            children: "Company ID",
                        }), createElement("th", {
                            className: "has-text-centered",
                            children: "Recipients",
                        })]),
                    }), createElement("tbody", {
                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((item_1) => {
                            const children_8 = ofArray([createElement("td", {
                                children: toString(item_1.ScheduledAtUtcTime, "yyyy-MM-dd HH:mm:ss"),
                            }), createElement("td", {
                                children: item_1.CompanyId,
                            }), createElement("td", {
                                className: "has-text-centered",
                                children: item_1.Recipients,
                            })]);
                            return createElement("tr", {
                                children: Interop_reactApi.Children.toArray(Array.from(children_8)),
                            });
                        }, scheduledCampaigns))))),
                    })])]]), createElement("table", createObj(Helpers_combineClasses("table", props_52))))), createElement("div", {
                        className: "column",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                    })));
                }));
            }));
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_55)));
}

export function render(model, dispatch) {
    return createElement(virtualNumbers, {
        model: model,
        dispatch: dispatch,
    });
}

