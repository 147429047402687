import { FSharpRef, Record } from "../fable-library.3.2.9/Types.js";
import { record_type, class_type, list_type, string_type } from "../fable-library.3.2.9/Reflection.js";
import { ofArray, collect, map as map_1, singleton, tail, cons, empty, head, isEmpty } from "../fable-library.3.2.9/List.js";
import { FSharpResult$2 } from "../fable-library.3.2.9/Choice.js";
import { tryParse } from "../fable-library.3.2.9/Int32.js";
import { empty as empty_1, ofSeq, tryFind } from "../fable-library.3.2.9/Map.js";
import { some, bind } from "../fable-library.3.2.9/Option.js";
import { equalsWith } from "../fable-library.3.2.9/Array.js";
import { comparePrimitives } from "../fable-library.3.2.9/Util.js";
import { ofFunc, tuple } from "./prelude.fs.js";
import { map as map_2, choose } from "../fable-library.3.2.9/Seq.js";
import { substring } from "../fable-library.3.2.9/String.js";

export class State$1 extends Record {
    constructor(visited, unvisited, args, value) {
        super();
        this.visited = visited;
        this.unvisited = unvisited;
        this.args = args;
        this.value = value;
    }
}

export function State$1$reflection(gen0) {
    return record_type("Elmish.UrlParser.State`1", [gen0], State$1, () => [["visited", list_type(string_type)], ["unvisited", list_type(string_type)], ["args", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type])], ["value", gen0]]);
}

export function StateModule_mkState(visited, unvisited, args, value) {
    return new State$1(visited, unvisited, args, value);
}

export function StateModule_map(f, _arg1) {
    return new State$1(_arg1.visited, _arg1.unvisited, _arg1.args, f(_arg1.value));
}

export function custom(tipe, stringToSomething) {
    return (_arg1) => {
        const unvisited = _arg1.unvisited;
        if (!isEmpty(unvisited)) {
            const next = head(unvisited);
            const matchValue = stringToSomething(next);
            if (matchValue.tag === 1) {
                return empty();
            }
            else {
                return singleton(StateModule_mkState(cons(next, _arg1.visited), tail(unvisited), _arg1.args, _arg1.value(matchValue.fields[0])));
            }
        }
        else {
            return empty();
        }
    };
}

export function str(state) {
    return custom("string", (arg0) => (new FSharpResult$2(0, arg0)))(state);
}

export function i32(state) {
    return custom("i32", (arg) => {
        let _arg1;
        let outArg = 0;
        _arg1 = [tryParse(arg, 511, false, 32, new FSharpRef(() => outArg, (v) => {
            outArg = (v | 0);
        })), outArg];
        return _arg1[0] ? (new FSharpResult$2(0, _arg1[1])) : (new FSharpResult$2(1, "Can\u0027t parse int"));
    })(state);
}

export function s(str_1) {
    return (_arg1) => {
        const unvisited = _arg1.unvisited;
        if (!isEmpty(unvisited)) {
            const next = head(unvisited);
            if (next === str_1) {
                return singleton(StateModule_mkState(cons(next, _arg1.visited), tail(unvisited), _arg1.args, _arg1.value));
            }
            else {
                return empty();
            }
        }
        else {
            return empty();
        }
    };
}

export function map(subValue, parse_1) {
    return (_arg1) => map_1((arg10$0040) => StateModule_map(_arg1.value, arg10$0040), parse_1(new State$1(_arg1.visited, _arg1.unvisited, _arg1.args, subValue)));
}

export function oneOf(parsers, state) {
    return collect((parser) => parser(state), parsers);
}

export function top(state) {
    return singleton(state);
}

export function customParam(key, func) {
    return (_arg1) => {
        const args = _arg1.args;
        return singleton(StateModule_mkState(_arg1.visited, _arg1.unvisited, args, _arg1.value(func(tryFind(key, args)))));
    };
}

export function stringParam(name) {
    return customParam(name, (x) => x);
}

export const intParamHelp = (option) => bind((value) => {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(value, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}, option);

export function intParam(name) {
    return customParam(name, intParamHelp);
}

export function parseHelp(states_mut) {
    parseHelp:
    while (true) {
        const states = states_mut;
        if (!isEmpty(states)) {
            const state = head(states);
            const matchValue = state.unvisited;
            let pattern_matching_result;
            if (!isEmpty(matchValue)) {
                if (head(matchValue) === "") {
                    if (isEmpty(tail(matchValue))) {
                        pattern_matching_result = 1;
                    }
                    else {
                        pattern_matching_result = 2;
                    }
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return some(state.value);
                }
                case 1: {
                    return some(state.value);
                }
                case 2: {
                    states_mut = tail(states);
                    continue parseHelp;
                }
            }
        }
        else {
            return void 0;
        }
        break;
    }
}

export function splitUrl(url) {
    const matchValue = ofArray(url.split("/"));
    let pattern_matching_result, segments, segments_1;
    if (!isEmpty(matchValue)) {
        if (head(matchValue) === "") {
            pattern_matching_result = 0;
            segments = tail(matchValue);
        }
        else {
            pattern_matching_result = 1;
            segments_1 = matchValue;
        }
    }
    else {
        pattern_matching_result = 1;
        segments_1 = matchValue;
    }
    switch (pattern_matching_result) {
        case 0: {
            return segments;
        }
        case 1: {
            return segments_1;
        }
    }
}

export function parse(parser, url, args) {
    return parseHelp(parser(new State$1(empty(), splitUrl(url), args, (x) => x)));
}

export function toKeyValuePair(segment) {
    const matchValue = segment.split("=");
    if ((!equalsWith((x, y) => comparePrimitives(x, y), matchValue, null)) ? (matchValue.length === 2) : false) {
        const value = matchValue[1];
        return tuple(ofFunc(decodeURIComponent, matchValue[0]), ofFunc(decodeURIComponent, value));
    }
    else {
        return void 0;
    }
}

export function parseParams(querystring) {
    if (querystring.length > 1) {
        return ofSeq(choose((x) => x, map_2((segment) => toKeyValuePair(segment), substring(querystring, 1).split("\u0026"))));
    }
    else {
        return empty_1();
    }
}

export function parsePath(parser, location) {
    return parse(parser, location.pathname, parseParams(location.search));
}

export function parseHash(parser, location) {
    let patternInput;
    const hash = (location.hash.length > 1) ? substring(location.hash, 1) : "";
    if (hash.indexOf("?") >= 0) {
        const h = substring(hash, 0, hash.indexOf("?"));
        patternInput = [h, substring(hash, h.length)];
    }
    else {
        patternInput = [hash, "?"];
    }
    return parse(parser, patternInput[0], parseParams(patternInput[1]));
}

