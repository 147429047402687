import { Option, ISize, column as column_1 } from "../../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import { TextAlignment_Option, Size_ISize, Modifier_IModifier, Color_IColor, Screen } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { empty, singleton, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { Option as Option_1, h3 } from "../../.fable/Fulma.2.14.1/Elements/Heading.fs.js";
import { box$0027 } from "../../.fable/Fulma.2.14.1/Elements/Box.fs.js";
import * as react from "react";
import { div } from "../../.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";
import { div as div_1 } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { Option as Option_2, IInputType, input } from "../../.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { Msg } from "./Model.js";
import { HTMLAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Option as Option_3, help } from "../../.fable/Fulma.2.14.1/Elements/Form/Help.fs.js";
import { Option as Option_4, button } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { body, Option as Option_5, hero } from "../../.fable/Fulma.2.14.1/Layouts/Hero.fs.js";
import { Option as Option_6, container } from "../../.fable/Fulma.2.14.1/Layouts/Container.fs.js";

export function column(state, dispatch) {
    return column_1(ofArray([new Option(0, new Screen(0), new ISize(8)), new Option(1, new Screen(0), new ISize(8))]), ofArray([h3(singleton(new Option_1(10, singleton(new Modifier_IModifier(1, new Color_IColor(14))))))(singleton("Login")), box$0027(empty(), singleton(react.createElement("form", {}, div(empty(), singleton(div_1(empty(), singleton(input(ofArray([new Option_2(1, new IInputType(8)), new Option_2(0, new Size_ISize(2)), new Option_2(12, "Your Login"), new Option_2(11, state.Login), new Option_2(13, (x) => {
        dispatch(new Msg(0, Browser_Types_Event__Event_get_Value(x)));
    }), new Option_2(15, singleton(new HTMLAttr(55, true)))])))))), div(empty(), ofArray([div_1(empty(), singleton(input(ofArray([new Option_2(1, new IInputType(1)), new Option_2(0, new Size_ISize(2)), new Option_2(11, state.Password), new Option_2(13, (x_1) => {
        dispatch(new Msg(1, Browser_Types_Event__Event_get_Value(x_1)));
    }), new Option_2(12, "Your Password")])))), help(singleton(new Option_3(2, new Color_IColor(8))), singleton(state.Error))])), button(ofArray([new Option_4(0, new Color_IColor(4)), new Option_4(2), new Option_4(13, state.LoggingInProgress), new Option_4(19, "is-large is-block"), new Option_4(18, (x_2) => {
        dispatch(new Msg(2));
        x_2.preventDefault();
    })]), singleton("Login")))))]));
}

export function view(model, dispatch) {
    return hero(singleton(new Option_5(5)), singleton(body(empty(), singleton(container(singleton(new Option_6(5, singleton(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0))))), singleton(column(model, dispatch)))))));
}

