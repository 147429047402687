import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { empty, singleton, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option, modal } from "../../.fable/Fulma.2.14.1/Components/Modal.fs.js";
import { DOMAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Color_IColor, Common_GenericOption } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { Option as Option_1, delete$ } from "../../.fable/Fulma.2.14.1/Elements/Delete.fs.js";
import { Option as Option_2, button } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";

export function renderKeyValue(key, value) {
    const props_4 = ofArray([["style", {
        height: "initial",
        marginBottom: 0,
    }], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-two-fifths"], ["className", "has-text-weight-bold"], ["children", key]])))), createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "is-one-fifth"], ["children", value]]))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_4)));
}

export function confirmationDialog(title, content, confirm, closeDisplay, buttonName) {
    const cancelDialog = (_arg1) => {
        closeDisplay();
    };
    return modal(singleton(new Option(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, cancelDialog)))), empty()), Card_card(empty(), ofArray([Card_head(singleton(new Common_GenericOption(1, singleton(["style", {
        textAlign: "left",
    }]))), ofArray([Card_title(empty(), singleton(title)), delete$(singleton(new Option_1(3, cancelDialog)), empty())])), Card_body(singleton(new Common_GenericOption(1, singleton(["style", {
        textAlign: "left",
    }]))), singleton(content)), Card_foot(singleton(new Common_GenericOption(1, singleton(["style", {
        justifyContent: "flex-end",
    }]))), ofArray([button(singleton(new Option_2(18, cancelDialog)), singleton("Cancel")), button(ofArray([new Option_2(0, new Color_IColor(8)), new Option_2(18, (_arg2) => {
        confirm();
    })]), singleton(buttonName))]))]))]));
}

