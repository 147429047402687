import { Record, Union } from "../fable-library.3.2.9/Types.js";
import { list_type, record_type, bool_type, class_type, option_type, string_type, union_type } from "../fable-library.3.2.9/Reflection.js";
import { fromSeconds } from "../fable-library.3.2.9/TimeSpan.js";
import { value as value_1, map as map_1, some } from "../fable-library.3.2.9/Option.js";
import { newGuid } from "../fable-library.3.2.9/Guid.js";
import { filter, cons, empty as empty_1, ofArray, map, singleton } from "../fable-library.3.2.9/List.js";
import * as react from "react";
import { join } from "../fable-library.3.2.9/String.js";
import { keyValueList } from "../fable-library.3.2.9/MapUtil.js";
import { empty, singleton as singleton_1, append, delay as delay_1, toList } from "../fable-library.3.2.9/Seq.js";
import { DOMAttr, HTMLAttr } from "../Fable.React.7.4.1/Fable.React.Props.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { ProgramModule_map } from "../Fable.Elmish.3.1.0/program.fs.js";
import { Cmd_batch, Cmd_OfPromise_either, Cmd_none, Cmd_map } from "../Fable.Elmish.3.1.0/cmd.fs.js";
import "./css/toast-base.css";
import "./css/toast-minimal.css";



export class Status extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Warning", "Error", "Info"];
    }
}

export function Status$reflection() {
    return union_type("Thoth.Elmish.Toast.Status", [], Status, () => [[], [], [], []]);
}

export class Position extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BottomRight", "BottomLeft", "BottomCenter", "TopRight", "TopLeft", "TopCenter"];
    }
}

export function Position$reflection() {
    return union_type("Thoth.Elmish.Toast.Position", [], Position, () => [[], [], [], [], [], []]);
}

export class Builder$2 extends Record {
    constructor(Message, Title, Icon, Position, Delay, DismissOnClick, WithCloseButton) {
        super();
        this.Message = Message;
        this.Title = Title;
        this.Icon = Icon;
        this.Position = Position;
        this.Delay = Delay;
        this.DismissOnClick = DismissOnClick;
        this.WithCloseButton = WithCloseButton;
    }
}

export function Builder$2$reflection(gen0, gen1) {
    return record_type("Thoth.Elmish.Toast.Builder`2", [gen0, gen1], Builder$2, () => [["Message", string_type], ["Title", option_type(string_type)], ["Icon", option_type(gen0)], ["Position", Position$reflection()], ["Delay", option_type(class_type("System.TimeSpan"))], ["DismissOnClick", bool_type], ["WithCloseButton", bool_type]]);
}

export function Builder$2_Empty() {
    return new Builder$2("", void 0, void 0, new Position(1), fromSeconds(3), false, false);
}

export class Toast$1 extends Record {
    constructor(Guid, Message, Title, Icon, Position, Delay, Status, DismissOnClick, WithCloseButton) {
        super();
        this.Guid = Guid;
        this.Message = Message;
        this.Title = Title;
        this.Icon = Icon;
        this.Position = Position;
        this.Delay = Delay;
        this.Status = Status;
        this.DismissOnClick = DismissOnClick;
        this.WithCloseButton = WithCloseButton;
    }
}

export function Toast$1$reflection(gen0) {
    return record_type("Thoth.Elmish.Toast.Toast`1", [gen0], Toast$1, () => [["Guid", class_type("System.Guid")], ["Message", string_type], ["Title", option_type(string_type)], ["Icon", option_type(gen0)], ["Position", Position$reflection()], ["Delay", option_type(class_type("System.TimeSpan"))], ["Status", Status$reflection()], ["DismissOnClick", bool_type], ["WithCloseButton", bool_type]]);
}

export function message(msg) {
    const inputRecord = Builder$2_Empty();
    return new Builder$2(msg, inputRecord.Title, inputRecord.Icon, inputRecord.Position, inputRecord.Delay, inputRecord.DismissOnClick, inputRecord.WithCloseButton);
}

export function title(title_1, builder) {
    return new Builder$2(builder.Message, title_1, builder.Icon, builder.Position, builder.Delay, builder.DismissOnClick, builder.WithCloseButton);
}

export function position(pos, builder) {
    return new Builder$2(builder.Message, builder.Title, builder.Icon, pos, builder.Delay, builder.DismissOnClick, builder.WithCloseButton);
}

export function icon(icon_1, builder) {
    return new Builder$2(builder.Message, builder.Title, some(icon_1), builder.Position, builder.Delay, builder.DismissOnClick, builder.WithCloseButton);
}

export function timeout(delay, builder) {
    return new Builder$2(builder.Message, builder.Title, builder.Icon, builder.Position, delay, builder.DismissOnClick, builder.WithCloseButton);
}

export function noTimeout(builder) {
    return new Builder$2(builder.Message, builder.Title, builder.Icon, builder.Position, void 0, builder.DismissOnClick, builder.WithCloseButton);
}

export function dismissOnClick(builder) {
    return new Builder$2(builder.Message, builder.Title, builder.Icon, builder.Position, builder.Delay, true, builder.WithCloseButton);
}

export function withCloseButton(builder) {
    return new Builder$2(builder.Message, builder.Title, builder.Icon, builder.Position, builder.Delay, builder.DismissOnClick, true);
}

function triggerEvent(builder, status, dispatch) {
    const detail = {
        detail: new Toast$1(newGuid(), builder.Message, builder.Title, builder.Icon, builder.Position, builder.Delay, status, builder.DismissOnClick, builder.WithCloseButton),
    };
    void window.dispatchEvent(new CustomEvent("thoth_elmish_toast_notify_event", detail));
}

export function success(builder) {
    return singleton((dispatch) => {
        triggerEvent(builder, new Status(0), dispatch);
    });
}

export function warning(builder) {
    return singleton((dispatch) => {
        triggerEvent(builder, new Status(1), dispatch);
    });
}

export function error(builder) {
    return singleton((dispatch) => {
        triggerEvent(builder, new Status(2), dispatch);
    });
}

export function info(builder) {
    return singleton((dispatch) => {
        triggerEvent(builder, new Status(3), dispatch);
    });
}

export class Program_Notifiable$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Add", "Remove", "UserMsg", "OnError"];
    }
}

export function Program_Notifiable$2$reflection(gen0, gen1) {
    return union_type("Thoth.Elmish.Toast.Program.Notifiable`2", [gen0, gen1], Program_Notifiable$2, () => [[["Item", Toast$1$reflection(gen0)]], [["Item", Toast$1$reflection(gen0)]], [["Item", gen1]], [["Item", class_type("System.Exception")]]]);
}

export class Program_Model$2 extends Record {
    constructor(UserModel, Toasts_BL, Toasts_BC, Toasts_BR, Toasts_TL, Toasts_TC, Toasts_TR) {
        super();
        this.UserModel = UserModel;
        this.Toasts_BL = Toasts_BL;
        this.Toasts_BC = Toasts_BC;
        this.Toasts_BR = Toasts_BR;
        this.Toasts_TL = Toasts_TL;
        this.Toasts_TC = Toasts_TC;
        this.Toasts_TR = Toasts_TR;
    }
}

export function Program_Model$2$reflection(gen0, gen1) {
    return record_type("Thoth.Elmish.Toast.Program.Model`2", [gen0, gen1], Program_Model$2, () => [["UserModel", gen1], ["Toasts_BL", list_type(Toast$1$reflection(gen0))], ["Toasts_BC", list_type(Toast$1$reflection(gen0))], ["Toasts_BR", list_type(Toast$1$reflection(gen0))], ["Toasts_TL", list_type(Toast$1$reflection(gen0))], ["Toasts_TC", list_type(Toast$1$reflection(gen0))], ["Toasts_TR", list_type(Toast$1$reflection(gen0))]]);
}

function Program_viewToastWrapper(classPosition, render_1, toasts, dispatch) {
    return react.createElement("div", {
        className: "toast-wrapper " + classPosition,
    }, ...map((n) => {
        let arg10_2, o_1, matchValue_1, arg10_1, o_5, arg20, arg10, o_3;
        const title_1 = map_1((arg00) => render_1.Title(arg00), n.Title);
        const containerClass = join(" ", ["toast-container", n.DismissOnClick ? "dismiss-on-click" : "", render_1.StatusToColor(n.Status)]);
        return react.createElement("div", keyValueList(toList(delay_1(() => append(singleton_1(new HTMLAttr(64, containerClass)), delay_1(() => (n.DismissOnClick ? singleton_1(new DOMAttr(40, (_arg2) => {
            dispatch(new Program_Notifiable$2(1, n));
        })) : empty()))))), 1), (arg10_2 = render_1.StatusToColor(n.Status), render_1.Toast(ofArray([(o_1 = (n.WithCloseButton ? render_1.CloseButton((_arg1) => {
            dispatch(new Program_Notifiable$2(1, n));
        }) : (void 0)), (o_1 == null) ? null : o_1), (matchValue_1 = n.Icon, (matchValue_1 == null) ? ((arg10_1 = render_1.Message(n.Message), render_1.SingleLayout((o_5 = title_1, (o_5 == null) ? null : o_5), arg10_1))) : ((arg20 = render_1.Message(n.Message), (arg10 = ((o_3 = title_1, (o_3 == null) ? null : o_3)), render_1.SplittedLayout(render_1.Icon(value_1(matchValue_1)), arg10, arg20)))))]), arg10_2)));
    }, toasts));
}

function Program_view(render_1, model, dispatch) {
    return react.createElement("div", {
        className: "elmish-toast",
    }, Program_viewToastWrapper("toast-wrapper-bottom-left", render_1, model.Toasts_BL, dispatch), Program_viewToastWrapper("toast-wrapper-bottom-center", render_1, model.Toasts_BC, dispatch), Program_viewToastWrapper("toast-wrapper-bottom-right", render_1, model.Toasts_BR, dispatch), Program_viewToastWrapper("toast-wrapper-top-left", render_1, model.Toasts_TL, dispatch), Program_viewToastWrapper("toast-wrapper-top-center", render_1, model.Toasts_TC, dispatch), Program_viewToastWrapper("toast-wrapper-top-right", render_1, model.Toasts_TR, dispatch));
}

function Program_delayedCmd(notification) {
    const matchValue = notification.Delay;
    if (matchValue == null) {
        throw (new Error("No delay attached to notification can\u0027t delayed it. `delayedCmd` should not have been called by the program"));
    }
    else {
        const delay = matchValue;
        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((new Promise(resolve => setTimeout(resolve, (~(~delay))))).then((() => (Promise.resolve(notification)))))));
    }
}

export function Program_withToast(renderer, program) {
    return ProgramModule_map((init, arg_1) => {
        let tupledArg;
        const tupledArg_1 = init(arg_1);
        tupledArg = [tupledArg_1[0], Cmd_map((arg0_3) => (new Program_Notifiable$2(2, arg0_3)), tupledArg_1[1])];
        return [new Program_Model$2(tupledArg[0], empty_1(), empty_1(), empty_1(), empty_1(), empty_1(), empty_1()), tupledArg[1]];
    }, (update, msg, model) => {
        let patternInput_1;
        switch (msg.tag) {
            case 0: {
                const newToast = msg.fields[0];
                const cmd_1 = (newToast.Delay == null) ? Cmd_none() : Cmd_OfPromise_either((notification) => Program_delayedCmd(notification), newToast, (arg0_1) => (new Program_Notifiable$2(1, arg0_1)), (arg0_2) => (new Program_Notifiable$2(3, arg0_2)));
                const matchValue_1 = newToast.Position;
                switch (matchValue_1.tag) {
                    case 2: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, cons(newToast, model.Toasts_BC), model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), cmd_1];
                        break;
                    }
                    case 0: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, cons(newToast, model.Toasts_BR), model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), cmd_1];
                        break;
                    }
                    case 4: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, cons(newToast, model.Toasts_TL), model.Toasts_TC, model.Toasts_TR), cmd_1];
                        break;
                    }
                    case 5: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, cons(newToast, model.Toasts_TC), model.Toasts_TR), cmd_1];
                        break;
                    }
                    case 3: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, cons(newToast, model.Toasts_TR)), cmd_1];
                        break;
                    }
                    default: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, cons(newToast, model.Toasts_BL), model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), cmd_1];
                    }
                }
                break;
            }
            case 1: {
                const toast = msg.fields[0];
                const matchValue_2 = toast.Position;
                switch (matchValue_2.tag) {
                    case 2: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, filter((item_1) => (item_1.Guid !== toast.Guid), model.Toasts_BC), model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), Cmd_none()];
                        break;
                    }
                    case 0: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, filter((item_2) => (item_2.Guid !== toast.Guid), model.Toasts_BR), model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), Cmd_none()];
                        break;
                    }
                    case 4: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, filter((item_3) => (item_3.Guid !== toast.Guid), model.Toasts_TL), model.Toasts_TC, model.Toasts_TR), Cmd_none()];
                        break;
                    }
                    case 5: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, filter((item_4) => (item_4.Guid !== toast.Guid), model.Toasts_TC), model.Toasts_TR), Cmd_none()];
                        break;
                    }
                    case 3: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, filter((item_5) => (item_5.Guid !== toast.Guid), model.Toasts_TR)), Cmd_none()];
                        break;
                    }
                    default: {
                        patternInput_1 = [new Program_Model$2(model.UserModel, filter((item) => (item.Guid !== toast.Guid), model.Toasts_BL), model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), Cmd_none()];
                    }
                }
                break;
            }
            case 3: {
                console.error(some(msg.fields[0].message));
                patternInput_1 = [model, Cmd_none()];
                break;
            }
            default: {
                const patternInput = update(msg.fields[0], model.UserModel);
                patternInput_1 = [new Program_Model$2(patternInput[0], model.Toasts_BL, model.Toasts_BC, model.Toasts_BR, model.Toasts_TL, model.Toasts_TC, model.Toasts_TR), Cmd_map((arg0) => (new Program_Notifiable$2(2, arg0)), patternInput[1])];
            }
        }
        return [patternInput_1[0], patternInput_1[1]];
    }, (view$0027, model_4, dispatch_1) => react.createElement(react.Fragment, {}, Program_view(renderer, model_4, dispatch_1), view$0027(model_4.UserModel, (arg_2) => {
        dispatch_1(new Program_Notifiable$2(2, arg_2));
    })), (setState, model_5, dispatch_2) => setState(model_5.UserModel, (arg_3) => dispatch_2(new Program_Notifiable$2(2, arg_3))), (subscribe, model_3) => Cmd_batch(ofArray([singleton((dispatch) => {
        window.addEventListener("thoth_elmish_toast_notify_event", (ev) => {
            dispatch(new Program_Notifiable$2(0, ev.detail));
        });
    }), Cmd_map((arg0_4) => (new Program_Notifiable$2(2, arg0_4)), subscribe(model_3.UserModel))])), program);
}

export const render = {
    Toast(children, _arg1) {
        return react.createElement("div", {
            className: "toast",
        }, ...children);
    },
    CloseButton(onClick) {
        return react.createElement("span", {
            className: "close-button",
            onClick: onClick,
        });
    },
    Title(txt) {
        return react.createElement("span", {
            className: "toast-title",
        }, txt);
    },
    Icon(icon_1) {
        return react.createElement("div", {
            className: "toast-layout-icon",
        }, react.createElement("i", {
            className: "fa fa-2x " + icon_1,
        }));
    },
    SingleLayout(title_1, message_1) {
        return react.createElement("div", {
            className: "toast-layout-content",
        }, title_1, message_1);
    },
    Message(txt_1) {
        return react.createElement("span", {
            className: "toast-message",
        }, txt_1);
    },
    SplittedLayout(iconView, title_2, message_2) {
        return react.createElement("div", {
            style: {
                display: "flex",
                width: "100%",
            },
        }, iconView, react.createElement("div", {
            className: "toast-layout-content",
        }, title_2, message_2));
    },
    StatusToColor(status) {
        return (status.tag === 1) ? "is-warning" : ((status.tag === 2) ? "is-error" : ((status.tag === 3) ? "is-info" : "is-success"));
    },
};

