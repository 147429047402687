import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { SearchSms_searchSmsApi } from "../../Api.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationEvent$1 } from "../Common/Extensions.js";
import { State, Msg } from "./Model.js";
import { addDays, now } from "../../.fable/fable-library.3.2.9/Date.js";
import { QuerySmsSearch } from "../../Shared/Shared.js";
import { Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";

export function Api_searchSms(query) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(SearchSms_searchSmsApi().getSearch(query), (_arg1) => singleton.Return(new Msg(0, new AsyncOperationEvent$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Msg(0, new AsyncOperationEvent$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function Update_init() {
    let copyOfStruct;
    return [new State("", new QuerySmsSearch("", (copyOfStruct = now(), addDays(copyOfStruct, -1)), now()), new Deferred$1(0)), Cmd_none()];
}

export function Update_update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord;
    if (msg.tag === 2) {
        return [new State(state.Phone, (inputRecord_1 = state.Searching, new QuerySmsSearch(inputRecord_1.Phone, msg.fields[0], inputRecord_1.EndDateTime)), state.Sms), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new State(state.Phone, (inputRecord_2 = state.Searching, new QuerySmsSearch(inputRecord_2.Phone, inputRecord_2.StartDateTime, msg.fields[0])), state.Sms), Cmd_none()];
    }
    else if (msg.tag === 0) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Phone, state.Searching, new Deferred$1(2, msg.fields[0].fields[0])), Cmd_none()];
        }
        else {
            return [new State(state.Phone, state.Searching, new Deferred$1(1)), Cmd_fromAsync(Api_searchSms(state.Searching))];
        }
    }
    else {
        return [new State(state.Phone, (inputRecord = state.Searching, new QuerySmsSearch(msg.fields[0], inputRecord.StartDateTime, inputRecord.EndDateTime)), state.Sms), Cmd_none()];
    }
}

