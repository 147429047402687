import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { VirtualNumberSettings$reflection } from "../../Shared/Shared.js";
import { class_type, union_type, string_type, unit_type, record_type, option_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";

export class State extends Record {
    constructor(VirtualNumberSettings) {
        super();
        this.VirtualNumberSettings = VirtualNumberSettings;
    }
}

export function State$reflection() {
    return record_type("Pages.Settings.Types.State", [], State, () => [["VirtualNumberSettings", option_type(VirtualNumberSettings$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SettingsLoaded", "SettingUpdated", "SettingsUpdated", "UpdateSetupCost", "UpdateMonthlyFee", "ShowToast", "Failed"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.Settings.Types.Msg", [], Msg, () => [[["Item", VirtualNumberSettings$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

