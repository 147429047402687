import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { empty as empty_1, cons, ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { join, interpolate, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { map, empty, singleton as singleton_1, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Sender__get_IsNew, PhoneValidationForm__get_IsNew } from "../../Shared/Shared.js";
import { ActiveTab, Msg } from "./Model.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../.fable/Feliz.1.49.0/React.fs.js";
import { toString } from "../../.fable/fable-library.3.2.9/Date.js";

export function confirmationDialog(title, content, submitButtonText, cancelButtonText, submitIsDanger, onConfirm, onCancel, isLoading) {
    let elms_1, elms, props_2, props_7, props_13, props_9, props_11;
    const onCancel_1 = (_arg2) => {
        onCancel();
    };
    const props_16 = ofArray([["className", "is-active"], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", onCancel_1])))), (elms_1 = ofArray([(elms = ofArray([(props_2 = singleton(["children", toText(interpolate("%P()", [title]))]), createElement("p", createObj(Helpers_combineClasses("modal-card-title", props_2)))), createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", onCancel_1]))))]), createElement("header", {
        className: "modal-card-head",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_7 = singleton(["children", Interop_reactApi.Children.toArray([content])]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_7)))), (props_13 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["disabled", isLoading], ["onClick", onCancel_1], ["children", toText(interpolate("%P()", [cancelButtonText]))]]), createElement("button", createObj(Helpers_combineClasses("button", props_9)))), (props_11 = toList(delay(() => append(submitIsDanger ? singleton_1(["className", "is-danger"]) : singleton_1(["className", "is-primary"]), delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1) => {
        onConfirm();
    }]), delay(() => singleton_1(["children", toText(interpolate("%P()", [submitButtonText]))]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_11))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_13))))]), createElement("div", {
        className: "modal-card",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_16)));
}

export function renderPhoneModal(onSave, onCancel, form, dispatch) {
    let children_5, props_11, props_2, props, elms_2, elms_1, props_26, props_15, props_13, elms_4, elms_3;
    return confirmationDialog(PhoneValidationForm__get_IsNew(form.Form) ? "Create phone validation exception" : "Update phone validation exception", (children_5 = ofArray([(props_11 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
        minWidth: 140,
    }], ["children", "Phone number"]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms_2 = singleton((elms_1 = toList(delay(() => {
        let elms, props_4;
        return append(singleton_1((elms = singleton((props_4 = toList(delay(() => append(form.IsEmptyPhone ? singleton_1(["className", "is-danger"]) : empty(), delay(() => append(singleton_1(["className", "removeArrows"]), delay(() => append(singleton_1(["placeholder", "Phone number"]), delay(() => append(singleton_1(["onChange", (ev) => {
            dispatch(new Msg(9, ev.target.value));
        }]), delay(() => {
            let value_17;
            return singleton_1((value_17 = form.Form.PhoneNumber, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_17)) : false) {
                    e.value = value_17;
                }
            }]));
        })))))))))), createElement("input", createObj(cons(["type", "number"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => (form.IsEmptyPhone ? singleton_1(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "has-text-danger"], ["children", "Can not be empty"]]))))) : empty())));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_11)))), (props_26 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["style", {
        minWidth: 140,
    }], ["children", "Country"]]), createElement("label", createObj(Helpers_combineClasses("label", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_15)))), (elms_4 = singleton((elms_3 = toList(delay(() => {
        let patternInput;
        return append(singleton_1((patternInput = Helpers_extractClasses(toList(delay(() => append(singleton_1(["className", "is-fullwidth"]), delay(() => append(form.IsNotSelectCountry ? singleton_1(["className", "is-danger"]) : empty(), delay(() => append(singleton_1(["onChange", (ev_1) => {
            dispatch(new Msg(8, ev_1.target.value));
        }]), delay(() => {
            let value_51;
            return append(singleton_1((value_51 = form.Form.Country, ["ref", (e_1) => {
                if ((!(e_1 == null)) ? (!equals(e_1.value, value_51)) : false) {
                    e_1.value = value_51;
                }
            }])), delay(() => singleton_1(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((c) => createElement("option", {
                selected: c.Id === -1,
                disabled: c.Id === -1,
                value: c.Code,
                children: c.Name,
            }), form.CountryList)))))])));
        }))))))))), createElement("div", {
            className: join(" ", cons("select", patternInput[0])),
            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
        }))), delay(() => (form.IsNotSelectCountry ? singleton_1(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "has-text-danger"], ["children", "Choose country"]]))))) : empty())));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_26))))]), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_5)),
    })), PhoneValidationForm__get_IsNew(form.Form) ? "Create" : "Update", "Cancel", false, onSave, onCancel, false);
}

export function renderSenderModal(onSave, onCancel, form, dispatch) {
    let children_3, props_11, props_2, props, elms_2, elms_1;
    return confirmationDialog(Sender__get_IsNew(form.Form) ? "Create sender" : "Update sender", (children_3 = singleton((props_11 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_2 = ofArray([["className", "is-normal"], ["className", "has-text-centered"], ["style", {
        marginRight: 0,
    }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
        minWidth: 140,
    }], ["children", "Sender"]]), createElement("label", createObj(Helpers_combineClasses("label", props))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_2)))), (elms_2 = singleton((elms_1 = toList(delay(() => {
        let elms, props_4;
        return append(singleton_1((elms = singleton((props_4 = toList(delay(() => append(form.IsEmptySender ? singleton_1(["className", "is-danger"]) : empty(), delay(() => append(singleton_1(["placeholder", "Sender"]), delay(() => append(singleton_1(["onChange", (ev) => {
            dispatch(new Msg(17, ev.target.value));
        }]), delay(() => {
            let value_20;
            return singleton_1((value_20 = form.Form.Name, ["ref", (e) => {
                if ((!(e == null)) ? (!equals(e.value, value_20)) : false) {
                    e.value = value_20;
                }
            }]));
        })))))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => (form.IsEmptySender ? singleton_1(createElement("p", createObj(Helpers_combineClasses("help", ofArray([["className", "has-text-danger"], ["children", "Can not be empty"]]))))) : empty())));
    })), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_11))))), createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_3)),
    })), Sender__get_IsNew(form.Form) ? "Create" : "Update", "Cancel", false, onSave, onCancel, false);
}

export function renderPhonesTable(renderPhonesTableInputProps) {
    const dispatch = renderPhonesTableInputProps.dispatch;
    const state = renderPhonesTableInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setOpenDeleteModal = patternInput[1];
    const isOpenDeleteModal = patternInput[0];
    const children_6 = toList(delay(() => {
        let matchValue, form_1, form_2;
        return append((matchValue = state.EditPhoneState, (matchValue != null) ? ((matchValue.Form.Id === 0) ? ((form_1 = matchValue, singleton_1(renderPhoneModal(() => {
            dispatch(new Msg(4, form_1.Form));
        }, () => {
            dispatch(new Msg(3));
        }, form_1, dispatch)))) : ((matchValue == null) ? singleton_1(null) : ((form_2 = matchValue, singleton_1(renderPhoneModal(() => {
            dispatch(new Msg(6, form_2.Form));
        }, () => {
            dispatch(new Msg(3));
        }, form_2, dispatch)))))) : ((matchValue == null) ? singleton_1(null) : ((form_2 = matchValue, singleton_1(renderPhoneModal(() => {
            dispatch(new Msg(6, form_2.Form));
        }, () => {
            dispatch(new Msg(3));
        }, form_2, dispatch)))))), delay(() => {
            let phone, props;
            return append((isOpenDeleteModal == null) ? singleton_1(null) : ((phone = isOpenDeleteModal, singleton_1(confirmationDialog("Delete phone", (props = singleton(["children", toText(interpolate("Are you sure want delete phone: \u0027%P()\u0027 with country: \u0027%P()\u0027", [phone.PhoneNumber, phone.Country]))]), createElement("p", createObj(Helpers_combineClasses("", props)))), "Delete", "Cancel", true, () => {
                dispatch(new Msg(7, phone));
                setOpenDeleteModal(void 0);
            }, () => {
                setOpenDeleteModal(void 0);
            }, false)))), delay(() => {
                let props_27, children_4;
                return append(singleton_1((props_27 = ofArray([["className", "is-fullwidth"], ["className", "is-hoverable"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
                    style: {
                        display: "table-caption",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("tr", {
                        style: {
                            display: "flex",
                            justifyContent: "space-between",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("th", {
                            style: {
                                minWidth: 10 + "rem",
                                borderWidth: 0,
                            },
                            children: "Phone",
                        }), createElement("th", {
                            style: {
                                minWidth: 8 + "rem",
                                borderWidth: 0,
                            },
                            children: "Country",
                        }), createElement("th", {
                            style: {
                                borderWidth: 0,
                                minWidth: 10 + "rem",
                            },
                            children: "Timestamp",
                        }), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", "Create"], ["onClick", (_arg7) => {
                            dispatch(new Msg(2));
                        }]]))))]),
                    })]),
                }), (children_4 = toList(delay(() => map((phone_1) => {
                    let props_22, props_14, elms_1, elms;
                    const children_2 = ofArray([createElement("td", {
                        style: {
                            minWidth: 12.5 + "rem",
                        },
                        children: phone_1.PhoneNumber,
                    }), createElement("td", {
                        style: {
                            minWidth: 10 + "rem",
                        },
                        children: phone_1.Country,
                    }), createElement("td", {
                        style: {
                            minWidth: 10 + "rem",
                        },
                        children: toString(phone_1.Timestamp, "yyyy-MM-dd HH:mm:ss"),
                    }), createElement("td", {
                        className: "has-text-right",
                        children: Interop_reactApi.Children.toArray([(props_22 = ofArray([["className", "is-hoverable"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-small"], ["className", "is-rounded"], ["className", "is-light"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("icon", singleton(["className", "fas fa-ellipsis-v fa-sm"]))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_14)))), (elms_1 = singleton((elms = ofArray([createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["children", "Edit"], ["onClick", (_arg8) => {
                            dispatch(new Msg(5, phone_1));
                        }]])))), createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["className", "has-text-danger"], ["onClick", (_arg9) => {
                            setOpenDeleteModal(phone_1);
                        }], ["children", "Delete"]]))))]), createElement("div", {
                            className: "dropdown-content",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        }))), createElement("div", {
                            className: "dropdown-menu",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("dropdown", props_22))))]),
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    });
                }, state.Phones))), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_27))))), delay(() => {
                    let props_29;
                    return equals(state.Phones, empty_1()) ? singleton_1(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                        },
                        children: Interop_reactApi.Children.toArray([(props_29 = toList(delay(() => (state.Loading ? singleton_1(["children", "Loading..."]) : singleton_1(["children", "You have not added any phone number"])))), createElement("p", createObj(Helpers_combineClasses("", props_29))))]),
                    })) : empty();
                }));
            }));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

export function renderSendersTable(renderSendersTableInputProps) {
    const dispatch = renderSendersTableInputProps.dispatch;
    const state = renderSendersTableInputProps.state;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setOpenDeleteModal = patternInput[1];
    const isOpenDeleteModal = patternInput[0];
    const children_6 = toList(delay(() => {
        let matchValue, form_1, form_2;
        return append((matchValue = state.EditSenderState, (matchValue != null) ? ((matchValue.Form.Id === 0) ? ((form_1 = matchValue, singleton_1(renderSenderModal(() => {
            dispatch(new Msg(14, form_1.Form));
        }, () => {
            dispatch(new Msg(13));
        }, form_1, dispatch)))) : ((matchValue == null) ? singleton_1(null) : ((form_2 = matchValue, singleton_1(renderSenderModal(() => {
            dispatch(new Msg(16, form_2.Form));
        }, () => {
            dispatch(new Msg(13));
        }, form_2, dispatch)))))) : ((matchValue == null) ? singleton_1(null) : ((form_2 = matchValue, singleton_1(renderSenderModal(() => {
            dispatch(new Msg(16, form_2.Form));
        }, () => {
            dispatch(new Msg(13));
        }, form_2, dispatch)))))), delay(() => {
            let sender, props;
            return append((isOpenDeleteModal == null) ? singleton_1(null) : ((sender = isOpenDeleteModal, singleton_1(confirmationDialog("Delete sender", (props = singleton(["children", toText(interpolate("Are you sure want delete sender: \u0027%P()?", [sender.Name]))]), createElement("p", createObj(Helpers_combineClasses("", props)))), "Delete", "Cancel", true, () => {
                dispatch(new Msg(18, sender));
                setOpenDeleteModal(void 0);
            }, () => {
                setOpenDeleteModal(void 0);
            }, false)))), delay(() => {
                let props_25, children_4;
                return append(singleton_1((props_25 = ofArray([["className", "is-fullwidth"], ["className", "is-hoverable"], ["children", Interop_reactApi.Children.toArray([createElement("thead", {
                    style: {
                        display: "table-caption",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("tr", {
                        style: {
                            display: "flex",
                            justifyContent: "space-between",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("th", {
                            style: {
                                minWidth: 10 + "rem",
                                borderWidth: 0,
                            },
                            children: "Sender",
                        }), createElement("th", {
                            style: {
                                borderWidth: 0,
                                minWidth: 12 + "rem",
                            },
                            children: "Timestamp",
                        }), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", "Create"], ["onClick", (_arg7) => {
                            dispatch(new Msg(12));
                        }]]))))]),
                    })]),
                }), (children_4 = toList(delay(() => map((sender_1) => {
                    let props_20, props_12, elms_1, elms;
                    const children_2 = ofArray([createElement("td", {
                        style: {
                            minWidth: 12.5 + "rem",
                        },
                        children: sender_1.Name,
                    }), createElement("td", {
                        style: {
                            minWidth: 12.5 + "rem",
                        },
                        children: toString(sender_1.Timestamp, "yyyy-MM-dd HH:mm:ss"),
                    }), createElement("td", {
                        className: "has-text-right",
                        children: Interop_reactApi.Children.toArray([(props_20 = ofArray([["className", "is-hoverable"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-small"], ["className", "is-rounded"], ["className", "is-light"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("icon", singleton(["className", "fas fa-ellipsis-v fa-sm"]))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_12)))), (elms_1 = singleton((elms = ofArray([createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["children", "Edit"], ["onClick", (_arg8) => {
                            dispatch(new Msg(15, sender_1));
                        }]])))), createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["className", "has-text-danger"], ["onClick", (_arg9) => {
                            setOpenDeleteModal(sender_1);
                        }], ["children", "Delete"]]))))]), createElement("div", {
                            className: "dropdown-content",
                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                        }))), createElement("div", {
                            className: "dropdown-menu",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("dropdown", props_20))))]),
                    })]);
                    return createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                    });
                }, state.Senders))), createElement("tbody", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                }))])]]), createElement("table", createObj(Helpers_combineClasses("table", props_25))))), delay(() => {
                    let props_27;
                    return equals(state.Senders, empty_1()) ? singleton_1(createElement("div", {
                        style: {
                            display: "flex",
                            justifyContent: "center",
                        },
                        children: Interop_reactApi.Children.toArray([(props_27 = toList(delay(() => (state.Loading ? singleton_1(["children", "Loading..."]) : singleton_1(["children", "You have not added any sender"])))), createElement("p", createObj(Helpers_combineClasses("", props_27))))]),
                    })) : empty();
                }));
            }));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

export function render(state, dispatch) {
    const children = toList(delay(() => {
        let props_9, props_2, props_6;
        return append(singleton_1((props_9 = ofArray([["className", "is-boxed"], ["children", Interop_reactApi.Children.toArray([createElement("ul", {
            style: {
                borderColor: "#20A4E6",
            },
            children: Interop_reactApi.Children.toArray([(props_2 = toList(delay(() => append(equals(state.ActiveTab, new ActiveTab(0)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
                let props;
                return singleton_1(["children", Interop_reactApi.Children.toArray([(props = toList(delay(() => append(equals(state.ActiveTab, new ActiveTab(0)) ? append(singleton_1(["style", {
                    borderColor: "#20A4E6",
                }]), delay(() => singleton_1(["className", "has-text-weight-bold"]))) : empty(), delay(() => append(singleton_1(["children", "Phones"]), delay(() => singleton_1(["onClick", (_arg1) => {
                    dispatch(new Msg(0));
                }]))))))), createElement("a", createObj(Helpers_combineClasses("button", props))))])]);
            })))), createElement("li", createObj(Helpers_combineClasses("", props_2)))), (props_6 = toList(delay(() => append(equals(state.ActiveTab, new ActiveTab(1)) ? singleton_1(["className", "is-active"]) : empty(), delay(() => {
                let props_4;
                return singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(equals(state.ActiveTab, new ActiveTab(1)) ? append(singleton_1(["style", {
                    borderColor: "#20A4E6",
                }]), delay(() => singleton_1(["className", "has-text-weight-bold"]))) : empty(), delay(() => append(singleton_1(["children", "Senders"]), delay(() => singleton_1(["onClick", (_arg2) => {
                    dispatch(new Msg(1));
                }]))))))), createElement("a", createObj(Helpers_combineClasses("button", props_4))))])]);
            })))), createElement("li", createObj(Helpers_combineClasses("", props_6))))]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("tabs", props_9))))), delay(() => ((state.ActiveTab.tag === 1) ? singleton_1(createElement(renderSendersTable, {
            state: state,
            dispatch: dispatch,
        })) : singleton_1(createElement(renderPhonesTable, {
            state: state,
            dispatch: dispatch,
        })))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

