import { MatchFailureException, FSharpRef, Record, Union } from "../../.fable/fable-library.3.2.9/Types.js";
import { lambda_type, option_type, int32_type, class_type, unit_type, string_type, record_type, bool_type, union_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { InvoiceItemModule_totalCost, InvoiceItemModule_totalSmsCount, AccountType$reflection, AccountType as AccountType_1, Errors_ApplicationError__get_label, SettingsFormState, DiscountInfo$reflection, Errors_ApplicationError$reflection, SettingsFormState$reflection } from "../../Shared/Shared.js";
import { FormState_Form$2, FormState_Form_withState, FormState_Form_enableValiadateAll, FormState_Form_withNoErrors, FormState_Form_withFormErrors, FormState_Form_create, FormState_Form$2$reflection } from "../Common/FormState.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { Cmd_batch, Cmd_none, Cmd_OfAsyncWith_perform, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { apiInvoices, api } from "./Communication.js";
import { cons, filter, empty, ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { join, printf, isNullOrEmpty, interpolate, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { tryParse, parse } from "../../.fable/fable-library.3.2.9/Int32.js";
import { toastSuccess, toastError } from "../Components/Toast.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../.fable/Feliz.1.49.0/React.fs.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { empty as empty_1, fold, map, collect, singleton as singleton_1, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { equals, createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { ConfirmationDialogProps, ConfirmationDialog } from "../Components/Dialog.js";
import { Fa_IconOption, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { rangeDouble } from "../../.fable/fable-library.3.2.9/Range.js";
import { toArray } from "../../.fable/fable-library.3.2.9/Option.js";

export class Types_Field extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompanyId", "InvoicingType", "IsPostpayingApproved", "CreditLimit", "InvoicingPeriod", "InvoiceRowTitle"];
    }
}

export function Types_Field$reflection() {
    return union_type("EditInvoicingSettings.Types.Field", [], Types_Field, () => [[], [], [], [], [], []]);
}

export class Types_State extends Record {
    constructor(Form, Saving) {
        super();
        this.Form = Form;
        this.Saving = Saving;
    }
}

export function Types_State$reflection() {
    return record_type("EditInvoicingSettings.Types.State", [], Types_State, () => [["Form", FormState_Form$2$reflection(SettingsFormState$reflection(), Types_Field$reflection())], ["Saving", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FormChangedIsPostpaymentApprove", "FormChangedPaymentType", "FormChangedCreditLimit", "FormChangedMainAccount", "FormChangedInvoiceRowTitle", "FormChangedPeriod", "FormChangedDiscount", "FormChangedInvoicingCurrency", "SaveInvoicingSettings", "Saved", "Failed", "MakeInvoice", "InvoiceWasMade", "DiscountInfoLoaded", "UnlinkISA", "UnlinkSuccess"];
    }
}

export function Types_Msg$reflection() {
    return union_type("EditInvoicingSettings.Types.Msg", [], Types_Msg, () => [[], [], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Errors_ApplicationError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Errors_ApplicationError$reflection()]]])]], [["Item", class_type("System.Exception")]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]], [["Item", DiscountInfo$reflection()]], [["Item", int32_type]], [["Item", int32_type]]]);
}

export function State_saveInvoicingSettingsCmd(settings) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, api().saveInvoicingSettings, settings, (arg0) => (new Types_Msg(9, arg0)), (arg0_1) => (new Types_Msg(10, arg0_1)));
}

export function State_makeInvoiceDraftCmd(companyId) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (companyId_1) => api().makeInvoiceDraft(companyId_1), companyId, (arg0) => (new Types_Msg(12, arg0)));
}

export function State_loadDiscountInfoCmd(companyId) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (companyId_1) => apiInvoices().getDiscountInfo(companyId_1), companyId, (arg0) => (new Types_Msg(13, arg0)));
}

export function State_unlinkISACmd(isaId) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (isaId_1) => apiInvoices().unlinkISA(isaId_1), isaId, (arg0) => (new Types_Msg(15, arg0)));
}

export function State_init(settings) {
    let matchValue, _isaList, ma;
    return [new Types_State(FormState_Form_create(new SettingsFormState(settings.CompanyId, settings.InvoicingType, settings.IsPostpayingApproved, settings.CreditLimit, settings.InvoicingPeriod, settings.Discount, settings.DiscountInfo, settings.TransactionsInfo, settings.AccountType, (matchValue = settings.AccountType, (matchValue == null) ? (void 0) : ((matchValue.tag === 0) ? ((_isaList = matchValue.fields[0], void 0)) : ((ma = (matchValue.fields[0] | 0), ma)))), settings.InvoicingCurrency, settings.InvoiceRowTitle)), false), State_loadDiscountInfoCmd(settings.CompanyId)];
}

export function State_withServerError(error, state) {
    return new Types_State(FormState_Form_withFormErrors(singleton(error), state.Form), state.Saving);
}

export function State_withNoErrors(state) {
    return new Types_State(FormState_Form_withNoErrors(state.Form), state.Saving);
}

export function State_saving(state) {
    return new Types_State(state.Form, true);
}

export function State_notSaving(state) {
    return new Types_State(state.Form, false);
}

export function State_withEnableValidateAll(state) {
    return new Types_State(FormState_Form_enableValiadateAll(state.Form), state.Saving);
}

export function State_withForm(form, state) {
    return new Types_State(form, state.Saving);
}

export function State_update(msg, state) {
    let inputRecord_1, matchValue, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_7, inputRecord_6, inputRecord_9, inputRecord_8, patternInput, outArg, inputRecord_11, inputRecord_10, inputRecord_13, inputRecord_12, isaList, inputRecord;
    switch (msg.tag) {
        case 1: {
            return [new Types_State(FormState_Form_withState((inputRecord_1 = state.Form.State, new SettingsFormState(inputRecord_1.CompanyId, (matchValue = (state.Form.State.InvoicingType | 0), (matchValue === 0) ? 1 : ((matchValue === 1) ? 0 : (() => {
                throw (new Error(toText(interpolate("Can not find this type %P()", [matchValue]))));
            })())), inputRecord_1.IsPostpayingApproved, inputRecord_1.CreditLimit, inputRecord_1.InvoicingPeriod, inputRecord_1.Discount, inputRecord_1.DiscountInfo, inputRecord_1.TransactionsInfo, inputRecord_1.AccountType, inputRecord_1.MainAccount, inputRecord_1.InvoicingCurrency, inputRecord_1.InvoiceRowTitle)), state.Form), state.Saving), Cmd_none()];
        }
        case 2: {
            return [new Types_State(FormState_Form_withState((inputRecord_2 = state.Form.State, new SettingsFormState(inputRecord_2.CompanyId, inputRecord_2.InvoicingType, inputRecord_2.IsPostpayingApproved, parse(msg.fields[0], 511, false, 32), inputRecord_2.InvoicingPeriod, inputRecord_2.Discount, inputRecord_2.DiscountInfo, inputRecord_2.TransactionsInfo, inputRecord_2.AccountType, inputRecord_2.MainAccount, inputRecord_2.InvoicingCurrency, inputRecord_2.InvoiceRowTitle)), state.Form), state.Saving), Cmd_none()];
        }
        case 5: {
            return [new Types_State(FormState_Form_withState((inputRecord_3 = state.Form.State, new SettingsFormState(inputRecord_3.CompanyId, inputRecord_3.InvoicingType, inputRecord_3.IsPostpayingApproved, inputRecord_3.CreditLimit, parse(msg.fields[0], 511, false, 32), inputRecord_3.Discount, inputRecord_3.DiscountInfo, inputRecord_3.TransactionsInfo, inputRecord_3.AccountType, inputRecord_3.MainAccount, inputRecord_3.InvoicingCurrency, inputRecord_3.InvoiceRowTitle)), state.Form), state.Saving), Cmd_none()];
        }
        case 6: {
            return [new Types_State(FormState_Form_withState((inputRecord_4 = state.Form.State, new SettingsFormState(inputRecord_4.CompanyId, inputRecord_4.InvoicingType, inputRecord_4.IsPostpayingApproved, inputRecord_4.CreditLimit, inputRecord_4.InvoicingPeriod, parse(msg.fields[0], 511, false, 32), inputRecord_4.DiscountInfo, inputRecord_4.TransactionsInfo, inputRecord_4.AccountType, inputRecord_4.MainAccount, inputRecord_4.InvoicingCurrency, inputRecord_4.InvoiceRowTitle)), state.Form), state.Saving), Cmd_none()];
        }
        case 7: {
            return [new Types_State(FormState_Form_withState((inputRecord_5 = state.Form.State, new SettingsFormState(inputRecord_5.CompanyId, inputRecord_5.InvoicingType, inputRecord_5.IsPostpayingApproved, inputRecord_5.CreditLimit, inputRecord_5.InvoicingPeriod, inputRecord_5.Discount, inputRecord_5.DiscountInfo, inputRecord_5.TransactionsInfo, inputRecord_5.AccountType, inputRecord_5.MainAccount, msg.fields[0], inputRecord_5.InvoiceRowTitle)), state.Form), state.Saving), Cmd_none()];
        }
        case 8: {
            return [State_saving(state), State_saveInvoicingSettingsCmd(state.Form.State)];
        }
        case 9: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                return [State_notSaving(state), Cmd_batch(ofArray([toastError(Errors_ApplicationError__get_label(result.fields[0])), State_loadDiscountInfoCmd(state.Form.State.CompanyId)]))];
            }
            else {
                return [State_notSaving(state), Cmd_batch(ofArray([toastSuccess("Settings have been saved"), State_loadDiscountInfoCmd(state.Form.State.CompanyId)]))];
            }
        }
        case 10: {
            return [State_notSaving(state), toastError("Something went wrong")];
        }
        case 11: {
            return [state, Cmd_batch(ofArray([State_makeInvoiceDraftCmd(msg.fields[0]), toastSuccess("Invoice will be generated within a minute")]))];
        }
        case 12: {
            if (msg.fields[0].tag === 1) {
                return [State_notSaving(state), Cmd_none()];
            }
            else {
                return [State_notSaving(state), Cmd_none()];
            }
        }
        case 13: {
            return [new Types_State((inputRecord_7 = state.Form, new FormState_Form$2((inputRecord_6 = state.Form.State, new SettingsFormState(inputRecord_6.CompanyId, inputRecord_6.InvoicingType, inputRecord_6.IsPostpayingApproved, inputRecord_6.CreditLimit, inputRecord_6.InvoicingPeriod, inputRecord_6.Discount, msg.fields[0], inputRecord_6.TransactionsInfo, inputRecord_6.AccountType, inputRecord_6.MainAccount, inputRecord_6.InvoicingCurrency, inputRecord_6.InvoiceRowTitle)), inputRecord_7.Validation, inputRecord_7.FieldErrors, inputRecord_7.FormErrors)), state.Saving), Cmd_none()];
        }
        case 3: {
            const id = msg.fields[0];
            return [new Types_State((inputRecord_9 = state.Form, new FormState_Form$2((inputRecord_8 = state.Form.State, new SettingsFormState(inputRecord_8.CompanyId, inputRecord_8.InvoicingType, inputRecord_8.IsPostpayingApproved, inputRecord_8.CreditLimit, inputRecord_8.InvoicingPeriod, inputRecord_8.Discount, inputRecord_8.DiscountInfo, inputRecord_8.TransactionsInfo, inputRecord_8.AccountType, isNullOrEmpty(id) ? (void 0) : ((patternInput = ((outArg = 0, [tryParse(id, 511, false, 32, new FSharpRef(() => outArg, (v) => {
                outArg = (v | 0);
            })), outArg])), patternInput[0] ? patternInput[1] : state.Form.State.MainAccount)), inputRecord_8.InvoicingCurrency, inputRecord_8.InvoiceRowTitle)), inputRecord_9.Validation, inputRecord_9.FieldErrors, inputRecord_9.FormErrors)), state.Saving), empty()];
        }
        case 4: {
            const value_6 = msg.fields[0];
            return [new Types_State((inputRecord_11 = state.Form, new FormState_Form$2((inputRecord_10 = state.Form.State, new SettingsFormState(inputRecord_10.CompanyId, inputRecord_10.InvoicingType, inputRecord_10.IsPostpayingApproved, inputRecord_10.CreditLimit, inputRecord_10.InvoicingPeriod, inputRecord_10.Discount, inputRecord_10.DiscountInfo, inputRecord_10.TransactionsInfo, inputRecord_10.AccountType, inputRecord_10.MainAccount, inputRecord_10.InvoicingCurrency, isNullOrEmpty(value_6) ? "" : value_6)), inputRecord_11.Validation, inputRecord_11.FieldErrors, inputRecord_11.FormErrors)), state.Saving), empty()];
        }
        case 14: {
            return [state, State_unlinkISACmd(msg.fields[0])];
        }
        case 15: {
            const at = state.Form.State.AccountType;
            return [new Types_State((inputRecord_13 = state.Form, new FormState_Form$2((inputRecord_12 = state.Form.State, new SettingsFormState(inputRecord_12.CompanyId, inputRecord_12.InvoicingType, inputRecord_12.IsPostpayingApproved, inputRecord_12.CreditLimit, inputRecord_12.InvoicingPeriod, inputRecord_12.Discount, inputRecord_12.DiscountInfo, inputRecord_12.TransactionsInfo, (at != null) ? ((at.tag === 0) ? ((isaList = at.fields[0], new AccountType_1(0, filter((x_1) => (x_1.CompanyId !== msg.fields[0]), isaList)))) : at) : at, inputRecord_12.MainAccount, inputRecord_12.InvoicingCurrency, inputRecord_12.InvoiceRowTitle)), inputRecord_13.Validation, inputRecord_13.FieldErrors, inputRecord_13.FormErrors)), state.Saving), toastSuccess("ISA was unlinked")];
        }
        default: {
            return [new Types_State(FormState_Form_withState((inputRecord = state.Form.State, new SettingsFormState(inputRecord.CompanyId, inputRecord.InvoicingType, !state.Form.State.IsPostpayingApproved, inputRecord.CreditLimit, inputRecord.InvoicingPeriod, inputRecord.Discount, inputRecord.DiscountInfo, inputRecord.TransactionsInfo, inputRecord.AccountType, inputRecord.MainAccount, inputRecord.InvoicingCurrency, inputRecord.InvoiceRowTitle)), state.Form), state.Saving), Cmd_none()];
        }
    }
}

export function View_renderTagsWithISA(view_renderTagsWithISAInputProps) {
    const dispatch = view_renderTagsWithISAInputProps.dispatch;
    const isaDetails = view_renderTagsWithISAInputProps.isaDetails;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        ISAId: 0,
        IsShown: false,
    });
    const setConfirmationDialog = patternInput[1];
    const confirmationDialog = patternInput[0];
    const props_11 = ofArray([["className", "is-horizontal"], ["style", {
        alignItems: "center",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", ""]))))), delay(() => {
        let elms, props_6;
        return append(singleton_1((elms = singleton((props_6 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((details) => {
            let props_2;
            return append(singleton_1((props_2 = ofArray([["className", "is-primary"], ["children", toText(printf("%s (%d)"))(details.CompanyName)(details.CompanyId)]]), createElement("span", createObj(Helpers_combineClasses("tag", props_2))))), delay(() => {
                let props_4;
                return singleton_1((props_4 = ofArray([["style", {
                    marginRight: 10,
                    border: (((1 + "px ") + "solid") + " ") + "#D3D3D3",
                }], ["id", details.CompanyId], ["className", "is-delete"], ["onClick", (_arg1) => {
                    setConfirmationDialog({
                        ISAId: details.CompanyId,
                        IsShown: true,
                    });
                }]]), createElement("span", createObj(Helpers_combineClasses("tag", props_4)))));
            }));
        }, isaDetails)))))]]), createElement("div", createObj(Helpers_combineClasses("tags", props_6))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        }))), delay(() => (confirmationDialog.IsShown ? singleton_1(createElement(ConfirmationDialog, new ConfirmationDialogProps("Unlink ISA", createElement("p", {
            children: "Are you sure you want to remove the link between IMA and ISA?",
        }), () => {
            dispatch(new Types_Msg(14, confirmationDialog.ISAId));
            setConfirmationDialog({
                ISAId: 0,
                IsShown: false,
            });
        }, () => {
            setConfirmationDialog({
                ISAId: 0,
                IsShown: false,
            });
        }, "Unlink", "Cancel"))) : singleton_1(null))));
    }))))))]]);
    return createElement("div", createObj(Helpers_combineClasses("field", props_11)));
}

export class View_ButtonWithConfirmationProps extends Record {
    constructor(ButtonText, CompanyId, IsPostpayment, AccountType, Action) {
        super();
        this.ButtonText = ButtonText;
        this.CompanyId = (CompanyId | 0);
        this.IsPostpayment = IsPostpayment;
        this.AccountType = AccountType;
        this.Action = Action;
    }
}

export function View_ButtonWithConfirmationProps$reflection() {
    return record_type("EditInvoicingSettings.View.ButtonWithConfirmationProps", [], View_ButtonWithConfirmationProps, () => [["ButtonText", string_type], ["CompanyId", int32_type], ["IsPostpayment", bool_type], ["AccountType", option_type(AccountType$reflection())], ["Action", lambda_type(unit_type, unit_type)]]);
}

export function View_buttonWithConfirmation(props) {
    let matchValue;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setDisplayConfirmation = patternInput[1];
    if (patternInput[0]) {
        return createElement(ConfirmationDialog, new ConfirmationDialogProps("Generate Invoice", react.createElement("p", {}, "Are you sure you want to manually generate an invoice?"), () => {
            props.Action();
            setDisplayConfirmation(false);
        }, () => {
            setDisplayConfirmation(false);
        }, "Yes", "No"));
    }
    else {
        const props_3 = ofArray([["className", "is-primary"], ["disabled", (!props.IsPostpayment) ? true : ((matchValue = props.AccountType, (matchValue == null) ? false : ((matchValue.tag === 1) ? true : false)))], ["style", {
            marginLeft: 5,
        }], ["children", "Make invoice"], ["onClick", (_arg3) => {
            setDisplayConfirmation(true);
        }]]);
        return createElement("button", createObj(Helpers_combineClasses("button", props_3)));
    }
}

export function View_render(state, dispatch) {
    const isPostpaymentApproved = state.Form.State.IsPostpayingApproved;
    let isPostpayment;
    const matchValue = state.Form.State.InvoicingType | 0;
    switch (matchValue) {
        case 0: {
            isPostpayment = false;
            break;
        }
        case 1: {
            isPostpayment = true;
            break;
        }
        default: {
            throw (new MatchFailureException("/app/src/Client/Pages/Customer/EditInvoicingSettings.fs", 267, 18));
        }
    }
    const props_89 = singleton(["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_9, elms, patternInput;
            return append(singleton_1((props_9 = ofArray([["className", "is-horizontal"], ["style", {
                alignItems: "center",
            }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Post-pay"])))), (elms = singleton((patternInput = Helpers_extractClasses(ofArray([["onChange", (ev) => {
                const _arg1 = ev.target.checked;
                dispatch(new Types_Msg(0));
            }], ["children", Interop_reactApi.Children.toArray([createElement("option", {
                selected: isPostpaymentApproved,
                children: "Enabled",
                value: 1,
                defaultValue: 1,
            }), createElement("option", {
                selected: !isPostpaymentApproved,
                children: "Disabled",
                value: 0,
                defaultValue: 0,
            })])]])), createElement("div", {
                className: join(" ", cons("select", patternInput[0])),
                children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
            }))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_9))))), delay(() => {
                let props_21, elms_1;
                return append(singleton_1((props_21 = ofArray([["className", "is-horizontal"], ["style", {
                    alignItems: "center",
                }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Invoice Type"])))), (elms_1 = toList(delay(() => {
                    let patternInput_1;
                    return append(singleton_1((patternInput_1 = Helpers_extractClasses(ofArray([["disabled", state.Form.State.AccountType != null], ["onChange", (ev_1) => {
                        const _arg2 = ev_1.target.checked;
                        dispatch(new Types_Msg(1));
                    }], ["children", Interop_reactApi.Children.toArray([createElement("option", {
                        selected: isPostpayment,
                        children: "Post-pay",
                        defaultValue: "postpayment",
                    }), createElement("option", {
                        selected: !isPostpayment,
                        children: "Pre-pay",
                        defaultValue: "prepayment",
                    })])]])), createElement("div", {
                        className: join(" ", cons("select", patternInput_1[0])),
                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_1[1]))]),
                    }))), delay(() => append(singleton_1(createElement(View_buttonWithConfirmation, new View_ButtonWithConfirmationProps("Generate", state.Form.State.CompanyId, state.Form.State.InvoicingType === 1, state.Form.State.AccountType, () => {
                        dispatch(new Types_Msg(11, state.Form.State.CompanyId));
                    }))), delay(() => {
                        let tooltipText;
                        const matchValue_1 = state.Form.State.TransactionsInfo;
                        if (matchValue_1 == null) {
                            tooltipText = "No data";
                        }
                        else {
                            const data = matchValue_1;
                            const patternInput_2 = [InvoiceItemModule_totalSmsCount(data.Campaign), InvoiceItemModule_totalCost(data.Campaign)];
                            tooltipText = toText(printf("Initial balance:%0.2f SEK\n- campaign: %i, %0.2f SEK\n\nAdjustments: %A\n "))(data.InitialBalance)(patternInput_2[0])(patternInput_2[1])(data.Adjustments);
                        }
                        return singleton_1(createElement("span", {
                            style: {
                                marginLeft: 5,
                            },
                            ["data-tooltip"]: tooltipText,
                            className: "has-tooltip-bottom",
                            className: "has-tooltip-multiline",
                            className: "has-tooltip-primary",
                            children: "",
                            children: Interop_reactApi.Children.toArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-info-circle")), [])]),
                        }));
                    }))));
                })), createElement("div", {
                    className: "field-body",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_21))))), delay(() => {
                    let props_28, elms_2, props_25, value_77;
                    return append(singleton_1((props_28 = ofArray([["className", "is-horizontal"], ["style", {
                        alignItems: "center",
                    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Credit Limit"])))), (elms_2 = singleton((props_25 = ofArray([["style", {
                        width: 120,
                    }], (value_77 = state.Form.State.CreditLimit, ["ref", (e) => {
                        if ((!(e == null)) ? (!equals(e.value, value_77)) : false) {
                            e.value = value_77;
                        }
                    }]), ["onChange", (ev_2) => {
                        dispatch(new Types_Msg(2, ev_2.target.value));
                    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_25)))))), createElement("div", {
                        className: "field-body",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_28))))), delay(() => {
                        let props_39, elms_3, patternInput_3, value_97;
                        return append(singleton_1((props_39 = ofArray([["className", "is-horizontal"], ["style", {
                            alignItems: "center",
                        }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Invoicing Period"])))), (elms_3 = singleton((patternInput_3 = Helpers_extractClasses(ofArray([["style", {
                            width: 120,
                        }], ["onChange", (ev_3) => {
                            dispatch(new Types_Msg(5, ev_3.target.value));
                        }], (value_97 = (state.Form.State.InvoicingPeriod | 0), ["ref", (e_1) => {
                            if ((!(e_1 == null)) ? (!equals(e_1.value, value_97)) : false) {
                                e_1.value = (value_97 | 0);
                            }
                        }]), ["children", Interop_reactApi.Children.toArray([createElement("option", {
                            value: 1,
                            children: "1 month",
                            id: 1,
                        }), createElement("option", {
                            value: 3,
                            children: "3 month",
                            id: 3,
                        })])]])), createElement("div", {
                            className: join(" ", cons("select", patternInput_3[0])),
                            children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_3[1]))]),
                        }))), createElement("div", {
                            className: "field-body",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_39))))), delay(() => {
                            let props_51, props_49;
                            return append(singleton_1((props_51 = ofArray([["className", "is-horizontal"], ["style", {
                                alignItems: "center",
                            }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Discount"])))), (props_49 = singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                let patternInput_4;
                                return append(singleton_1((patternInput_4 = Helpers_extractClasses(ofArray([["style", {
                                    width: 120,
                                }], ["onChange", (ev_4) => {
                                    dispatch(new Types_Msg(6, ev_4.target.value));
                                }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => map((i) => {
                                    let value_134;
                                    return createElement("option", createObj(ofArray([["selected", state.Form.State.Discount === i], ["children", toText(printf("Level %d"))(i)], (value_134 = (i | 0), ["ref", (e_2) => {
                                        if ((!(e_2 == null)) ? (!equals(e_2.value, value_134)) : false) {
                                            e_2.value = (value_134 | 0);
                                        }
                                    }])])));
                                }, rangeDouble(0, 1, 10))))))]])), createElement("div", {
                                    className: join(" ", cons("select", patternInput_4[0])),
                                    children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_4[1]))]),
                                }))), delay(() => {
                                    let tooltipText_1;
                                    const matchValue_2 = state.Form.State.DiscountInfo;
                                    if (matchValue_2 != null) {
                                        const data_1 = matchValue_2;
                                        const patternInput_5 = data_1.LastMonth;
                                        const patternInput_6 = data_1.CurrentMonth;
                                        tooltipText_1 = toText(printf("Previous month:\n- sms: %d\n- discount: %0.2f SEK\n\nCurrent month:\n- sms: %d\n- discount: %0.2f SEK"))(patternInput_5[0])(patternInput_5[1])(patternInput_6[0])(patternInput_6[1]);
                                    }
                                    else {
                                        tooltipText_1 = "";
                                    }
                                    return singleton_1(createElement("span", {
                                        style: {
                                            marginLeft: 5,
                                        },
                                        ["data-tooltip"]: tooltipText_1,
                                        className: "has-tooltip-bottom",
                                        className: "has-tooltip-multiline",
                                        className: "has-tooltip-primary",
                                        children: "",
                                        children: Interop_reactApi.Children.toArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-info-circle")), [])]),
                                    }));
                                }));
                            }))))]), createElement("div", createObj(Helpers_combineClasses("field-body", props_49))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_51))))), delay(() => {
                                let props_59, props_57, props_55;
                                return append(singleton_1((props_59 = ofArray([["className", "is-horizontal"], ["style", {
                                    alignItems: "center",
                                }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Main account"])))), (props_57 = singleton(["children", Interop_reactApi.Children.toArray([(props_55 = toList(delay(() => {
                                    const matchValue_3 = state.Form.State.AccountType;
                                    let pattern_matching_result;
                                    if (matchValue_3 != null) {
                                        if (matchValue_3.tag === 0) {
                                            pattern_matching_result = 0;
                                        }
                                        else {
                                            pattern_matching_result = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result = 1;
                                    }
                                    switch (pattern_matching_result) {
                                        case 0: {
                                            return append(singleton_1(["style", {
                                                width: 120,
                                            }]), delay(() => append(singleton_1(["disabled", true]), delay(() => singleton_1(["placeholder", "This is IMA"])))));
                                        }
                                        case 1: {
                                            return append(singleton_1(["style", {
                                                width: 120,
                                            }]), delay(() => append(singleton_1(["placeholder", "Enter IMA ID"]), delay(() => {
                                                let value_171;
                                                return append(singleton_1((value_171 = fold((_arg4, x_2) => toText(interpolate("%d%P()", [x_2])), "", toArray(state.Form.State.MainAccount)), ["ref", (e_3) => {
                                                    if ((!(e_3 == null)) ? (!equals(e_3.value, value_171)) : false) {
                                                        e_3.value = value_171;
                                                    }
                                                }])), delay(() => singleton_1(["onChange", (ev_5) => {
                                                    dispatch(new Types_Msg(3, ev_5.target.value));
                                                }])));
                                            }))));
                                        }
                                    }
                                })), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_55)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_57))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_59))))), delay(() => {
                                    let props_71, elms_4, patternInput_7;
                                    return append(singleton_1((props_71 = ofArray([["className", "is-horizontal"], ["style", {
                                        alignItems: "center",
                                    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Invoice currency"])))), (elms_4 = singleton((patternInput_7 = Helpers_extractClasses(ofArray([["style", {
                                        width: 120,
                                    }], ["onChange", (ev_6) => {
                                        dispatch(new Types_Msg(7, ev_6.target.value));
                                    }], ["children", Interop_reactApi.Children.toArray([createElement("option", {
                                        selected: state.Form.State.InvoicingCurrency === "sek",
                                        children: "SEK",
                                        defaultValue: "sek",
                                    }), createElement("option", {
                                        selected: state.Form.State.InvoicingCurrency === "eur",
                                        children: "EUR",
                                        defaultValue: "eur",
                                    }), createElement("option", {
                                        selected: state.Form.State.InvoicingCurrency === "nok",
                                        children: "NOK",
                                        defaultValue: "nok",
                                    })])]])), createElement("div", {
                                        className: join(" ", cons("select", patternInput_7[0])),
                                        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput_7[1]))]),
                                    }))), createElement("div", {
                                        className: "field-body",
                                        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
                                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_71))))), delay(() => {
                                        let matchValue_4, isaList;
                                        return append((matchValue_4 = state.Form.State.AccountType, (matchValue_4 != null) ? ((matchValue_4.tag === 0) ? ((isaList = matchValue_4.fields[0], singleton_1(createElement(View_renderTagsWithISA, {
                                            isaDetails: isaList,
                                            dispatch: dispatch,
                                        })))) : ((empty_1()))) : ((empty_1()))), delay(() => {
                                            let props_79, props_77, props_75, value_225;
                                            return append(singleton_1((props_79 = ofArray([["className", "is-horizontal"], ["style", {
                                                alignItems: "center",
                                            }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", singleton(["children", "Invoice row title"])))), (props_77 = singleton(["children", Interop_reactApi.Children.toArray([(props_75 = ofArray([["style", {
                                                width: 400,
                                            }], ["placeholder", "Enter Invoice row title"], (value_225 = state.Form.State.InvoiceRowTitle, ["ref", (e_4) => {
                                                if ((!(e_4 == null)) ? (!equals(e_4.value, value_225)) : false) {
                                                    e_4.value = value_225;
                                                }
                                            }]), ["onChange", (ev_7) => {
                                                dispatch(new Types_Msg(4, ev_7.target.value));
                                            }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_75)))))])]), createElement("div", createObj(Helpers_combineClasses("field-body", props_77))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_79))))), delay(() => {
                                                let props_86, elms_5, props_83;
                                                return singleton_1((props_86 = ofArray([["className", "is-horizontal"], ["style", {
                                                    alignItems: "center",
                                                }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("field-label", empty()))), (elms_5 = singleton((props_83 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(state.Saving ? singleton_1(["className", "is-loading"]) : empty_1(), delay(() => append(singleton_1(["style", {
                                                    width: 120,
                                                }]), delay(() => append(singleton_1(["children", "Save"]), delay(() => singleton_1(["onClick", (_arg5) => {
                                                    dispatch(new Types_Msg(8));
                                                }]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_83))))), createElement("div", {
                                                    className: "field-body",
                                                    children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
                                                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_86)))));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        })))),
    })])]);
    return createElement("div", createObj(Helpers_combineClasses("container", props_89)));
}

