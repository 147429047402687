import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { int32ToString, equals as equals_1, createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { Msg } from "./Model.js";
import { empty, singleton, cons, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../.fable/Feliz.1.49.0/React.fs.js";
import { Fa_IconOption, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { confirmationDialog } from "../Common/View.js";
import { collect, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { interpolate, replace, printf, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { op_Addition, fromParts, equals } from "../../.fable/fable-library.3.2.9/Decimal.js";
import { InvoiceSortFields, InvoiceState } from "../../Shared/Shared.js";
import { map, value as value_53 } from "../../.fable/fable-library.3.2.9/Option.js";
import { toString } from "../../.fable/fable-library.3.2.9/Date.js";
import { View_render } from "../Common/Sorting.js";
import { View_render as View_render_1 } from "../Common/Paging.js";

export function filter(state, dispatch) {
    let props_4, elms_1, elms;
    const props_8 = ofArray([["className", "is-vcentered"], ["className", "has-background-white"], ["style", {
        borderBottom: (((1 + "px ") + "solid") + " ") + "#ccc",
        margin: "auto",
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = ofArray([["className", "is-one-quarter"], ["children", Interop_reactApi.Children.toArray([(elms_1 = singleton((elms = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Search customer"], ["value", state.Filter], ["onChange", (ev) => {
        dispatch(new Msg(2, ev.target.value));
    }]])))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), createElement("div", createObj(Helpers_combineClasses("column", empty())))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_8)));
}

export function invoiceDraftsSummary(invoiceDraftsSummaryInputProps) {
    let data, props_7;
    const unitVar2 = invoiceDraftsSummaryInputProps.unitVar2;
    const dispatch = invoiceDraftsSummaryInputProps.dispatch;
    const draftSummary = invoiceDraftsSummaryInputProps.draftSummary;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmation = patternInput[1];
    let loaderView;
    const props_5 = ofArray([["className", "is-horizontal"], ["style", {
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([createElement("span", {
        children: ["To Invoice: "],
    }), createElement("span", {
        style: {
            marginLeft: 5,
            marginRight: 5,
        },
        children: Interop_reactApi.Children.toArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-spinner"), new Fa_IconOption(12)]), [])]),
    }), createElement("span", {
        children: ["items,"],
    }), createElement("span", {
        style: {
            marginLeft: 5,
            marginRight: 5,
        },
        children: Interop_reactApi.Children.toArray([Fa_i(ofArray([new Fa_IconOption(11, "fas fa-spinner"), new Fa_IconOption(12)]), [])]),
    }), createElement("span", {
        children: ["SEK"],
    })])]]);
    loaderView = createElement("div", createObj(Helpers_combineClasses("field", props_5)));
    const renderConfirmationDialog = patternInput[0] ? confirmationDialog("Sending all postpayment invoices", createElement("span", {
        children: "Are you sure you want to send all postpayment invoices?",
    }), () => {
        dispatch(new Msg(16));
        setShowConfirmation(false);
    }, () => {
        setShowConfirmation(false);
    }, "Confirm") : (null);
    const render = (content) => {
        let props_14, props_12, props_10;
        const props_16 = ofArray([["className", "is-vcentered"], ["className", "has-background-white"], ["style", {
            borderBottom: (((1 + "px ") + "solid") + " ") + "#ccc",
            margin: "auto",
        }], ["children", Interop_reactApi.Children.toArray([(props_14 = singleton(["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([content, renderConfirmationDialog, (props_10 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["className", "is-small"]), delay(() => append(singleton_1(["style", {
            marginLeft: 10,
        }]), delay(() => {
            let draftSummary_2;
            return append((draftSummary != null) ? ((draftSummary.ItemCount <= 0) ? ((draftSummary_2 = draftSummary, singleton_1(["disabled", true]))) : ((empty_1()))) : singleton_1(["disabled", true]), delay(() => append(singleton_1(["onClick", (_arg3) => {
                setShowConfirmation(true);
            }]), delay(() => singleton_1(["children", "Send All"])))));
        })))))))), createElement("button", createObj(Helpers_combineClasses("button", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_14))))])]]);
        return createElement("div", createObj(Helpers_combineClasses("columns", props_16)));
    };
    if (draftSummary != null) {
        return render((data = draftSummary, (props_7 = ofArray([["style", {
            padding: 2,
        }], ["children", toText(printf("To invoice: %d items, %.2f SEK"))(data.ItemCount)(data.Amount)]]), createElement("span", createObj(Helpers_combineClasses("", props_7))))));
    }
    else {
        return render(loaderView);
    }
}

export function invoiceDetailRow(details) {
    let children_2, children, children_4;
    const props_14 = ofArray([["className", "is-fullwidth"], ["children", Interop_reactApi.Children.toArray([(children_2 = singleton((children = ofArray([createElement("th", {
        style: {
            textAlign: "center",
        },
        children: "Description",
    }), createElement("th", {
        style: {
            textAlign: "center",
        },
        children: "Cost",
    }), createElement("th", {
        style: {
            textAlign: "center",
        },
        children: "VAT",
    }), createElement("th", {
        style: {
            textAlign: "center",
        },
        children: "Total",
    })]), createElement("tr", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => collect((matchValue) => append(singleton_1(createElement("tr", {
        children: Interop_reactApi.Children.toArray([createElement("td", {
            colSpan: 12,
            style: {
                textDecoration: "underline",
                textAlign: "center",
            },
            children: matchValue[0],
        })]),
    })), delay(() => collect((row_1) => {
        let row, arg10_2;
        return (!equals(row_1.Cost, fromParts(0, 0, 0, false, 0))) ? singleton_1((row = row_1, createElement("tr", {
            children: Interop_reactApi.Children.toArray([createElement("td", {
                children: replace(row.Description, "\u003c\u003cVirtual number\u003e\u003e::", ""),
            }), createElement("td", {
                style: {
                    textAlign: "center",
                },
                children: toText(printf("%.2f"))(row.Cost) + " SEK",
            }), createElement("td", {
                style: {
                    textAlign: "center",
                },
                children: toText(printf("%.2f"))(row.Tax) + " SEK",
            }), createElement("td", {
                style: {
                    fontWeight: "bold",
                    textAlign: "center",
                },
                children: (arg10_2 = op_Addition(row.Tax, row.Cost), toText(printf("%.2f SEK"))(arg10_2)),
            })]),
        }))) : empty_1();
    }, matchValue[1]))), details))), createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(children_4)),
    }))])]]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_14)));
}

export function renderActions(renderActionsInputProps) {
    const unitVar2 = renderActionsInputProps.unitVar2;
    const dispatch = renderActionsInputProps.dispatch;
    const invoice = renderActionsInputProps.invoice;
    const patternInput = useFeliz_React__React_useState_Static_1505({
        Descr: "",
        Func: new Msg(7, invoice.Id),
        Show: false,
        Title: "",
    });
    const showConfirmation = patternInput[0];
    const setShowConfirmation = patternInput[1];
    const isDraft = equals_1(invoice.State, new InvoiceState(0)) ? true : equals_1(invoice.State, new InvoiceState(3));
    const props_23 = ofArray([["className", "is-hoverable"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let f;
        return append(showConfirmation.Show ? singleton_1((f = showConfirmation.Func, confirmationDialog(showConfirmation.Title, createElement("span", {
            children: showConfirmation.Descr,
        }), () => {
            dispatch(f);
            setShowConfirmation({
                Descr: "",
                Func: f,
                Show: false,
                Title: "",
            });
        }, () => {
            setShowConfirmation({
                Descr: "",
                Func: new Msg(7, invoice.Id),
                Show: false,
                Title: "",
            });
        }, "Confirm"))) : singleton_1(null), delay(() => {
            let props_3;
            return append(singleton_1((props_3 = ofArray([["className", "is-small"], ["className", "is-rounded"], ["className", "is-light"], ["children", Interop_reactApi.Children.toArray([createElement("span", createObj(Helpers_combineClasses("icon", singleton(["className", "fas fa-ellipsis-v fa-sm"]))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_3))))), delay(() => {
                let elms_1, elms;
                return singleton_1((elms_1 = singleton((elms = toList(delay(() => {
                    let props_11;
                    return append(singleton_1((props_11 = toList(delay(() => append((!isDraft) ? singleton_1(["className", "has-text-grey-light"]) : empty_1(), delay(() => append(isDraft ? singleton_1(["onClick", (x) => {
                        setShowConfirmation({
                            Descr: "Are you sure you want to send invoice?",
                            Func: new Msg(7, invoice.Id),
                            Show: true,
                            Title: "Sending confirmation",
                        });
                        x.preventDefault();
                    }]) : empty_1(), delay(() => {
                        let props_9;
                        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_9 = toList(delay(() => append(isDraft ? singleton_1(["className", "has-text-primary"]) : empty_1(), delay(() => {
                            let props_5;
                            return singleton_1(["children", Interop_reactApi.Children.toArray([(props_5 = ofArray([["className", "fas fa-envelope fa-sm"], ["style", {
                                marginRight: 15,
                            }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_5)))), createElement("span", createObj(Helpers_combineClasses("", singleton(["children", "Send"]))))])]);
                        })))), createElement("div", createObj(Helpers_combineClasses("field", props_9))))])]);
                    })))))), createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_11))))), delay(() => {
                        let props_19, props_17, props_13;
                        return isDraft ? singleton_1((props_19 = ofArray([["onClick", (x_1) => {
                            setShowConfirmation({
                                Descr: "Are you sure you want to delete the invoice draft?",
                                Func: new Msg(8, invoice.Id),
                                Show: true,
                                Title: "Delete invoice draft",
                            });
                            x_1.preventDefault();
                        }], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "has-text-danger"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["className", "fas fa-trash"], ["style", {
                            marginRight: 15,
                        }]]), createElement("span", createObj(Helpers_combineClasses("icon", props_13)))), createElement("span", createObj(Helpers_combineClasses("", singleton(["children", "Delete"]))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_17))))])]]), createElement("a", createObj(Helpers_combineClasses("dropdown-item", props_19))))) : empty_1();
                    }));
                })), createElement("div", {
                    className: "dropdown-content",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))), createElement("div", {
                    className: "dropdown-menu",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                })));
            }));
        }));
    }))))]]);
    return createElement("div", createObj(Helpers_combineClasses("dropdown", props_23)));
}

export function invoicesList(invoicesListInputProps) {
    const unitVar2 = invoicesListInputProps.unitVar2;
    const dispatch = invoicesListInputProps.dispatch;
    const invoices = invoicesListInputProps.invoices;
    const patternInput = useFeliz_React__React_useState_Static_1505(0);
    const setExpendedRow = patternInput[1];
    const expandedRow = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(0);
    const setConfirmatingInvoice = patternInput_1[1];
    const confirmatingInvoice = patternInput_1[0] | 0;
    const renderConfirmationDialog = (confirmatingInvoice > 0) ? confirmationDialog("Sending postpayment invoice", createElement("span", {
        children: ["Are you sure you want to send postpayment invoice?"],
    }), () => {
        dispatch(new Msg(7, confirmatingInvoice));
        setConfirmatingInvoice(0);
    }, () => {
        setConfirmatingInvoice(0);
    }, "Confirm") : (null);
    return createElement("tbody", {
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(renderConfirmationDialog), delay(() => collect((invoice_1) => {
            let invoice, tdStyle, expandIcon, copyOfStruct, children;
            return append(singleton_1((invoice = invoice_1, (tdStyle = ["style", {
                textAlign: "center",
                verticalAlign: "middle",
                alignItems: "center",
            }], (expandIcon = ((invoice.Id === expandedRow) ? createElement("a", {
                onClick: (_arg1) => {
                    setExpendedRow(0);
                },
                children: Interop_reactApi.Children.toArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-angle-down")), [])]),
            }) : createElement("a", {
                onClick: (_arg2) => {
                    setExpendedRow(invoice.Id);
                },
                children: Interop_reactApi.Children.toArray([Fa_i(singleton(new Fa_IconOption(11, "fas fa-angle-right")), [])]),
            })), createElement("tr", {
                children: Interop_reactApi.Children.toArray([createElement("td", createObj(ofArray([tdStyle, ["children", Interop_reactApi.Children.toArray([expandIcon])]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toText(interpolate("%P()", [invoice.Id]))]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toText(interpolate("%P()", [invoice.CompanyId]))]]))), createElement("td", createObj(ofArray([tdStyle, ["children", (invoice.P1Id != null) ? ((copyOfStruct = value_53(invoice.P1Id), int32ToString(copyOfStruct))) : ""]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toText(interpolate("%P()", [invoice.CompanyName]))]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toString(invoice.DateFrom, "yyyy-MM-dd HH:mm:ss")]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toString(invoice.DateTo, "yyyy-MM-dd HH:mm:ss")]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toText(interpolate("%P()", [invoice.SmsCount]))]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toText(printf("%.2f SEK"))(invoice.TotalAmount)]]))), createElement("td", createObj(ofArray([tdStyle, ["children", toText(interpolate("%P()", [invoice.State]))]]))), (children = singleton(createElement(renderActions, {
                    invoice: invoice,
                    dispatch: dispatch,
                })), createElement("td", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))]),
            }))))), delay(() => {
                let children_2;
                return (expandedRow === invoice_1.Id) ? singleton_1((children_2 = singleton(createElement("td", {
                    colSpan: 12,
                    children: Interop_reactApi.Children.toArray([invoiceDetailRow(invoice_1.Details)]),
                })), createElement("tr", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_2)),
                }))) : empty_1();
            }));
        }, invoices))))))),
    });
}

export function table(state, dispatch) {
    const matchValue = state.Invoices;
    switch (matchValue.tag) {
        case 1: {
            return "Loading...";
        }
        case 2: {
            const data = matchValue.fields[0];
            const sortHeader = (field, name) => View_render(state.Sorting, field, name, (arg) => {
                dispatch(new Msg(4, arg));
            });
            const props_4 = ofArray([["className", "is-fullwidth"], ["style", {
                fontSize: 13 + "px",
            }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                let children;
                return append(singleton_1(createElement("thead", {
                    children: Interop_reactApi.Children.toArray([(children = ofArray([createElement("th", {
                        width: 40,
                    }), sortHeader(new InvoiceSortFields(0), "InvoiceId"), sortHeader(new InvoiceSortFields(2), "CustomerId"), sortHeader(new InvoiceSortFields(3), "P1 Id"), sortHeader(new InvoiceSortFields(1), "CustomerName"), sortHeader(new InvoiceSortFields(4), "Start (UTC)"), sortHeader(new InvoiceSortFields(5), "End (UTC)"), sortHeader(new InvoiceSortFields(6), "Sms"), sortHeader(new InvoiceSortFields(7), "Total"), sortHeader(new InvoiceSortFields(8), "State"), createElement("th", {})]), createElement("tr", {
                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                    }))]),
                })), delay(() => ((!equals_1(data, empty())) ? singleton_1(createElement(invoicesList, {
                    invoices: data,
                    dispatch: dispatch,
                })) : empty_1())));
            }))))]]);
            return createElement("table", createObj(Helpers_combineClasses("table", props_4)));
        }
        default: {
            return "Invoices weren\u0027t loaded";
        }
    }
}

export function paging(state, dispatch) {
    const o_1 = map((s) => View_render_1(s, (arg) => {
        dispatch(new Msg(3, arg));
    }), state.Paging);
    if (o_1 == null) {
        return null;
    }
    else {
        return o_1;
    }
}

export function view(state, dispatch) {
    const elms = ofArray([filter(state, dispatch), createElement(invoiceDraftsSummary, {
        draftSummary: state.DraftSummary,
        dispatch: dispatch,
    }), table(state, dispatch), paging(state, dispatch)]);
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

