import { api } from "../../Api.js";
import { State } from "./Model.js";
import { Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";

export function Api_makeInvoicesByDateCmd(date) {
    return api().makeInvoicesByDate(date);
}

export function Update_init() {
    return [new State(""), Cmd_none()];
}

export function Update_update(msg, prevState) {
    if (msg.tag === 1) {
        return [prevState, Cmd_none()];
    }
    else {
        return [prevState, Cmd_none()];
    }
}

