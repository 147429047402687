import { Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { list_type, bool_type, class_type, union_type, record_type, lambda_type, unit_type, option_type, string_type, int32_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { Api_AddVirtualNumberRequest, Api_AddVirtualNumberRequest$reflection, Contract_VirtualNumber$reflection, Api_AssignVirtualNumberRequest, Api_AssignVirtualNumberRequest$reflection } from "../../Shared/VirtualNumbers.js";
import { View_render as View_render_1 } from "../Common/Paging.js";
import { Msg$reflection, Msg } from "./Types.js";
import { defaultArg, map } from "../../.fable/fable-library.3.2.9/Option.js";
import { useFeliz_React__React_useState_Static_1505 } from "../../.fable/Feliz.1.49.0/React.fs.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import * as react from "react";
import { int32ToString, createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_extractClasses, Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { map as map_1, isEmpty, cons, ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { join, isNullOrEmpty, interpolate, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { parse } from "../../.fable/fable-library.3.2.9/Int32.js";
import { Errors_ApplicationError$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D, Deferred$1 } from "../../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { empty, append, singleton as singleton_1, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { ConfirmationDialogProps, ConfirmationDialog } from "../Components/Dialog.js";
import { Helpers_nothing } from "../../.fable/Fable.React.7.4.1/Fable.React.Helpers.fs.js";
import { apiVirtualNumbers } from "../../Api.js";
import { toString } from "../../.fable/fable-library.3.2.9/Date.js";
import { Deferred$1$reflection } from "../Common/Extensions.js";

class Components_ConfirmationDialogProps extends Record {
    constructor(VirtualNumberId, VirtualNumber, Prefix, ConfirmAction, CancelAction) {
        super();
        this.VirtualNumberId = (VirtualNumberId | 0);
        this.VirtualNumber = VirtualNumber;
        this.Prefix = Prefix;
        this.ConfirmAction = ConfirmAction;
        this.CancelAction = CancelAction;
    }
}

function Components_ConfirmationDialogProps$reflection() {
    return record_type("VirtualNumbers.Components.ConfirmationDialogProps", [], Components_ConfirmationDialogProps, () => [["VirtualNumberId", int32_type], ["VirtualNumber", string_type], ["Prefix", option_type(string_type)], ["ConfirmAction", lambda_type(Api_AssignVirtualNumberRequest$reflection(), unit_type)], ["CancelAction", lambda_type(unit_type, unit_type)]]);
}

function Components_renderPaging(state, dispatch) {
    return View_render_1(state, (arg) => {
        dispatch(new Msg(3, arg));
    });
}

function Components_renderPagingOpt(state, dispatch) {
    const o_1 = map((s) => Components_renderPaging(s, dispatch), state);
    if (o_1 == null) {
        return null;
    }
    else {
        return o_1;
    }
}

function Components_AssignVirtualNumberDialog(props) {
    let props_32, props_7, props_24, elms, props_21, props_14, props_12, elms_3, elms_2, elms_1, props_30;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const props_34 = ofArray([["className", "is-active"], ["style", {
        textAlign: "left",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg1) => {
        props.CancelAction();
    }])))), (props_32 = singleton(["children", Interop_reactApi.Children.toArray([(props_7 = singleton(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("modal-card-title", singleton(["children", "Assign virtual number"])))), createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", (_arg2) => {
        props.CancelAction();
    }]))))])]), createElement("header", createObj(Helpers_combineClasses("modal-card-head", props_7)))), (props_24 = singleton(["children", Interop_reactApi.Children.toArray([createElement("div", {
        children: Interop_reactApi.Children.toArray([(elms = singleton(defaultArg(map((prefix) => createElement("p", {
            children: toText(interpolate("Type Company ID which will be assigned to %P() Prefix: %P():", [props.VirtualNumber, prefix])),
        }), props.Prefix), createElement("p", {
            children: toText(interpolate("Type Company ID which will be assigned to %P():", [props.VirtualNumber])),
        }))), createElement("div", {
            className: "block",
            children: Interop_reactApi.Children.toArray(Array.from(elms)),
        })), (props_21 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["style", {
            minWidth: 100,
        }], ["children", "Company ID"]]), createElement("label", createObj(Helpers_combineClasses("label", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_14)))), (elms_3 = singleton((elms_2 = singleton((elms_1 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Company ID"], ["onChange", (ev) => {
            patternInput[1](ev.target.value);
        }]])))))), createElement("div", {
            className: "control",
            children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
        }))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
        }))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_21))))]),
    })])]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_24)))), (props_30 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Cancel"], ["onClick", (_arg3) => {
        props.CancelAction();
    }]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", "Assign"], ["onClick", (_arg4) => {
        props.ConfirmAction(new Api_AssignVirtualNumberRequest(props.VirtualNumberId, parse(patternInput[0], 511, false, 32)));
        props.CancelAction();
    }]]))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_30))))])]), createElement("div", createObj(Helpers_combineClasses("modal-card", props_32))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_34)));
}

class Components_AssignOldestVirtualNumberButtonProps extends Record {
    constructor(LoadOldestVirtualNumber, ConfirmAction) {
        super();
        this.LoadOldestVirtualNumber = LoadOldestVirtualNumber;
        this.ConfirmAction = ConfirmAction;
    }
}

function Components_AssignOldestVirtualNumberButtonProps$reflection() {
    return record_type("VirtualNumbers.Components.AssignOldestVirtualNumberButtonProps", [], Components_AssignOldestVirtualNumberButtonProps, () => [["LoadOldestVirtualNumber", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [Contract_VirtualNumber$reflection(), Errors_ApplicationError$reflection()], FSharpResult$2, () => [[["ResultValue", Contract_VirtualNumber$reflection()]], [["ErrorValue", Errors_ApplicationError$reflection()]]])]))], ["ConfirmAction", lambda_type(Api_AssignVirtualNumberRequest$reflection(), unit_type)]]);
}

function Components_AssignOldestVirtualNumberButton(props) {
    let props_34, props_7, props_26, props_32, props_30;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setShowConfirmation = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const virtualNumber = patternInput_2[0];
    const setVirtualNumber = patternInput_2[1];
    const clear = () => {
        setVirtualNumber(new Deferred$1(0));
        setShowConfirmation(false);
    };
    const startLoadingData = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => props.LoadOldestVirtualNumber(), setVirtualNumber);
    let confirmation;
    const props_36 = ofArray([["className", "is-active"], ["style", {
        textAlign: "left",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg1) => {
        clear();
    }])))), (props_34 = singleton(["children", Interop_reactApi.Children.toArray([(props_7 = singleton(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("modal-card-title", singleton(["children", "Assign virtual number"])))), createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", (_arg2) => {
        clear();
    }]))))])]), createElement("header", createObj(Helpers_combineClasses("modal-card-head", props_7)))), (props_26 = singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let elms, props_21, props_14, props_12, elms_3, elms_2, elms_1;
        return (virtualNumber.tag === 2) ? ((virtualNumber.fields[0].tag === 0) ? singleton_1(createElement("div", {
            children: Interop_reactApi.Children.toArray([(elms = singleton(defaultArg(map((prefix) => createElement("p", {
                children: toText(interpolate("Type Company ID which will be assigned to %P() Prefix: %P():", [virtualNumber.fields[0].fields[0].PhoneNumber, prefix])),
            }), virtualNumber.fields[0].fields[0].Prefix), createElement("p", {
                children: toText(interpolate("Type Company ID which will be assigned to %P():", [virtualNumber.fields[0].fields[0].PhoneNumber])),
            }))), createElement("div", {
                className: "block",
                children: Interop_reactApi.Children.toArray(Array.from(elms)),
            })), (props_21 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([(props_12 = ofArray([["style", {
                minWidth: 100,
            }], ["children", "Company ID"]]), createElement("label", createObj(Helpers_combineClasses("label", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_14)))), (elms_3 = singleton((elms_2 = singleton((elms_1 = singleton(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Company ID"], ["onChange", (ev) => {
                patternInput[1](ev.target.value);
            }]])))))), createElement("div", {
                className: "control",
                children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
            }))), createElement("div", {
                className: "field",
                children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
            }))), createElement("div", {
                className: "field-body",
                children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
            }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_21))))]),
        })) : singleton_1(createElement("div", {
            children: "There is no free virtual number",
        }))) : ((virtualNumber.tag === 0) ? singleton_1(createElement("div", {
            children: "Loading ...",
        })) : ((virtualNumber.tag === 1) ? singleton_1(createElement("div", {
            children: "Loading ...",
        })) : singleton_1(createElement("div", {
            children: "There is no free virtual number",
        }))));
    }))))]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_26)))), (props_32 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", "Cancel"], ["onClick", (_arg3) => {
        clear();
    }]])))), (props_30 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(singleton_1(["children", "Assign"]), delay(() => ((virtualNumber.tag === 2) ? ((virtualNumber.fields[0].tag === 0) ? singleton_1(["onClick", (_arg4) => {
        props.ConfirmAction(new Api_AssignVirtualNumberRequest(virtualNumber.fields[0].fields[0].Id, parse(patternInput[0], 511, false, 32)));
        clear();
    }]) : singleton_1(["disabled", true])) : singleton_1(["disabled", true])))))))), createElement("button", createObj(Helpers_combineClasses("button", props_30))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_32))))])]), createElement("div", createObj(Helpers_combineClasses("modal-card", props_34))))])]]);
    confirmation = createElement("div", createObj(Helpers_combineClasses("modal", props_36)));
    const button = createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-outlined"], ["children", "Assign"], ["onClick", (_arg5) => {
        startLoadingData();
        setShowConfirmation(true);
    }]]))));
    return react.createElement(react.Fragment, {}, ...toList(delay(() => append(patternInput_1[0] ? singleton_1(confirmation) : empty(), delay(() => singleton_1(button))))));
}

class Components_CreateVirtualNumberButtonProps extends Record {
    constructor(CreateVirtualNumber) {
        super();
        this.CreateVirtualNumber = CreateVirtualNumber;
    }
}

function Components_CreateVirtualNumberButtonProps$reflection() {
    return record_type("VirtualNumbers.Components.CreateVirtualNumberButtonProps", [], Components_CreateVirtualNumberButtonProps, () => [["CreateVirtualNumber", lambda_type(Api_AddVirtualNumberRequest$reflection(), unit_type)]]);
}

function Components_CreateVirtualNumberButton(props) {
    let props_11, props_3, props_1, elms_1, elms, props_7, props_23, props_15, props_13, elms_3, elms_2, props_19;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setCreatingNumber = patternInput[1];
    const creatingNumber = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setPrefix = patternInput_1[1];
    let confirmationContent;
    const props_25 = ofArray([["className", "is-fluid"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["style", {
        minWidth: 140,
    }], ["children", "Phone number"]]), createElement("label", createObj(Helpers_combineClasses("label", props_1))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_3)))), (elms_1 = singleton((elms = singleton((props_7 = singleton(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Virtual number"], ["onChange", (ev) => {
        setCreatingNumber(ev.target.value);
    }]])))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_7))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_11)))), (props_23 = ofArray([["className", "is-horizontal"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", "is-normal"], ["children", Interop_reactApi.Children.toArray([(props_13 = ofArray([["style", {
        minWidth: 140,
    }], ["children", "Prefix (optional)"]]), createElement("label", createObj(Helpers_combineClasses("label", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_15)))), (elms_3 = singleton((elms_2 = singleton((props_19 = singleton(["children", Interop_reactApi.Children.toArray([createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["placeholder", "Prefix"], ["onChange", (ev_1) => {
        const str = ev_1.target.value;
        if (!isNullOrEmpty(str)) {
            setPrefix(str);
        }
        else {
            setPrefix(void 0);
        }
    }]])))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_19))))), createElement("div", {
        className: "field",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))), createElement("div", {
        className: "field-body",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_23))))])]]);
    confirmationContent = createElement("div", createObj(Helpers_combineClasses("container", props_25)));
    const resetForm = () => {
        setCreatingNumber(void 0);
        setPrefix(void 0);
    };
    return react.createElement(react.Fragment, {}, defaultArg(map((number_1) => createElement(ConfirmationDialog, new ConfirmationDialogProps("Create virtual number", confirmationContent, () => {
        const matchValue = map((number) => (new Api_AddVirtualNumberRequest(number, patternInput_1[0])), creatingNumber);
        if (matchValue == null) {
        }
        else {
            props.CreateVirtualNumber(matchValue);
            resetForm();
        }
    }, () => {
        resetForm();
    }, "Create", "Cancel")), creatingNumber), Helpers_nothing), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", "Create"], ["onClick", (_arg3) => {
        setCreatingNumber("");
    }]])))));
}

class Components_HeaderComponentProps extends Record {
    constructor(FilterChanged, KindChanged, Dispatch) {
        super();
        this.FilterChanged = FilterChanged;
        this.KindChanged = KindChanged;
        this.Dispatch = Dispatch;
    }
}

function Components_HeaderComponentProps$reflection() {
    return record_type("VirtualNumbers.Components.HeaderComponentProps", [], Components_HeaderComponentProps, () => [["FilterChanged", lambda_type(string_type, unit_type)], ["KindChanged", lambda_type(string_type, unit_type)], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

function Components_HeaderComponent(props) {
    let props_26, props_11, elms, props_8, props_6, props_1, props_4, props_24, elms_2, elms_1, patternInput, elms_5, elms_4, elms_3;
    const elms_6 = singleton((props_26 = ofArray([["className", "has-background-white"], ["style", {
        padding: 16,
    }], ["children", Interop_reactApi.Children.toArray([(props_11 = singleton(["children", Interop_reactApi.Children.toArray([(elms = singleton((props_8 = singleton(["children", Interop_reactApi.Children.toArray([(props_6 = ofArray([["className", "has-icons-left"], ["children", Interop_reactApi.Children.toArray([(props_1 = ofArray([["style", {
        width: 400,
    }], ["placeholder", "Search virtual number"], ["onChange", (ev) => {
        props.FilterChanged(ev.target.value);
    }]]), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_1))))), (props_4 = ofArray([["className", "is-left"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: "fas fa-search",
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_4))))])]]), createElement("p", createObj(Helpers_combineClasses("control", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_8))))), createElement("div", {
        className: "level-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("level-left", props_11)))), (props_24 = singleton(["children", Interop_reactApi.Children.toArray([(elms_2 = singleton((elms_1 = singleton((patternInput = Helpers_extractClasses(ofArray([["onChange", (ev_1) => {
        props.KindChanged(ev_1.target.value);
    }], ["children", Interop_reactApi.Children.toArray([createElement("option", {
        children: "All",
    }), createElement("option", {
        children: "Only free",
    })])]])), createElement("div", {
        className: join(" ", cons("select", patternInput[0])),
        children: Interop_reactApi.Children.toArray([createElement("select", createObj(patternInput[1]))]),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    }))), createElement("div", {
        className: "level-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    })), (elms_5 = singleton((elms_4 = singleton((elms_3 = ofArray([createElement(Components_CreateVirtualNumberButton, new Components_CreateVirtualNumberButtonProps((arg) => {
        props.Dispatch(new Msg(6, arg));
    })), createElement(Components_AssignOldestVirtualNumberButton, new Components_AssignOldestVirtualNumberButtonProps(apiVirtualNumbers().getFree, (arg_1) => {
        props.Dispatch(new Msg(8, arg_1));
    }))]), createElement("div", {
        className: "buttons",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    }))), createElement("div", {
        className: "level-item",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))])]), createElement("div", createObj(Helpers_combineClasses("level-right", props_24))))])]]), createElement("nav", createObj(Helpers_combineClasses("level", props_26)))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    });
}

class Components_ActionButtonComponentProps extends Record {
    constructor(VirtualNumberId, VirtualNumber, CustomerId, Prefix, Dispatch) {
        super();
        this.VirtualNumberId = (VirtualNumberId | 0);
        this.VirtualNumber = VirtualNumber;
        this.CustomerId = CustomerId;
        this.Prefix = Prefix;
        this.Dispatch = Dispatch;
    }
}

function Components_ActionButtonComponentProps$reflection() {
    return record_type("VirtualNumbers.Components.ActionButtonComponentProps", [], Components_ActionButtonComponentProps, () => [["VirtualNumberId", int32_type], ["VirtualNumber", string_type], ["CustomerId", option_type(int32_type)], ["Prefix", option_type(string_type)], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

function Components_ActionButtonComponent(props) {
    let props_25, props_13, props_11, props_9, props_23, props_21;
    const patternInput = useFeliz_React__React_useState_Static_1505(void 0);
    const setAssigningNumber = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const setUnassigningNumber = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(void 0);
    const setDeletingNumber = patternInput_2[1];
    return react.createElement(react.Fragment, {}, defaultArg(map((virtualNumberId) => createElement(Components_AssignVirtualNumberDialog, new Components_ConfirmationDialogProps(virtualNumberId, props.VirtualNumber, props.Prefix, (arg) => {
        props.Dispatch(new Msg(8, arg));
    }, () => {
        setAssigningNumber(void 0);
    })), patternInput[0]), Helpers_nothing), defaultArg(map((virtualNumberId_1) => createElement(ConfirmationDialog, new ConfirmationDialogProps("Unassign virtual number", createElement("p", {
        children: toText(interpolate("Are you sure you want to unassign the virtual number %P()?", [props.VirtualNumber])),
    }), () => {
        props.Dispatch(new Msg(9, virtualNumberId_1));
        setUnassigningNumber(void 0);
    }, () => {
        setUnassigningNumber(void 0);
    }, "Unassign", "Cancel")), patternInput_1[0]), Helpers_nothing), defaultArg(map((tupledArg) => {
        const phoneNumber = tupledArg[1];
        return createElement(ConfirmationDialog, new ConfirmationDialogProps("Delete virtual number", defaultArg(map((prefix_1) => createElement("div", {
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: toText(interpolate("Are you sure you want to delete the virtual number:", [])),
            }), createElement("p", {
                style: {
                    fontWeight: "bold",
                },
                children: toText(interpolate("%P()    Prefix: %P()", [phoneNumber, prefix_1])),
            })]),
        }), tupledArg[2]), createElement("div", {
            children: Interop_reactApi.Children.toArray([createElement("p", {
                children: toText(interpolate("Are you sure you want to delete the virtual number:", [])),
            }), createElement("p", {
                style: {
                    fontWeight: "bold",
                },
                children: toText(interpolate("%P()", [phoneNumber])),
            })]),
        })), () => {
            props.Dispatch(new Msg(10, props.VirtualNumberId));
            setDeletingNumber(void 0);
        }, () => {
            setDeletingNumber(void 0);
        }, "Delete", "Cancel"));
    }, patternInput_2[0]), Helpers_nothing), (props_25 = ofArray([["className", "is-hoverable"], ["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_13 = singleton(["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["aria-controls", join(" ", ["dropdown-menu"])], ["className", "is-inverted"], ["className", "is-rounded"], ["className", "is-small"], ["children", Interop_reactApi.Children.toArray([(props_9 = ofArray([["className", "is-small"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: "fas fa-ellipsis-v",
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_9))))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_11))))])]), createElement("div", createObj(Helpers_combineClasses("dropdown-trigger", props_13)))), (props_23 = ofArray([["id", "dropdown-menu"], ["role", join(" ", ["menu"])], ["children", Interop_reactApi.Children.toArray([(props_21 = singleton(["children", Interop_reactApi.Children.toArray([defaultArg(map((_arg7) => createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["children", "Unassign"], ["onClick", (_arg6) => {
        setUnassigningNumber(props.VirtualNumberId);
    }]])))), props.CustomerId), createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["children", "Assign"], ["onClick", (_arg8) => {
        setAssigningNumber(props.VirtualNumberId);
    }]]))))), createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["children", "Delete"], ["onClick", (_arg9) => {
        setDeletingNumber([props.VirtualNumberId, props.VirtualNumber, props.Prefix]);
    }]]))))])]), createElement("div", createObj(Helpers_combineClasses("dropdown-content", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("dropdown-menu", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("dropdown", props_25)))));
}

class Components_VirtualNumberLineProps extends Record {
    constructor(VirtualNumberId, PhoneNumber, Prefix, CustomerId, CustomerName, AssignedAt, UnassignedAt, IsAssigned, Dispatch) {
        super();
        this.VirtualNumberId = (VirtualNumberId | 0);
        this.PhoneNumber = PhoneNumber;
        this.Prefix = Prefix;
        this.CustomerId = CustomerId;
        this.CustomerName = CustomerName;
        this.AssignedAt = AssignedAt;
        this.UnassignedAt = UnassignedAt;
        this.IsAssigned = IsAssigned;
        this.Dispatch = Dispatch;
    }
}

function Components_VirtualNumberLineProps$reflection() {
    return record_type("VirtualNumbers.Components.VirtualNumberLineProps", [], Components_VirtualNumberLineProps, () => [["VirtualNumberId", int32_type], ["PhoneNumber", string_type], ["Prefix", option_type(string_type)], ["CustomerId", option_type(int32_type)], ["CustomerName", string_type], ["AssignedAt", string_type], ["UnassignedAt", string_type], ["IsAssigned", bool_type], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

function Components_toVirtualNumberLineProps(dispatch, v) {
    let patternInput;
    const matchValue = v.State;
    patternInput = ((matchValue.tag === 1) ? [true, void 0, matchValue.fields[2], matchValue.fields[0], matchValue.fields[1]] : [false, matchValue.fields[0], void 0, void 0, void 0]);
    return new Components_VirtualNumberLineProps(v.Id, v.PhoneNumber, v.Prefix, patternInput[3], defaultArg(patternInput[4], ""), defaultArg(map((date) => toString(date, "yyyy-MM-dd HH:mm:ss"), patternInput[2]), ""), defaultArg(map((date_1) => toString(date_1, "yyyy-MM-dd HH:mm:ss"), patternInput[1]), ""), patternInput[0], dispatch);
}

function Components_VirtualNumberLine(props) {
    const customerId = defaultArg(map((value) => int32ToString(value), props.CustomerId), "");
    return createElement("tr", {
        children: Interop_reactApi.Children.toArray([createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [props.VirtualNumberId])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [props.PhoneNumber])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [defaultArg(map((x) => x, props.Prefix), "")])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [customerId])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [props.CustomerName])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [props.AssignedAt])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [props.UnassignedAt])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: toText(interpolate("%P()", [props.IsAssigned])),
        }), createElement("td", {
            style: {
                textAlign: "center",
            },
            children: Interop_reactApi.Children.toArray([createElement(Components_ActionButtonComponent, new Components_ActionButtonComponentProps(props.VirtualNumberId, props.PhoneNumber, props.CustomerId, props.Prefix, props.Dispatch))]),
        })]),
    });
}

class Components_TableProps extends Record {
    constructor(Numbers, Dispatch) {
        super();
        this.Numbers = Numbers;
        this.Dispatch = Dispatch;
    }
}

function Components_TableProps$reflection() {
    return record_type("VirtualNumbers.Components.TableProps", [], Components_TableProps, () => [["Numbers", Deferred$1$reflection(list_type(Contract_VirtualNumber$reflection()))], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

function Components_TableComponent(props) {
    const props_17 = ofArray([["className", "is-fullwidth"], ["className", "is-hoverable"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
        let children;
        return append(singleton_1((children = singleton(createElement("tr", {
            children: Interop_reactApi.Children.toArray([createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Id",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Phone number",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Prefix",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Customer Id",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Customer name",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Assigned At (UTC)",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Unassigned At (UTC)",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "Assigned",
            }), createElement("th", {
                style: {
                    textAlign: "center",
                },
                children: "",
            })]),
        })), createElement("thead", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let children_2;
            const matchValue = props.Numbers;
            return (matchValue.tag === 2) ? (isEmpty(matchValue.fields[0]) ? singleton_1((children_2 = singleton(createElement("p", {
                children: "There are not any virtual numbers",
            })), createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(children_2)),
            }))) : singleton_1(createElement("tbody", {
                children: Interop_reactApi.Children.toArray(Array.from(map_1((arg) => createElement(Components_VirtualNumberLine, Components_toVirtualNumberLineProps(props.Dispatch, arg)), matchValue.fields[0]))),
            }))) : ((matchValue.tag === 1) ? singleton_1(createElement("p", {
                children: "Loading...",
            })) : singleton_1(Helpers_nothing));
        }));
    }))))]]);
    return createElement("table", createObj(Helpers_combineClasses("table", props_17)));
}

export const View_renderTitle = createElement("h4", createObj(Helpers_combineClasses("title is-4", singleton(["children", "Virtual Numbers"]))));

export function View_render(state, dispatch) {
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement(Components_HeaderComponent, new Components_HeaderComponentProps((arg) => {
            dispatch(new Msg(2, arg));
        }, (arg_1) => {
            dispatch(new Msg(15, arg_1));
        }, dispatch)), createElement(Components_TableComponent, new Components_TableProps(state.VirtualNumbers, dispatch)), Components_renderPagingOpt(state.Paging, dispatch)]),
    });
}

