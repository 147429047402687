import { Cmd_batch, Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { customerApi } from "../../Api.js";
import { State, Msg } from "./Model.js";
import { singleton, empty } from "../../.fable/fable-library.3.2.9/List.js";
import { remove, add, empty as empty_1 } from "../../.fable/fable-library.3.2.9/Map.js";
import { toastError } from "../Components/Toast.js";

export function Api_getCampaignStatsCmd() {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, () => customerApi().getCampaignStats(), void 0, (arg0) => (new Msg(0, arg0)));
}

export function Api_pauseCampaignCmd(campaignId) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, () => customerApi().pauseCampaign(campaignId), void 0, () => (new Msg(3, campaignId)), (arg0) => (new Msg(6, arg0)));
}

export function Api_unPauseCampaignCmd(campaignId) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, () => customerApi().unPauseCampaign(campaignId), void 0, () => (new Msg(4, campaignId)), (arg0) => (new Msg(6, arg0)));
}

export function Update_init() {
    return [new State(empty(), empty_1(), true), Cmd_batch(singleton(Api_getCampaignStatsCmd()))];
}

export function Update_update(msg, model) {
    switch (msg.tag) {
        case 5: {
            return [new State(model.CampaignStats, model.BusyCampaign, true), Cmd_batch(singleton(Api_getCampaignStatsCmd()))];
        }
        case 1: {
            const campaignId = msg.fields[0] | 0;
            return [new State(model.CampaignStats, add(campaignId, true, model.BusyCampaign), model.IsLoading), Cmd_batch(singleton(Api_pauseCampaignCmd(campaignId)))];
        }
        case 2: {
            const campaignId_1 = msg.fields[0] | 0;
            return [new State(model.CampaignStats, add(campaignId_1, true, model.BusyCampaign), model.IsLoading), Cmd_batch(singleton(Api_unPauseCampaignCmd(campaignId_1)))];
        }
        case 3: {
            return [new State(model.CampaignStats, remove(msg.fields[0], model.BusyCampaign), true), Cmd_batch(singleton(Api_getCampaignStatsCmd()))];
        }
        case 4: {
            return [new State(model.CampaignStats, remove(msg.fields[0], model.BusyCampaign), true), Cmd_batch(singleton(Api_getCampaignStatsCmd()))];
        }
        case 6: {
            return [model, Cmd_batch(singleton(toastError("Something went wrong")))];
        }
        default: {
            return [new State(msg.fields[0], model.BusyCampaign, false), empty()];
        }
    }
}

