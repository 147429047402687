import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "../../.fable/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { Api_IInvoiceStore$reflection, Api_ICustomerApi$reflection, Route_builder } from "../../Shared/Api.js";
import { getBearerToken } from "../../LocalStorage.js";

export function api() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_ICustomerApi$reflection,
    });
}

export function apiInvoices() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_IInvoiceStore$reflection,
    });
}

