import { Union } from "../../../fable-library.3.2.9/Types.js";
import { union_type, list_type, class_type, string_type } from "../../../fable-library.3.2.9/Reflection.js";
import { Color_ofColor, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Color_IColor$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.3.2.9/MapUtil.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CustomClass", "Props", "Color", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Help.Option", [], Option, () => [[["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", Color_IColor$reflection()]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function help(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            }
        }
    }, "help"), (props_1, children_1) => react.createElement("p", keyValueList(props_1, 1), ...children_1), children);
}

