import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { referenceApi, validationExceptionsApi } from "../../Api.js";
import { EditSenderState as EditSenderState_6, EditPhoneState as EditPhoneState_2, State, ActiveTab as ActiveTab_2, Msg } from "./Model.js";
import { date, now } from "../../.fable/fable-library.3.2.9/Date.js";
import { Country, PhoneValidationForm, Sender } from "../../Shared/Shared.js";
import { singleton as singleton_1, append, ofArray, empty } from "../../.fable/fable-library.3.2.9/List.js";
import { Cmd_none, Cmd_batch } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_fromAsync } from "../Common/Extensions.js";
import { isNullOrWhiteSpace } from "../../.fable/fable-library.3.2.9/String.js";
import { empty as empty_1, singleton as singleton_2, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { message } from "../Components/Toast.js";
import { success } from "../../.fable/Thoth.Elmish.Toast.3.0.0/Toast.fs.js";

export const InvalidSender_get = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().getSenders(), (_arg1) => singleton.Return(new Msg(22, _arg1)))), (_arg2) => singleton.Return(new Msg(23))));

export function InvalidSender_add(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().addSender(form), () => singleton.Return(new Msg(10)))), (_arg2) => singleton.Return(new Msg(23))));
}

export function InvalidSender_update(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().updateSender(form), () => singleton.Return(new Msg(11)))), (_arg2) => singleton.Return(new Msg(23))));
}

export function InvalidSender_delete(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().deleteSender(form.Id), () => singleton.Return(new Msg(19)))), (_arg2) => singleton.Return(new Msg(23))));
}

export const InvalidSender_defaultForm = new Sender("", (() => {
    let copyOfStruct = now();
    return date(copyOfStruct);
})(), 0);

export const ExceptionPhone_getCountryList = singleton.Delay(() => singleton.Bind(referenceApi().getCountries(), (_arg1) => {
    const result = _arg1;
    return (result.tag === 1) ? singleton.Return(new Msg(23)) : singleton.Return(new Msg(20, result.fields[0]));
}));

export const ExceptionPhone_get = singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().getPhones(), (_arg1) => singleton.Return(new Msg(21, _arg1)))), (_arg2) => singleton.Return(new Msg(23))));

export function ExceptionPhone_add(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().addPhone(form), () => singleton.Return(new Msg(10)))), (_arg2) => singleton.Return(new Msg(23))));
}

export function ExceptionPhone_delete(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().deletePhone(form.Id), () => singleton.Return(new Msg(19)))), (_arg2) => singleton.Return(new Msg(23))));
}

export function ExceptionPhone_update(form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(validationExceptionsApi().updatePhone(form), () => singleton.Return(new Msg(11)))), (_arg2) => singleton.Return(new Msg(23))));
}

export const ExceptionPhone_defaultForm = new PhoneValidationForm("", "", (() => {
    let copyOfStruct = now();
    return date(copyOfStruct);
})(), 0);

export function init() {
    return [new State(void 0, void 0, empty(), empty(), false, true, new ActiveTab_2(0)), Cmd_batch(ofArray([Cmd_fromAsync(ExceptionPhone_get), Cmd_fromAsync(InvalidSender_get)]))];
}

export function update(msg, state) {
    let inputRecord_1, inputRecord_2, inputRecord;
    switch (msg.tag) {
        case 2: {
            return [new State(new EditPhoneState_2(ExceptionPhone_defaultForm, false, false, empty()), state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_fromAsync(ExceptionPhone_getCountryList)];
        }
        case 3: {
            return [new State(void 0, state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
        }
        case 20: {
            const matchValue_1 = state.EditPhoneState;
            if (matchValue_1 == null) {
                return [state, Cmd_none()];
            }
            else {
                const editState_2 = matchValue_1;
                return [new State(new EditPhoneState_2(editState_2.Form, editState_2.IsEmptyPhone, editState_2.IsNotSelectCountry, append(singleton_1(new Country(-1, "", "Select country")), msg.fields[0])), state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
            }
        }
        case 23: {
            return [state, Cmd_none()];
        }
        case 9: {
            const matchValue_2 = state.EditPhoneState;
            if (matchValue_2 == null) {
                return [state, Cmd_none()];
            }
            else {
                const editState_3 = matchValue_2;
                return [new State(new EditPhoneState_2((inputRecord_1 = editState_3.Form, new PhoneValidationForm(msg.fields[0], inputRecord_1.Country, inputRecord_1.Timestamp, inputRecord_1.Id)), false, editState_3.IsNotSelectCountry, editState_3.CountryList), state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
            }
        }
        case 21: {
            return [new State(state.EditPhoneState, state.EditSenderState, msg.fields[0], state.Senders, state.IsSaving, false, state.ActiveTab), Cmd_none()];
        }
        case 22: {
            return [new State(state.EditPhoneState, state.EditSenderState, state.Phones, msg.fields[0], state.IsSaving, false, state.ActiveTab), Cmd_none()];
        }
        case 4: {
            const form = msg.fields[0];
            const isEmptyPhone = isNullOrWhiteSpace(form.PhoneNumber);
            const isNotSelectCountry_1 = form.Country === "";
            const matchValue_3 = state.EditPhoneState;
            if (matchValue_3 == null) {
                return [state, Cmd_none()];
            }
            else {
                const editState_4 = matchValue_3;
                return [new State(new EditPhoneState_2(editState_4.Form, isEmptyPhone, isNotSelectCountry_1, editState_4.CountryList), state.EditSenderState, state.Phones, state.Senders, true, state.Loading, state.ActiveTab), Cmd_batch(toList(delay(() => ((!(isNotSelectCountry_1 ? true : isEmptyPhone)) ? singleton_2(Cmd_fromAsync(ExceptionPhone_add(form))) : empty_1()))))];
            }
        }
        case 10: {
            return [new State(void 0, void 0, state.Phones, state.Senders, false, state.Loading, state.ActiveTab), Cmd_batch(ofArray([message("Success", (builder) => success(builder)), Cmd_fromAsync(ExceptionPhone_get), Cmd_fromAsync(InvalidSender_get)]))];
        }
        case 7: {
            return [state, Cmd_fromAsync(ExceptionPhone_delete(msg.fields[0]))];
        }
        case 19: {
            return [new State(void 0, void 0, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_batch(ofArray([message("Deleted", (builder_1) => success(builder_1)), Cmd_fromAsync(ExceptionPhone_get), Cmd_fromAsync(InvalidSender_get)]))];
        }
        case 5: {
            return [new State(new EditPhoneState_2(msg.fields[0], false, false, empty()), state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_fromAsync(ExceptionPhone_getCountryList)];
        }
        case 6: {
            return [new State(state.EditPhoneState, state.EditSenderState, state.Phones, state.Senders, true, state.Loading, state.ActiveTab), Cmd_fromAsync(ExceptionPhone_update(msg.fields[0]))];
        }
        case 11: {
            return [new State(void 0, void 0, state.Phones, state.Senders, false, state.Loading, state.ActiveTab), Cmd_batch(ofArray([message("Updated", (builder_2) => success(builder_2)), Cmd_fromAsync(ExceptionPhone_get), Cmd_fromAsync(InvalidSender_get)]))];
        }
        case 0: {
            return [new State(state.EditPhoneState, state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, new ActiveTab_2(0)), Cmd_none()];
        }
        case 1: {
            return [new State(state.EditPhoneState, state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, new ActiveTab_2(1)), Cmd_none()];
        }
        case 12: {
            return [new State(state.EditPhoneState, new EditSenderState_6(InvalidSender_defaultForm, false), state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
        }
        case 15: {
            return [new State(state.EditPhoneState, new EditSenderState_6(msg.fields[0], false), state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
        }
        case 14: {
            const form_4 = msg.fields[0];
            const isEmptySender = isNullOrWhiteSpace(form_4.Name);
            const matchValue_4 = state.EditSenderState;
            if (matchValue_4 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.EditPhoneState, new EditSenderState_6(matchValue_4.Form, isEmptySender), state.Phones, state.Senders, true, state.Loading, state.ActiveTab), Cmd_batch(toList(delay(() => ((!isEmptySender) ? singleton_2(Cmd_fromAsync(InvalidSender_add(form_4))) : empty_1()))))];
            }
        }
        case 16: {
            return [new State(state.EditPhoneState, state.EditSenderState, state.Phones, state.Senders, true, state.Loading, state.ActiveTab), Cmd_fromAsync(InvalidSender_update(msg.fields[0]))];
        }
        case 17: {
            const matchValue_5 = state.EditSenderState;
            if (matchValue_5 == null) {
                return [state, Cmd_none()];
            }
            else {
                return [new State(state.EditPhoneState, new EditSenderState_6((inputRecord_2 = matchValue_5.Form, new Sender(msg.fields[0], inputRecord_2.Timestamp, inputRecord_2.Id)), false), state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
            }
        }
        case 13: {
            return [new State(state.EditPhoneState, void 0, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
        }
        case 18: {
            return [state, Cmd_fromAsync(InvalidSender_delete(msg.fields[0]))];
        }
        default: {
            const value = msg.fields[0];
            const isNotSelectCountry = value === "";
            const matchValue = state.EditPhoneState;
            if (matchValue == null) {
                return [state, Cmd_none()];
            }
            else {
                const editState = matchValue;
                return [new State(new EditPhoneState_2((inputRecord = editState.Form, new PhoneValidationForm(inputRecord.PhoneNumber, value, inputRecord.Timestamp, inputRecord.Id)), editState.IsEmptyPhone, isNotSelectCountry, editState.CountryList), state.EditSenderState, state.Phones, state.Senders, state.IsSaving, state.Loading, state.ActiveTab), Cmd_none()];
            }
        }
    }
}

