import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { class_type, int32_type, union_type, unit_type, record_type, list_type, option_type, string_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { SelfMessage$1$reflection, State$reflection as State$reflection_1 } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { Types_Msg$reflection, Types_State$reflection } from "../Common/Paging.js";
import { Api_AssignVirtualNumberRequest$reflection, Api_AddVirtualNumberRequest$reflection, Api_KindOfNumbers$reflection, Contract_VirtualNumber$reflection, Api_VirtualNumbersSortFields$reflection } from "../../Shared/VirtualNumbers.js";
import { Types_Msg$1$reflection, Types_State$1$reflection } from "../Common/Sorting.js";
import { Deferred$1$reflection } from "../Common/Extensions.js";
import { Errors_ApplicationError$reflection, Errors_VirtualNumberError$reflection, PagingResult$1$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";

export class State extends Record {
    constructor(Filter, Debouncer, Paging, Sorting, VirtualNumbers, KindOfNumbers) {
        super();
        this.Filter = Filter;
        this.Debouncer = Debouncer;
        this.Paging = Paging;
        this.Sorting = Sorting;
        this.VirtualNumbers = VirtualNumbers;
        this.KindOfNumbers = KindOfNumbers;
    }
}

export function State$reflection() {
    return record_type("Pages.VirtualNumbers.Types.State", [], State, () => [["Filter", string_type], ["Debouncer", State$reflection_1()], ["Paging", option_type(Types_State$reflection())], ["Sorting", Types_State$1$reflection(Api_VirtualNumbersSortFields$reflection())], ["VirtualNumbers", Deferred$1$reflection(list_type(Contract_VirtualNumber$reflection()))], ["KindOfNumbers", Api_KindOfNumbers$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DebouncerSelfMsg", "EndOfInputSearch", "FilterChanged", "PagingMsg", "SortingMsg", "VirtualNumbersLoaded", "AddNewVirtualNumber", "VirtualNumberWasAdded", "AssignVirtualNumber", "UnassignVirtualNumber", "DeleteVirtualNumber", "VirtualNumbersWasAssigned", "VirtualNumbersWasUnassigned", "VirtualNumbersWasDeleted", "Failed", "KindOfNumbersChanged"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.VirtualNumbers.Types.Msg", [], Msg, () => [[["Item", SelfMessage$1$reflection(Msg$reflection())]], [], [["Item", string_type]], [["Item", Types_Msg$reflection()]], [["Item", Types_Msg$1$reflection(Api_VirtualNumbersSortFields$reflection())]], [["Item", PagingResult$1$reflection(Contract_VirtualNumber$reflection())]], [["Item", Api_AddVirtualNumberRequest$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Errors_VirtualNumberError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Errors_VirtualNumberError$reflection()]]])]], [["Item", Api_AssignVirtualNumberRequest$reflection()]], [["Item", int32_type]], [["Item", int32_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Contract_VirtualNumber$reflection(), Errors_ApplicationError$reflection()], FSharpResult$2, () => [[["ResultValue", Contract_VirtualNumber$reflection()]], [["ErrorValue", Errors_ApplicationError$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, Errors_ApplicationError$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", Errors_ApplicationError$reflection()]]])]], [["Item", class_type("System.Exception")]], [["Item", string_type]]]);
}

