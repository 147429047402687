import { delete$, Option, notification } from "../../.fable/Fulma.2.14.1/Elements/Notification.fs.js";
import { ofArray, empty, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { DOMAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Screen, Size_ISize, Common_GenericOption } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { h5 } from "../../.fable/Fulma.2.14.1/Elements/Heading.fs.js";
import { Option as Option_1, icon as icon_1 } from "../../.fable/Fulma.2.14.1/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_ISize, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import * as react from "react";
import { Option as Option_2, columns } from "../../.fable/Fulma.2.14.1/Layouts/Columns.fs.js";
import { Option as Option_3, ISize, column } from "../../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import { error, success, timeout, message as message_3, Position, position } from "../../.fable/Thoth.Elmish.Toast.3.0.0/Toast.fs.js";
import { fromSeconds } from "../../.fable/fable-library.3.2.9/TimeSpan.js";

export const renderToastWithFulma = {
    Toast(children, color) {
        return notification(singleton(new Option(2, color)), children);
    },
    CloseButton(onClick) {
        return delete$(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, onClick)))), empty());
    },
    Title(txt) {
        return h5(empty())(singleton(txt));
    },
    Icon(icon) {
        return icon_1(singleton(new Option_1(0, new Size_ISize(1))), singleton(Fa_i(ofArray([icon, new Fa_IconOption(0, new Fa_ISize(3))]), [])));
    },
    SingleLayout(title, message_1) {
        return react.createElement("div", {}, title, message_1);
    },
    Message(txt_1) {
        return react.createElement("span", {}, txt_1);
    },
    SplittedLayout(iconView, title_1, message_2) {
        return columns(ofArray([new Option_2(3), new Option_2(1)]), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(6))), singleton(iconView)), column(empty(), ofArray([title_1, message_2]))]));
    },
    StatusToColor(status) {
        return (status.tag === 1) ? "is-warning" : ((status.tag === 2) ? "is-danger" : ((status.tag === 3) ? "is-info" : "is-primary"));
    },
};

export function message(text, messageType) {
    let builder_1;
    return messageType((builder_1 = position(new Position(0), message_3(text)), timeout(fromSeconds(3), builder_1)));
}

export function toastSuccess(text) {
    return message(text, (builder) => success(builder));
}

export function toastError(text) {
    return message(text, (builder) => error(builder));
}

