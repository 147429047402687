import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { union_type, record_type, int32_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import * as react from "react";
import { ellipsis, Link_Option, link, list, next, previous, Option, pagination } from "../../.fable/Fulma.2.14.1/Components/Pagination.fs.js";
import { empty, ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { DOMAttr, HTMLAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Common_GenericOption } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.2.9/String.js";

export class Types_State extends Record {
    constructor(CurrentPage, TotalPages) {
        super();
        this.CurrentPage = (CurrentPage | 0);
        this.TotalPages = (TotalPages | 0);
    }
}

export function Types_State$reflection() {
    return record_type("Common.Paging.Types.State", [], Types_State, () => [["CurrentPage", int32_type], ["TotalPages", int32_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Previous", "Next", "Goto"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Common.Paging.Types.Msg", [], Types_Msg, () => [[], [], [["Item", int32_type]]]);
}

export class Types_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GoTo"];
    }
}

export function Types_ExternalMsg$reflection() {
    return union_type("Common.Paging.Types.ExternalMsg", [], Types_ExternalMsg, () => [[["Item", int32_type]]]);
}

export function State_init(page, pageSize, total) {
    return new Types_State(page, ~(~Math.ceil(total / pageSize)));
}

export function State_update(state, _arg1) {
    let x;
    let pattern_matching_result;
    if (_arg1.tag === 0) {
        if (state.CurrentPage > 1) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new Types_State(state.CurrentPage - 1, state.TotalPages), new Types_ExternalMsg(0, state.CurrentPage - 1)];
        }
        case 1: {
            let pattern_matching_result_1;
            if (_arg1.tag === 1) {
                if (state.CurrentPage < state.TotalPages) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new Types_State(state.CurrentPage + 1, state.TotalPages), new Types_ExternalMsg(0, state.CurrentPage + 1)];
                }
                case 1: {
                    let pattern_matching_result_2, x_1;
                    if (_arg1.tag === 2) {
                        if ((x = (_arg1.fields[0] | 0), (x >= 1) ? (x <= state.TotalPages) : false)) {
                            pattern_matching_result_2 = 0;
                            x_1 = _arg1.fields[0];
                        }
                        else {
                            pattern_matching_result_2 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_2 = 1;
                    }
                    switch (pattern_matching_result_2) {
                        case 0: {
                            return [new Types_State(x_1, state.TotalPages), new Types_ExternalMsg(0, x_1)];
                        }
                        case 1: {
                            return [state, void 0];
                        }
                    }
                }
            }
        }
    }
}

export function View_render(state, dispatch) {
    let arg10, arg10_2;
    if (state.TotalPages === 0) {
        return react.createElement("div", {});
    }
    else {
        return react.createElement("div", {}, pagination(singleton(new Option(0)), ofArray([previous(singleton(new Common_GenericOption(1, ofArray([new HTMLAttr(79, state.CurrentPage === 1), new DOMAttr(40, (_arg1) => {
            dispatch(new Types_Msg(0));
        })]))), singleton("Previous")), next(singleton(new Common_GenericOption(1, ofArray([new HTMLAttr(79, state.CurrentPage >= state.TotalPages), new DOMAttr(40, (_arg2) => {
            dispatch(new Types_Msg(1));
        })]))), singleton("Next")), list(empty(), ofArray([link(ofArray([new Link_Option(0, state.CurrentPage === 1), new Link_Option(2, singleton(new DOMAttr(40, (_arg3) => {
            dispatch(new Types_Msg(2, 1));
        })))]), singleton("1")), (state.CurrentPage > 3) ? ellipsis(empty()) : react.createElement("div", {}), (state.CurrentPage > 2) ? link(singleton(new Link_Option(2, singleton(new DOMAttr(40, (_arg4) => {
            dispatch(new Types_Msg(2, state.CurrentPage - 1));
        })))), singleton((arg10 = ((state.CurrentPage - 1) | 0), toText(printf("%i"))(arg10)))) : react.createElement("div", {}), ((state.CurrentPage > 1) ? (state.CurrentPage < state.TotalPages) : false) ? link(singleton(new Link_Option(0, true)), singleton(toText(printf("%i"))(state.CurrentPage))) : react.createElement("div", {}), (state.CurrentPage < (state.TotalPages - 1)) ? link(singleton(new Link_Option(2, singleton(new DOMAttr(40, (_arg5) => {
            dispatch(new Types_Msg(2, state.CurrentPage + 1));
        })))), singleton((arg10_2 = ((state.CurrentPage + 1) | 0), toText(printf("%i"))(arg10_2)))) : react.createElement("div", {}), (state.CurrentPage < (state.TotalPages - 2)) ? ellipsis(empty()) : react.createElement("div", {}), (state.TotalPages > 1) ? link(ofArray([new Link_Option(0, state.CurrentPage === state.TotalPages), new Link_Option(2, singleton(new DOMAttr(40, (_arg6) => {
            dispatch(new Types_Msg(2, state.TotalPages));
        })))]), singleton(toText(printf("%i"))(state.TotalPages))) : react.createElement("div", {})]))])));
    }
}

