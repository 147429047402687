import { Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { record_type, lambda_type, unit_type, class_type, string_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";

export class ConfirmationDialogProps extends Record {
    constructor(Title, Content, ConfirmAction, CancelAction, ConfirmButtonName, CancelButtonName) {
        super();
        this.Title = Title;
        this.Content = Content;
        this.ConfirmAction = ConfirmAction;
        this.CancelAction = CancelAction;
        this.ConfirmButtonName = ConfirmButtonName;
        this.CancelButtonName = CancelButtonName;
    }
}

export function ConfirmationDialogProps$reflection() {
    return record_type("Components.Dialog.ConfirmationDialogProps", [], ConfirmationDialogProps, () => [["Title", string_type], ["Content", class_type("Fable.React.ReactElement")], ["ConfirmAction", lambda_type(unit_type, unit_type)], ["CancelAction", lambda_type(unit_type, unit_type)], ["ConfirmButtonName", string_type], ["CancelButtonName", string_type]]);
}

export function ConfirmationDialog(props) {
    let props_16, props_6, props_8, props_14;
    const props_18 = ofArray([["className", "is-active"], ["style", {
        textAlign: "left",
    }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("modal-background", singleton(["onClick", (_arg1) => {
        props.CancelAction();
    }])))), (props_16 = singleton(["children", Interop_reactApi.Children.toArray([(props_6 = singleton(["children", Interop_reactApi.Children.toArray([createElement("p", {
        className: "modal-card-title",
        children: Interop_reactApi.Children.toArray([props.Title]),
    }), createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", (_arg2) => {
        props.CancelAction();
    }]))))])]), createElement("header", createObj(Helpers_combineClasses("modal-card-head", props_6)))), (props_8 = singleton(["children", Interop_reactApi.Children.toArray([props.Content])]), createElement("section", createObj(Helpers_combineClasses("modal-card-body", props_8)))), (props_14 = ofArray([["style", {
        justifyContent: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["children", props.CancelButtonName], ["onClick", (_arg3) => {
        props.CancelAction();
    }]])))), createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-primary"], ["children", props.ConfirmButtonName], ["onClick", (_arg4) => {
        props.ConfirmAction();
    }]]))))])]]), createElement("footer", createObj(Helpers_combineClasses("modal-card-foot", props_14))))])]), createElement("div", createObj(Helpers_combineClasses("modal-card", props_16))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("modal", props_18)));
}

