import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { cons, singleton as singleton_1, isEmpty, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { createElement } from "react";
import { createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { map, append, singleton, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { interpolate, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { Msg } from "./Model.js";
import { SelectionMode, OnChange, TimeTwentyFour, EnableTimePicker, custom, ClassName, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { AsyncOperationEvent$1 } from "../Common/Extensions.js";

export function renderTable(state) {
    let props, props_2, props_4;
    let headerTable;
    const props_6 = ofArray([["style", {
        fontWeight: 700,
        borderTop: (((1 + "px ") + "solid") + " ") + "#ccc",
        borderBottom: (((2 + "px ") + "solid") + " ") + "#ccc",
        padding: 0,
    }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
        paddingBottom: 0,
        paddingBottom: 5,
    }], ["children", "Company Id"]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = ofArray([["style", {
        paddingBottom: 0,
        paddingBottom: 5,
    }], ["children", "User Id"]]), createElement("div", createObj(Helpers_combineClasses("column", props_2)))), (props_4 = ofArray([["style", {
        paddingBottom: 0,
        paddingBottom: 5,
    }], ["children", "Created date (UTC)"]]), createElement("div", createObj(Helpers_combineClasses("column", props_4))))])]]);
    headerTable = createElement("div", createObj(Helpers_combineClasses("columns", props_6)));
    const children = toList(delay(() => {
        const matchValue = state.Sms;
        switch (matchValue.tag) {
            case 1: {
                return singleton(createElement("span", {
                    className: "",
                    children: "Loading...",
                }));
            }
            case 2: {
                const result = matchValue.fields[0];
                if (result.tag === 1) {
                    return singleton(createElement("span", {
                        className: "",
                        children: result.fields[0],
                    }));
                }
                else {
                    const result_1 = result.fields[0];
                    return isEmpty(result_1) ? singleton(createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "has-text-centered"], ["children", "No results in matching"]]))))) : append(singleton(headerTable), delay(() => map((item_1) => {
                        let props_12;
                        const item = item_1;
                        const props_14 = ofArray([["style", {
                            borderBottom: (((1 + "px ") + "solid") + " ") + "#ccc",
                            padding: 0,
                        }], ["children", Interop_reactApi.Children.toArray([createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["children", item.CompanyId])))), createElement("div", createObj(Helpers_combineClasses("column", singleton_1(["children", item.UserId])))), (props_12 = singleton_1(["children", toText(interpolate("%P()", [item.CreatedDate]))]), createElement("div", createObj(Helpers_combineClasses("column", props_12))))])]]);
                        return createElement("div", createObj(Helpers_combineClasses("columns", props_14)));
                    }, result_1)));
                }
            }
            default: {
                return singleton(createElement("div", createObj(Helpers_combineClasses("column", ofArray([["className", "has-text-centered"], ["children", "No results yet"]])))));
            }
        }
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

export function searchBlock(searchBlockInputProps) {
    let props_6, props_4, props_14, props_12, props_10, props_22, props_20, props_18, props_26;
    const dispatch = searchBlockInputProps.dispatch;
    const state = searchBlockInputProps.state;
    const props_28 = ofArray([["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_6 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-grey"], ["className", "is-size-6"], ["children", "Phone"]])))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["value", state.Searching.Phone], ["onChange", (ev) => {
        dispatch(new Msg(1, ev.target.value));
    }]])))))])]), createElement("p", createObj(Helpers_combineClasses("control", props_4))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_6)))), (props_14 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-grey"], ["className", "is-size-6"], ["children", "Start date and time"]])))), (props_12 = ofArray([["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_10 = singleton_1(["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([ClassName("input"), custom("value", state.Searching.StartDateTime, false), EnableTimePicker(true), TimeTwentyFour(true), OnChange((x_1) => {
        dispatch(new Msg(2, x_1));
    })]))])]), createElement("p", createObj(Helpers_combineClasses("control", props_10))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_12))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_14)))), (props_22 = ofArray([["className", "is-narrow"], ["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "has-text-grey"], ["className", "is-size-6"], ["children", "End date and time"]])))), (props_20 = ofArray([["className", "is-grouped"], ["children", Interop_reactApi.Children.toArray([(props_18 = singleton_1(["children", Interop_reactApi.Children.toArray([flatpickr(ofArray([ClassName("input"), SelectionMode("single"), custom("value", state.Searching.EndDateTime, false), EnableTimePicker(true), TimeTwentyFour(true), OnChange((x_2) => {
        dispatch(new Msg(3, x_2));
    })]))])]), createElement("p", createObj(Helpers_combineClasses("control", props_18))))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22)))), (props_26 = ofArray([["className", "is-2"], ["style", {
        alignSelf: "flex-end",
    }], ["children", Interop_reactApi.Children.toArray([createElement("button", createObj(Helpers_combineClasses("button", ofArray([["className", "is-fullwidth"], ["children", "Search"], ["className", "is-primary"], ["onClick", (_arg1) => {
        dispatch(new Msg(0, new AsyncOperationEvent$1(0)));
    }]]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_26))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("columns", props_28)));
}

export function view(state, dispatch) {
    const props = ofArray([["style", {
        paddingLeft: 0,
        paddingRight: 0,
    }], ["children", Interop_reactApi.Children.toArray([createElement(searchBlock, {
        state: state,
        dispatch: dispatch,
    }), renderTable(state)])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props)));
}

