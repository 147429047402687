import { View_render } from "../Common/Paging.js";
import { Field, Msg } from "./Model.js";
import { defaultArg, map } from "../../.fable/fable-library.3.2.9/Option.js";
import { button, list, Option, a } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { Common_GenericOption, Modifier_IModifier, Screen, Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { Route, toPath } from "../../Router.js";
import { DOMAttr, HTMLAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { empty, ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { Printer_printUserStatus, Printer_printCustomerStatus, CustomerSortFields, Printer_printUserStatusString, AcceptCustomerType, CustomerStatus } from "../../Shared/Shared.js";
import { Option as Option_1, div } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { TableOption, table as table_1 } from "../../.fable/Fulma.2.14.1/Elements/Table.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Option as Option_2, columns } from "../../.fable/Fulma.2.14.1/Layouts/Columns.fs.js";
import { Option as Option_3, ISize, column } from "../../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import { Option as Option_4, IInputType, input } from "../../.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { Option as Option_5, select } from "../../.fable/Fulma.2.14.1/Elements/Form/Select.fs.js";
import { map as map_1, empty as empty_1, append, singleton as singleton_1, collect, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { Item_Option, Item_a, content as content_1, menu, Option as Option_6, dropdown } from "../../.fable/Fulma.2.14.1/Components/Dropdown.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_7, modal } from "../../.fable/Fulma.2.14.1/Components/Modal.fs.js";
import { Option as Option_8, delete$ } from "../../.fable/Fulma.2.14.1/Elements/Delete.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { View_render as View_render_1 } from "../Common/Sorting.js";
import { p } from "../../.fable/Fulma.2.14.1/Elements/Heading.fs.js";
import { right, left, level } from "../../.fable/Fulma.2.14.1/Layouts/Level.fs.js";
import { container } from "../../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { body, Label_Option, label, Option as Option_9, div as div_1 } from "../../.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";
import { FormState_Form_getFormError, FormState_Form_getFieldError, FormState_Form_getFieldColor } from "../Common/FormState.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { Option as Option_10, help } from "../../.fable/Fulma.2.14.1/Elements/Form/Help.fs.js";

export function renderPaging(state, dispatch) {
    return View_render(state, (arg) => {
        dispatch(new Msg(8, arg));
    });
}

export function renderPagingOpt(state, dispatch) {
    const o_1 = map((s) => renderPaging(s, dispatch), state);
    if (o_1 == null) {
        return null;
    }
    else {
        return o_1;
    }
}

export function renderDetails(customer) {
    return a(ofArray([new Option(0, new Color_IColor(4)), new Option(17, singleton(new HTMLAttr(94, toPath(new Route(4, customer.Id)))))]), singleton("Details"));
}

export function renderActionsOld(customer, dispatch) {
    let patternInput;
    const matchValue = customer.Customer.Status;
    switch (matchValue.tag) {
        case 2: {
            patternInput = [true, false];
            break;
        }
        case 3: {
            patternInput = [true, false];
            break;
        }
        case 4: {
            patternInput = [false, true];
            break;
        }
        case 1: {
            patternInput = [false, false];
            break;
        }
        default: {
            patternInput = [true, true];
        }
    }
    const matchValue_1 = customer.Customer.Status;
    switch (matchValue_1.tag) {
        case 2:
        case 3:
        case 4:
        case 1: {
            if (customer.Loading) {
                return "loading...";
            }
            else {
                return list(empty(), ofArray([renderDetails(customer), button(ofArray([new Option(0, new Color_IColor(4)), new Option(16, patternInput[0]), new Option(18, (x) => {
                    dispatch(new Msg(3, customer));
                    x.preventDefault();
                })]), singleton("Accept")), button(ofArray([new Option(0, new Color_IColor(8)), new Option(16, patternInput[1]), new Option(18, (x_1) => {
                    dispatch(new Msg(5, customer));
                    x_1.preventDefault();
                })]), singleton("Decline"))]));
            }
        }
        default: {
            return list(empty(), singleton(renderDetails(customer)));
        }
    }
}

export function toStrValue(_arg1) {
    if (_arg1 == null) {
        return "All";
    }
    else if (_arg1.tag === 1) {
        return "AwaitingAcceptance";
    }
    else if (_arg1.tag === 2) {
        return "ManuallyAccepted";
    }
    else if (_arg1.tag === 3) {
        return "AutoAccepted";
    }
    else if (_arg1.tag === 4) {
        return "Declined";
    }
    else {
        return "AwaitingCompanyInfo";
    }
}

export function fromStrValue(_arg1) {
    switch (_arg1) {
        case "AwaitingCompanyInfo": {
            return new CustomerStatus(0);
        }
        case "AwaitingAcceptance": {
            return new CustomerStatus(1);
        }
        case "ManuallyAccepted": {
            return new CustomerStatus(2);
        }
        case "AutoAccepted": {
            return new CustomerStatus(3);
        }
        case "Declined": {
            return new CustomerStatus(4);
        }
        default: {
            return void 0;
        }
    }
}

export const customerStatusOptions = ofArray([void 0, new CustomerStatus(3), new CustomerStatus(1), new CustomerStatus(0), new CustomerStatus(4), new CustomerStatus(2)]);

export function filter(state, dispatch) {
    let props_10, props_4, props_6, props_8, props_12;
    return div(singleton(new Option_1(3)), singleton(table_1(ofArray([new TableOption(4), new TableOption(2)]), singleton(react.createElement("tr", {}, react.createElement("td", {}, columns(singleton(new Option_2(1)), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(0))), singleton(input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(12, "Search customers"), new Option_4(15, ofArray([new DOMAttr(9, (e) => {
        dispatch(new Msg(10, e.target.value));
    }), new HTMLAttr(55, true)]))])))), column(singleton(new Option_3(0, new Screen(0), new ISize(1))), singleton(select(singleton(new Option_5(1)), singleton(react.createElement("select", {
        value: toStrValue(state.StatusFilter),
        onChange: (e_1) => {
            dispatch(new Msg(11, fromStrValue(e_1.target.value)));
        },
        placeholder: "Status",
    }, ...toList(delay(() => collect((customerStatusOption) => {
        const value = toStrValue(customerStatusOption);
        return singleton_1(react.createElement("option", {
            value: value,
        }, value));
    }, customerStatusOptions)))))))), column(empty(), singleton((props_10 = ofArray([["className", "has-addons"], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_1(["children", "All"]), delay(() => append(((!state.ShowDeleted) ? (!state.ShowOverdue) : false) ? singleton_1(["className", "is-primary"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg1_3) => {
        dispatch(new Msg(38, false));
    }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4)))), (props_6 = toList(delay(() => append(singleton_1(["children", "Deleted"]), delay(() => append((state.ShowDeleted ? (!state.ShowOverdue) : false) ? singleton_1(["className", "is-primary"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg2) => {
        dispatch(new Msg(38, true));
    }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_6)))), (props_8 = toList(delay(() => append(singleton_1(["children", "Overdue"]), delay(() => append(state.ShowOverdue ? singleton_1(["className", "is-primary"]) : empty_1(), delay(() => singleton_1(["onClick", (_arg3) => {
        dispatch(new Msg(39));
    }]))))))), createElement("button", createObj(Helpers_combineClasses("button", props_8))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_10)))))), column(empty(), singleton((props_12 = ofArray([["style", {
        float: "right",
    }], ["children", Interop_reactApi.Children.toArray([button(ofArray([new Option(0, new Color_IColor(4)), new Option(16, state.Exporting), new Option(18, (ev) => {
        dispatch(new Msg(40));
        ev.preventDefault();
    })]), singleton("Export")), button(ofArray([new Option(0, new Color_IColor(4)), new Option(18, (ev_1) => {
        dispatch(new Msg(26));
        ev_1.preventDefault();
    })]), singleton("Create"))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_12))))))]))))))));
}

export function renderActions(customer, dispatch) {
    let props_1;
    let patternInput;
    const matchValue = customer.Customer.Status;
    switch (matchValue.tag) {
        case 2: {
            patternInput = [new Color_IColor(15), new Color_IColor(0)];
            break;
        }
        case 3: {
            patternInput = [new Color_IColor(15), new Color_IColor(0)];
            break;
        }
        case 4: {
            patternInput = [new Color_IColor(0), new Color_IColor(15)];
            break;
        }
        case 1: {
            patternInput = [new Color_IColor(0), new Color_IColor(0)];
            break;
        }
        default: {
            patternInput = [new Color_IColor(15), new Color_IColor(15)];
        }
    }
    const declineButtonTextColor = patternInput[1];
    const acceptButtonTextColor = patternInput[0];
    let patternInput_1;
    const matchValue_1 = customer.Customer.Status;
    switch (matchValue_1.tag) {
        case 2: {
            patternInput_1 = [true, false];
            break;
        }
        case 3: {
            patternInput_1 = [true, false];
            break;
        }
        case 4: {
            patternInput_1 = [false, true];
            break;
        }
        case 1: {
            patternInput_1 = [false, false];
            break;
        }
        default: {
            patternInput_1 = [true, true];
        }
    }
    const isDeclineButtonDisabled = patternInput_1[1];
    const isAcceptButtonDisabled = patternInput_1[0];
    return dropdown(ofArray([new Option_6(1), new Option_6(2)]), ofArray([(props_1 = ofArray([["className", "has-text-grey-light"], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: "fas fa-ellipsis-v",
    })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_1)))), menu(empty(), singleton(content_1(empty(), singleton(list(empty(), ofArray([Item_a(ofArray([new Item_Option(3, empty()), new Item_Option(1, singleton(new HTMLAttr(94, toPath(new Route(4, customer.Id)))))]), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-address-card"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(empty(), singleton("Details"))])))), Item_a(ofArray([new Item_Option(3, singleton(new Modifier_IModifier(1, acceptButtonTextColor))), new Item_Option(1, toList(delay(() => ((!isAcceptButtonDisabled) ? singleton_1(new DOMAttr(40, (x) => {
        dispatch(new Msg(1, customer, new AcceptCustomerType(0)));
        x.preventDefault();
    })) : empty_1()))))]), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-check-double"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(empty(), singleton("Accept and email"))])))), Item_a(ofArray([new Item_Option(3, singleton(new Modifier_IModifier(1, acceptButtonTextColor))), new Item_Option(1, toList(delay(() => ((!isAcceptButtonDisabled) ? singleton_1(new DOMAttr(40, (x_1) => {
        dispatch(new Msg(1, customer, new AcceptCustomerType(1)));
        x_1.preventDefault();
    })) : empty_1()))))]), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-check"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(empty(), singleton("Accept silently"))])))), Item_a(ofArray([new Item_Option(3, singleton(new Modifier_IModifier(1, declineButtonTextColor))), new Item_Option(1, toList(delay(() => ((!isDeclineButtonDisabled) ? singleton_1(new DOMAttr(40, (x_2) => {
        dispatch(new Msg(5, customer));
        x_2.preventDefault();
    })) : empty_1()))))]), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-ban"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(empty(), singleton("Decline and email"))])))), Item_a(ofArray([new Item_Option(3, singleton(new Modifier_IModifier(1, declineButtonTextColor))), new Item_Option(1, toList(delay(() => ((!isDeclineButtonDisabled) ? singleton_1(new DOMAttr(40, (x_3) => {
        dispatch(new Msg(6, customer));
        x_3.preventDefault();
    })) : empty_1()))))]), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-comment-slash"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(singleton(new Option_3(3, singleton(["style", {
        paddingLeft: "9px",
    }]))), singleton("Decline silently"))])))), Item_a(ofArray([new Item_Option(3, empty()), new Item_Option(1, singleton(new DOMAttr(40, (x_4) => {
        dispatch(new Msg(29, customer.Customer, 3));
        x_4.preventDefault();
    })))]), singleton(columns(empty(), ofArray([column(singleton(new Option_3(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-trash"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(empty(), singleton("Delete"))]))))]))))))]));
}

export function confirmationDialog(title, content, confirm, closeDisplay, buttonName) {
    const cancelDialog = (_arg1) => {
        closeDisplay();
    };
    return modal(singleton(new Option_7(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, cancelDialog)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton(title)), delete$(singleton(new Option_8(3, cancelDialog)), empty())])), Card_body(empty(), singleton(content)), Card_foot(singleton(new Common_GenericOption(1, singleton(["style", {
        justifyContent: "flex-end",
    }]))), ofArray([button(singleton(new Option(18, cancelDialog)), singleton("Cancel")), button(ofArray([new Option(0, new Color_IColor(8)), new Option(18, (_arg2) => {
        confirm();
    })]), singleton(buttonName))]))]))]));
}

function renderConfirmationToChangeUserStatus(state, dispatch) {
    let arg10;
    const matchValue = state.ConfirmChangeUserStatus;
    let pattern_matching_result, customer_1, userStatus_1;
    if (matchValue.tag === 0) {
        if (!matchValue.fields[0].HasVirtualNumbers) {
            pattern_matching_result = 0;
            customer_1 = matchValue.fields[0];
            userStatus_1 = matchValue.fields[1];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return confirmationDialog("Changing User\u0027s status", react.createElement("p", {}, (arg10 = Printer_printUserStatusString(userStatus_1), toText(printf("Are you sure you want to change User\u0027s status to %s?"))(arg10))), () => {
                dispatch(new Msg(32, state.ConfirmChangeUserStatus));
            }, () => {
                dispatch(new Msg(30));
            }, "Change");
        }
        case 1: {
            let pattern_matching_result_1;
            if (matchValue.tag === 0) {
                if (matchValue.fields[0].HasVirtualNumbers) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const cancelDialog = (_arg3) => {
                        dispatch(new Msg(30));
                    };
                    const content = react.createElement("p", {}, "This account has active virtual numbers. Please unlink them first before deleting the account.");
                    return modal(singleton(new Option_7(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, cancelDialog)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton("Can\u0027t delete company")), delete$(singleton(new Option_8(3, cancelDialog)), empty())])), Card_body(empty(), singleton(content)), Card_foot(singleton(new Common_GenericOption(1, singleton(["style", {
                        justifyContent: "flex-end",
                    }]))), singleton(button(singleton(new Option(18, cancelDialog)), singleton("Ok"))))]))]));
                }
                case 1: {
                    return null;
                }
            }
        }
    }
}

function renderAcceptingConfirmation(state, dispatch) {
    const matchValue = state.ShowAcceptingConfirmation;
    if (matchValue == null) {
        return null;
    }
    else {
        const ids = matchValue[1];
        const customerData = matchValue[0];
        const acceptCustomerType = matchValue[2];
        const confirm = (acceptCustomerType.tag === 1) ? (() => {
            dispatch(new Msg(4, customerData));
        }) : (() => {
            dispatch(new Msg(3, customerData));
        });
        return confirmationDialog("VAT/Company number already exist.", react.createElement("p", {}, ...toList(delay(() => append(singleton_1("Are you sure you want to accept this information?"), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => append(singleton_1("Company IDs:"), delay(() => append(singleton_1(react.createElement("br", {})), delay(() => collect((id) => append(singleton_1(toText(printf("%A"))(id)), delay(() => singleton_1(react.createElement("br", {})))), ids)))))))))))))), confirm, () => {
            dispatch(new Msg(31));
        }, "Accept");
    }
}

export function table(state, dispatch) {
    if (state.Loading) {
        return "loading";
    }
    else {
        const sortHeader = (field, name) => View_render_1(state.Sorting, field, name, (arg) => {
            dispatch(new Msg(9, arg));
        });
        return react.createElement("div", {}, table_1(ofArray([new TableOption(4), new TableOption(2)]), ofArray([react.createElement("thead", {}, react.createElement("tr", {}, sortHeader(new CustomerSortFields(0), "Id"), sortHeader(new CustomerSortFields(2), "First name"), sortHeader(new CustomerSortFields(3), "Last name"), sortHeader(new CustomerSortFields(5), "Company"), sortHeader(new CustomerSortFields(6), "Country"), sortHeader(new CustomerSortFields(7), "VAT"), sortHeader(new CustomerSortFields(4), "Account created (UTC)"), sortHeader(new CustomerSortFields(8), "Status"), sortHeader(new CustomerSortFields(9), "User status"), react.createElement("th", {}))), react.createElement("tbody", {}, ...toList(delay(() => map_1((customer) => react.createElement("tr", {}, react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "right",
            },
        }, toText(printf("%d"))(customer.Customer.Id)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, customer.Customer.FirstName), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, customer.Customer.LastName), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, defaultArg(map((x) => x.Name, customer.Customer.CompanyInformation), "")), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, defaultArg(map((x_1) => x_1.Country, customer.Customer.CompanyInformation), "")), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, defaultArg(map((x_2) => x_2.VAT, customer.Customer.CompanyInformation), "")), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, toText(printf("%A"))(customer.Customer.Created)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, Printer_printCustomerStatus(customer.Customer.Status)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, Printer_printUserStatus(customer.Customer.UserStatus)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, renderActions(customer, dispatch))), state.Customers))))])), renderPagingOpt(state.Paging, dispatch));
    }
}

export function renderDialog(isActive, title, content, doBtn, cancelBtn, onSave, onClose) {
    return modal(singleton(new Option_7(1, isActive)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, onClose)))), empty()), Card_card(singleton(new Common_GenericOption(1, singleton(["style", {
        minWidth: "60%",
    }]))), singleton(Card_body(empty(), ofArray([p(empty(), singleton(title)), content, level(empty(), ofArray([left(empty(), empty()), right(empty(), singleton(react.createElement("div", {
        className: "block",
    }, a(ofArray([new Option(0, new Color_IColor(8)), new Option(17, singleton(["style", {
        marginRight: "5px",
    }])), new Option(18, onClose)]), singleton(cancelBtn)), a(ofArray([new Option(0, new Color_IColor(4)), new Option(18, onSave)]), singleton(doBtn)))))]))]))))]));
}

export function renderNewCustomerDialog(state, dispatch) {
    if (state.ShowNewCustomerForm) {
        return renderDialog(true, "New customer", div(empty(), ofArray([react.createElement("div", {}, container(empty(), ofArray([div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("FirstName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(0))(state.NewCustomerForm)), new Option_4(13, (x) => {
            dispatch(new Msg(14, Browser_Types_Event__Event_get_Value(x)));
        }), new Option_4(12, "First name"), new Option_4(11, state.NewCustomerForm.State.FirstName), new Option_4(15, singleton(new HTMLAttr(55, true)))])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(0))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("LastName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(1))(state.NewCustomerForm)), new Option_4(13, (x_1) => {
            dispatch(new Msg(15, Browser_Types_Event__Event_get_Value(x_1)));
        }), new Option_4(12, "Last name"), new Option_4(11, state.NewCustomerForm.State.LastName)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(1))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("CompanyName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(2))(state.NewCustomerForm)), new Option_4(13, (x_2) => {
            dispatch(new Msg(16, Browser_Types_Event__Event_get_Value(x_2)));
        }), new Option_4(12, "Company name"), new Option_4(11, state.NewCustomerForm.State.CompanyName)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(2))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Email")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(13, (x_3) => {
            dispatch(new Msg(17, Browser_Types_Event__Event_get_Value(x_3)));
        }), new Option_4(2, FormState_Form_getFieldColor(new Field(3))(state.NewCustomerForm)), new Option_4(12, "Email"), new Option_4(11, state.NewCustomerForm.State.Email)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(3))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("PhoneNumber")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(4))(state.NewCustomerForm)), new Option_4(13, (x_4) => {
            dispatch(new Msg(18, Browser_Types_Event__Event_get_Value(x_4)));
        }), new Option_4(12, "Phone number"), new Option_4(11, state.NewCustomerForm.State.PhoneNumber)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(4))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Country")), body(empty(), singleton(div_1(empty(), ofArray([select(ofArray([new Option_5(1), new Option_5(10, ofArray([new DOMAttr(9, (x_5) => {
            dispatch(new Msg(19, Browser_Types_Event__Event_get_Value(x_5)));
        }), new HTMLAttr(128, "Country")]))]), singleton(react.createElement("select", {}, ...toList(delay(() => map_1((c) => react.createElement("option", {
            selected: c.Id === -1,
            disabled: c.Id === -1,
            value: c.Code,
        }, c.Name), state.CountryList)))))), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(5))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("City")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(6))(state.NewCustomerForm)), new Option_4(13, (x_6) => {
            dispatch(new Msg(20, Browser_Types_Event__Event_get_Value(x_6)));
        }), new Option_4(12, "City"), new Option_4(11, state.NewCustomerForm.State.City)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(6))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("PostCode")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(7))(state.NewCustomerForm)), new Option_4(13, (x_7) => {
            dispatch(new Msg(21, Browser_Types_Event__Event_get_Value(x_7)));
        }), new Option_4(12, "Post code"), new Option_4(11, state.NewCustomerForm.State.PostCode)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(7))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("AddressLine1")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(8))(state.NewCustomerForm)), new Option_4(13, (x_8) => {
            dispatch(new Msg(22, Browser_Types_Event__Event_get_Value(x_8)));
        }), new Option_4(12, "Address line 1"), new Option_4(11, state.NewCustomerForm.State.AddressLine1)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(8))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("AddressLine2")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(9))(state.NewCustomerForm)), new Option_4(13, (x_9) => {
            dispatch(new Msg(23, Browser_Types_Event__Event_get_Value(x_9)));
        }), new Option_4(12, "Address line 2"), new Option_4(11, state.NewCustomerForm.State.AddressLine2)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(9))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("VAT")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(0)), new Option_4(2, FormState_Form_getFieldColor(new Field(10))(state.NewCustomerForm)), new Option_4(13, (x_10) => {
            dispatch(new Msg(24, Browser_Types_Event__Event_get_Value(x_10)));
        }), new Option_4(12, "VAT"), new Option_4(11, state.NewCustomerForm.State.Vat)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(10))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Password")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_4(1, new IInputType(1)), new Option_4(2, FormState_Form_getFieldColor(new Field(11))(state.NewCustomerForm)), new Option_4(13, (x_11) => {
            dispatch(new Msg(25, Browser_Types_Event__Event_get_Value(x_11)));
        }), new Option_4(12, "Password"), new Option_4(11, state.NewCustomerForm.State.Password)])), help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Field(11))(state.NewCustomerForm)))]))))])), div_1(singleton(new Option_9(8)), ofArray([label(empty(), empty()), body(empty(), singleton(help(singleton(new Option_10(2, new Color_IColor(8))), singleton(FormState_Form_getFormError(state.NewCustomerForm)))))])), div_1(singleton(new Option_9(8)), ofArray([label(empty(), empty()), body(empty(), singleton(react.createElement("h1", {}, "Remember to update company settings by logging in as user so that data is synced with Fortnox")))]))]))), react.createElement("br", {})])), "Save", "Cancel", (_arg1) => {
            dispatch(new Msg(28));
        }, (_arg2) => {
            dispatch(new Msg(27));
        });
    }
    else {
        return null;
    }
}

export function view(state, dispatch) {
    return react.createElement("div", {}, filter(state, dispatch), table(state, dispatch), renderConfirmationToChangeUserStatus(state, dispatch), renderAcceptingConfirmation(state, dispatch), renderNewCustomerDialog(state, dispatch));
}

