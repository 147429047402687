import { State } from "./Model.js";
import { Cmd_batch, Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { singleton, empty } from "../../.fable/fable-library.3.2.9/List.js";
import { map } from "../../.fable/fable-library.3.2.9/Option.js";
import Decimal from "../../.fable/fable-library.3.2.9/Decimal.js";
import { VirtualNumberSettings } from "../../Shared/Shared.js";
import { toastError, toastSuccess } from "../Components/Toast.js";

export function init() {
    return [new State(void 0), Cmd_none()];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 0: {
            return [new State(msg.fields[0]), empty()];
        }
        case 3: {
            try {
                return [new State(map((s) => (new VirtualNumberSettings(new Decimal(msg.fields[0]), s.MonthlyFee, s.PrefixesSetupCost, s.PrefixesMonthlyFee)), model.VirtualNumberSettings)), empty()];
            }
            catch (matchValue) {
                return [model, empty()];
            }
        }
        case 4: {
            try {
                return [new State(map((s_1) => (new VirtualNumberSettings(s_1.SetupCost, new Decimal(msg.fields[0]), s_1.PrefixesSetupCost, s_1.PrefixesMonthlyFee)), model.VirtualNumberSettings)), empty()];
            }
            catch (matchValue_1) {
                return [model, empty()];
            }
        }
        case 2: {
            if (msg.fields[0].tag === 0) {
                return [model, Cmd_batch(singleton(toastSuccess("Settings have been saved")))];
            }
            else {
                return [model, Cmd_batch(singleton(toastError("Something went wrong")))];
            }
        }
        case 6: {
            return [model, Cmd_batch(singleton(toastError("Something went wrong")))];
        }
        case 5: {
            return [model, toastSuccess(msg.fields[0])];
        }
        default: {
            throw (new Error("Match failure: Pages.Settings.Types.Msg"));
        }
    }
}

