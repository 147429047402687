import { Result_MapError, Result_Map, Result_Bind, FSharpResult$2 } from "../fable-library.3.2.9/Choice.js";
import { equals, uncurry, curry } from "../fable-library.3.2.9/Util.js";
import { value as value_1 } from "../fable-library.3.2.9/Option.js";
import { tryHead, isEmpty } from "../fable-library.3.2.9/Seq.js";
import { singleton } from "../fable-library.3.2.9/AsyncBuilder.js";

export function isOk(x) {
    if (x.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function isError(x) {
    return !isOk(x);
}

export function either(okF, errorF, x) {
    if (x.tag === 1) {
        return errorF(x.fields[0]);
    }
    else {
        return okF(x.fields[0]);
    }
}

export function eitherMap(okF, errorF, x) {
    return either((arg) => (new FSharpResult$2(0, okF(arg))), (arg_1) => (new FSharpResult$2(1, errorF(arg_1))), x);
}

export function apply(f, x) {
    return Result_Bind((f$0027) => Result_Bind((x$0027) => (new FSharpResult$2(0, f$0027(x$0027))), x), f);
}

export function map2(f, x, y) {
    return apply(apply(new FSharpResult$2(0, curry(2, f)), x), y);
}

export function map3(f, x, y, z) {
    return apply(map2(uncurry(2, curry(3, f)), x, y), z);
}

export function fold(onOk, onError, r) {
    if (r.tag === 1) {
        return onError(r.fields[0]);
    }
    else {
        return onOk(r.fields[0]);
    }
}

export function ofChoice(c) {
    if (c.tag === 1) {
        return new FSharpResult$2(1, c.fields[0]);
    }
    else {
        return new FSharpResult$2(0, c.fields[0]);
    }
}

export function ignore(result) {
    return Result_Map((value) => {
    }, result);
}

export function requireTrue(error, value) {
    if (value) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return new FSharpResult$2(1, error);
    }
}

export function requireFalse(error, value) {
    if (!value) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return new FSharpResult$2(1, error);
    }
}

export function requireSome(error, option) {
    if (option == null) {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, value_1(option));
    }
}

export function requireNone(error, option) {
    if (option == null) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return new FSharpResult$2(1, error);
    }
}

export function requireNotNull(error, value) {
    if (equals(value, null)) {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, value);
    }
}

export function requireEqualTo(other, err, this$) {
    if (equals(this$, other)) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return new FSharpResult$2(1, err);
    }
}

export function requireEqual(x1, x2, error) {
    if (equals(x1, x2)) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return new FSharpResult$2(1, error);
    }
}

export function requireEmpty(error, xs) {
    if (isEmpty(xs)) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return new FSharpResult$2(1, error);
    }
}

export function requireNotEmpty(error, xs) {
    if (isEmpty(xs)) {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, void 0);
    }
}

export function requireHead(error, xs) {
    const matchValue = tryHead(xs);
    if (matchValue == null) {
        return new FSharpResult$2(1, error);
    }
    else {
        return new FSharpResult$2(0, value_1(matchValue));
    }
}

export function setError(error, result) {
    return Result_MapError((_arg1) => error, result);
}

export function withError(error, result) {
    return Result_MapError(() => error, result);
}

export function defaultValue(ifError, result) {
    if (result.tag === 1) {
        return ifError;
    }
    else {
        return result.fields[0];
    }
}

export function defaultError(ifOk, result) {
    if (result.tag === 0) {
        return ifOk;
    }
    else {
        return result.fields[0];
    }
}

export function defaultWith(ifErrorThunk, result) {
    if (result.tag === 1) {
        return ifErrorThunk();
    }
    else {
        return result.fields[0];
    }
}

export function ignoreError(result) {
    defaultValue(void 0, result);
}

export function teeIf(predicate, f, result) {
    if (result.tag === 1) {
    }
    else {
        const x = result.fields[0];
        if (predicate(x)) {
            f(x);
        }
    }
    return result;
}

export function teeErrorIf(predicate, f, result) {
    if (result.tag === 1) {
        const x = result.fields[0];
        if (predicate(x)) {
            f(x);
        }
    }
    return result;
}

export function tee(f, result) {
    return teeIf((_arg1) => true, f, result);
}

export function teeError(f, result) {
    return teeErrorIf((_arg1) => true, f, result);
}

export function sequenceAsync(resAsync) {
    return singleton.Delay(() => ((resAsync.tag === 1) ? singleton.Return(new FSharpResult$2(1, resAsync.fields[0])) : singleton.Bind(resAsync.fields[0], (_arg1) => singleton.Return(new FSharpResult$2(0, _arg1)))));
}

export function valueOr(f, res) {
    if (res.tag === 1) {
        return f(res.fields[0]);
    }
    else {
        return res.fields[0];
    }
}

export function zip(x1, x2) {
    const matchValue = [x1, x2];
    const copyOfStruct = matchValue[0];
    if (copyOfStruct.tag === 1) {
        return new FSharpResult$2(1, copyOfStruct.fields[0]);
    }
    else {
        const copyOfStruct_1 = matchValue[1];
        if (copyOfStruct_1.tag === 1) {
            return new FSharpResult$2(1, copyOfStruct_1.fields[0]);
        }
        else {
            return new FSharpResult$2(0, [copyOfStruct.fields[0], copyOfStruct_1.fields[0]]);
        }
    }
}

export function zipError(x1, x2) {
    const matchValue = [x1, x2];
    const copyOfStruct = matchValue[0];
    if (copyOfStruct.tag === 0) {
        return new FSharpResult$2(0, copyOfStruct.fields[0]);
    }
    else {
        const copyOfStruct_1 = matchValue[1];
        if (copyOfStruct_1.tag === 0) {
            return new FSharpResult$2(0, copyOfStruct_1.fields[0]);
        }
        else {
            return new FSharpResult$2(1, [copyOfStruct.fields[0], copyOfStruct_1.fields[0]]);
        }
    }
}

