import { SignInRequest } from "../../Shared/Shared.js";
import { Cmd_none, Cmd_OfFunc_perform, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { signInApi } from "../../Api.js";
import { State, Msg } from "./Model.js";
import { saveToken } from "../../LocalStorage.js";
import { Route, navigateTo } from "../../Router.js";

export function Api_signInCmd(state) {
    const request = new SignInRequest(state.Login, state.Password);
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, signInApi().signIn, request, (arg0) => (new Msg(4, arg0)));
}

export function Api_saveTokenCmd(token) {
    return Cmd_OfFunc_perform((token_1) => {
        saveToken(token_1);
    }, token, () => (new Msg(3)));
}

export function Update_init() {
    return [new State("", "", false, ""), Cmd_none()];
}

export function Update_update(msg, state) {
    switch (msg.tag) {
        case 1: {
            return [new State(state.Login, msg.fields[0], state.LoggingInProgress, state.Error), Cmd_none()];
        }
        case 2: {
            return [new State(state.Login, state.Password, true, state.Error), Api_signInCmd(state)];
        }
        case 4: {
            const x_2 = msg.fields[0];
            if (x_2.tag === 1) {
                return [new State(state.Login, state.Password, false, x_2.fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Login, state.Password, false, state.Error), Api_saveTokenCmd(x_2.fields[0])];
            }
        }
        case 3: {
            return [state, navigateTo(new Route(1))];
        }
        default: {
            return [new State(msg.fields[0], state.Password, state.LoggingInProgress, state.Error), Cmd_none()];
        }
    }
}

