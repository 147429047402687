import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { union_type, class_type, record_type, int32_type, list_type, option_type, string_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { SelfMessage$1$reflection, State$reflection as State$reflection_1 } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { Types_Msg$reflection, Types_State$reflection } from "../Common/Paging.js";
import { PagingResult$1$reflection, InvoiceState$reflection, DraftSummary$reflection, InvoiceRow$reflection, Invoice$reflection, InvoiceSortFields$reflection } from "../../Shared/Shared.js";
import { Types_Msg$1$reflection, Types_State$1$reflection } from "../Common/Sorting.js";
import { Deferred$1$reflection } from "../Common/Extensions.js";

export class State extends Record {
    constructor(Filter, Debouncer, Paging, Sorting, Invoices, ExpandedRow, InvoiceDetails, DraftSummary) {
        super();
        this.Filter = Filter;
        this.Debouncer = Debouncer;
        this.Paging = Paging;
        this.Sorting = Sorting;
        this.Invoices = Invoices;
        this.ExpandedRow = (ExpandedRow | 0);
        this.InvoiceDetails = InvoiceDetails;
        this.DraftSummary = DraftSummary;
    }
}

export function State$reflection() {
    return record_type("Pages.Invoices.Model.State", [], State, () => [["Filter", string_type], ["Debouncer", State$reflection_1()], ["Paging", option_type(Types_State$reflection())], ["Sorting", Types_State$1$reflection(InvoiceSortFields$reflection())], ["Invoices", Deferred$1$reflection(list_type(Invoice$reflection()))], ["ExpandedRow", int32_type], ["InvoiceDetails", list_type(InvoiceRow$reflection())], ["DraftSummary", option_type(DraftSummary$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DebouncerSelfMsg", "EndOfInputSearch", "FilterChanged", "PagingMsg", "SortingMsg", "ExpandRow", "ShowInvoiceDetails", "MarkInvoiceForSending", "DeleteInvoice", "InvoiceDetailsLoadSuccess", "InvoiceWasChanged", "InvoiceWasDeleted", "InvoiceLoadSuccess", "DraftsSummaryLoadSuccess", "Failed", "UpdateDraftSummary", "MarkAllDraftsForSending", "AllDraftsWereMarkedForSending"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.Invoices.Model.Msg", [], Msg, () => [[["Item", SelfMessage$1$reflection(Msg$reflection())]], [], [["Item", string_type]], [["Item", Types_Msg$reflection()]], [["Item", Types_Msg$1$reflection(InvoiceSortFields$reflection())]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", int32_type]], [["Item", list_type(InvoiceRow$reflection())]], [["Item1", int32_type], ["Item2", InvoiceState$reflection()]], [["Item", int32_type]], [["Item", PagingResult$1$reflection(Invoice$reflection())]], [["Item", option_type(DraftSummary$reflection())]], [["Item", class_type("System.Exception")]], [], [], []]);
}

