import { Cmd_ofSub } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { startImmediate } from "../../.fable/fable-library.3.2.9/Async.js";
import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { Union } from "../../.fable/fable-library.3.2.9/Types.js";
import { union_type } from "../../.fable/fable-library.3.2.9/Reflection.js";

export function Cmd_fromAsync(operation) {
    return Cmd_ofSub((dispatch) => {
        startImmediate(singleton.Delay(() => singleton.Bind(operation, (_arg1) => {
            dispatch(_arg1);
            return singleton.Zero();
        })));
    });
}

export function Async_map(f, computation) {
    return singleton.Delay(() => singleton.Bind(computation, (_arg1) => singleton.Return(f(_arg1))));
}

export class Deferred$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HasNotStartedYet", "InProgress", "Resolved"];
    }
}

export function Deferred$1$reflection(gen0) {
    return union_type("Extensions.Deferred`1", [gen0], Deferred$1, () => [[], [], [["Item", gen0]]]);
}

export class AsyncOperationEvent$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Started", "Finished"];
    }
}

export function AsyncOperationEvent$1$reflection(gen0) {
    return union_type("Extensions.AsyncOperationEvent`1", [gen0], AsyncOperationEvent$1, () => [[], [["Item", gen0]]]);
}

export function Deferred_resolved(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return false;
        }
        case 2: {
            return true;
        }
        default: {
            return false;
        }
    }
}

export function Deferred_inProgress(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return true;
        }
        case 2: {
            return false;
        }
        default: {
            return false;
        }
    }
}

export function Deferred_map(transform, deferred) {
    switch (deferred.tag) {
        case 1: {
            return new Deferred$1(1);
        }
        case 2: {
            return new Deferred$1(2, transform(deferred.fields[0]));
        }
        default: {
            return new Deferred$1(0);
        }
    }
}

export function Deferred_exists(predicate, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return false;
        }
        case 2: {
            return predicate(_arg1.fields[0]);
        }
        default: {
            return false;
        }
    }
}

export function Deferred_bind(transform, deferred) {
    switch (deferred.tag) {
        case 1: {
            return new Deferred$1(1);
        }
        case 2: {
            return transform(deferred.fields[0]);
        }
        default: {
            return new Deferred$1(0);
        }
    }
}

