import { Record, Union } from "../../.fable/fable-library.3.2.9/Types.js";
import { record_type, list_type, string_type, union_type, class_type, bool_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { forAll as forAll_1, isEmpty, fold as fold_1, singleton, empty, filter } from "../../.fable/fable-library.3.2.9/List.js";
import { defaultArg } from "../../.fable/fable-library.3.2.9/Option.js";
import { forAll, add, empty as empty_1, tryFind } from "../../.fable/fable-library.3.2.9/Map.js";
import { fold } from "../../.fable/FsToolkit.ErrorHandling.2.6.0/Result.fs.js";
import { substring, isNullOrEmpty, isNullOrWhiteSpace, printf, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { create, match } from "../../.fable/fable-library.3.2.9/RegExp.js";
import { Result_Bind, FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { ResultCE_result, ResultCE_ResultBuilder__Delay_Z4709C901 } from "../../.fable/FsToolkit.ErrorHandling.2.6.0/ResultCE.fs.js";
import { isWhiteSpace, isDigit } from "../../.fable/fable-library.3.2.9/Char.js";
import { toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { fromParts, compare } from "../../.fable/fable-library.3.2.9/Decimal.js";

export class FormState_Validation$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["All", "PerField"];
    }
}

export function FormState_Validation$1$reflection(gen0) {
    return union_type("Pages.Common.FormState.Validation`1", [gen0], FormState_Validation$1, () => [[], [["Item", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [gen0, bool_type])]]]);
}

export class FormState_Form$2 extends Record {
    constructor(State, Validation, FieldErrors, FormErrors) {
        super();
        this.State = State;
        this.Validation = Validation;
        this.FieldErrors = FieldErrors;
        this.FormErrors = FormErrors;
    }
}

export function FormState_Form$2$reflection(gen0, gen1) {
    return record_type("Pages.Common.FormState.Form`2", [gen0, gen1], FormState_Form$2, () => [["State", gen0], ["Validation", FormState_Validation$1$reflection(gen1)], ["FieldErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [gen1, list_type(string_type)])], ["FormErrors", list_type(string_type)]]);
}

export function FormState_Validation_map(f, _arg1) {
    if (_arg1.tag === 1) {
        return new FormState_Validation$1(1, f(_arg1.fields[0]));
    }
    else {
        return new FormState_Validation$1(0);
    }
}

export function FormState_Validation_filter(x, errors) {
    if (x.tag === 1) {
        return filter((arg) => defaultArg(tryFind(arg[0], x.fields[0]), false), errors);
    }
    else {
        return errors;
    }
}

export function FormState_Validation_withField(field) {
    return (r) => fold((_arg1) => [field, empty()], (x) => [field, singleton(x)], r);
}

export function FormState_Form_create(state) {
    return new FormState_Form$2(state, new FormState_Validation$1(1, empty_1()), empty_1(), empty());
}

export function FormState_Form_withState(s, form) {
    return new FormState_Form$2(s, form.Validation, form.FieldErrors, form.FormErrors);
}

export function FormState_Form_withValidation(x, form) {
    return new FormState_Form$2(form.State, x, form.FieldErrors, form.FormErrors);
}

export function FormState_Form_withFieldErrors(errors, form) {
    return new FormState_Form$2(form.State, form.Validation, errors, form.FormErrors);
}

export function FormState_Form_withNoFieldErrors(form) {
    return new FormState_Form$2(form.State, form.Validation, empty_1(), form.FormErrors);
}

export function FormState_Form_withFormErrors(errors, form) {
    return new FormState_Form$2(form.State, form.Validation, form.FieldErrors, errors);
}

export function FormState_Form_withNoFormErrors(form) {
    return new FormState_Form$2(form.State, form.Validation, form.FieldErrors, empty());
}

export function FormState_Form_withNoErrors(form) {
    return FormState_Form_withNoFormErrors(FormState_Form_withNoFieldErrors(form));
}

export function FormState_Form_enableValidateField(field, form) {
    return FormState_Form_withValidation(FormState_Validation_map((table) => add(field, true, table), form.Validation), form);
}

export function FormState_Form_enableValiadateAll(form) {
    return FormState_Form_withValidation(new FormState_Validation$1(0), form);
}

export function FormState_Form_validate(fValidate, form) {
    let list;
    return FormState_Form_withFieldErrors((list = FormState_Validation_filter(form.Validation, fValidate(form)), fold_1((a, tupledArg) => add(tupledArg[0], tupledArg[1], a), empty_1(), list)), form);
}

export function FormState_Form_isValid(form) {
    if (forAll((_arg1, x) => isEmpty(x), form.FieldErrors)) {
        return isEmpty(form.FormErrors);
    }
    else {
        return false;
    }
}

export function FormState_Form_mapFieldError(field, f, form) {
    return f(defaultArg(tryFind(field, form.FieldErrors), empty()));
}

export function FormState_Form_mapFormError(f, form) {
    return f(form.FormErrors);
}

export function FormState_Form_getFieldError(field) {
    return (form) => FormState_Form_mapFieldError(field, (list) => fold_1((s, x) => toText(printf("%s %s"))(s)(x), "", list), form);
}

export function FormState_Form_getFormError(form) {
    return FormState_Form_mapFormError((list) => fold_1((s, x) => toText(printf("%s %s"))(s)(x), "", list), form);
}

export function FormState_Form_getFieldColor(field) {
    return (form) => FormState_Form_mapFieldError(field, (_arg1) => {
        if (isEmpty(_arg1)) {
            return new Color_IColor(20);
        }
        else {
            return new Color_IColor(8);
        }
    }, form);
}

export function Validation_validateEmailStr(email) {
    return match(create("^\\w+([-+.\u0027]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$"), email) != null;
}

export function Validation_validatePassword(value) {
    if (isNullOrWhiteSpace(value) ? true : (value.length < 8)) {
        return new FSharpResult$2(1, "Password is required and should contains at least 8 characters");
    }
    else {
        return new FSharpResult$2(0, value);
    }
}

export function Validation_validateString(fieldName, isRequired, minLength, maxLength, value) {
    if (isRequired ? isNullOrWhiteSpace(value) : false) {
        return new FSharpResult$2(1, toText(printf("%s is required"))(fieldName));
    }
    else if (value.length < minLength) {
        return new FSharpResult$2(1, toText(printf("%s cannot be less than %i"))(fieldName)(minLength));
    }
    else if (value.length > maxLength) {
        return new FSharpResult$2(1, toText(printf("%s cannot be more than %i"))(fieldName)(maxLength));
    }
    else {
        return new FSharpResult$2(0, value);
    }
}

export function Validation_requiredString(min, max, value) {
    return Validation_validateString("The field", true, min, max, value);
}

export function Validation_notRequiredString(min, max, value) {
    return Validation_validateString("The field", false, min, max, value);
}

export function Validation_notRequiredString200(value) {
    return Validation_validateString("The field", false, 0, 200, value);
}

export function Validation_notRequiredString50(value) {
    return Validation_validateString("The field", false, 0, 50, value);
}

export function Validation_requiredStringField(field, min, max, value) {
    return Validation_validateString(field, true, min, max, value);
}

export function Validation_notRequiredStringField(field, min, max, value) {
    return Validation_validateString(field, false, min, max, value);
}

export function Validation_notRequiredString200Field(field, value) {
    return Validation_validateString(field, false, 0, 200, value);
}

export function Validation_notRequiredString50Field(field, value) {
    return Validation_validateString(field, false, 0, 50, value);
}

export function Validation_requiredString50Field(field, value) {
    return Validation_validateString(field, true, 0, 50, value);
}

export function Validation_validateEmail(email) {
    if (Validation_validateEmailStr(email)) {
        return new FSharpResult$2(0, email);
    }
    else {
        return new FSharpResult$2(1, "Email is invalid");
    }
}

export function Validation_requiredEmail(email) {
    return ResultCE_ResultBuilder__Delay_Z4709C901(ResultCE_result, () => Result_Bind((_arg1) => Validation_validateEmail(_arg1), Validation_requiredStringField("Email", 3, 512, email)))();
}

export function Validation_notRequiredEmail(email) {
    return ResultCE_ResultBuilder__Delay_Z4709C901(ResultCE_result, () => (isNullOrEmpty(email) ? (new FSharpResult$2(0, email)) : Result_Bind((_arg1) => Validation_validateEmail(_arg1), Validation_requiredStringField("Email", 3, 512, email))))();
}

export function Validation_validateSender(sender) {
    return ResultCE_ResultBuilder__Delay_Z4709C901(ResultCE_result, () => {
        let s;
        return Result_Bind((_arg1) => {
            const s_1 = _arg1;
            const isNumber = forAll_1((c_1) => isDigit(c_1), toList(((s_1.indexOf("+") === 0) ? substring(s_1, 1) : s_1).split("")));
            const isAlphaNumeric = forAll_1((c_2) => {
                let c;
                if (((c = c_2, ((c >= "a") ? (c <= "z") : false) ? true : ((c >= "A") ? (c <= "Z") : false))) ? true : isWhiteSpace(c_2)) {
                    return true;
                }
                else {
                    return isDigit(c_2);
                }
            }, toList(s_1.split("")));
            let pattern_matching_result;
            if (isNumber) {
                if (sender.length > 15) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return new FSharpResult$2(1, "Field should be less than 15 chars");
                }
                case 1: {
                    if (isNumber) {
                        return new FSharpResult$2(0, sender);
                    }
                    else {
                        const matchValue = [isAlphaNumeric, !((sender.length >= 3) ? (sender.length <= 11) : false)];
                        if (matchValue[0]) {
                            if (matchValue[1]) {
                                return new FSharpResult$2(1, "Field should be greater than 3 and less than 11 chars");
                            }
                            else {
                                return new FSharpResult$2(0, sender);
                            }
                        }
                        else {
                            return new FSharpResult$2(1, "Invalid characters.");
                        }
                    }
                }
            }
        }, (s = sender, isNullOrWhiteSpace(s) ? (new FSharpResult$2(1, "Sender is required")) : (new FSharpResult$2(0, s))));
    })();
}

export function Validation_validateAmount(amount) {
    if (compare(amount, fromParts(10, 0, 0, false, 0)) < 0) {
        return new FSharpResult$2(1, "Amount should be greater at least 10 SEK");
    }
    else {
        return new FSharpResult$2(0, amount);
    }
}

export const Validation_validateAmountOption = (arg) => fold((_arg1) => (void 0), (arg0) => arg0, Validation_validateAmount(arg));

