import { Record, Union } from "../../.fable/fable-library.3.2.9/Types.js";
import { record_type, class_type, string_type, int32_type, union_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { parse } from "../../.fable/fable-library.3.2.9/Decimal.js";
import { AddTransaction } from "../../Shared/Shared.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { api } from "./Communication.js";
import { FSharpSet__Contains, FSharpSet__get_IsEmpty, FSharpSet__Add, empty } from "../../.fable/fable-library.3.2.9/Set.js";
import { compare } from "../../.fable/fable-library.3.2.9/Util.js";
import { isNullOrWhiteSpace } from "../../.fable/fable-library.3.2.9/String.js";
import { Validator_validateAmount } from "../Common/Utils.js";
import * as react from "react";
import { Option as Option_3, div } from "../../.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";
import { ofArray, singleton, empty as empty_1 } from "../../.fable/fable-library.3.2.9/List.js";
import { label } from "../../.fable/Fulma.2.14.1/Elements/Form/Label.fs.js";
import { div as div_1 } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { Option, IInputType, input } from "../../.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { Option as Option_1, help } from "../../.fable/Fulma.2.14.1/Elements/Form/Help.fs.js";
import { Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { singleton as singleton_1, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Option as Option_2, textarea } from "../../.fable/Fulma.2.14.1/Elements/Form/Textarea.fs.js";
import { HTMLAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Option as Option_4, button } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { content, Header_title, header, card } from "../../.fable/Fulma.2.14.1/Components/Card.fs.js";
import { content as content_1 } from "../../.fable/Fulma.2.14.1/Elements/Content.fs.js";

export class Types_ValidateError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AmountIsRequired", "CommentIsRequired"];
    }
}

export function Types_ValidateError$reflection() {
    return union_type("TopUp.Types.ValidateError", [], Types_ValidateError, () => [[], []]);
}

export class Types_EditState extends Record {
    constructor(CustomerId, Amount, Comment$, Errors) {
        super();
        this.CustomerId = (CustomerId | 0);
        this.Amount = Amount;
        this.Comment = Comment$;
        this.Errors = Errors;
    }
}

export function Types_EditState$reflection() {
    return record_type("TopUp.Types.EditState", [], Types_EditState, () => [["CustomerId", int32_type], ["Amount", string_type], ["Comment", string_type], ["Errors", class_type("Microsoft.FSharp.Collections.FSharpSet`1", [Types_ValidateError$reflection()])]]);
}

export class Types_State extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Initial", "Edit", "Saving", "AfterSave"];
    }
}

export function Types_State$reflection() {
    return union_type("TopUp.Types.State", [], Types_State, () => [[["customerId", int32_type]], [["Item", Types_EditState$reflection()]], [], [["Item", Types_EditState$reflection()]]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AmountChanged", "CommentChanged", "Save", "Cancel", "Saved", "AddOneMore"];
    }
}

export function Types_Msg$reflection() {
    return union_type("TopUp.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [], [["Item", Types_EditState$reflection()]], []]);
}

export class Types_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BalanceChanged"];
    }
}

export function Types_ExternalMsg$reflection() {
    return union_type("TopUp.Types.ExternalMsg", [], Types_ExternalMsg, () => [[]]);
}

function State_toAddTransaction(state) {
    return new AddTransaction(state.CustomerId, parse(state.Amount), state.Comment);
}

export function State_save(state) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, api().saveTransaction, State_toAddTransaction(state), () => (new Types_Msg(4, state)));
}

function State_initEditState(customerId) {
    return new Types_State(1, new Types_EditState(customerId, "10", "", empty({
        Compare: (x, y) => compare(x, y),
    })));
}

export function State_init(customerId) {
    return new Types_State(0, customerId);
}

function State_validate(state) {
    let state_3;
    let state_2;
    const state_1 = state;
    state_2 = (new Types_EditState(state_1.CustomerId, state_1.Amount, state_1.Comment, empty({
        Compare: (x, y) => compare(x, y),
    })));
    state_3 = (isNullOrWhiteSpace(state_2.Amount) ? (new Types_EditState(state_2.CustomerId, state_2.Amount, state_2.Comment, FSharpSet__Add(state_2.Errors, new Types_ValidateError(0)))) : state_2);
    if (isNullOrWhiteSpace(state_3.Comment)) {
        return new Types_EditState(state_3.CustomerId, state_3.Amount, state_3.Comment, FSharpSet__Add(state_3.Errors, new Types_ValidateError(1)));
    }
    else {
        return state_3;
    }
}

export function State_update(state, msg) {
    const matchValue = [msg, state];
    let pattern_matching_result, state_2, x_1;
    if (matchValue[0].tag === 0) {
        if (matchValue[1].tag === 1) {
            if (Validator_validateAmount(matchValue[0].fields[0])) {
                pattern_matching_result = 0;
                state_2 = matchValue[1].fields[0];
                x_1 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [new Types_State(1, new Types_EditState(state_2.CustomerId, x_1, state_2.Comment, state_2.Errors)), Cmd_none(), void 0];
        }
        case 1: {
            let pattern_matching_result_1, editState, x_2, editState_1, state_4, state_5, customerId, state_6;
            if (matchValue[0].tag === 1) {
                if (matchValue[1].tag === 1) {
                    pattern_matching_result_1 = 0;
                    editState = matchValue[1].fields[0];
                    x_2 = matchValue[0].fields[0];
                }
                else {
                    pattern_matching_result_1 = 6;
                }
            }
            else if (matchValue[0].tag === 2) {
                if (matchValue[1].tag === 1) {
                    pattern_matching_result_1 = 1;
                    editState_1 = matchValue[1].fields[0];
                }
                else {
                    pattern_matching_result_1 = 6;
                }
            }
            else if (matchValue[0].tag === 4) {
                if (matchValue[1].tag === 2) {
                    pattern_matching_result_1 = 2;
                    state_4 = matchValue[0].fields[0];
                }
                else {
                    pattern_matching_result_1 = 6;
                }
            }
            else if (matchValue[0].tag === 5) {
                if (matchValue[1].tag === 3) {
                    pattern_matching_result_1 = 3;
                    state_5 = matchValue[1].fields[0];
                }
                else if (matchValue[1].tag === 0) {
                    pattern_matching_result_1 = 4;
                    customerId = matchValue[1].fields[0];
                }
                else {
                    pattern_matching_result_1 = 6;
                }
            }
            else if (matchValue[0].tag === 3) {
                if (matchValue[1].tag === 1) {
                    pattern_matching_result_1 = 5;
                    state_6 = matchValue[1].fields[0];
                }
                else {
                    pattern_matching_result_1 = 6;
                }
            }
            else {
                pattern_matching_result_1 = 6;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    if (x_2.length <= 50) {
                        return [new Types_State(1, new Types_EditState(editState.CustomerId, editState.Amount, x_2, editState.Errors)), Cmd_none(), void 0];
                    }
                    else {
                        return [state, Cmd_none(), void 0];
                    }
                }
                case 1: {
                    const newEditState = State_validate(editState_1);
                    if (FSharpSet__get_IsEmpty(newEditState.Errors)) {
                        return [new Types_State(2), State_save(editState_1), void 0];
                    }
                    else {
                        return [new Types_State(1, newEditState), Cmd_none(), void 0];
                    }
                }
                case 2: {
                    return [new Types_State(3, state_4), Cmd_none(), new Types_ExternalMsg(0)];
                }
                case 3: {
                    return [State_initEditState(state_5.CustomerId), Cmd_none(), void 0];
                }
                case 4: {
                    return [State_initEditState(customerId), Cmd_none(), void 0];
                }
                case 5: {
                    return [new Types_State(0, state_6.CustomerId), Cmd_none(), void 0];
                }
                case 6: {
                    return [state, Cmd_none(), void 0];
                }
            }
        }
    }
}

export function View_renderEdit(state, dispatch) {
    return react.createElement("div", {}, div(empty_1(), ofArray([label(empty_1(), singleton("Amount")), div_1(empty_1(), ofArray([input(ofArray([new Option(1, new IInputType(0)), new Option(11, state.Amount), new Option(13, (x) => {
        dispatch(new Types_Msg(0, Browser_Types_Event__Event_get_Value(x)));
        x.preventDefault();
    })])), help(singleton(new Option_1(2, new Color_IColor(8))), toList(delay(() => (FSharpSet__Contains(state.Errors, new Types_ValidateError(0)) ? singleton_1("Amount is required") : singleton_1("")))))]))])), div(empty_1(), ofArray([label(empty_1(), singleton("Comment")), div_1(empty_1(), ofArray([textarea(ofArray([new Option_2(14, singleton(new HTMLAttr(115, 50))), new Option_2(12, state.Comment), new Option_2(15, (x_1) => {
        dispatch(new Types_Msg(1, Browser_Types_Event__Event_get_Value(x_1)));
        x_1.preventDefault();
    })]), empty_1()), help(singleton(new Option_1(2, new Color_IColor(8))), toList(delay(() => (FSharpSet__Contains(state.Errors, new Types_ValidateError(1)) ? singleton_1("Comment is required") : singleton_1("")))))]))])), div(singleton(new Option_3(5)), ofArray([div_1(empty_1(), singleton(button(singleton(new Option_4(18, (x_2) => {
        dispatch(new Types_Msg(3));
        x_2.preventDefault();
    })), singleton("Cancel")))), div_1(empty_1(), singleton(button(ofArray([new Option_4(0, new Color_IColor(4)), new Option_4(18, (x_3) => {
        dispatch(new Types_Msg(2));
        x_3.preventDefault();
    })]), singleton("Save"))))])));
}

export function View_renderInitial(dispatch) {
    return react.createElement("div", {}, div(empty_1(), singleton(div_1(empty_1(), singleton("Here you can top up customer balance.")))), div(empty_1(), singleton(div_1(empty_1(), singleton(button(singleton(new Option_4(18, (x) => {
        dispatch(new Types_Msg(5));
        x.preventDefault();
    })), singleton("Top up")))))));
}

export function View_renderAfterSave(dispatch) {
    return react.createElement("div", {}, div(empty_1(), singleton(div_1(empty_1(), singleton("Transaction saved successfully.")))), div(empty_1(), singleton(div_1(empty_1(), singleton(button(singleton(new Option_4(18, (x) => {
        dispatch(new Types_Msg(5));
        x.preventDefault();
    })), singleton("Add one more")))))));
}

export function View_renderContent(state, dispatch) {
    switch (state.tag) {
        case 1: {
            return View_renderEdit(state.fields[0], dispatch);
        }
        case 2: {
            return react.createElement("div", {}, "Saving ...");
        }
        case 3: {
            return View_renderAfterSave(dispatch);
        }
        default: {
            return View_renderInitial(dispatch);
        }
    }
}

export function View_render(state, dispatch) {
    return card(empty_1(), ofArray([header(empty_1(), singleton(Header_title(empty_1(), singleton("TopUp balance")))), content(empty_1(), singleton(content_1(empty_1(), singleton(View_renderContent(state, dispatch)))))]));
}

