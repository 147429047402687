import { referenceApi, apiVirtualNumbers } from "../../Api.js";
import { useReact_useEffect_Z101E1A95, useFeliz_React__React_useState_Static_1505 } from "../../.fable/Feliz.1.49.0/React.fs.js";
import { printf, toText, isNullOrEmpty } from "../../.fable/fable-library.3.2.9/String.js";
import { isMatch } from "../../.fable/fable-library.3.2.9/RegExp.js";
import { toString } from "../../.fable/fable-library.3.2.9/Decimal.js";
import Decimal from "../../.fable/fable-library.3.2.9/Decimal.js";
import { useFeliz_React__React_useDeferredCallback_Static_7088D81D } from "../../.fable/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { Msg } from "./Model.js";
import { Interop_reactApi } from "../../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";
import { cons, singleton, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { empty, singleton as singleton_1, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Contract_Tariff } from "../../Shared/VirtualNumbers.js";

export function VirtualNumbers(virtualNumbersInputProps) {
    let props_8, props_6, props_16, props_14, props_24, props_22, props_32, props_30, props_37, elms, props_34;
    const dispatch = virtualNumbersInputProps.dispatch;
    const loadData = apiVirtualNumbers().getSettings();
    const patternInput = useFeliz_React__React_useState_Static_1505(true);
    const isLoading = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("0.00");
    const setupCostValue = patternInput_1[0];
    const setSetupCostValue = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505("0.00");
    const setMonthlyFeeValue = patternInput_2[1];
    const monthlyFeeValue = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("0.00");
    const setPrefixesSetupCostValue = patternInput_3[1];
    const prefixesSetupCostValue = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505("0.00");
    const setPrefixesMonthlyFeeValue = patternInput_4[1];
    const prefixesMonthlyFeeValue = patternInput_4[0];
    const isValidValue = (x) => {
        let copyOfStruct;
        if ((isNullOrEmpty(x) ? true : isMatch(x, "\\.[0-9]+")) ? true : isMatch(x, "[0-9]+\\.")) {
            return true;
        }
        else {
            return ((copyOfStruct = (new Decimal(x)), toString(copyOfStruct))) === x;
        }
    };
    const isDecimal = (x_1) => {
        let copyOfStruct_1;
        if (!isNullOrEmpty(x_1)) {
            return ((copyOfStruct_1 = (new Decimal(x_1)), toString(copyOfStruct_1))) === x_1;
        }
        else {
            return false;
        }
    };
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setIsChanged = patternInput_5[1];
    const isReadyToSave = () => {
        if (((patternInput_5[0] ? isDecimal(setupCostValue) : false) ? isDecimal(monthlyFeeValue) : false) ? isDecimal(prefixesSetupCostValue) : false) {
            return isDecimal(prefixesMonthlyFeeValue);
        }
        else {
            return false;
        }
    };
    const startLoadingData = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => loadData, (data) => {
        let pattern_matching_result, values;
        if (data.tag === 2) {
            if (data.fields[0].tag === 0) {
                pattern_matching_result = 0;
                values = data.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                setSetupCostValue(toText(printf("%.2f"))(values.WholeNumberSetupCost));
                setMonthlyFeeValue(toText(printf("%.2f"))(values.WholeNumberMonthlyFee));
                setPrefixesSetupCostValue(toText(printf("%.2f"))(values.NumberWithPrefixSetupCost));
                setPrefixesMonthlyFeeValue(toText(printf("%.2f"))(values.NumberWithPrefixMonthlyFee));
                patternInput[1](false);
                break;
            }
            case 1: {
                break;
            }
        }
    });
    const saveSettings = useFeliz_React__React_useDeferredCallback_Static_7088D81D((settings) => apiVirtualNumbers().saveTariff(settings), (r) => {
        let pattern_matching_result_1;
        if (r.tag === 2) {
            if (r.fields[0].tag === 0) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                dispatch(new Msg(5, "Settings were saved"));
                startLoadingData();
                break;
            }
            case 1: {
                break;
            }
        }
    });
    useReact_useEffect_Z101E1A95(startLoadingData, []);
    const props_39 = ofArray([["style", {
        maxWidth: 400,
    }], ["children", Interop_reactApi.Children.toArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-5"], ["children", "Virtual numbers"]])))), (props_8 = singleton(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Setup cost"])))), (props_6 = toList(delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => {
        let props_4;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append((!isLoading) ? singleton_1(["value", setupCostValue]) : empty(), delay(() => singleton_1(["onChange", (ev) => {
            const x_2 = ev.target.value;
            if (isValidValue(x_2)) {
                setSetupCostValue(x_2);
                setIsChanged(true);
            }
        }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_4)))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("control", props_6))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_8)))), (props_16 = singleton(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Monthly fee"])))), (props_14 = toList(delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => {
        let props_12;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_12 = toList(delay(() => append((!isLoading) ? singleton_1(["value", monthlyFeeValue]) : empty(), delay(() => singleton_1(["onChange", (ev_1) => {
            const x_3 = ev_1.target.value;
            if (isValidValue(x_3)) {
                setMonthlyFeeValue(x_3);
                setIsChanged(true);
            }
        }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_12)))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("control", props_14))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_16)))), (props_24 = singleton(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Prefixes Setup cost"])))), (props_22 = toList(delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => {
        let props_20;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_20 = toList(delay(() => append((!isLoading) ? singleton_1(["value", prefixesSetupCostValue]) : empty(), delay(() => singleton_1(["onChange", (ev_2) => {
            const x_4 = ev_2.target.value;
            if (isValidValue(x_4)) {
                setPrefixesSetupCostValue(x_4);
                setIsChanged(true);
            }
        }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_20)))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("control", props_22))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_24)))), (props_32 = singleton(["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Prefixes Monthly fee"])))), (props_30 = toList(delay(() => append(isLoading ? singleton_1(["className", "is-loading"]) : empty(), delay(() => {
        let props_28;
        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_28 = toList(delay(() => append((!isLoading) ? singleton_1(["value", prefixesMonthlyFeeValue]) : empty(), delay(() => singleton_1(["onChange", (ev_3) => {
            const x_5 = ev_3.target.value;
            if (isValidValue(x_5)) {
                setPrefixesMonthlyFeeValue(x_5);
                setIsChanged(true);
            }
        }]))))), createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", props_28)))))])]);
    })))), createElement("div", createObj(Helpers_combineClasses("control", props_30))))])]), createElement("div", createObj(Helpers_combineClasses("field", props_32)))), (props_37 = ofArray([["className", "is-grouped"], ["className", "is-grouped-right"], ["children", Interop_reactApi.Children.toArray([(elms = singleton((props_34 = ofArray([["className", "is-success"], ["children", "Save"], ["onClick", (_arg1) => {
        if (isReadyToSave()) {
            saveSettings(new Contract_Tariff(new Decimal(setupCostValue), new Decimal(monthlyFeeValue), new Decimal(prefixesSetupCostValue), new Decimal(prefixesMonthlyFeeValue)));
        }
    }], ["disabled", !isReadyToSave()]]), createElement("button", createObj(Helpers_combineClasses("button", props_34))))), createElement("div", {
        className: "control",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_37))))])]]);
    return createElement("div", createObj(Helpers_combineClasses("box", props_39)));
}

export function RenderAnnouncement(renderAnnouncementInputProps) {
    let props_6, props_4;
    const dispatch = renderAnnouncementInputProps.dispatch;
    const loadData = referenceApi().getAnnouncement();
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const setAnnouncementValue = patternInput[1];
    const announcementValue = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const setIsLoading = patternInput_1[1];
    const startLoadingData = useFeliz_React__React_useDeferredCallback_Static_7088D81D(() => loadData, (data) => {
        let pattern_matching_result, value;
        if (data.tag === 2) {
            if (data.fields[0].tag === 0) {
                pattern_matching_result = 0;
                value = data.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                setAnnouncementValue(value);
                setIsLoading(false);
                break;
            }
            case 1: {
                break;
            }
        }
    });
    const saveAnnouncement = useFeliz_React__React_useDeferredCallback_Static_7088D81D((announcement) => referenceApi().saveAnnouncement(announcement), (r) => {
        setIsLoading(true);
        let pattern_matching_result_1;
        if (r.tag === 2) {
            if (r.fields[0].tag === 0) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                dispatch(new Msg(5, "Announcement saved"));
                startLoadingData();
                break;
            }
            case 1: {
                break;
            }
        }
    });
    useReact_useEffect_Z101E1A95(startLoadingData, []);
    const elms = ofArray([createElement("h1", createObj(Helpers_combineClasses("title", ofArray([["className", "is-5"], ["children", "Announcement"]])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["onChange", (ev) => {
        setAnnouncementValue(ev.target.value);
    }], ["value", announcementValue]])))), (props_6 = ofArray([["className", "is-right"], ["style", {
        marginTop: 10,
    }], ["children", Interop_reactApi.Children.toArray([(props_4 = toList(delay(() => append(singleton_1(["className", "is-primary"]), delay(() => append(patternInput_1[0] ? singleton_1(["className", "is-loading"]) : empty(), delay(() => append(singleton_1(["onClick", (_arg1) => {
        console.debug("here");
        saveAnnouncement(announcementValue);
    }]), delay(() => singleton_1(["children", "Save"]))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_4))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_6))))]);
    return createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

export function render(model, dispatch) {
    let props, props_2;
    const elms = ofArray([(props = ofArray([["className", "is-4"], ["children", Interop_reactApi.Children.toArray([createElement(VirtualNumbers, {
        dispatch: dispatch,
    })])]]), createElement("div", createObj(Helpers_combineClasses("column", props)))), (props_2 = singleton(["children", Interop_reactApi.Children.toArray([createElement(RenderAnnouncement, {
        dispatch: dispatch,
    })])]), createElement("div", createObj(Helpers_combineClasses("column", props_2))))]);
    return createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

