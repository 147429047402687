import { Union } from "../../fable-library.3.2.9/Types.js";
import { Common_GenericOptions__AddProp_7BFEDA81, Common_parseOptions, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Reflection_getCaseName, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Size_ISize$reflection } from "../Common.fs.js";
import { bool_type, union_type, list_type, class_type, string_type } from "../../fable-library.3.2.9/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.2.9/MapUtil.js";
import { DOMAttr, DangerousHtml } from "../../Fable.React.7.4.1/Fable.React.Props.fs.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-centered", "is-right", "is-rounded", "Size", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Pagination.Option", [], Option, () => [[], [], [], [["Item", Size_ISize$reflection()]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function pagination(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 2: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 3: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
            case 5: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 6: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "pagination"), (props_1, children_1) => react.createElement("nav", keyValueList(props_1, 1), ...children_1), children);
}

export function Previous_previous(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "pagination-previous"), element, children);
}

export function Previous_a(options, children) {
    return Previous_previous((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Previous_button(options, children) {
    return Previous_previous((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export function previous(options, children) {
    return Previous_button(options, children);
}

export function Next_next(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "pagination-next"), element, children);
}

export function Next_a(options, children) {
    return Next_next((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Next_button(options, children) {
    return Next_next((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export function next(options, children) {
    return Next_button(options, children);
}

export class Link_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-current", "CustomClass", "Props", "Modifiers"];
    }
}

export function Link_Option$reflection() {
    return union_type("Fulma.Pagination.Link.Option", [], Link_Option, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function Link_link(element, options, children) {
    return react.createElement("li", {}, Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "pagination-link"), element, children));
}

export function Link_a(options, children) {
    return Link_link((props, children_1) => react.createElement("a", keyValueList(props, 1), ...children_1), options, children);
}

export function Link_button(options, children) {
    return Link_link((props, children_1) => react.createElement("button", keyValueList(props, 1), ...children_1), options, children);
}

export function link(options, children) {
    return Link_button(options, children);
}

export function ellipsis(options) {
    return react.createElement("li", {}, Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions__AddProp_7BFEDA81(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "pagination-ellipsis"), new DOMAttr(0, new DangerousHtml("\u0026hellip;"))), (props, children) => react.createElement("span", keyValueList(props, 1), ...children)));
}

export function list(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => Common_parseOptions(result, option), "pagination-list"), (props, children_1) => react.createElement("ul", keyValueList(props, 1), ...children_1), children);
}

