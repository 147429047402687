import { View_render } from "../Common/Paging.js";
import { Msg } from "./Model.js";
import { map } from "../../.fable/fable-library.3.2.9/Option.js";
import { Option, div } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { empty, ofArray, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { TableOption, table as table_1 } from "../../.fable/Fulma.2.14.1/Elements/Table.fs.js";
import * as react from "react";
import { Option as Option_1, columns } from "../../.fable/Fulma.2.14.1/Layouts/Columns.fs.js";
import { Option as Option_2, ISize, column } from "../../.fable/Fulma.2.14.1/Layouts/Column.fs.js";
import { Common_GenericOption, Color_IColor, Size_ISize, Screen } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { Option as Option_3, IInputType, input } from "../../.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { HTMLAttr, DOMAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Item_Option, Item_a, content as content_1, menu, Option as Option_4, dropdown } from "../../.fable/Fulma.2.14.1/Components/Dropdown.fs.js";
import { list, Option as Option_5, button } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { Fa_ISize, Fa_IconOption, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_6, modal } from "../../.fable/Fulma.2.14.1/Components/Modal.fs.js";
import { Option as Option_7, delete$ } from "../../.fable/Fulma.2.14.1/Elements/Delete.fs.js";
import { map as map_1, singleton as singleton_1, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { View_render as View_render_1 } from "../Common/Sorting.js";
import { OptedOutSortFields } from "../../Shared/Shared.js";
import { printf, toText } from "../../.fable/fable-library.3.2.9/String.js";
import { toString } from "../../.fable/fable-library.3.2.9/Date.js";
import { equals } from "../../.fable/fable-library.3.2.9/Util.js";

export function renderPaging(state, dispatch) {
    return View_render(state, (arg) => {
        dispatch(new Msg(2, arg));
    });
}

export function renderPagingOpt(state, dispatch) {
    const o_1 = map((s) => renderPaging(s, dispatch), state);
    if (o_1 == null) {
        return null;
    }
    else {
        return o_1;
    }
}

export function filter(_state, dispatch) {
    return div(singleton(new Option(3)), singleton(table_1(ofArray([new TableOption(4), new TableOption(2)]), singleton(react.createElement("tr", {}, react.createElement("td", {}, columns(singleton(new Option_1(1)), singleton(column(singleton(new Option_2(0, new Screen(0), new ISize(0))), singleton(div(empty(), singleton(input(ofArray([new Option_3(1, new IInputType(0)), new Option_3(12, "Search..."), new Option_3(15, ofArray([new DOMAttr(9, (e) => {
        dispatch(new Msg(4, e.target.value));
    }), new HTMLAttr(55, true)]))]))))))))))))));
}

export function renderActions(optedOut, isDeleting, dispatch) {
    return dropdown(ofArray([new Option_4(1), new Option_4(2)]), ofArray([button(ofArray([new Option_5(1, new Size_ISize(0)), new Option_5(8), new Option_5(0, new Color_IColor(2)), new Option_5(16, isDeleting)]), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-ellipsis-v"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), menu(empty(), singleton(content_1(empty(), singleton(list(empty(), singleton(Item_a(ofArray([new Item_Option(3, empty()), new Item_Option(1, singleton(new DOMAttr(40, (x) => {
        dispatch(new Msg(7, optedOut));
        x.preventDefault();
    })))]), singleton(columns(empty(), ofArray([column(singleton(new Option_2(0, new Screen(0), new ISize(17))), singleton(Fa_i(ofArray([new Fa_IconOption(11, "fas fa-trash"), new Fa_IconOption(0, new Fa_ISize(1))]), []))), column(empty(), singleton("Delete"))]))))))))))]));
}

export function confirmationDialog(title, content, confirm, closeDisplay, buttonName) {
    const cancelDialog = (_arg1) => {
        closeDisplay();
    };
    return modal(singleton(new Option_6(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, cancelDialog)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton(title)), delete$(singleton(new Option_7(3, cancelDialog)), empty())])), Card_body(empty(), singleton(content)), Card_foot(singleton(new Common_GenericOption(1, singleton(["style", {
        justifyContent: "flex-end",
    }]))), ofArray([button(singleton(new Option_5(18, cancelDialog)), singleton("Cancel")), button(ofArray([new Option_5(0, new Color_IColor(8)), new Option_5(18, (_arg2) => {
        confirm();
    })]), singleton(buttonName))]))]))]));
}

function renderDeleteConfirmation(state, dispatch) {
    const matchValue = state.ShowDeleteConfirmation;
    if (matchValue == null) {
        return null;
    }
    else {
        const optedOut = matchValue;
        return confirmationDialog("Delete", react.createElement("p", {}, ...toList(delay(() => singleton_1("Are you sure you want to delete opt-out?")))), () => {
            dispatch(new Msg(9, optedOut));
        }, () => {
            dispatch(new Msg(8));
        }, "Delete");
    }
}

export function table(state, dispatch) {
    if (state.Loading) {
        return "loading";
    }
    else {
        const sortHeader = (field, name) => View_render_1(state.Sorting, field, name, (arg) => {
            dispatch(new Msg(3, arg));
        });
        return react.createElement("div", {}, table_1(ofArray([new TableOption(4), new TableOption(2)]), ofArray([react.createElement("thead", {}, react.createElement("tr", {}, sortHeader(new OptedOutSortFields(0), "Customer ID"), sortHeader(new OptedOutSortFields(1), "Customer name"), sortHeader(new OptedOutSortFields(2), "Phone"), sortHeader(new OptedOutSortFields(3), "Timestamp (UTC)"), react.createElement("th", {}))), react.createElement("tbody", {}, ...toList(delay(() => map_1((optedOut) => react.createElement("tr", {}, react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, toText(printf("%d"))(optedOut.CompanyId)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, optedOut.CompanyName), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, optedOut.PhoneNumber), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, toString(optedOut.CreatedUtcTime, "yyyy-MM-dd HH:mm:ss")), react.createElement("td", {
            style: {
                verticalAlign: "middle",
                textAlign: "center",
            },
        }, renderActions(optedOut, equals(state.Deleting, optedOut), dispatch))), state.OptedOuts))))])), renderPagingOpt(state.Paging, dispatch));
    }
}

export function view(state, dispatch) {
    return react.createElement("div", {}, filter(state, dispatch), table(state, dispatch), renderDeleteConfirmation(state, dispatch));
}

