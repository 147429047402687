import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { Api_KindOfNumbers, Api_VirtualNumbersSortFields, Api_VirtualNumberSearchRequest } from "../../Shared/VirtualNumbers.js";
import { apiVirtualNumbers } from "../../Api.js";
import { State, Msg } from "./Types.js";
import { Cmd_none, Cmd_map, Cmd_batch, Cmd_OfAsyncWith_perform, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Errors_VirtualNumberError, SortDirection } from "../../Shared/Shared.js";
import { update, bounce, create } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { State_update as State_update_1, Types_State$1 } from "../Common/Sorting.js";
import { Cmd_fromAsync, Deferred$1 } from "../Common/Extensions.js";
import { ofArray, singleton as singleton_1 } from "../../.fable/fable-library.3.2.9/List.js";
import { fromSeconds } from "../../.fable/fable-library.3.2.9/TimeSpan.js";
import { value } from "../../.fable/fable-library.3.2.9/Option.js";
import { State_init, State_update } from "../Common/Paging.js";
import { toastSuccess, toastError } from "../Components/Toast.js";
import { equals } from "../../.fable/fable-library.3.2.9/Util.js";
import { printf, toConsole } from "../../.fable/fable-library.3.2.9/String.js";

export function Api_loadVirtualNumbersCmd(sortField, sortDirection, page, pageSize, filter, kindOfNumbers) {
    return singleton.Delay(() => {
        const request = new Api_VirtualNumberSearchRequest(sortField, sortDirection, page, pageSize, filter, kindOfNumbers);
        return singleton.Bind(apiVirtualNumbers().findByPageWithFilter(request), (_arg1) => singleton.Return(new Msg(5, _arg1)));
    });
}

export function Api_addVirtualNumberCmd(request) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, apiVirtualNumbers().add, request, (arg0) => (new Msg(7, arg0)), (arg0_1) => (new Msg(14, arg0_1)));
}

export function Api_assignVirtualNumberCmd(request) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, apiVirtualNumbers().assign, request, (arg0) => (new Msg(11, arg0)), (arg0_1) => (new Msg(14, arg0_1)));
}

export function Api_unassignVirtualNumberCmd(id) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, apiVirtualNumbers().unassign, id, (_arg1) => (new Msg(12)), (arg0) => (new Msg(14, arg0)));
}

export function Api_deleteVirtualNumberCmd(id) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, () => apiVirtualNumbers().delete(id), void 0, (arg0) => (new Msg(13, arg0)));
}

export const Model_DEFAULT_SORT_FIELD = new Api_VirtualNumbersSortFields(5);

export const Model_DEFAULT_SORT_DIRECTION = new SortDirection(0);

export function Model_init() {
    return [new State("", create(), void 0, new Types_State$1(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION), new Deferred$1(1), new Api_KindOfNumbers(0)), Cmd_batch(singleton_1(Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))))];
}

export function Model_update(msg, model) {
    if (msg.tag === 2) {
        const patternInput_1 = bounce(fromSeconds(1), "virtual_search", new Msg(1), model.Debouncer);
        return [new State(msg.fields[0], patternInput_1[0], model.Paging, model.Sorting, model.VirtualNumbers, model.KindOfNumbers), Cmd_batch(singleton_1(Cmd_map((arg0) => (new Msg(0, arg0)), patternInput_1[1])))];
    }
    else if (msg.tag === 15) {
        const kind = (msg.fields[0].toLocaleLowerCase() === "only free") ? (new Api_KindOfNumbers(1)) : (new Api_KindOfNumbers(0));
        console.log(kind);
        return [new State(model.Filter, model.Debouncer, model.Paging, model.Sorting, model.VirtualNumbers, kind), Cmd_batch(singleton_1(Cmd_fromAsync(Api_loadVirtualNumbersCmd(model.Sorting.Field, model.Sorting.Direction, value(model.Paging).CurrentPage, 20, model.Filter, kind))))];
    }
    else if (msg.tag === 1) {
        return [model, Cmd_batch(singleton_1(Cmd_fromAsync(Api_loadVirtualNumbersCmd(model.Sorting.Field, model.Sorting.Direction, value(model.Paging).CurrentPage, 20, model.Filter, model.KindOfNumbers))))];
    }
    else if (msg.tag === 3) {
        const matchValue_1 = model.Paging;
        if (matchValue_1 != null) {
            const patternInput_2 = State_update(matchValue_1, msg.fields[0]);
            const extCmd = patternInput_2[1];
            return [new State(model.Filter, model.Debouncer, patternInput_2[0], model.Sorting, new Deferred$1(1), model.KindOfNumbers), (extCmd == null) ? Cmd_none() : Cmd_fromAsync(Api_loadVirtualNumbersCmd(model.Sorting.Field, model.Sorting.Direction, extCmd.fields[0], 20, model.Filter, model.KindOfNumbers))];
        }
        else {
            return [model, Cmd_none()];
        }
    }
    else if (msg.tag === 4) {
        const nextState_1 = State_update_1(model.Sorting, msg.fields[0])[0];
        return [new State(model.Filter, model.Debouncer, model.Paging, nextState_1, new Deferred$1(1), model.KindOfNumbers), Cmd_fromAsync(Api_loadVirtualNumbersCmd(nextState_1.Field, nextState_1.Direction, value(model.Paging).CurrentPage, 20, model.Filter, model.KindOfNumbers))];
    }
    else if (msg.tag === 5) {
        return [new State(model.Filter, model.Debouncer, State_init(msg.fields[0].Page, msg.fields[0].PageSize, msg.fields[0].Total), model.Sorting, new Deferred$1(2, msg.fields[0].Items), model.KindOfNumbers), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [model, Api_addVirtualNumberCmd(msg.fields[0])];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            return [model, (msg.fields[0].fields[0].tag === 4) ? Cmd_batch(ofArray([toastError("Virtual number/Prefix already exists"), Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))])) : Cmd_batch(ofArray([toastError("Something went wrong. Try again later"), Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))]))];
        }
        else {
            return [model, Cmd_batch(ofArray([toastSuccess("Virtual number was added"), Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))]))];
        }
    }
    else if (msg.tag === 12) {
        return [model, Cmd_batch(ofArray([toastSuccess("Virtual number was unassigned"), Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))]))];
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            return [model, (msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(0)) ? toastError("Virtual number should be free") : ((msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(1)) ? toastError("Not enough money") : ((msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(2)) ? toastError("No such virtual number") : Cmd_none()) : Cmd_none())) : ((msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(2)) ? toastError("No such virtual number") : Cmd_none()) : Cmd_none()))) : ((msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(1)) ? toastError("Not enough money") : ((msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(2)) ? toastError("No such virtual number") : Cmd_none()) : Cmd_none())) : ((msg.fields[0].fields[0].tag === 0) ? (equals(msg.fields[0].fields[0].fields[0], new Errors_VirtualNumberError(2)) ? toastError("No such virtual number") : Cmd_none()) : Cmd_none()))];
        }
        else {
            return [model, Cmd_batch(ofArray([toastSuccess("Virtual number was assigned"), Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))]))];
        }
    }
    else if (msg.tag === 8) {
        return [model, Api_assignVirtualNumberCmd(msg.fields[0])];
    }
    else if (msg.tag === 9) {
        return [model, Api_unassignVirtualNumberCmd(msg.fields[0])];
    }
    else if (msg.tag === 10) {
        return [model, Api_deleteVirtualNumberCmd(msg.fields[0])];
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            toConsole(printf("TESSST"));
            return [model, toastError((msg.fields[0].fields[0].tag === 0) ? ((msg.fields[0].fields[0].fields[0].tag === 3) ? "Virtual number has incoming messages" : "Something went wrong. Try again later") : "Something went wrong. Try again later")];
        }
        else {
            return [model, Cmd_fromAsync(Api_loadVirtualNumbersCmd(Model_DEFAULT_SORT_FIELD, Model_DEFAULT_SORT_DIRECTION, 1, 20, "", new Api_KindOfNumbers(0)))];
        }
    }
    else if (msg.tag === 14) {
        return [model, toastError("Something went wrong. Try again later")];
    }
    else {
        const patternInput = update(msg.fields[0], model.Debouncer);
        return [new State(model.Filter, patternInput[0], model.Paging, model.Sorting, model.VirtualNumbers, model.KindOfNumbers), patternInput[1]];
    }
}

