import { Cmd_map, Cmd_none, Cmd_batch, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { optedOutApi } from "../../Api.js";
import { SortDirection, OptedOutSortFields, DeleteOptedOut, OptedOutRequest } from "../../Shared/Shared.js";
import { State, Msg } from "./Model.js";
import { singleton, empty } from "../../.fable/fable-library.3.2.9/List.js";
import { State_update as State_update_1, Types_State$1 } from "../Common/Sorting.js";
import { update, bounce, create } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";
import { toastError } from "../Components/Toast.js";
import { State_init, State_update } from "../Common/Paging.js";
import { value } from "../../.fable/fable-library.3.2.9/Option.js";
import { fromSeconds } from "../../.fable/fable-library.3.2.9/TimeSpan.js";

export function Api_loadOptedOutsCmd(field, direction, page, pageSize, filter) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, (page_1) => optedOutApi().getOptedOuts(new OptedOutRequest(field, direction, page_1, pageSize, filter)), page, (arg0) => (new Msg(0, arg0)), (arg0_1) => (new Msg(1, arg0_1)));
}

export function Api_deleteOptedOut(optedOut) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, optedOutApi().deleteOptedOut, new DeleteOptedOut(optedOut.CompanyId, optedOut.PhoneNumber), () => (new Msg(10, optedOut)), (arg0) => (new Msg(11, arg0)));
}

export const Update_DEFAULT_SORT_FIELD = new OptedOutSortFields(0);

export const Update_DEFAULT_SORT_DIRECTION = new SortDirection(1);

export function Update_init() {
    return [new State(empty(), void 0, new Types_State$1(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION), true, "", "", create(), void 0, void 0), Cmd_batch(singleton(Api_loadOptedOutsCmd(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION, 1, 20, "")))];
}

export function Update_update(msg, state) {
    switch (msg.tag) {
        case 1: {
            return [new State(state.OptedOuts, state.Paging, state.Sorting, false, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, state.Deleting), Cmd_batch(singleton(toastError("Load error")))];
        }
        case 2: {
            const matchValue = state.Paging;
            if (matchValue != null) {
                const patternInput = State_update(matchValue, msg.fields[0]);
                const extCmd = patternInput[1];
                return [new State(state.OptedOuts, patternInput[0], state.Sorting, true, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, state.Deleting), (extCmd == null) ? Cmd_none() : Api_loadOptedOutsCmd(state.Sorting.Field, state.Sorting.Direction, extCmd.fields[0], 20, state.Filter)];
            }
            else {
                return [state, Cmd_none()];
            }
        }
        case 3: {
            const nextState_1 = State_update_1(state.Sorting, msg.fields[0])[0];
            return [new State(state.OptedOuts, state.Paging, nextState_1, true, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, state.Deleting), Api_loadOptedOutsCmd(nextState_1.Field, nextState_1.Direction, value(state.Paging).CurrentPage, 20, state.Filter)];
        }
        case 4: {
            const patternInput_2 = bounce(fromSeconds(1), "opted-outs.search", new Msg(6), state.Debouncer);
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, msg.fields[0], patternInput_2[0], state.ShowDeleteConfirmation, state.Deleting), Cmd_batch(singleton(Cmd_map((arg0) => (new Msg(5, arg0)), patternInput_2[1])))];
        }
        case 5: {
            const patternInput_3 = update(msg.fields[0], state.Debouncer);
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, patternInput_3[0], state.ShowDeleteConfirmation, state.Deleting), patternInput_3[1]];
        }
        case 6: {
            return [new State(state.OptedOuts, state.Paging, state.Sorting, true, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, state.Deleting), Cmd_batch(singleton(Api_loadOptedOutsCmd(state.Sorting.Field, state.Sorting.Direction, value(state.Paging).CurrentPage, 20, state.Filter)))];
        }
        case 7: {
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.Debouncer, msg.fields[0], state.Deleting), Cmd_none()];
        }
        case 8: {
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.Debouncer, void 0, state.Deleting), Cmd_none()];
        }
        case 9: {
            const optedOut_1 = msg.fields[0];
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.Debouncer, void 0, optedOut_1), Cmd_batch(singleton(Api_deleteOptedOut(optedOut_1)))];
        }
        case 10: {
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, void 0), Cmd_batch(singleton(Api_loadOptedOutsCmd(Update_DEFAULT_SORT_FIELD, Update_DEFAULT_SORT_DIRECTION, 1, 20, "")))];
        }
        case 11: {
            return [new State(state.OptedOuts, state.Paging, state.Sorting, state.Loading, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, void 0), Cmd_batch(singleton(toastError("Delete error")))];
        }
        default: {
            const x = msg.fields[0];
            return [new State(x.Items, State_init(x.Page, x.PageSize, x.Total), state.Sorting, false, state.Error, state.Filter, state.Debouncer, state.ShowDeleteConfirmation, state.Deleting), Cmd_none()];
        }
    }
}

