import { endsWith, printf, toText, trimEnd } from "../../.fable/fable-library.3.2.9/String.js";
import { toNumber } from "../../.fable/fable-library.3.2.9/Decimal.js";
import { match } from "../../.fable/fable-library.3.2.9/RegExp.js";

export function msgOfExn(ex) {
    return ex.message;
}

export function Printer_money(m) {
    const matchValue = trimEnd(toText(printf("%.2f"))(m), "0");
    if (endsWith(matchValue, ".")) {
        return toText(printf("%s0"))(matchValue);
    }
    else {
        return matchValue;
    }
}

export function Printer_moneyM(m) {
    return Printer_money(toNumber(m));
}

export function Printer_sek(m) {
    const arg10 = Printer_money(m);
    return toText(printf("%s SEK"))(arg10);
}

export function Printer_sekM(m) {
    const arg10 = Printer_moneyM(m);
    return toText(printf("%s SEK"))(arg10);
}

export function Validator_validateAmount(x) {
    return match(x, "(^$)|(^\\-?(0|[1-9][0-9]*)?(([\\.])(\\d+)?)?$)") != null;
}

