import { toString, Record, Union } from "../../.fable/fable-library.3.2.9/Types.js";
import { record_type, tuple_type, string_type, option_type, enum_type, bool_type, int32_type, union_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { Printer_printUserStatus, Printer_printUserStatusString, SettingsFormState, TransactionsInfo$reflection, DiscountInfo$reflection, Subaccount$reflection, SettingsFormState$reflection, CustomerDetails$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { Deferred_inProgress, AsyncOperationEvent$1, Cmd_fromAsync, Deferred$1, AsyncOperationEvent$1$reflection, Deferred$1$reflection } from "../Common/Extensions.js";
import { View_render as View_render_3, State_update as State_update_1, State_init as State_init_1, Types_Msg$reflection as Types_Msg$reflection_4, Types_State$reflection as Types_State$reflection_1 } from "./Balance.js";
import { View_render, State_init as State_init_4, State_update as State_update_2, Types_Msg$reflection as Types_Msg$reflection_1, Types_State$reflection as Types_State$reflection_2 } from "./EditCustomer.js";
import { View_render as View_render_2, State_update as State_update_4, State_init as State_init_3, Types_Msg$reflection as Types_Msg$reflection_3, Types_State$reflection as Types_State$reflection_3 } from "./EditSubaccauntDialog.js";
import { View_render as View_render_1, Types_State as Types_State_1, State_update as State_update_3, State_init as State_init_2, Types_Msg$reflection as Types_Msg$reflection_2, Types_State$reflection as Types_State$reflection_4 } from "./EditInvoicingSettings.js";
import { Cmd_OfFunc_result, Cmd_none, Cmd_map, Cmd_batch, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { apiInvoices, api } from "./Communication.js";
import { int32ToString, equals, partialApply } from "../../.fable/fable-library.3.2.9/Util.js";
import { toString as toString_1, now } from "../../.fable/fable-library.3.2.9/Date.js";
import { isEmpty, empty, singleton as singleton_1, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { some, map, defaultArg, value as value_3 } from "../../.fable/fable-library.3.2.9/Option.js";
import { singleton } from "../../.fable/fable-library.3.2.9/AsyncBuilder.js";
import { toText, printf, toFail } from "../../.fable/fable-library.3.2.9/String.js";
import { Navigation_newUrl } from "../../.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { FormState_Form$2 } from "../Common/FormState.js";
import { Tab_Option, tab, Option, tabs } from "../../.fable/Fulma.2.14.1/Components/Tabs.fs.js";
import * as react from "react";
import { Option as Option_1, notification } from "../../.fable/Fulma.2.14.1/Elements/Notification.fs.js";
import { Common_GenericOption, Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { Helpers_nothing } from "../../.fable/Fable.React.7.4.1/Fable.React.Helpers.fs.js";
import { Card_foot, Card_body, Card_title, Card_head, Card_card, background, Option as Option_2, modal } from "../../.fable/Fulma.2.14.1/Components/Modal.fs.js";
import { DOMAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Option as Option_3, delete$ } from "../../.fable/Fulma.2.14.1/Elements/Delete.fs.js";
import { a, List_Option, list, Option as Option_4, button } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";
import { renderKeyValue } from "../Common/View.js";
import { section } from "../../.fable/Fulma.2.14.1/Layouts/Section.fs.js";
import { container } from "../../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { collect, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { div } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import { TableOption, table } from "../../.fable/Fulma.2.14.1/Elements/Table.fs.js";
import { h2 } from "../../.fable/Fulma.2.14.1/Elements/Heading.fs.js";

export class Types_ActiveTab extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Overview", "Info", "Invoice", "SubAccounts", "Balance"];
    }
}

export function Types_ActiveTab$reflection() {
    return union_type("CustomerDetails.Types.ActiveTab", [], Types_ActiveTab, () => [[], [], [], [], []]);
}

export class Types_changeUserStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Need", "NotNeed"];
    }
}

export function Types_changeUserStatus$reflection() {
    return union_type("CustomerDetails.Types.changeUserStatus", [], Types_changeUserStatus, () => [[["Item1", int32_type], ["Item2", bool_type], ["Item3", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])], ["Item4", bool_type]], []]);
}

export class Types_State extends Record {
    constructor(Loading, Customer, LoginAs, LoginAsSubaccount, Balance, Error$, EditCustomerState, EditSubaccauntDialogState, EditInvoicingSettingsState, ActiveTab, ConfirmChangeUserStatus, ConfirmHardDeleteUser) {
        super();
        this.Loading = Loading;
        this.Customer = Customer;
        this.LoginAs = LoginAs;
        this.LoginAsSubaccount = LoginAsSubaccount;
        this.Balance = Balance;
        this.Error = Error$;
        this.EditCustomerState = EditCustomerState;
        this.EditSubaccauntDialogState = EditSubaccauntDialogState;
        this.EditInvoicingSettingsState = EditInvoicingSettingsState;
        this.ActiveTab = ActiveTab;
        this.ConfirmChangeUserStatus = ConfirmChangeUserStatus;
        this.ConfirmHardDeleteUser = ConfirmHardDeleteUser;
    }
}

export function Types_State$reflection() {
    return record_type("CustomerDetails.Types.State", [], Types_State, () => [["Loading", bool_type], ["Customer", option_type(CustomerDetails$reflection())], ["LoginAs", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))], ["LoginAsSubaccount", tuple_type(bool_type, int32_type)], ["Balance", Types_State$reflection_1()], ["Error", string_type], ["EditCustomerState", option_type(Types_State$reflection_2())], ["EditSubaccauntDialogState", option_type(Types_State$reflection_3())], ["EditInvoicingSettingsState", option_type(Types_State$reflection_4())], ["ActiveTab", Types_ActiveTab$reflection()], ["ConfirmChangeUserStatus", Types_changeUserStatus$reflection()], ["ConfirmHardDeleteUser", option_type(int32_type)]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadSuccess", "LoadInvoicingSettingsSuccess", "SwitchToOverview", "SwitchToInfo", "SwitchToInvoice", "SwitchToSubaccounts", "SwitchToBalance", "LoginAs", "LoginAsSubaccount", "EditCustomerMsg", "EditInvoicingSettingsMsg", "OpenEditSubaccauntDialog", "EditSubaccauntDialogMsg", "BalanceMsg", "AskChangeUserStatusConfirmation", "AskHardDeleteUser", "CancelChangeUserStatusConfirmation", "CancelHardDeleteUser", "ChangeUserStatus", "HardDeleteUser", "UserStatusChanged", "UserHardDeleted", "DiscountInfoLoaded", "TransactionsInfoLoaded"];
    }
}

export function Types_Msg$reflection() {
    return union_type("CustomerDetails.Types.Msg", [], Types_Msg, () => [[["Item", CustomerDetails$reflection()]], [["Item", SettingsFormState$reflection()]], [], [], [], [], [], [["Item", AsyncOperationEvent$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]]))]], [["Item", int32_type]], [["Item", Types_Msg$reflection_1()]], [["Item", Types_Msg$reflection_2()]], [["Item", Subaccount$reflection()]], [["Item", Types_Msg$reflection_3()]], [["Item", Types_Msg$reflection_4()]], [["Item1", int32_type], ["Item2", bool_type], ["Item3", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])], ["Item4", bool_type]], [["Item", option_type(int32_type)]], [], [], [["Item", Types_changeUserStatus$reflection()]], [["Item", int32_type]], [], [], [["Item", DiscountInfo$reflection()]], [["Item", TransactionsInfo$reflection()]]]);
}

export function State_loadCustomerCmd(id) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, api().getCustomerDetails, id, (arg0) => (new Types_Msg(0, arg0)));
}

export function State_loadCustomerInvoicingSettingsCmd(id) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, api().getInvoicingSettings, id, (arg0) => (new Types_Msg(1, arg0)));
}

export function State_changeUserStatusCmd(userId, userStatus) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, partialApply(1, api().changeUserStatus, [userId]), userStatus, () => (new Types_Msg(20)));
}

export function State_hardDeleteUserCmd(userId) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, api().hardDelete, userId, () => (new Types_Msg(21)));
}

export function State_loadDiscountInfoCmd(companyId) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (companyId_1) => apiInvoices().getDiscountInfo(companyId_1), companyId, (arg0) => (new Types_Msg(22, arg0)));
}

export function State_loadTransactionsInfoCmd(companyId) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, (companyId_1) => apiInvoices().getTransactionsInfo(companyId_1, now()), companyId, (arg0) => (new Types_Msg(23, arg0)));
}

export function State_init(customerId) {
    const patternInput = State_init_1(customerId);
    return [new Types_State(true, void 0, new Deferred$1(0), [false, 0], patternInput[0], "", void 0, void 0, void 0, new Types_ActiveTab(0), new Types_changeUserStatus(1), void 0), Cmd_batch(ofArray([State_loadCustomerCmd(customerId), State_loadCustomerInvoicingSettingsCmd(customerId), Cmd_map((arg0) => (new Types_Msg(13, arg0)), patternInput[1])]))];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord, inputRecord_3, inputRecord_2;
    if (msg.tag === 1) {
        return [new Types_State(false, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, State_init_2(msg.fields[0])[0], state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), (msg.fields[0].InvoicingType === 0) ? State_loadDiscountInfoCmd(msg.fields[0].CompanyId) : Cmd_batch(ofArray([State_loadDiscountInfoCmd(msg.fields[0].CompanyId), State_loadTransactionsInfoCmd(msg.fields[0].CompanyId)]))];
    }
    else if (msg.tag === 2) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, new Types_ActiveTab(0), state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, new Types_ActiveTab(1), state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, new Types_ActiveTab(2), state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, new Types_ActiveTab(3), state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, new Types_ActiveTab(4), state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 13) {
        const patternInput_2 = State_update_1(state.Balance, msg.fields[0]);
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, patternInput_2[0], state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_map((arg0) => (new Types_Msg(13, arg0)), patternInput_2[1])];
    }
    else if (msg.tag === 9) {
        const matchValue = state.EditCustomerState;
        if (matchValue == null) {
            return [state, Cmd_none()];
        }
        else {
            const patternInput_3 = State_update_2(msg.fields[0], matchValue);
            const outMsg = patternInput_3[2];
            if (outMsg == null) {
                return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, patternInput_3[0], state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_map((arg0_1) => (new Types_Msg(9, arg0_1)), patternInput_3[1])];
            }
            else if (outMsg.tag === 1) {
                return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, void 0, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), State_loadCustomerCmd(value_3(state.Customer).Id)];
            }
            else {
                return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, void 0, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 10) {
        const matchValue_1 = state.EditInvoicingSettingsState;
        if (matchValue_1 == null) {
            return [state, Cmd_none()];
        }
        else {
            const patternInput_4 = State_update_3(msg.fields[0], matchValue_1);
            return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, patternInput_4[0], state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_map((arg0_2) => (new Types_Msg(10, arg0_2)), patternInput_4[1])];
        }
    }
    else if (msg.tag === 11) {
        const patternInput_5 = State_init_3(msg.fields[0]);
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, patternInput_5[0], state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_map((arg0_3) => (new Types_Msg(12, arg0_3)), patternInput_5[1])];
    }
    else if (msg.tag === 12) {
        const matchValue_2 = state.EditSubaccauntDialogState;
        if (matchValue_2 == null) {
            return [state, Cmd_none()];
        }
        else {
            const patternInput_6 = State_update_4(msg.fields[0], matchValue_2);
            const outMsg_1 = patternInput_6[2];
            if (outMsg_1 == null) {
                return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, patternInput_6[0], state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_map((arg0_4) => (new Types_Msg(12, arg0_4)), patternInput_6[1])];
            }
            else if (outMsg_1.tag === 1) {
                return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, void 0, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), State_loadCustomerCmd(value_3(state.Customer).Id)];
            }
            else {
                return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, void 0, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                if (state.Customer == null) {
                    return [state, Cmd_none()];
                }
                else {
                    return [new Types_State(state.Loading, state.Customer, new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
                }
            }
            else if (state.Customer == null) {
                return [state, Cmd_none()];
            }
            else {
                void window.open(msg.fields[0].fields[0].fields[0], "_blank");
                return [new Types_State(state.Loading, state.Customer, new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
            }
        }
        else {
            const matchValue_3 = state.Customer;
            if (matchValue_3 == null) {
                return [state, Cmd_none()];
            }
            else {
                const customer = matchValue_3;
                return [new Types_State(state.Loading, state.Customer, new Deferred$1(1), [false, 0], state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_fromAsync(singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
                    let matchValue_4;
                    return singleton.Bind((matchValue_4 = state.LoginAsSubaccount, matchValue_4[0] ? api().signInAs(matchValue_4[1]) : api().signInAs(customer.Id)), (_arg1) => singleton.Return(new Types_Msg(7, new AsyncOperationEvent$1(1, new FSharpResult$2(0, _arg1)))));
                }), (_arg2) => singleton.Return(new Types_Msg(7, new AsyncOperationEvent$1(1, new FSharpResult$2(1, _arg2.message)))))))];
            }
        }
    }
    else if (msg.tag === 8) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, [true, msg.fields[0]], state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_OfFunc_result(new Types_Msg(7, new AsyncOperationEvent$1(0)))];
    }
    else if (msg.tag === 14) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, new Types_changeUserStatus(0, msg.fields[0], msg.fields[1], msg.fields[2], msg.fields[3]), state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 16) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, new Types_changeUserStatus(1), state.ConfirmHardDeleteUser), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 17) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, void 0), Cmd_none()];
    }
    else if (msg.tag === 18) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, new Types_changeUserStatus(1), state.ConfirmHardDeleteUser), Cmd_none()];
        }
        else {
            const userStatus_1 = msg.fields[0].fields[2] | 0;
            const userId_4 = msg.fields[0].fields[0] | 0;
            return [state, (userStatus_1 === 0) ? State_changeUserStatusCmd(userId_4, 1) : ((userStatus_1 === 1) ? State_changeUserStatusCmd(userId_4, 1) : ((userStatus_1 === 2) ? State_changeUserStatusCmd(userId_4, 2) : ((userStatus_1 === 3) ? State_changeUserStatusCmd(userId_4, 3) : toFail(printf("Unexpected UserStatus = \u0027%A\u0027"))(userStatus_1))))];
        }
    }
    else if (msg.tag === 20) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, new Types_changeUserStatus(1), state.ConfirmHardDeleteUser), State_loadCustomerCmd(value_3(state.Customer).Id)];
    }
    else if (msg.tag === 19) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, void 0), State_hardDeleteUserCmd(msg.fields[0])];
    }
    else if (msg.tag === 21) {
        return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, void 0), Navigation_newUrl("#customers")];
    }
    else if (msg.tag === 22) {
        const matchValue_7 = state.EditInvoicingSettingsState;
        if (matchValue_7 != null) {
            const x_3 = matchValue_7;
            return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, new Types_State_1((inputRecord_1 = x_3.Form, new FormState_Form$2((inputRecord = x_3.Form.State, new SettingsFormState(inputRecord.CompanyId, inputRecord.InvoicingType, inputRecord.IsPostpayingApproved, inputRecord.CreditLimit, inputRecord.InvoicingPeriod, inputRecord.Discount, msg.fields[0], inputRecord.TransactionsInfo, inputRecord.AccountType, inputRecord.MainAccount, inputRecord.InvoicingCurrency, inputRecord.InvoiceRowTitle)), inputRecord_1.Validation, inputRecord_1.FieldErrors, inputRecord_1.FormErrors)), x_3.Saving), state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else if (msg.tag === 23) {
        const matchValue_8 = state.EditInvoicingSettingsState;
        if (matchValue_8 != null) {
            const x_4 = matchValue_8;
            return [new Types_State(state.Loading, state.Customer, state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, state.EditCustomerState, state.EditSubaccauntDialogState, new Types_State_1((inputRecord_3 = x_4.Form, new FormState_Form$2((inputRecord_2 = x_4.Form.State, new SettingsFormState(inputRecord_2.CompanyId, inputRecord_2.InvoicingType, inputRecord_2.IsPostpayingApproved, inputRecord_2.CreditLimit, inputRecord_2.InvoicingPeriod, inputRecord_2.Discount, inputRecord_2.DiscountInfo, msg.fields[0], inputRecord_2.AccountType, inputRecord_2.MainAccount, inputRecord_2.InvoicingCurrency, inputRecord_2.InvoiceRowTitle)), inputRecord_3.Validation, inputRecord_3.FieldErrors, inputRecord_3.FormErrors)), x_4.Saving), state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
        }
        else {
            return [state, Cmd_none()];
        }
    }
    else {
        return [new Types_State(false, msg.fields[0], state.LoginAs, state.LoginAsSubaccount, state.Balance, state.Error, State_init_4(msg.fields[0])[0], state.EditSubaccauntDialogState, state.EditInvoicingSettingsState, state.ActiveTab, state.ConfirmChangeUserStatus, state.ConfirmHardDeleteUser), Cmd_none()];
    }
}

function View_renderTabs(state, dispatch) {
    return tabs(singleton_1(new Option(1)), [tab(singleton_1(new Tab_Option(0, equals(state.ActiveTab, new Types_ActiveTab(0)))), singleton_1(react.createElement("a", {
        onClick: (ev) => {
            dispatch(new Types_Msg(2));
            ev.preventDefault();
        },
    }, "Overview"))), tab(singleton_1(new Tab_Option(0, equals(state.ActiveTab, new Types_ActiveTab(1)))), singleton_1(react.createElement("a", {
        onClick: (ev_1) => {
            dispatch(new Types_Msg(3));
            ev_1.preventDefault();
        },
    }, "Info"))), tab(singleton_1(new Tab_Option(0, equals(state.ActiveTab, new Types_ActiveTab(2)))), singleton_1(react.createElement("a", {
        onClick: (ev_2) => {
            dispatch(new Types_Msg(4));
            ev_2.preventDefault();
        },
    }, "Invoice"))), tab(singleton_1(new Tab_Option(0, equals(state.ActiveTab, new Types_ActiveTab(3)))), singleton_1(react.createElement("a", {
        onClick: (ev_3) => {
            dispatch(new Types_Msg(5));
            ev_3.preventDefault();
        },
    }, "Subaccounts"))), tab(singleton_1(new Tab_Option(0, equals(state.ActiveTab, new Types_ActiveTab(4)))), singleton_1(react.createElement("a", {
        onClick: (ev_4) => {
            dispatch(new Types_Msg(6));
            ev_4.preventDefault();
        },
    }, "Balance")))]);
}

export function View_renderLoginAsError(state) {
    const matchValue = state.LoginAs;
    let pattern_matching_result, ex;
    if (matchValue.tag === 2) {
        if (matchValue.fields[0].tag === 1) {
            pattern_matching_result = 0;
            ex = matchValue.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return notification(singleton_1(new Option_1(0, new Color_IColor(8))), singleton_1(ex));
        }
        case 1: {
            return Helpers_nothing;
        }
    }
}

export function View_confirmationDialog(title, content, confirm, closeDisplay, buttonName) {
    const cancelDialog = (_arg1) => {
        closeDisplay();
    };
    return modal(singleton_1(new Option_2(1, true)), ofArray([background(singleton_1(new Common_GenericOption(1, singleton_1(new DOMAttr(40, cancelDialog)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton_1(title)), delete$(singleton_1(new Option_3(3, cancelDialog)), empty())])), Card_body(empty(), singleton_1(content)), Card_foot(singleton_1(new Common_GenericOption(1, singleton_1(["style", {
        justifyContent: "flex-end",
    }]))), ofArray([button(singleton_1(new Option_4(18, cancelDialog)), singleton_1("Cancel")), button(ofArray([new Option_4(0, new Color_IColor(8)), new Option_4(18, (_arg2) => {
        confirm();
    })]), singleton_1(buttonName))]))]))]));
}

function View_renderConfirmationToChangeUserStatus(state, dispatch) {
    let arg10;
    const matchValue = state.ConfirmChangeUserStatus;
    let pattern_matching_result, _userId_1, hasVirtualNumbers_1, isBlockButton_1, userStatus_1;
    if (matchValue.tag === 0) {
        if ((!matchValue.fields[1]) ? true : matchValue.fields[3]) {
            pattern_matching_result = 0;
            _userId_1 = matchValue.fields[0];
            hasVirtualNumbers_1 = matchValue.fields[1];
            isBlockButton_1 = matchValue.fields[3];
            userStatus_1 = matchValue.fields[2];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return View_confirmationDialog("Changing User\u0027s status", react.createElement("p", {}, (arg10 = Printer_printUserStatusString(userStatus_1), toText(printf("Are you sure you want to change User\u0027s status to %s?"))(arg10))), () => {
                dispatch(new Types_Msg(18, state.ConfirmChangeUserStatus));
            }, () => {
                dispatch(new Types_Msg(16));
            }, "Change");
        }
        case 1: {
            let pattern_matching_result_1;
            if (matchValue.tag === 0) {
                if (matchValue.fields[1]) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const cancelDialog = (_arg3) => {
                        dispatch(new Types_Msg(16));
                    };
                    const content = react.createElement("p", {}, "This account has active virtual numbers. Please unlink them first before deleting the account.");
                    return modal(singleton_1(new Option_2(1, true)), ofArray([background(singleton_1(new Common_GenericOption(1, singleton_1(new DOMAttr(40, cancelDialog)))), empty()), Card_card(empty(), ofArray([Card_head(empty(), ofArray([Card_title(empty(), singleton_1("Can\u0027t delete company")), delete$(singleton_1(new Option_3(3, cancelDialog)), empty())])), Card_body(empty(), singleton_1(content)), Card_foot(singleton_1(new Common_GenericOption(1, singleton_1(["style", {
                        justifyContent: "flex-end",
                    }]))), singleton_1(button(singleton_1(new Option_4(18, cancelDialog)), singleton_1("Ok"))))]))]));
                }
                case 1: {
                    return null;
                }
            }
        }
    }
}

function View_renderConfirmationToHardDeleteUser(state, dispatch) {
    const matchValue = state.ConfirmHardDeleteUser;
    if (matchValue != null) {
        const userId = matchValue | 0;
        return View_confirmationDialog("Hard delete user", react.createElement("p", {}, toText(printf("Are you sure you want to DELETE USER WITH DELETING ALL INFORMATION (userId: %i)?"))(userId)), () => {
            dispatch(new Types_Msg(19, userId));
        }, () => {
            dispatch(new Types_Msg(16));
        }, "DELETE");
    }
    else {
        return null;
    }
}

export function View_renderOverview(details, state, dispatch) {
    let arg10_1, arg10_5, arg10_6, arg10_7;
    return react.createElement("div", {}, View_renderConfirmationToChangeUserStatus(state, dispatch), View_renderConfirmationToHardDeleteUser(state, dispatch), renderKeyValue("Company ID", toText(printf("%d"))(details.Id)), renderKeyValue("P1 ID", (arg10_1 = ((details.P1Id != null) ? toString(details.P1Id) : ""), toText(printf("%s"))(arg10_1))), renderKeyValue("Status", Printer_printUserStatusString(details.UserStatus)), renderKeyValue("Contacts", toText(printf("%d"))(details.Summary.Contacts)), renderKeyValue("Sent SMS", toText(printf("%d"))(details.Summary.SentSms)), renderKeyValue("Sent SMS per month", toText(printf("%d"))(details.Summary.SentSmsPerMonth)), renderKeyValue("Account created (UTC)", (arg10_5 = toString_1(details.Summary.Created, "yyyy-MM-dd HH:mm:ss"), toText(printf("%s"))(arg10_5))), renderKeyValue("Last login (UTC)", (arg10_6 = defaultArg(map((x) => toString_1(x, "yyyy-MM-dd HH:mm:ss"), details.Summary.LastLoginDate), "Has not logged yet"), toText(printf("%s"))(arg10_6))), renderKeyValue("Number of logons", (arg10_7 = defaultArg(map((value_1) => int32ToString(value_1), details.Summary.NumberOfLogins), "0"), toText(printf("%s"))(arg10_7))), section(empty(), singleton_1(container(empty(), toList(delay(() => append(singleton_2(div(empty(), singleton_1(button(ofArray([new Option_4(17, singleton_1(["style", {
        margin: "5px",
        width: "150px",
    }])), new Option_4(18, (x_1) => {
        dispatch(new Types_Msg(7, new AsyncOperationEvent$1(0)));
        x_1.preventDefault();
    }), new Option_4(13, Deferred_inProgress(state.LoginAs))]), singleton_1("Login as"))))), delay(() => {
        const matchValue = state.Customer;
        if (matchValue != null) {
            const customer = matchValue;
            return append((customer.UserStatus === 2) ? singleton_2(div(empty(), singleton_1(button(ofArray([new Option_4(17, singleton_1(["style", {
                margin: "5px",
                width: "150px",
            }])), new Option_4(18, (x_2) => {
                dispatch(new Types_Msg(14, customer.Id, customer.HasVirtualNumbers, 1, true));
                x_2.preventDefault();
            })]), singleton_1("Unblock"))))) : singleton_2(div(empty(), singleton_1(button(ofArray([new Option_4(17, singleton_1(["style", {
                margin: "5px",
                width: "150px",
            }])), new Option_4(18, (x_3) => {
                dispatch(new Types_Msg(14, customer.Id, customer.HasVirtualNumbers, 2, true));
                x_3.preventDefault();
            })]), singleton_1("Block"))))), delay(() => append(singleton_2(div(empty(), singleton_1(button(ofArray([new Option_4(17, singleton_1(["style", {
                margin: "5px",
                width: "150px",
            }])), new Option_4(0, new Color_IColor(8)), new Option_4(18, (x_4) => {
                dispatch(new Types_Msg(14, customer.Id, customer.HasVirtualNumbers, 3, false));
                x_4.preventDefault();
            })]), singleton_1("Delete"))))), delay(() => singleton_2(div(empty(), singleton_1(button(ofArray([new Option_4(17, singleton_1(["style", {
                margin: "5px",
                width: "150px",
            }])), new Option_4(0, new Color_IColor(8)), new Option_4(18, (x_5) => {
                dispatch(new Types_Msg(15, customer.Id));
                x_5.preventDefault();
            })]), singleton_1("Hard delete")))))))));
        }
        else {
            return empty_1();
        }
    }))))))));
}

export function View_renderEditCustomer(state, dispatch) {
    const matchValue = state.EditCustomerState;
    if (matchValue != null) {
        return View_render(matchValue, (arg) => {
            dispatch(new Types_Msg(9, arg));
        });
    }
    else {
        return Helpers_nothing;
    }
}

export function View_renderInfo(state, dispatch) {
    return react.createElement("div", {}, View_renderEditCustomer(state, dispatch));
}

export function View_renderInvoicingSettings(state, dispatch) {
    const matchValue = state.EditInvoicingSettingsState;
    if (matchValue != null) {
        return View_render_1(matchValue, (arg) => {
            dispatch(new Types_Msg(10, arg));
        });
    }
    else {
        return Helpers_nothing;
    }
}

export function View_renderInvoice(state, dispatch) {
    return react.createElement("div", {}, View_renderInvoicingSettings(state, dispatch));
}

export function View_renderSubaccountButtons(subaccount, state, dispatch) {
    return list(singleton_1(new List_Option(2)), toList(delay(() => append(singleton_2(button(ofArray([new Option_4(18, (x) => {
        dispatch(new Types_Msg(8, subaccount.Id));
        x.preventDefault();
    }), new Option_4(13, Deferred_inProgress(state.LoginAs))]), singleton_1("Login as"))), delay(() => append(singleton_2(a(singleton_1(new Option_4(18, (_arg1) => {
        dispatch(new Types_Msg(11, subaccount));
    })), singleton_1("Details"))), delay(() => append((subaccount.UserStatus === 2) ? singleton_2(button(ofArray([new Option_4(17, singleton_1(["style", {
        width: "85px",
    }])), new Option_4(18, (x_1) => {
        dispatch(new Types_Msg(14, subaccount.Id, subaccount.HasVirtualNumbers, 1, true));
        x_1.preventDefault();
    })]), singleton_1("Unblock"))) : singleton_2(button(ofArray([new Option_4(17, singleton_1(["style", {
        width: "85px",
    }])), new Option_4(18, (x_2) => {
        dispatch(new Types_Msg(14, subaccount.Id, subaccount.HasVirtualNumbers, 2, true));
        x_2.preventDefault();
    })]), singleton_1("Block"))), delay(() => singleton_2(button(ofArray([new Option_4(0, new Color_IColor(8)), new Option_4(18, (x_3) => {
        dispatch(new Types_Msg(14, subaccount.Id, subaccount.HasVirtualNumbers, 3, false));
        x_3.preventDefault();
    })]), singleton_1("Delete"))))))))))));
}

export function View_renderEditSubaccauntDialogState(state, dispatch) {
    const matchValue = state.EditSubaccauntDialogState;
    if (matchValue != null) {
        return View_render_2(matchValue, (arg) => {
            dispatch(new Types_Msg(12, arg));
        });
    }
    else {
        return Helpers_nothing;
    }
}

export function View_renderSubaccounts(customer, state, dispatch) {
    return react.createElement("div", {}, ...toList(delay(() => append(singleton_2(View_renderConfirmationToChangeUserStatus(state, dispatch)), delay(() => (isEmpty(customer.Subaccounts) ? singleton_2("List is empty") : singleton_2(table(ofArray([new TableOption(4), new TableOption(2)]), ofArray([react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("th", {}, "Id"), react.createElement("th", {}, "First name"), react.createElement("th", {}, "Last name"), react.createElement("th", {}, "Email"), react.createElement("th", {}, "Status"), react.createElement("th", {}, ""))), react.createElement("tbody", {}, ...toList(delay(() => collect((x) => {
        console.log(some(x.Email), x.UserStatus, x.Id);
        return singleton_2(react.createElement("tr", {}, react.createElement("td", {
            style: {
                verticalAlign: "middle",
            },
        }, toText(printf("%d"))(x.Id)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
            },
        }, x.FirstName), react.createElement("td", {
            style: {
                verticalAlign: "middle",
            },
        }, x.LastName), react.createElement("td", {
            style: {
                verticalAlign: "middle",
            },
        }, x.Email), react.createElement("td", {
            style: {
                verticalAlign: "middle",
            },
        }, Printer_printUserStatus(x.UserStatus)), react.createElement("td", {
            style: {
                verticalAlign: "middle",
            },
        }, ...toList(delay(() => ((customer.Id === x.Id) ? singleton_2(Helpers_nothing) : singleton_2(View_renderSubaccountButtons(x, state, dispatch))))))));
    }, customer.Subaccounts))))])))))))));
}

export function View_renderBalance(state, dispatch) {
    return react.createElement("div", {}, View_render_3(state.Balance, (arg) => {
        dispatch(new Types_Msg(13, arg));
    }));
}

function View_renderActiveTab(state, dispatch) {
    const matchValue = state.Customer;
    if (matchValue == null) {
        return Helpers_nothing;
    }
    else {
        const customer = matchValue;
        return react.createElement(react.Fragment, {}, ...toList(delay(() => append((customer.UserStatus === 3) ? singleton_2(h2(empty())(singleton_1("Customer was deleted"))) : singleton_2(Helpers_nothing), delay(() => {
            const matchValue_2 = state.ActiveTab;
            switch (matchValue_2.tag) {
                case 1: {
                    return singleton_2(View_renderInfo(state, dispatch));
                }
                case 2: {
                    return singleton_2(View_renderInvoice(state, dispatch));
                }
                case 3: {
                    return singleton_2(View_renderSubaccounts(customer, state, dispatch));
                }
                case 4: {
                    return singleton_2(View_renderBalance(state, dispatch));
                }
                default: {
                    return singleton_2(View_renderOverview(customer, state, dispatch));
                }
            }
        })))));
    }
}

export function View_view(state, dispatch) {
    return react.createElement("div", {}, View_renderTabs(state, dispatch), View_renderActiveTab(state, dispatch), View_renderEditSubaccauntDialogState(state, dispatch));
}

