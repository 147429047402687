import { Union } from "../../../fable-library.3.2.9/Types.js";
import { union_type, list_type, class_type, string_type, bool_type } from "../../../fable-library.3.2.9/Reflection.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.3.2.9/MapUtil.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["has-icons-right", "has-icons-left", "is-loading", "is-expanded", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Control.Option", [], Option, () => [[], [], [["Item", bool_type]], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function controlView(element, options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 1:
            case 3: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 2: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
            case 5: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 4: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 6: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "control"), element, children);
}

export function div(x, y) {
    return controlView((props, children) => react.createElement("div", keyValueList(props, 1), ...children), x, y);
}

export function p(x, y) {
    return controlView((props, children) => react.createElement("p", keyValueList(props, 1), ...children), x, y);
}

