import { Union } from "./.fable/fable-library.3.2.9/Types.js";
import { union_type, int32_type } from "./.fable/fable-library.3.2.9/Reflection.js";
import { oneOf, i32, s, map } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { ofArray, collect } from "./.fable/fable-library.3.2.9/List.js";
import { printf, toText } from "./.fable/fable-library.3.2.9/String.js";
import { Navigation_modifyUrl, Navigation_newUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";

export class Route extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SignIn", "Customers", "Invoices", "Tools", "CustomerDetails", "VirtualNumbers", "Settings", "Perfomance", "OptedOut", "SmsSearch", "ValidationExceptions"];
    }
}

export function Route$reflection() {
    return union_type("Router.Route", [], Route, () => [[], [], [], [], [["Item", int32_type]], [], [], [], [], [], []]);
}

export const routeParser = (() => {
    let parseBefore;
    const parsers = ofArray([map(new Route(0), s("signin")), map(new Route(1), s("customers")), map((arg0) => (new Route(4, arg0)), (parseBefore = s("customers"), (state_1) => collect((state) => i32(state), parseBefore(state_1)))), map(new Route(2), s("invoices")), map(new Route(3), s("tools")), map(new Route(5), s("virtualnumbers")), map(new Route(6), s("settings")), map(new Route(7), s("perfomance")), map(new Route(8), s("opted-out")), map(new Route(9), s("sms-search")), map(new Route(10), s("validation-exceptions"))]);
    return (state_3) => oneOf(parsers, state_3);
})();

export function toPath(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return "/#customers";
        }
        case 2: {
            return "/#invoices";
        }
        case 3: {
            return "#/tools";
        }
        case 5: {
            return "#/virtualnumbers";
        }
        case 4: {
            return toText(printf("/#customers/%i"))(_arg1.fields[0]);
        }
        case 6: {
            return "#/settings";
        }
        case 7: {
            return "#/perfomance";
        }
        case 8: {
            return "#/opted-out";
        }
        case 9: {
            return "#/sms-search";
        }
        case 10: {
            return "#/validation-exceptions";
        }
        default: {
            return "/#signin";
        }
    }
}

export function navigateTo(page) {
    return Navigation_newUrl(toPath(page));
}

export function modifyUrl(page) {
    return Navigation_modifyUrl(toPath(page));
}

