import { Record, Union } from "../.fable/fable-library.3.2.9/Types.js";
import { tuple_type, class_type, bool_type, enum_type, option_type, string_type, record_type, list_type, int32_type, union_type } from "../.fable/fable-library.3.2.9/Reflection.js";
import { ofArray, sumBy, map } from "../.fable/fable-library.3.2.9/List.js";
import { op_Addition } from "../.fable/fable-library.3.2.9/Decimal.js";
import Decimal from "../.fable/fable-library.3.2.9/Decimal.js";
import { toFail, printf, toText } from "../.fable/fable-library.3.2.9/String.js";
import { Interop_reactApi } from "../.fable/Feliz.1.49.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../.fable/fable-library.3.2.9/Util.js";
import { Helpers_combineClasses } from "../.fable/Feliz.Bulma.2.16.0/ElementBuilders.fs.js";

export class SortDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Asc", "Desc"];
    }
}

export function SortDirection$reflection() {
    return union_type("Shared.SortDirection", [], SortDirection, () => [[], []]);
}

export class PagingResult$1 extends Record {
    constructor(Page, PageSize, Total, Items) {
        super();
        this.Page = (Page | 0);
        this.PageSize = (PageSize | 0);
        this.Total = (Total | 0);
        this.Items = Items;
    }
}

export function PagingResult$1$reflection(gen0) {
    return record_type("Shared.PagingResult`1", [gen0], PagingResult$1, () => [["Page", int32_type], ["PageSize", int32_type], ["Total", int32_type], ["Items", list_type(gen0)]]);
}

export function PagingResult_map(f, x) {
    return new PagingResult$1(x.Page, x.PageSize, x.Total, map(f, x.Items));
}

export class CompanyInformation extends Record {
    constructor(Name, Country, VAT) {
        super();
        this.Name = Name;
        this.Country = Country;
        this.VAT = VAT;
    }
}

export function CompanyInformation$reflection() {
    return record_type("Shared.CompanyInformation", [], CompanyInformation, () => [["Name", string_type], ["Country", string_type], ["VAT", string_type]]);
}

export class CompanyDetails extends Record {
    constructor(Id, CompanyName, AddressLine1, AddressLine2, CompanyNumber, Vat, PaymentMethod, GLN, PostCode, Country, City, InvoiceEmail, InvoiceEmailCc, TaxResidence) {
        super();
        this.Id = (Id | 0);
        this.CompanyName = CompanyName;
        this.AddressLine1 = AddressLine1;
        this.AddressLine2 = AddressLine2;
        this.CompanyNumber = CompanyNumber;
        this.Vat = Vat;
        this.PaymentMethod = PaymentMethod;
        this.GLN = GLN;
        this.PostCode = PostCode;
        this.Country = Country;
        this.City = City;
        this.InvoiceEmail = InvoiceEmail;
        this.InvoiceEmailCc = InvoiceEmailCc;
        this.TaxResidence = TaxResidence;
    }
}

export function CompanyDetails$reflection() {
    return record_type("Shared.CompanyDetails", [], CompanyDetails, () => [["Id", int32_type], ["CompanyName", string_type], ["AddressLine1", string_type], ["AddressLine2", string_type], ["CompanyNumber", string_type], ["Vat", string_type], ["PaymentMethod", string_type], ["GLN", string_type], ["PostCode", string_type], ["Country", string_type], ["City", string_type], ["InvoiceEmail", string_type], ["InvoiceEmailCc", string_type], ["TaxResidence", string_type]]);
}

export class CustomerStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AwaitingCompanyInfo", "AwaitingAcceptance", "ManuallyAccepted", "AutoAccepted", "Declined"];
    }
}

export function CustomerStatus$reflection() {
    return union_type("Shared.CustomerStatus", [], CustomerStatus, () => [[], [], [], [], []]);
}

export class Customer extends Record {
    constructor(Id, PixieLogin, FirstName, LastName, Status, UserStatus, CompanyInformation, HasVirtualNumbers, HasOverDueInvoice, Created) {
        super();
        this.Id = (Id | 0);
        this.PixieLogin = PixieLogin;
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Status = Status;
        this.UserStatus = (UserStatus | 0);
        this.CompanyInformation = CompanyInformation;
        this.HasVirtualNumbers = HasVirtualNumbers;
        this.HasOverDueInvoice = HasOverDueInvoice;
        this.Created = Created;
    }
}

export function Customer$reflection() {
    return record_type("Shared.Customer", [], Customer, () => [["Id", int32_type], ["PixieLogin", option_type(int32_type)], ["FirstName", string_type], ["LastName", string_type], ["Status", CustomerStatus$reflection()], ["UserStatus", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])], ["CompanyInformation", option_type(CompanyInformation$reflection())], ["HasVirtualNumbers", bool_type], ["HasOverDueInvoice", bool_type], ["Created", class_type("System.DateTime")]]);
}

export class CustomerSortFields extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Id", "PixieLogin", "FirstName", "LastName", "Created", "CompanyName", "Country", "VAT", "Status", "UserStatus"];
    }
}

export function CustomerSortFields$reflection() {
    return union_type("Shared.CustomerSortFields", [], CustomerSortFields, () => [[], [], [], [], [], [], [], [], [], []]);
}

export class InvoiceSortFields extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InvoiceId", "CustomerName", "CustomerId", "P1Id", "StartDate", "EndDate", "SmsQty", "Total", "State"];
    }
}

export function InvoiceSortFields$reflection() {
    return union_type("Shared.InvoiceSortFields", [], InvoiceSortFields, () => [[], [], [], [], [], [], [], [], []]);
}

export class InvoiceRow extends Record {
    constructor(Description, Cost, Tax, Total, SmsCount) {
        super();
        this.Description = Description;
        this.Cost = Cost;
        this.Tax = Tax;
        this.Total = Total;
        this.SmsCount = (SmsCount | 0);
    }
}

export function InvoiceRow$reflection() {
    return record_type("Shared.InvoiceRow", [], InvoiceRow, () => [["Description", string_type], ["Cost", class_type("System.Decimal")], ["Tax", class_type("System.Decimal")], ["Total", class_type("System.Decimal")], ["SmsCount", int32_type]]);
}

export class AddTransaction extends Record {
    constructor(CustomerId, Amount, Comment$) {
        super();
        this.CustomerId = (CustomerId | 0);
        this.Amount = Amount;
        this.Comment = Comment$;
    }
}

export function AddTransaction$reflection() {
    return record_type("Shared.AddTransaction", [], AddTransaction, () => [["CustomerId", int32_type], ["Amount", class_type("System.Decimal")], ["Comment", string_type]]);
}

export class InvoiceState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Draft", "WaitsForSending", "Sent", "Error"];
    }
}

export function InvoiceState$reflection() {
    return union_type("Shared.InvoiceState", [], InvoiceState, () => [[], [], [], []]);
}

export class Subaccount extends Record {
    constructor(Id, FirstName, LastName, Email, UserStatus, HasVirtualNumbers) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Email = Email;
        this.UserStatus = (UserStatus | 0);
        this.HasVirtualNumbers = HasVirtualNumbers;
    }
}

export function Subaccount$reflection() {
    return record_type("Shared.Subaccount", [], Subaccount, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["Email", string_type], ["UserStatus", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])], ["HasVirtualNumbers", bool_type]]);
}

export class CustomerSummary extends Record {
    constructor(Contacts, SentSms, SentSmsPerMonth, Created, LastLoginDate, NumberOfLogins) {
        super();
        this.Contacts = (Contacts | 0);
        this.SentSms = (SentSms | 0);
        this.SentSmsPerMonth = (SentSmsPerMonth | 0);
        this.Created = Created;
        this.LastLoginDate = LastLoginDate;
        this.NumberOfLogins = NumberOfLogins;
    }
}

export function CustomerSummary$reflection() {
    return record_type("Shared.CustomerSummary", [], CustomerSummary, () => [["Contacts", int32_type], ["SentSms", int32_type], ["SentSmsPerMonth", int32_type], ["Created", class_type("System.DateTime")], ["LastLoginDate", option_type(class_type("System.DateTime"))], ["NumberOfLogins", option_type(int32_type)]]);
}

export class Country extends Record {
    constructor(Id, Code, Name) {
        super();
        this.Id = (Id | 0);
        this.Code = Code;
        this.Name = Name;
    }
}

export function Country$reflection() {
    return record_type("Shared.Country", [], Country, () => [["Id", int32_type], ["Code", string_type], ["Name", string_type]]);
}

export class Invoice extends Record {
    constructor(Id, CompanyId, P1Id, CompanyName, DateFrom, DateTo, State, SmsCount, Tax, TotalAmount, Details, InvoiceRowTitle) {
        super();
        this.Id = (Id | 0);
        this.CompanyId = (CompanyId | 0);
        this.P1Id = P1Id;
        this.CompanyName = CompanyName;
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.State = State;
        this.SmsCount = (SmsCount | 0);
        this.Tax = Tax;
        this.TotalAmount = TotalAmount;
        this.Details = Details;
        this.InvoiceRowTitle = InvoiceRowTitle;
    }
}

export function Invoice$reflection() {
    return record_type("Shared.Invoice", [], Invoice, () => [["Id", int32_type], ["CompanyId", int32_type], ["P1Id", option_type(int32_type)], ["CompanyName", string_type], ["DateFrom", class_type("System.DateTime")], ["DateTo", class_type("System.DateTime")], ["State", InvoiceState$reflection()], ["SmsCount", int32_type], ["Tax", class_type("System.Decimal")], ["TotalAmount", class_type("System.Decimal")], ["Details", list_type(tuple_type(string_type, list_type(InvoiceRow$reflection())))], ["InvoiceRowTitle", string_type]]);
}

export class DetailedCampaignRow extends Record {
    constructor(SendThrough, CorrelationId, TotalSmsCount, TotalCost) {
        super();
        this.SendThrough = (SendThrough | 0);
        this.CorrelationId = CorrelationId;
        this.TotalSmsCount = (TotalSmsCount | 0);
        this.TotalCost = TotalCost;
    }
}

export function DetailedCampaignRow$reflection() {
    return record_type("Shared.DetailedCampaignRow", [], DetailedCampaignRow, () => [["SendThrough", enum_type("Shared.SendThrough", int32_type, [["Sms", 0], ["EmailToSms", 1], ["ApiSms", 2], ["WebSms", 3]])], ["CorrelationId", string_type], ["TotalSmsCount", int32_type], ["TotalCost", class_type("System.Decimal")]]);
}

export class InvoiceItem extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AggregatedCampaign", "DetailedCampaign"];
    }
}

export function InvoiceItem$reflection() {
    return union_type("Shared.InvoiceItem", [], InvoiceItem, () => [[["totalSmsCount", int32_type], ["totalCost", class_type("System.Decimal")]], [["Item", list_type(DetailedCampaignRow$reflection())]]]);
}

export function InvoiceItemModule_totalCost(invoiceRow) {
    if (invoiceRow.tag === 1) {
        return sumBy((x) => x.TotalCost, invoiceRow.fields[0], {
            GetZero: () => (new Decimal(0)),
            Add: (x_1, y) => op_Addition(x_1, y),
        });
    }
    else {
        return invoiceRow.fields[1];
    }
}

export function InvoiceItemModule_totalSmsCount(invoiceRow) {
    if (invoiceRow.tag === 1) {
        return sumBy((x) => x.TotalSmsCount, invoiceRow.fields[0], {
            GetZero: () => 0,
            Add: (x_1, y) => (x_1 + y),
        }) | 0;
    }
    else {
        return invoiceRow.fields[0] | 0;
    }
}

export class TransactionsInfo extends Record {
    constructor(Campaign, Adjustments, Discount, InitialBalance) {
        super();
        this.Campaign = Campaign;
        this.Adjustments = Adjustments;
        this.Discount = Discount;
        this.InitialBalance = InitialBalance;
    }
}

export function TransactionsInfo$reflection() {
    return record_type("Shared.TransactionsInfo", [], TransactionsInfo, () => [["Campaign", InvoiceItem$reflection()], ["Adjustments", list_type(tuple_type(string_type, class_type("System.Decimal")))], ["Discount", list_type(tuple_type(string_type, class_type("System.Decimal")))], ["InitialBalance", class_type("System.Decimal")]]);
}

export class VirtualNumberSettings extends Record {
    constructor(SetupCost, MonthlyFee, PrefixesSetupCost, PrefixesMonthlyFee) {
        super();
        this.SetupCost = SetupCost;
        this.MonthlyFee = MonthlyFee;
        this.PrefixesSetupCost = PrefixesSetupCost;
        this.PrefixesMonthlyFee = PrefixesMonthlyFee;
    }
}

export function VirtualNumberSettings$reflection() {
    return record_type("Shared.VirtualNumberSettings", [], VirtualNumberSettings, () => [["SetupCost", class_type("System.Decimal")], ["MonthlyFee", class_type("System.Decimal")], ["PrefixesSetupCost", class_type("System.Decimal")], ["PrefixesMonthlyFee", class_type("System.Decimal")]]);
}

export class CustomerDetails extends Record {
    constructor(Id, P1Id, FirstName, LastName, Email, PhoneNumber, Status, CompanyDetails, Subaccounts, Summary, Countries, UserStatus, HasVirtualNumbers) {
        super();
        this.Id = (Id | 0);
        this.P1Id = P1Id;
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Email = Email;
        this.PhoneNumber = PhoneNumber;
        this.Status = Status;
        this.CompanyDetails = CompanyDetails;
        this.Subaccounts = Subaccounts;
        this.Summary = Summary;
        this.Countries = Countries;
        this.UserStatus = (UserStatus | 0);
        this.HasVirtualNumbers = HasVirtualNumbers;
    }
}

export function CustomerDetails$reflection() {
    return record_type("Shared.CustomerDetails", [], CustomerDetails, () => [["Id", int32_type], ["P1Id", option_type(int32_type)], ["FirstName", string_type], ["LastName", string_type], ["Email", string_type], ["PhoneNumber", string_type], ["Status", CustomerStatus$reflection()], ["CompanyDetails", option_type(CompanyDetails$reflection())], ["Subaccounts", list_type(Subaccount$reflection())], ["Summary", CustomerSummary$reflection()], ["Countries", list_type(Country$reflection())], ["UserStatus", enum_type("Shared.UserStatus", int32_type, [["WaitingForEmailVerification", 0], ["Active", 1], ["Blocked", 2], ["Deleted", 3], ["WaitingForSmsVerification", 4]])], ["HasVirtualNumbers", bool_type]]);
}

export class SaveCustomer extends Record {
    constructor(Id, FirstName, LastName, CompanyName, Email, PhoneNumber, Country, City, PostCode, AddressLine1, AddressLine2, CompanyNumber, TaxResidence, Vat, InvoiceEmail, InvoiceEmailCc) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.CompanyName = CompanyName;
        this.Email = Email;
        this.PhoneNumber = PhoneNumber;
        this.Country = Country;
        this.City = City;
        this.PostCode = PostCode;
        this.AddressLine1 = AddressLine1;
        this.AddressLine2 = AddressLine2;
        this.CompanyNumber = CompanyNumber;
        this.TaxResidence = TaxResidence;
        this.Vat = Vat;
        this.InvoiceEmail = InvoiceEmail;
        this.InvoiceEmailCc = InvoiceEmailCc;
    }
}

export function SaveCustomer$reflection() {
    return record_type("Shared.SaveCustomer", [], SaveCustomer, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["CompanyName", string_type], ["Email", string_type], ["PhoneNumber", string_type], ["Country", string_type], ["City", string_type], ["PostCode", string_type], ["AddressLine1", string_type], ["AddressLine2", string_type], ["CompanyNumber", string_type], ["TaxResidence", string_type], ["Vat", string_type], ["InvoiceEmail", string_type], ["InvoiceEmailCc", option_type(string_type)]]);
}

export class SaveSubaccount extends Record {
    constructor(Id, FirstName, LastName, Email) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Email = Email;
    }
}

export function SaveSubaccount$reflection() {
    return record_type("Shared.SaveSubaccount", [], SaveSubaccount, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["Email", string_type]]);
}

export class BalanceInformation extends Record {
    constructor(Balance) {
        super();
        this.Balance = Balance;
    }
}

export function BalanceInformation$reflection() {
    return record_type("Shared.BalanceInformation", [], BalanceInformation, () => [["Balance", class_type("System.Decimal")]]);
}

export class CardDetails extends Record {
    constructor(CardType, Last4Digits, ExternalId, ReceiptId) {
        super();
        this.CardType = CardType;
        this.Last4Digits = Last4Digits;
        this.ExternalId = ExternalId;
        this.ReceiptId = ReceiptId;
    }
}

export function CardDetails$reflection() {
    return record_type("Shared.CardDetails", [], CardDetails, () => [["CardType", string_type], ["Last4Digits", string_type], ["ExternalId", string_type], ["ReceiptId", string_type]]);
}

export class CampaignStat extends Record {
    constructor(CustomerId, CampaignId, State, Sent, Queue, ScheduledAtUtcTime, CompanyId, Recipients) {
        super();
        this.CustomerId = (CustomerId | 0);
        this.CampaignId = (CampaignId | 0);
        this.State = (State | 0);
        this.Sent = (Sent | 0);
        this.Queue = (Queue | 0);
        this.ScheduledAtUtcTime = ScheduledAtUtcTime;
        this.CompanyId = (CompanyId | 0);
        this.Recipients = (Recipients | 0);
    }
}

export function CampaignStat$reflection() {
    return record_type("Shared.CampaignStat", [], CampaignStat, () => [["CustomerId", int32_type], ["CampaignId", int32_type], ["State", int32_type], ["Sent", int32_type], ["Queue", int32_type], ["ScheduledAtUtcTime", class_type("System.DateTime")], ["CompanyId", int32_type], ["Recipients", int32_type]]);
}

export class InvoiceStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Unpaid", "Paid", "OverDue", "Canceled"];
    }
}

export function InvoiceStatus$reflection() {
    return union_type("Shared.InvoiceStatus", [], InvoiceStatus, () => [[], [], [], []]);
}

export class InvoiceDetails extends Record {
    constructor(Status, ReceiptId) {
        super();
        this.Status = Status;
        this.ReceiptId = ReceiptId;
    }
}

export function InvoiceDetails$reflection() {
    return record_type("Shared.InvoiceDetails", [], InvoiceDetails, () => [["Status", InvoiceStatus$reflection()], ["ReceiptId", string_type]]);
}

export class PaymentDetails extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Card", "Invoice", "Manual"];
    }
}

export function PaymentDetails$reflection() {
    return union_type("Shared.PaymentDetails", [], PaymentDetails, () => [[["Item", CardDetails$reflection()]], [["Item", InvoiceDetails$reflection()]], [["comment", option_type(string_type)]]]);
}

export class Transaction extends Record {
    constructor(Id, Amount, Details, CreatedUtcTime, ReceiptId) {
        super();
        this.Id = (Id | 0);
        this.Amount = Amount;
        this.Details = Details;
        this.CreatedUtcTime = CreatedUtcTime;
        this.ReceiptId = ReceiptId;
    }
}

export function Transaction$reflection() {
    return record_type("Shared.Transaction", [], Transaction, () => [["Id", int32_type], ["Amount", class_type("System.Decimal")], ["Details", PaymentDetails$reflection()], ["CreatedUtcTime", class_type("System.DateTime")], ["ReceiptId", option_type(string_type)]]);
}

export class NewCustomerFormState extends Record {
    constructor(FirstName, LastName, CompanyName, Email, PhoneNumber, Country, City, PostCode, AddressLine1, AddressLine2, Vat, Password) {
        super();
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.CompanyName = CompanyName;
        this.Email = Email;
        this.PhoneNumber = PhoneNumber;
        this.Country = Country;
        this.City = City;
        this.PostCode = PostCode;
        this.AddressLine1 = AddressLine1;
        this.AddressLine2 = AddressLine2;
        this.Vat = Vat;
        this.Password = Password;
    }
}

export function NewCustomerFormState$reflection() {
    return record_type("Shared.NewCustomerFormState", [], NewCustomerFormState, () => [["FirstName", string_type], ["LastName", string_type], ["CompanyName", string_type], ["Email", string_type], ["PhoneNumber", string_type], ["Country", string_type], ["City", string_type], ["PostCode", string_type], ["AddressLine1", string_type], ["AddressLine2", string_type], ["Vat", string_type], ["Password", string_type]]);
}

export class PhoneValidationForm extends Record {
    constructor(PhoneNumber, Country, Timestamp, Id) {
        super();
        this.PhoneNumber = PhoneNumber;
        this.Country = Country;
        this.Timestamp = Timestamp;
        this.Id = (Id | 0);
    }
}

export function PhoneValidationForm$reflection() {
    return record_type("Shared.PhoneValidationForm", [], PhoneValidationForm, () => [["PhoneNumber", string_type], ["Country", string_type], ["Timestamp", class_type("System.DateTime")], ["Id", int32_type]]);
}

export function PhoneValidationForm__get_IsNew(self) {
    return self.Id === 0;
}

export class Sender extends Record {
    constructor(Name, Timestamp, Id) {
        super();
        this.Name = Name;
        this.Timestamp = Timestamp;
        this.Id = (Id | 0);
    }
}

export function Sender$reflection() {
    return record_type("Shared.Sender", [], Sender, () => [["Name", string_type], ["Timestamp", class_type("System.DateTime")], ["Id", int32_type]]);
}

export function Sender__get_IsNew(self) {
    return self.Id === 0;
}

export class DiscountInfo extends Record {
    constructor(CurrentMonth, LastMonth) {
        super();
        this.CurrentMonth = CurrentMonth;
        this.LastMonth = LastMonth;
    }
}

export function DiscountInfo$reflection() {
    return record_type("Shared.DiscountInfo", [], DiscountInfo, () => [["CurrentMonth", tuple_type(int32_type, class_type("System.Decimal"))], ["LastMonth", tuple_type(int32_type, class_type("System.Decimal"))]]);
}

export class ISADetail extends Record {
    constructor(CompanyId, CompanyName) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.CompanyName = CompanyName;
    }
}

export function ISADetail$reflection() {
    return record_type("Shared.ISADetail", [], ISADetail, () => [["CompanyId", int32_type], ["CompanyName", string_type]]);
}

export class AccountType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IMA", "ISA"];
    }
}

export function AccountType$reflection() {
    return union_type("Shared.AccountType", [], AccountType, () => [[["Item", list_type(ISADetail$reflection())]], [["Item", int32_type]]]);
}

export class SettingsFormState extends Record {
    constructor(CompanyId, InvoicingType, IsPostpayingApproved, CreditLimit, InvoicingPeriod, Discount, DiscountInfo, TransactionsInfo, AccountType, MainAccount, InvoicingCurrency, InvoiceRowTitle) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.InvoicingType = (InvoicingType | 0);
        this.IsPostpayingApproved = IsPostpayingApproved;
        this.CreditLimit = (CreditLimit | 0);
        this.InvoicingPeriod = (InvoicingPeriod | 0);
        this.Discount = (Discount | 0);
        this.DiscountInfo = DiscountInfo;
        this.TransactionsInfo = TransactionsInfo;
        this.AccountType = AccountType;
        this.MainAccount = MainAccount;
        this.InvoicingCurrency = InvoicingCurrency;
        this.InvoiceRowTitle = InvoiceRowTitle;
    }
}

export function SettingsFormState$reflection() {
    return record_type("Shared.SettingsFormState", [], SettingsFormState, () => [["CompanyId", int32_type], ["InvoicingType", enum_type("Shared.PaymentType", int32_type, [["Prepayment", 0], ["Postpayment", 1]])], ["IsPostpayingApproved", bool_type], ["CreditLimit", int32_type], ["InvoicingPeriod", int32_type], ["Discount", int32_type], ["DiscountInfo", option_type(DiscountInfo$reflection())], ["TransactionsInfo", option_type(TransactionsInfo$reflection())], ["AccountType", option_type(AccountType$reflection())], ["MainAccount", option_type(int32_type)], ["InvoicingCurrency", string_type], ["InvoiceRowTitle", string_type]]);
}

export class DraftSummary extends Record {
    constructor(ItemCount, Amount, Datetime) {
        super();
        this.ItemCount = (ItemCount | 0);
        this.Amount = Amount;
        this.Datetime = Datetime;
    }
}

export function DraftSummary$reflection() {
    return record_type("Shared.DraftSummary", [], DraftSummary, () => [["ItemCount", int32_type], ["Amount", class_type("System.Decimal")], ["Datetime", class_type("System.DateTime")]]);
}

export class Errors_Error extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserAlreadyExists", "OperationFailed"];
    }
}

export function Errors_Error$reflection() {
    return union_type("Shared.Errors.Error", [], Errors_Error, () => [[], []]);
}

export class Errors_Settings_SaveInvoicingSettingsError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LinkedIMAIsNotValid", "LinkedIMAMustHavePostpaidInvoicingType", "CurrentISAMustHavePostpaidInvoicingType", "LinkedIMAMustNotBeISA"];
    }
}

export function Errors_Settings_SaveInvoicingSettingsError$reflection() {
    return union_type("Shared.Errors.Settings.SaveInvoicingSettingsError", [], Errors_Settings_SaveInvoicingSettingsError, () => [[], [], [], []]);
}

export function Errors_Settings_SaveInvoicingSettingsError__get_label(this$) {
    switch (this$.tag) {
        case 1: {
            return toText(printf("Linked IMA must have post-paid invoicing type"));
        }
        case 2: {
            return toText(printf("Current ISA must have postpaid invoicing type"));
        }
        case 3: {
            return toText(printf("Linked IMA must not be ISA"));
        }
        default: {
            return toText(printf("Linked IMA is not valid"));
        }
    }
}

export class Errors_VirtualNumberError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["VirtualNumberShouldBeFreeBeforeAssigning", "NotEnoughMoney", "NoSuchVirtualNumber", "VirtualNumberHasIncomingMessages", "Duplicate"];
    }
}

export function Errors_VirtualNumberError$reflection() {
    return union_type("Shared.Errors.VirtualNumberError", [], Errors_VirtualNumberError, () => [[], [], [], [], []]);
}

export class Errors_ApplicationError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["VirtualNumber", "CommonError", "DbError", "SettingsError"];
    }
}

export function Errors_ApplicationError$reflection() {
    return union_type("Shared.Errors.ApplicationError", [], Errors_ApplicationError, () => [[["Item", Errors_VirtualNumberError$reflection()]], [], [], [["Item", Errors_Settings_SaveInvoicingSettingsError$reflection()]]]);
}

export function Errors_ApplicationError__get_label(this$) {
    if (this$.tag === 3) {
        return Errors_Settings_SaveInvoicingSettingsError__get_label(this$.fields[0]);
    }
    else {
        return "Error";
    }
}

export class AcceptCustomerType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Email", "Silently"];
    }
}

export function AcceptCustomerType$reflection() {
    return union_type("Shared.AcceptCustomerType", [], AcceptCustomerType, () => [[], []]);
}

export class CustomersRequest extends Record {
    constructor(SortField, SortDirection, PageIndex, PageSize, SearchFilter, StatusFilter, WithDeleted) {
        super();
        this.SortField = SortField;
        this.SortDirection = SortDirection;
        this.PageIndex = (PageIndex | 0);
        this.PageSize = (PageSize | 0);
        this.SearchFilter = SearchFilter;
        this.StatusFilter = StatusFilter;
        this.WithDeleted = WithDeleted;
    }
}

export function CustomersRequest$reflection() {
    return record_type("Shared.CustomersRequest", [], CustomersRequest, () => [["SortField", CustomerSortFields$reflection()], ["SortDirection", SortDirection$reflection()], ["PageIndex", int32_type], ["PageSize", int32_type], ["SearchFilter", string_type], ["StatusFilter", option_type(CustomerStatus$reflection())], ["WithDeleted", bool_type]]);
}

export class InvoiceRequest extends Record {
    constructor(SortField, SortDirection, PageIndex, PageSize, SearchFilter) {
        super();
        this.SortField = SortField;
        this.SortDirection = SortDirection;
        this.PageIndex = (PageIndex | 0);
        this.PageSize = (PageSize | 0);
        this.SearchFilter = SearchFilter;
    }
}

export function InvoiceRequest$reflection() {
    return record_type("Shared.InvoiceRequest", [], InvoiceRequest, () => [["SortField", InvoiceSortFields$reflection()], ["SortDirection", SortDirection$reflection()], ["PageIndex", int32_type], ["PageSize", int32_type], ["SearchFilter", string_type]]);
}

export class TransactionFilter extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OnlyPositiveTransactions", "AllTransactions"];
    }
}

export function TransactionFilter$reflection() {
    return union_type("Shared.TransactionFilter", [], TransactionFilter, () => [[], []]);
}

export class SignInRequest extends Record {
    constructor(Login, Password) {
        super();
        this.Login = Login;
        this.Password = Password;
    }
}

export function SignInRequest$reflection() {
    return record_type("Shared.SignInRequest", [], SignInRequest, () => [["Login", string_type], ["Password", string_type]]);
}

export function Printer_renderIcon(icon, text, color) {
    const props_1 = ofArray([color, ["data-tooltip", text], ["className", "has-tooltip-bottom"], ["title", text], ["children", Interop_reactApi.Children.toArray([createElement("i", {
        className: icon,
    })])]]);
    return createElement("span", createObj(Helpers_combineClasses("icon", props_1)));
}

export function Printer_printCustomerStatus(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return Printer_renderIcon("fa fa-user-clock", "Awaiting Acceptance", ["className", "has-text-warning"]);
        }
        case 2: {
            return Printer_renderIcon("fa fa-user-check", "Manually Accepted", ["className", "has-text-primary"]);
        }
        case 3: {
            return Printer_renderIcon("fa fa-user-shield", "Auto Accepted", ["className", "has-text-primary"]);
        }
        case 4: {
            return Printer_renderIcon("fa fa-user-minus", "Declined", ["className", "has-text-danger"]);
        }
        default: {
            return Printer_renderIcon("fa fa-business-time", "Awaiting Company Info", ["className", "has-text-warning"]);
        }
    }
}

export function Printer_printUserStatus(_arg1) {
    switch (_arg1) {
        case 0: {
            return Printer_renderIcon("fa fa-clock", "Waiting For Email Verification", ["className", "has-text-warning"]);
        }
        case 1: {
            return Printer_renderIcon("fa fa-check", "Active", ["className", "has-text-primary"]);
        }
        case 2: {
            return Printer_renderIcon("fa fa-ban", "Blocked", ["className", "has-text-danger"]);
        }
        case 3: {
            return Printer_renderIcon("fa fa-trash", "Deleted", ["className", "has-text-danger"]);
        }
        case 4: {
            return Printer_renderIcon("fa fa-sms", "Waiting SMS Verification", ["className", "has-text-warning"]);
        }
        default: {
            return toFail(printf("Unexpected UserStatus = \u0027%A\u0027"))(_arg1);
        }
    }
}

export function Printer_printUserStatusString(_arg1) {
    switch (_arg1) {
        case 0: {
            return "Waiting For Email Verification";
        }
        case 1: {
            return "Active";
        }
        case 2: {
            return "Blocked";
        }
        case 3: {
            return "Deleted";
        }
        case 4: {
            return "Waiting For SMS Verification";
        }
        default: {
            return toFail(printf("Unexpected UserStatus = \u0027%A\u0027"))(_arg1);
        }
    }
}

export class OptedOut extends Record {
    constructor(CompanyId, CompanyName, PhoneNumber, CreatedUtcTime) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.CompanyName = CompanyName;
        this.PhoneNumber = PhoneNumber;
        this.CreatedUtcTime = CreatedUtcTime;
    }
}

export function OptedOut$reflection() {
    return record_type("Shared.OptedOut", [], OptedOut, () => [["CompanyId", int32_type], ["CompanyName", string_type], ["PhoneNumber", string_type], ["CreatedUtcTime", class_type("System.DateTimeOffset")]]);
}

export class OptedOutSortFields extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CompanyId", "CompanyName", "Phone", "Timestamp"];
    }
}

export function OptedOutSortFields$reflection() {
    return union_type("Shared.OptedOutSortFields", [], OptedOutSortFields, () => [[], [], [], []]);
}

export class DeleteOptedOut extends Record {
    constructor(CompanyId, PhoneNumber) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.PhoneNumber = PhoneNumber;
    }
}

export function DeleteOptedOut$reflection() {
    return record_type("Shared.DeleteOptedOut", [], DeleteOptedOut, () => [["CompanyId", int32_type], ["PhoneNumber", string_type]]);
}

export class OptedOutRequest extends Record {
    constructor(SortField, SortDirection, PageIndex, PageSize, SearchFilter) {
        super();
        this.SortField = SortField;
        this.SortDirection = SortDirection;
        this.PageIndex = (PageIndex | 0);
        this.PageSize = (PageSize | 0);
        this.SearchFilter = SearchFilter;
    }
}

export function OptedOutRequest$reflection() {
    return record_type("Shared.OptedOutRequest", [], OptedOutRequest, () => [["SortField", OptedOutSortFields$reflection()], ["SortDirection", SortDirection$reflection()], ["PageIndex", int32_type], ["PageSize", int32_type], ["SearchFilter", string_type]]);
}

export class QuerySmsSearch extends Record {
    constructor(Phone, StartDateTime, EndDateTime) {
        super();
        this.Phone = Phone;
        this.StartDateTime = StartDateTime;
        this.EndDateTime = EndDateTime;
    }
}

export function QuerySmsSearch$reflection() {
    return record_type("Shared.QuerySmsSearch", [], QuerySmsSearch, () => [["Phone", string_type], ["StartDateTime", class_type("System.DateTime")], ["EndDateTime", class_type("System.DateTime")]]);
}

export class SmsSearchResult extends Record {
    constructor(CompanyId, UserId, CreatedDate) {
        super();
        this.CompanyId = (CompanyId | 0);
        this.UserId = (UserId | 0);
        this.CreatedDate = CreatedDate;
    }
}

export function SmsSearchResult$reflection() {
    return record_type("Shared.SmsSearchResult", [], SmsSearchResult, () => [["CompanyId", int32_type], ["UserId", int32_type], ["CreatedDate", class_type("System.DateTimeOffset")]]);
}

