import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { class_type, record_type, union_type, list_type, string_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { SmsSearchResult$reflection, QuerySmsSearch$reflection } from "../../Shared/Shared.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { AsyncOperationEvent$1$reflection, Deferred$1$reflection } from "../Common/Extensions.js";

export class State extends Record {
    constructor(Phone, Searching, Sms) {
        super();
        this.Phone = Phone;
        this.Searching = Searching;
        this.Sms = Sms;
    }
}

export function State$reflection() {
    return record_type("Pages.SmsSearch.Model.State", [], State, () => [["Phone", string_type], ["Searching", QuerySmsSearch$reflection()], ["Sms", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SmsSearchResult$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(SmsSearchResult$reflection())]], [["ErrorValue", string_type]]]))]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SearchSms", "PhoneStringChange", "StartDateChange", "EndDateChange"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.SmsSearch.Model.Msg", [], Msg, () => [[["Item", AsyncOperationEvent$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SmsSearchResult$reflection()), string_type], FSharpResult$2, () => [[["ResultValue", list_type(SmsSearchResult$reflection())]], [["ErrorValue", string_type]]]))]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]]]);
}

