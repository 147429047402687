import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { class_type, bool_type, list_type, union_type, record_type, string_type, int32_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { FormState_Form_getFormError, FormState_Form_getFieldError, FormState_Form_getFieldColor, FormState_Form_isValid, FormState_Form_withState, FormState_Form_validate, FormState_Form_create, FormState_Form_enableValiadateAll, FormState_Form_withNoErrors, FormState_Form_withFormErrors, FormState_Validation_withField, Validation_notRequiredStringField, Validation_requiredStringField, FormState_Form$2$reflection } from "../Common/FormState.js";
import { SaveCustomer, Country$reflection } from "../../Shared/Shared.js";
import { isNullOrWhiteSpace, isNullOrEmpty } from "../../.fable/fable-library.3.2.9/String.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { api } from "./Communication.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";
import { ofArray, empty, singleton } from "../../.fable/fable-library.3.2.9/List.js";
import { map, defaultArg } from "../../.fable/fable-library.3.2.9/Option.js";
import { message } from "../Components/Toast.js";
import { success } from "../../.fable/Thoth.Elmish.Toast.3.0.0/Toast.fs.js";
import { msgOfExn } from "../Common/Utils.js";
import { div } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import * as react from "react";
import { container } from "../../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { body, Label_Option, label, Option, div as div_1 } from "../../.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";
import { Option as Option_1, IInputType, input } from "../../.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { DOMAttr, HTMLAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Option as Option_2, help } from "../../.fable/Fulma.2.14.1/Elements/Form/Help.fs.js";
import { Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { map as map_1, singleton as singleton_1, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Option as Option_3, select } from "../../.fable/Fulma.2.14.1/Elements/Form/Select.fs.js";
import { Option as Option_4, a } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";

export class Types_FormState extends Record {
    constructor(Id, FirstName, LastName, CompanyName, Email, PhoneNumber, Country, City, PostCode, AddressLine1, AddressLine2, CompanyNumber, Vat, PaymentMethod, GLN, TaxResidence, InvoiceEmail, InvoiceEmailCc) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.CompanyName = CompanyName;
        this.Email = Email;
        this.PhoneNumber = PhoneNumber;
        this.Country = Country;
        this.City = City;
        this.PostCode = PostCode;
        this.AddressLine1 = AddressLine1;
        this.AddressLine2 = AddressLine2;
        this.CompanyNumber = CompanyNumber;
        this.Vat = Vat;
        this.PaymentMethod = PaymentMethod;
        this.GLN = GLN;
        this.TaxResidence = TaxResidence;
        this.InvoiceEmail = InvoiceEmail;
        this.InvoiceEmailCc = InvoiceEmailCc;
    }
}

export function Types_FormState$reflection() {
    return record_type("EditCustomer.Types.FormState", [], Types_FormState, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["CompanyName", string_type], ["Email", string_type], ["PhoneNumber", string_type], ["Country", string_type], ["City", string_type], ["PostCode", string_type], ["AddressLine1", string_type], ["AddressLine2", string_type], ["CompanyNumber", string_type], ["Vat", string_type], ["PaymentMethod", string_type], ["GLN", string_type], ["TaxResidence", string_type], ["InvoiceEmail", string_type], ["InvoiceEmailCc", string_type]]);
}

export class Types_Field extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Id", "FirstName", "LastName", "CompanyName", "Email", "PhoneNumber", "Country", "City", "PostCode", "AddressLine1", "AddressLine2", "CompanyNumber", "Vat", "InvoiceEmail", "InvoiceEmailCc", "TaxResidence", "PaymentMethod", "GLN"];
    }
}

export function Types_Field$reflection() {
    return union_type("EditCustomer.Types.Field", [], Types_Field, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Types_State extends Record {
    constructor(Form, CountryList, Saving) {
        super();
        this.Form = Form;
        this.CountryList = CountryList;
        this.Saving = Saving;
    }
}

export function Types_State$reflection() {
    return record_type("EditCustomer.Types.State", [], Types_State, () => [["Form", FormState_Form$2$reflection(Types_FormState$reflection(), Types_Field$reflection())], ["CountryList", list_type(Country$reflection())], ["Saving", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FirstNameChange", "LastNameChange", "CompanyNameChange", "EmailChange", "PhoneNumberChange", "CountryChange", "CityChange", "PostCodeChange", "AddressLine1Change", "AddressLine2Change", "CompanyNumberChange", "VatChange", "PaymentMethodChange", "GLNChange", "TaxResidenceChange", "InvoiceEmailChange", "InvoiceEmailCcChange", "Saved", "SaveError", "Cancel", "Save"];
    }
}

export function Types_Msg$reflection() {
    return union_type("EditCustomer.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", class_type("System.Exception")]], [], []]);
}

export class Types_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Cancel", "Save"];
    }
}

export function Types_ExternalMsg$reflection() {
    return union_type("EditCustomer.Types.ExternalMsg", [], Types_ExternalMsg, () => [[], []]);
}

function State_toSave(state) {
    return new SaveCustomer(state.Id, state.FirstName, state.LastName, state.CompanyName, state.Email, state.PhoneNumber, state.Country, state.City, state.PostCode, state.AddressLine1, state.AddressLine2, state.CompanyNumber, state.TaxResidence, state.Vat, state.InvoiceEmail, isNullOrEmpty(state.InvoiceEmailCc) ? (void 0) : state.InvoiceEmailCc);
}

export function State_createSubaccountCmd(formState) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, api().saveCustomer, State_toSave(formState), () => (new Types_Msg(17)), (arg0) => (new Types_Msg(18, arg0)));
}

export function State_validateCompanyNumber(country, value) {
    if ((country === "SE") ? isNullOrWhiteSpace(value) : false) {
        return new FSharpResult$2(1, "Company Number is required");
    }
    else {
        return new FSharpResult$2(0, value);
    }
}

export function State_validateVat(taxType, vat) {
    if (taxType === "EU") {
        return Validation_requiredStringField("Vat", 2, 100, vat);
    }
    else {
        return Validation_notRequiredStringField("Vat", 0, 100, vat);
    }
}

export function State_validate(form) {
    return singleton(FormState_Validation_withField(new Types_Field(5))(Validation_requiredStringField("Phone number", 2, 50, form.State.PhoneNumber)));
}

export function State_withServerError(error, state) {
    return new Types_State(FormState_Form_withFormErrors(singleton(error), state.Form), state.CountryList, state.Saving);
}

export function State_withNoErrors(state) {
    return new Types_State(FormState_Form_withNoErrors(state.Form), state.CountryList, state.Saving);
}

export function State_saving(state) {
    return new Types_State(state.Form, state.CountryList, true);
}

export function State_notSaving(state) {
    return new Types_State(state.Form, state.CountryList, false);
}

export function State_withEnableValidateAll(state) {
    return new Types_State(FormState_Form_enableValiadateAll(state.Form), state.CountryList, state.Saving);
}

export function State_withForm(form, state) {
    return new Types_State(form, state.CountryList, state.Saving);
}

export function State_init(customer) {
    let CompanyName, Country, City, PostCode, AddressLine1, AddressLine2, CompanyNumber, Vat, PaymentMethod, GLN, InvoiceEmail, InvoiceEmailCc;
    return [new Types_State(FormState_Form_create((CompanyName = defaultArg(map((x) => x.CompanyName, customer.CompanyDetails), ""), (Country = defaultArg(map((x_1) => x_1.Country, customer.CompanyDetails), ""), (City = defaultArg(map((x_2) => x_2.City, customer.CompanyDetails), ""), (PostCode = defaultArg(map((x_3) => x_3.PostCode, customer.CompanyDetails), ""), (AddressLine1 = defaultArg(map((x_4) => x_4.AddressLine1, customer.CompanyDetails), ""), (AddressLine2 = defaultArg(map((x_5) => x_5.AddressLine2, customer.CompanyDetails), ""), (CompanyNumber = defaultArg(map((x_6) => x_6.CompanyNumber, customer.CompanyDetails), ""), (Vat = defaultArg(map((x_7) => x_7.Vat, customer.CompanyDetails), ""), (PaymentMethod = defaultArg(map((x_8) => x_8.PaymentMethod, customer.CompanyDetails), ""), (GLN = defaultArg(map((x_9) => x_9.GLN, customer.CompanyDetails), ""), (InvoiceEmail = defaultArg(map((x_10) => x_10.InvoiceEmail, customer.CompanyDetails), ""), (InvoiceEmailCc = defaultArg(map((x_11) => x_11.InvoiceEmailCc, customer.CompanyDetails), ""), new Types_FormState(customer.Id, customer.FirstName, customer.LastName, CompanyName, customer.Email, customer.PhoneNumber, Country, City, PostCode, AddressLine1, AddressLine2, CompanyNumber, Vat, PaymentMethod, GLN, defaultArg(map((x_12) => x_12.TaxResidence, customer.CompanyDetails), ""), InvoiceEmail, InvoiceEmailCc)))))))))))))), customer.Countries, false), Cmd_none()];
}

export function State_update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8, inputRecord_9, inputRecord_10, inputRecord_11, inputRecord_12, inputRecord_13, inputRecord_14, state_5;
    switch (msg.tag) {
        case 0: {
            return [new Types_State(FormState_Form_validate((form_2) => State_validate(form_2), FormState_Form_withState((inputRecord = state.Form.State, new Types_FormState(inputRecord.Id, msg.fields[0], inputRecord.LastName, inputRecord.CompanyName, inputRecord.Email, inputRecord.PhoneNumber, inputRecord.Country, inputRecord.City, inputRecord.PostCode, inputRecord.AddressLine1, inputRecord.AddressLine2, inputRecord.CompanyNumber, inputRecord.Vat, inputRecord.PaymentMethod, inputRecord.GLN, inputRecord.TaxResidence, inputRecord.InvoiceEmail, inputRecord.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 1: {
            return [new Types_State(FormState_Form_validate((form_6) => State_validate(form_6), FormState_Form_withState((inputRecord_1 = state.Form.State, new Types_FormState(inputRecord_1.Id, inputRecord_1.FirstName, msg.fields[0], inputRecord_1.CompanyName, inputRecord_1.Email, inputRecord_1.PhoneNumber, inputRecord_1.Country, inputRecord_1.City, inputRecord_1.PostCode, inputRecord_1.AddressLine1, inputRecord_1.AddressLine2, inputRecord_1.CompanyNumber, inputRecord_1.Vat, inputRecord_1.PaymentMethod, inputRecord_1.GLN, inputRecord_1.TaxResidence, inputRecord_1.InvoiceEmail, inputRecord_1.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 2: {
            return [new Types_State(FormState_Form_validate((form_10) => State_validate(form_10), FormState_Form_withState((inputRecord_2 = state.Form.State, new Types_FormState(inputRecord_2.Id, inputRecord_2.FirstName, inputRecord_2.LastName, msg.fields[0], inputRecord_2.Email, inputRecord_2.PhoneNumber, inputRecord_2.Country, inputRecord_2.City, inputRecord_2.PostCode, inputRecord_2.AddressLine1, inputRecord_2.AddressLine2, inputRecord_2.CompanyNumber, inputRecord_2.Vat, inputRecord_2.PaymentMethod, inputRecord_2.GLN, inputRecord_2.TaxResidence, inputRecord_2.InvoiceEmail, inputRecord_2.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 3: {
            return [new Types_State(FormState_Form_validate((form_14) => State_validate(form_14), FormState_Form_withState((inputRecord_3 = state.Form.State, new Types_FormState(inputRecord_3.Id, inputRecord_3.FirstName, inputRecord_3.LastName, inputRecord_3.CompanyName, msg.fields[0], inputRecord_3.PhoneNumber, inputRecord_3.Country, inputRecord_3.City, inputRecord_3.PostCode, inputRecord_3.AddressLine1, inputRecord_3.AddressLine2, inputRecord_3.CompanyNumber, inputRecord_3.Vat, inputRecord_3.PaymentMethod, inputRecord_3.GLN, inputRecord_3.TaxResidence, inputRecord_3.InvoiceEmail, inputRecord_3.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 15: {
            return [new Types_State(FormState_Form_validate((form_18) => State_validate(form_18), FormState_Form_withState((inputRecord_4 = state.Form.State, new Types_FormState(inputRecord_4.Id, inputRecord_4.FirstName, inputRecord_4.LastName, inputRecord_4.CompanyName, inputRecord_4.Email, inputRecord_4.PhoneNumber, inputRecord_4.Country, inputRecord_4.City, inputRecord_4.PostCode, inputRecord_4.AddressLine1, inputRecord_4.AddressLine2, inputRecord_4.CompanyNumber, inputRecord_4.Vat, inputRecord_4.PaymentMethod, inputRecord_4.GLN, inputRecord_4.TaxResidence, msg.fields[0], inputRecord_4.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 16: {
            return [new Types_State(FormState_Form_validate((form_22) => State_validate(form_22), FormState_Form_withState((inputRecord_5 = state.Form.State, new Types_FormState(inputRecord_5.Id, inputRecord_5.FirstName, inputRecord_5.LastName, inputRecord_5.CompanyName, inputRecord_5.Email, inputRecord_5.PhoneNumber, inputRecord_5.Country, inputRecord_5.City, inputRecord_5.PostCode, inputRecord_5.AddressLine1, inputRecord_5.AddressLine2, inputRecord_5.CompanyNumber, inputRecord_5.Vat, inputRecord_5.PaymentMethod, inputRecord_5.GLN, inputRecord_5.TaxResidence, inputRecord_5.InvoiceEmail, msg.fields[0])), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 4: {
            return [new Types_State(FormState_Form_validate((form_26) => State_validate(form_26), FormState_Form_withState((inputRecord_6 = state.Form.State, new Types_FormState(inputRecord_6.Id, inputRecord_6.FirstName, inputRecord_6.LastName, inputRecord_6.CompanyName, inputRecord_6.Email, msg.fields[0], inputRecord_6.Country, inputRecord_6.City, inputRecord_6.PostCode, inputRecord_6.AddressLine1, inputRecord_6.AddressLine2, inputRecord_6.CompanyNumber, inputRecord_6.Vat, inputRecord_6.PaymentMethod, inputRecord_6.GLN, inputRecord_6.TaxResidence, inputRecord_6.InvoiceEmail, inputRecord_6.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 5: {
            return [new Types_State(FormState_Form_validate((form_30) => State_validate(form_30), FormState_Form_withState((inputRecord_7 = state.Form.State, new Types_FormState(inputRecord_7.Id, inputRecord_7.FirstName, inputRecord_7.LastName, inputRecord_7.CompanyName, inputRecord_7.Email, inputRecord_7.PhoneNumber, msg.fields[0], inputRecord_7.City, inputRecord_7.PostCode, inputRecord_7.AddressLine1, inputRecord_7.AddressLine2, inputRecord_7.CompanyNumber, inputRecord_7.Vat, inputRecord_7.PaymentMethod, inputRecord_7.GLN, inputRecord_7.TaxResidence, inputRecord_7.InvoiceEmail, inputRecord_7.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 6: {
            return [new Types_State(FormState_Form_validate((form_34) => State_validate(form_34), FormState_Form_withState((inputRecord_8 = state.Form.State, new Types_FormState(inputRecord_8.Id, inputRecord_8.FirstName, inputRecord_8.LastName, inputRecord_8.CompanyName, inputRecord_8.Email, inputRecord_8.PhoneNumber, inputRecord_8.Country, msg.fields[0], inputRecord_8.PostCode, inputRecord_8.AddressLine1, inputRecord_8.AddressLine2, inputRecord_8.CompanyNumber, inputRecord_8.Vat, inputRecord_8.PaymentMethod, inputRecord_8.GLN, inputRecord_8.TaxResidence, inputRecord_8.InvoiceEmail, inputRecord_8.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 7: {
            return [new Types_State(FormState_Form_validate((form_38) => State_validate(form_38), FormState_Form_withState((inputRecord_9 = state.Form.State, new Types_FormState(inputRecord_9.Id, inputRecord_9.FirstName, inputRecord_9.LastName, inputRecord_9.CompanyName, inputRecord_9.Email, inputRecord_9.PhoneNumber, inputRecord_9.Country, inputRecord_9.City, msg.fields[0], inputRecord_9.AddressLine1, inputRecord_9.AddressLine2, inputRecord_9.CompanyNumber, inputRecord_9.Vat, inputRecord_9.PaymentMethod, inputRecord_9.GLN, inputRecord_9.TaxResidence, inputRecord_9.InvoiceEmail, inputRecord_9.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 8: {
            return [new Types_State(FormState_Form_validate((form_42) => State_validate(form_42), FormState_Form_withState((inputRecord_10 = state.Form.State, new Types_FormState(inputRecord_10.Id, inputRecord_10.FirstName, inputRecord_10.LastName, inputRecord_10.CompanyName, inputRecord_10.Email, inputRecord_10.PhoneNumber, inputRecord_10.Country, inputRecord_10.City, inputRecord_10.PostCode, msg.fields[0], inputRecord_10.AddressLine2, inputRecord_10.CompanyNumber, inputRecord_10.Vat, inputRecord_10.PaymentMethod, inputRecord_10.GLN, inputRecord_10.TaxResidence, inputRecord_10.InvoiceEmail, inputRecord_10.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 9: {
            return [new Types_State(FormState_Form_validate((form_46) => State_validate(form_46), FormState_Form_withState((inputRecord_11 = state.Form.State, new Types_FormState(inputRecord_11.Id, inputRecord_11.FirstName, inputRecord_11.LastName, inputRecord_11.CompanyName, inputRecord_11.Email, inputRecord_11.PhoneNumber, inputRecord_11.Country, inputRecord_11.City, inputRecord_11.PostCode, inputRecord_11.AddressLine1, msg.fields[0], inputRecord_11.CompanyNumber, inputRecord_11.Vat, inputRecord_11.PaymentMethod, inputRecord_11.GLN, inputRecord_11.TaxResidence, inputRecord_11.InvoiceEmail, inputRecord_11.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 10: {
            return [new Types_State(FormState_Form_validate((form_50) => State_validate(form_50), FormState_Form_withState((inputRecord_12 = state.Form.State, new Types_FormState(inputRecord_12.Id, inputRecord_12.FirstName, inputRecord_12.LastName, inputRecord_12.CompanyName, inputRecord_12.Email, inputRecord_12.PhoneNumber, inputRecord_12.Country, inputRecord_12.City, inputRecord_12.PostCode, inputRecord_12.AddressLine1, inputRecord_12.AddressLine2, msg.fields[0], inputRecord_12.Vat, inputRecord_12.PaymentMethod, inputRecord_12.GLN, inputRecord_12.TaxResidence, inputRecord_12.InvoiceEmail, inputRecord_12.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 11: {
            return [new Types_State(FormState_Form_validate((form_54) => State_validate(form_54), FormState_Form_withState((inputRecord_13 = state.Form.State, new Types_FormState(inputRecord_13.Id, inputRecord_13.FirstName, inputRecord_13.LastName, inputRecord_13.CompanyName, inputRecord_13.Email, inputRecord_13.PhoneNumber, inputRecord_13.Country, inputRecord_13.City, inputRecord_13.PostCode, inputRecord_13.AddressLine1, inputRecord_13.AddressLine2, inputRecord_13.CompanyNumber, msg.fields[0], inputRecord_13.PaymentMethod, inputRecord_13.GLN, inputRecord_13.TaxResidence, inputRecord_13.InvoiceEmail, inputRecord_13.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 14: {
            return [new Types_State(FormState_Form_validate((form_58) => State_validate(form_58), FormState_Form_withState((inputRecord_14 = state.Form.State, new Types_FormState(inputRecord_14.Id, inputRecord_14.FirstName, inputRecord_14.LastName, inputRecord_14.CompanyName, inputRecord_14.Email, inputRecord_14.PhoneNumber, inputRecord_14.Country, inputRecord_14.City, inputRecord_14.PostCode, inputRecord_14.AddressLine1, inputRecord_14.AddressLine2, inputRecord_14.CompanyNumber, inputRecord_14.Vat, inputRecord_14.PaymentMethod, inputRecord_14.GLN, msg.fields[0], inputRecord_14.InvoiceEmail, inputRecord_14.InvoiceEmailCc)), state.Form)), state.CountryList, state.Saving), Cmd_none(), void 0];
        }
        case 19: {
            return [state, Cmd_none(), new Types_ExternalMsg(0)];
        }
        case 20: {
            const form_64 = FormState_Form_validate((form_63) => State_validate(form_63), FormState_Form_withNoErrors(FormState_Form_enableValiadateAll(state.Form)));
            const nextState = State_withForm(form_64, state);
            if (FormState_Form_isValid(form_64)) {
                return [State_saving(nextState), State_createSubaccountCmd(state.Form.State), void 0];
            }
            else {
                return [nextState, Cmd_none(), void 0];
            }
        }
        case 17: {
            return [State_notSaving(state), message("Customer has been saved", (builder) => success(builder)), new Types_ExternalMsg(1)];
        }
        case 18: {
            return [(state_5 = State_notSaving(state), State_withServerError(msgOfExn(msg.fields[0]), state_5)), Cmd_none(), void 0];
        }
        default: {
            throw (new Error("Match failure: EditCustomer.Types.Msg"));
        }
    }
}

export function View_render(state, dispatch) {
    return div(empty(), ofArray([react.createElement("div", {}, container(empty(), ofArray([react.createElement("fieldset", {
        disabled: true,
        style: {
            marginBottom: 12,
        },
    }, div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("FirstName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(1))(state.Form)), new Option_1(13, (x) => {
        dispatch(new Types_Msg(0, Browser_Types_Event__Event_get_Value(x)));
    }), new Option_1(12, "First name"), new Option_1(11, state.Form.State.FirstName), new Option_1(15, singleton(new HTMLAttr(55, true)))])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(1))(state.Form)))]))))])), div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("LastName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(2))(state.Form)), new Option_1(13, (x_1) => {
        dispatch(new Types_Msg(1, Browser_Types_Event__Event_get_Value(x_1)));
    }), new Option_1(12, "Last name"), new Option_1(11, state.Form.State.LastName)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(2))(state.Form)))]))))])), div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("CompanyName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(3))(state.Form)), new Option_1(13, (x_2) => {
        dispatch(new Types_Msg(2, Browser_Types_Event__Event_get_Value(x_2)));
    }), new Option_1(12, "Company name"), new Option_1(11, state.Form.State.CompanyName)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(3))(state.Form)))]))))])), div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Email")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(13, (x_3) => {
        dispatch(new Types_Msg(3, Browser_Types_Event__Event_get_Value(x_3)));
    }), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(4))(state.Form)), new Option_1(12, "Email"), new Option_1(11, state.Form.State.Email)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(4))(state.Form)))]))))]))), div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("PhoneNumber")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(5))(state.Form)), new Option_1(13, (x_4) => {
        dispatch(new Types_Msg(4, Browser_Types_Event__Event_get_Value(x_4)));
    }), new Option_1(12, "Phone number"), new Option_1(11, state.Form.State.PhoneNumber)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(5))(state.Form)))]))))])), react.createElement("fieldset", {
        disabled: true,
    }, ...toList(delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Country")), body(empty(), singleton(div_1(empty(), ofArray([select(ofArray([new Option_3(1), new Option_3(10, ofArray([new DOMAttr(9, (x_5) => {
        dispatch(new Types_Msg(5, Browser_Types_Event__Event_get_Value(x_5)));
    }), new HTMLAttr(128, "Country")]))]), singleton(react.createElement("select", {}, ...toList(delay(() => map_1((c) => react.createElement("option", {
        selected: state.Form.State.Country === c.Code,
        value: c.Code,
    }, c.Name), state.CountryList)))))), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(6))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("City")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(7))(state.Form)), new Option_1(13, (x_6) => {
        dispatch(new Types_Msg(6, Browser_Types_Event__Event_get_Value(x_6)));
    }), new Option_1(12, "City"), new Option_1(11, state.Form.State.City)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(7))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("PostCode")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(8))(state.Form)), new Option_1(13, (x_7) => {
        dispatch(new Types_Msg(7, Browser_Types_Event__Event_get_Value(x_7)));
    }), new Option_1(12, "Post code"), new Option_1(11, state.Form.State.PostCode)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(8))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("AddressLine1")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(9))(state.Form)), new Option_1(13, (x_8) => {
        dispatch(new Types_Msg(8, Browser_Types_Event__Event_get_Value(x_8)));
    }), new Option_1(12, "Address line 1"), new Option_1(11, state.Form.State.AddressLine1)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(9))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("AddressLine2")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(10))(state.Form)), new Option_1(13, (x_9) => {
        dispatch(new Types_Msg(9, Browser_Types_Event__Event_get_Value(x_9)));
    }), new Option_1(12, "Address line 2"), new Option_1(11, state.Form.State.AddressLine2)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(10))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Company Number")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(11))(state.Form)), new Option_1(13, (x_10) => {
        dispatch(new Types_Msg(10, Browser_Types_Event__Event_get_Value(x_10)));
    }), new Option_1(12, "Company Number"), new Option_1(11, state.Form.State.CompanyNumber)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(11))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("TaxResidence")), body(empty(), singleton(div_1(empty(), ofArray([select(ofArray([new Option_3(1), new Option_3(10, ofArray([new DOMAttr(9, (x_11) => {
        dispatch(new Types_Msg(14, Browser_Types_Event__Event_get_Value(x_11)));
    }), new HTMLAttr(128, "Tax Residence")]))]), singleton(react.createElement("select", {}, ...toList(delay(() => map_1((c_1) => react.createElement("option", {
        selected: state.Form.State.TaxResidence === c_1.Code,
        value: c_1.Code,
    }, c_1.Name), state.CountryList)))))), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(15))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("VAT")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(12))(state.Form)), new Option_1(13, (x_12) => {
        dispatch(new Types_Msg(11, Browser_Types_Event__Event_get_Value(x_12)));
    }), new Option_1(12, "VAT"), new Option_1(11, state.Form.State.Vat)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(12))(state.Form)))]))))]))), delay(() => {
        let paymentMethod;
        const matchValue = state.Form.State.PaymentMethod;
        switch (matchValue) {
            case "email": {
                paymentMethod = "E-Mail";
                break;
            }
            case "einvoice": {
                paymentMethod = "E-Invoice";
                break;
            }
            default: {
                paymentMethod = "";
            }
        }
        return append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Payment Method")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(16))(state.Form)), new Option_1(13, (x_13) => {
            dispatch(new Types_Msg(12, Browser_Types_Event__Event_get_Value(x_13)));
        }), new Option_1(12, "Payment method"), new Option_1(11, paymentMethod)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(16))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("GLN")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(17))(state.Form)), new Option_1(13, (x_14) => {
            dispatch(new Types_Msg(13, Browser_Types_Event__Event_get_Value(x_14)));
        }), new Option_1(12, "GLN"), new Option_1(11, state.Form.State.GLN)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(17))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Invoice Email")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(13, (x_15) => {
            dispatch(new Types_Msg(15, Browser_Types_Event__Event_get_Value(x_15)));
        }), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(13))(state.Form)), new Option_1(12, "Invoice Email"), new Option_1(11, state.Form.State.InvoiceEmail)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(13))(state.Form)))]))))]))), delay(() => append(singleton_1(div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Invoice Email Cc")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(13, (x_16) => {
            dispatch(new Types_Msg(16, Browser_Types_Event__Event_get_Value(x_16)));
        }), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(14))(state.Form)), new Option_1(12, "Invoice Email Cc"), new Option_1(11, state.Form.State.InvoiceEmailCc)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(14))(state.Form)))]))))]))), delay(() => singleton_1(div_1(singleton(new Option(8)), ofArray([label(empty(), empty()), body(empty(), singleton(help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFormError(state.Form)))))])))))))))));
    }))))))))))))))))))), div_1(singleton(new Option(8)), ofArray([label(empty(), empty()), body(empty(), singleton(a(ofArray([new Option_4(0, new Color_IColor(4)), new Option_4(18, (_arg1) => {
        dispatch(new Types_Msg(20));
    }), new Option_4(13, state.Saving)]), singleton("Save"))))]))]))), react.createElement("br", {})]));
}

