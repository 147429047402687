import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder, Remoting_buildProxy_Z15584635 } from "./.fable/Fable.Remoting.Client.7.16.0/Remoting.fs.js";
import { IValidationExceptionsApi$reflection, ISmsSearchApi$reflection, Api_IOptedOutApi$reflection, Api_IReferenceStore$reflection, Api_ICustomerStore$reflection, Api_ICustomerApi$reflection, Api_IInvoiceStore$reflection, Api_ISigninApi$reflection, Route_builder } from "./Shared/Api.js";
import { getBearerToken } from "./LocalStorage.js";
import { Api_IVirtualNumbersStore$reflection } from "./Shared/VirtualNumbers.js";

export function apiVirtualNumbers() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_IVirtualNumbersStore$reflection,
    });
}

export function signInApi() {
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), RemotingModule_createApi()), {
        ResolveType: Api_ISigninApi$reflection,
    });
}

export function api() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_IInvoiceStore$reflection,
    });
}

export function customerApi() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_ICustomerApi$reflection,
    });
}

export function customerStore() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_ICustomerStore$reflection,
    });
}

export function referenceApi() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_IReferenceStore$reflection,
    });
}

export function optedOutApi() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: Api_IOptedOutApi$reflection,
    });
}

export function SearchSms_searchSmsApi() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: ISmsSearchApi$reflection,
    });
}

export function validationExceptionsApi() {
    let options;
    return Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder((typeName, methodName) => Route_builder(typeName, methodName), (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(getBearerToken(), options))), {
        ResolveType: IValidationExceptionsApi$reflection,
    });
}

