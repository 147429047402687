import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { union_type, record_type, bool_type, string_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.2.9/Choice.js";

export class State extends Record {
    constructor(Login, Password, LoggingInProgress, Error$) {
        super();
        this.Login = Login;
        this.Password = Password;
        this.LoggingInProgress = LoggingInProgress;
        this.Error = Error$;
    }
}

export function State$reflection() {
    return record_type("Pages.SignIn.Model.State", [], State, () => [["Login", string_type], ["Password", string_type], ["LoggingInProgress", bool_type], ["Error", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoginChanged", "PasswordChanged", "Login", "TokenSaved", "LoginResult"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.SignIn.Model.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]])]]]);
}

