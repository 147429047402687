import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { SortDirection, SortDirection$reflection } from "../../Shared/Shared.js";
import { int32_type, union_type, record_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { equals } from "../../.fable/fable-library.3.2.9/Util.js";
import * as react from "react";
import { singleton, append, delay, toList } from "../../.fable/fable-library.3.2.9/Seq.js";
import { Option, icon } from "../../.fable/Fulma.2.14.1/Elements/Icon.fs.js";
import { Size_ISize } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { singleton as singleton_1 } from "../../.fable/fable-library.3.2.9/List.js";
import { Fa_IconOption, Fa_i } from "../../.fable/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Helpers_nothing } from "../../.fable/Fable.React.7.4.1/Fable.React.Helpers.fs.js";

export class Types_State$1 extends Record {
    constructor(Field, Direction) {
        super();
        this.Field = Field;
        this.Direction = Direction;
    }
}

export function Types_State$1$reflection(gen0) {
    return record_type("Common.Sorting.Types.State`1", [gen0], Types_State$1, () => [["Field", gen0], ["Direction", SortDirection$reflection()]]);
}

export class Types_Msg$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Change", "Toggle"];
    }
}

export function Types_Msg$1$reflection(gen0) {
    return union_type("Common.Sorting.Types.Msg`1", [gen0], Types_Msg$1, () => [[["Item1", gen0], ["Item2", SortDirection$reflection()]], [["Item", gen0]]]);
}

export class Types_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GoTo"];
    }
}

export function Types_ExternalMsg$reflection() {
    return union_type("Common.Sorting.Types.ExternalMsg", [], Types_ExternalMsg, () => [[["Item", int32_type]]]);
}

export function State_init(field, direction) {
    return new Types_State$1(field, direction);
}

export function State_update(state, _arg1) {
    if (_arg1.tag === 1) {
        if (equals(state.Field, _arg1.fields[0])) {
            if (state.Direction.tag === 1) {
                return [new Types_State$1(state.Field, new SortDirection(0)), void 0];
            }
            else {
                return [new Types_State$1(state.Field, new SortDirection(1)), void 0];
            }
        }
        else {
            let pattern_matching_result, field_4;
            if (_arg1.tag === 1) {
                if (!equals(state.Field, _arg1.fields[0])) {
                    pattern_matching_result = 0;
                    field_4 = _arg1.fields[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return [new Types_State$1(field_4, new SortDirection(0)), void 0];
                }
                case 1: {
                    return [state, void 0];
                }
            }
        }
    }
    else {
        return [new Types_State$1(_arg1.fields[0], _arg1.fields[1]), void 0];
    }
}

export function View_render(state, field, fieldName, dispatch) {
    return react.createElement("th", {
        style: {
            cursor: "pointer",
            textAlign: "center",
        },
        onClick: (_arg1) => {
            dispatch(new Types_Msg$1(1, field));
        },
    }, ...toList(delay(() => append(singleton(fieldName), delay(() => {
        const matchValue = [state.Field, state.Direction];
        let pattern_matching_result;
        if (matchValue[1].tag === 0) {
            if (equals(matchValue[0], field)) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(icon(singleton_1(new Option(0, new Size_ISize(0))), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-up")), []))));
            }
            case 1: {
                let pattern_matching_result_1;
                if (matchValue[1].tag === 1) {
                    if (equals(matchValue[0], field)) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return singleton(icon(singleton_1(new Option(0, new Size_ISize(0))), singleton_1(Fa_i(singleton_1(new Fa_IconOption(11, "fas fa-angle-down")), []))));
                    }
                    case 1: {
                        return singleton(Helpers_nothing);
                    }
                }
            }
        }
    })))));
}

