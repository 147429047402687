import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { PagingResult$1$reflection, OptedOutSortFields$reflection, OptedOut$reflection } from "../../Shared/Shared.js";
import { union_type, class_type, record_type, string_type, bool_type, option_type, list_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { Types_Msg$reflection, Types_State$reflection } from "../Common/Paging.js";
import { Types_Msg$1$reflection, Types_State$1$reflection } from "../Common/Sorting.js";
import { SelfMessage$1$reflection, State$reflection as State$reflection_1 } from "../../.fable/Thoth.Elmish.Debouncer.1.0.0/Debouncer.fs.js";

export class State extends Record {
    constructor(OptedOuts, Paging, Sorting, Loading, Error$, Filter, Debouncer, ShowDeleteConfirmation, Deleting) {
        super();
        this.OptedOuts = OptedOuts;
        this.Paging = Paging;
        this.Sorting = Sorting;
        this.Loading = Loading;
        this.Error = Error$;
        this.Filter = Filter;
        this.Debouncer = Debouncer;
        this.ShowDeleteConfirmation = ShowDeleteConfirmation;
        this.Deleting = Deleting;
    }
}

export function State$reflection() {
    return record_type("Pages.OptedOut.Model.State", [], State, () => [["OptedOuts", list_type(OptedOut$reflection())], ["Paging", option_type(Types_State$reflection())], ["Sorting", Types_State$1$reflection(OptedOutSortFields$reflection())], ["Loading", bool_type], ["Error", string_type], ["Filter", string_type], ["Debouncer", State$reflection_1()], ["ShowDeleteConfirmation", option_type(OptedOut$reflection())], ["Deleting", option_type(OptedOut$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LoadSuccess", "LoadError", "PagingMsg", "SortingMsg", "FilterChanged", "DebouncerSelfMsg", "EndOfInputSearch", "AskDeleteConfirmation", "CancelDeleteConfirmation", "DeleteOptedOut", "DeletedOptedOut", "DeleteError"];
    }
}

export function Msg$reflection() {
    return union_type("Pages.OptedOut.Model.Msg", [], Msg, () => [[["Item", PagingResult$1$reflection(OptedOut$reflection())]], [["Item", class_type("System.Exception")]], [["Item", Types_Msg$reflection()]], [["Item", Types_Msg$1$reflection(OptedOutSortFields$reflection())]], [["Item", string_type]], [["Item", SelfMessage$1$reflection(Msg$reflection())]], [], [["Item", OptedOut$reflection()]], [], [["Item", OptedOut$reflection()]], [["Item", OptedOut$reflection()]], [["Item", class_type("System.Exception")]]]);
}

