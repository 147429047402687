import { Union, Record } from "../../.fable/fable-library.3.2.9/Types.js";
import { class_type, bool_type, union_type, record_type, string_type, int32_type } from "../../.fable/fable-library.3.2.9/Reflection.js";
import { FormState_Form_getFormError, FormState_Form_getFieldError, FormState_Form_getFieldColor, FormState_Form_isValid, FormState_Form_withState, FormState_Form_validate, FormState_Form_create, FormState_Form_enableValiadateAll, FormState_Form_withNoErrors, FormState_Form_withFormErrors, Validation_validateEmail, Validation_requiredStringField, FormState_Validation_withField, FormState_Form$2$reflection } from "../Common/FormState.js";
import { SaveSubaccount } from "../../Shared/Shared.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { api } from "./Communication.js";
import { empty, singleton, ofArray } from "../../.fable/fable-library.3.2.9/List.js";
import { msgOfExn } from "../Common/Utils.js";
import { div } from "../../.fable/Fulma.2.14.1/Elements/Form/Control.fs.js";
import * as react from "react";
import { container } from "../../.fable/Fulma.2.14.1/Layouts/Container.fs.js";
import { body, Label_Option, label, Option, div as div_1 } from "../../.fable/Fulma.2.14.1/Elements/Form/Field.fs.js";
import { Option as Option_1, IInputType, input } from "../../.fable/Fulma.2.14.1/Elements/Form/Input.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.4.1/Fable.React.Extensions.fs.js";
import { DOMAttr, HTMLAttr } from "../../.fable/Fable.React.7.4.1/Fable.React.Props.fs.js";
import { Option as Option_2, help } from "../../.fable/Fulma.2.14.1/Elements/Form/Help.fs.js";
import { Common_GenericOption, Color_IColor } from "../../.fable/Fulma.2.14.1/Common.fs.js";
import { Card_body, Card_card, background, Option as Option_3, modal } from "../../.fable/Fulma.2.14.1/Components/Modal.fs.js";
import { p } from "../../.fable/Fulma.2.14.1/Elements/Heading.fs.js";
import { right, left, level } from "../../.fable/Fulma.2.14.1/Layouts/Level.fs.js";
import { Option as Option_4, a } from "../../.fable/Fulma.2.14.1/Elements/Button.fs.js";

export class Types_FormState extends Record {
    constructor(Id, FirstName, LastName, Email) {
        super();
        this.Id = (Id | 0);
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Email = Email;
    }
}

export function Types_FormState$reflection() {
    return record_type("EditSubaccauntDialog.Types.FormState", [], Types_FormState, () => [["Id", int32_type], ["FirstName", string_type], ["LastName", string_type], ["Email", string_type]]);
}

export class Types_Field extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Id", "FirstName", "LastName", "Email"];
    }
}

export function Types_Field$reflection() {
    return union_type("EditSubaccauntDialog.Types.Field", [], Types_Field, () => [[], [], [], []]);
}

export class Types_State extends Record {
    constructor(Form, Saving) {
        super();
        this.Form = Form;
        this.Saving = Saving;
    }
}

export function Types_State$reflection() {
    return record_type("EditSubaccauntDialog.Types.State", [], Types_State, () => [["Form", FormState_Form$2$reflection(Types_FormState$reflection(), Types_Field$reflection())], ["Saving", bool_type]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FirstNameChange", "LastNameChange", "EmailChange", "Saved", "SaveError", "Cancel", "Save"];
    }
}

export function Types_Msg$reflection() {
    return union_type("EditSubaccauntDialog.Types.Msg", [], Types_Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", class_type("System.Exception")]], [], []]);
}

export class Types_ExternalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Cancel", "Save"];
    }
}

export function Types_ExternalMsg$reflection() {
    return union_type("EditSubaccauntDialog.Types.ExternalMsg", [], Types_ExternalMsg, () => [[], []]);
}

function State_toSaveSubaccount(state) {
    return new SaveSubaccount(state.Id, state.FirstName, state.LastName, state.Email);
}

export function State_createSubaccountCmd(formState) {
    return Cmd_OfAsyncWith_perform((x) => {
        Cmd_OfAsync_start(x);
    }, api().saveSubaccount, State_toSaveSubaccount(formState), () => (new Types_Msg(3)));
}

export function State_validate(form) {
    return ofArray([FormState_Validation_withField(new Types_Field(1))(Validation_requiredStringField("First name", 2, 200, form.State.FirstName)), FormState_Validation_withField(new Types_Field(2))(Validation_requiredStringField("Last name", 2, 200, form.State.LastName)), FormState_Validation_withField(new Types_Field(3))(Validation_validateEmail(form.State.Email))]);
}

export function State_withServerError(error, state) {
    return new Types_State(FormState_Form_withFormErrors(singleton(error), state.Form), state.Saving);
}

export function State_withNoErrors(state) {
    return new Types_State(FormState_Form_withNoErrors(state.Form), state.Saving);
}

export function State_saving(state) {
    return new Types_State(state.Form, true);
}

export function State_notSaving(state) {
    return new Types_State(state.Form, false);
}

export function State_withEnableValidateAll(state) {
    return new Types_State(FormState_Form_enableValiadateAll(state.Form), state.Saving);
}

export function State_withForm(form, state) {
    return new Types_State(form, state.Saving);
}

export function State_init(subaccount) {
    return [new Types_State(FormState_Form_create(new Types_FormState(subaccount.Id, subaccount.FirstName, subaccount.LastName, subaccount.Email)), false), Cmd_none()];
}

export function State_update(msg, state) {
    let inputRecord_1, inputRecord_2, state_5, inputRecord;
    switch (msg.tag) {
        case 1: {
            return [new Types_State(FormState_Form_validate((form_6) => State_validate(form_6), FormState_Form_withState((inputRecord_1 = state.Form.State, new Types_FormState(inputRecord_1.Id, inputRecord_1.FirstName, msg.fields[0], inputRecord_1.Email)), state.Form)), state.Saving), Cmd_none(), void 0];
        }
        case 2: {
            return [new Types_State(FormState_Form_validate((form_10) => State_validate(form_10), FormState_Form_withState((inputRecord_2 = state.Form.State, new Types_FormState(inputRecord_2.Id, inputRecord_2.FirstName, inputRecord_2.LastName, msg.fields[0])), state.Form)), state.Saving), Cmd_none(), void 0];
        }
        case 5: {
            return [state, Cmd_none(), new Types_ExternalMsg(0)];
        }
        case 6: {
            const form_16 = FormState_Form_validate((form_15) => State_validate(form_15), FormState_Form_withNoErrors(FormState_Form_enableValiadateAll(state.Form)));
            const nextState = State_withForm(form_16, state);
            if (FormState_Form_isValid(form_16)) {
                return [State_saving(nextState), State_createSubaccountCmd(state.Form.State), void 0];
            }
            else {
                return [nextState, Cmd_none(), void 0];
            }
        }
        case 3: {
            return [State_notSaving(state), Cmd_none(), new Types_ExternalMsg(1)];
        }
        case 4: {
            return [(state_5 = State_notSaving(state), State_withServerError(msgOfExn(msg.fields[0]), state_5)), Cmd_none(), void 0];
        }
        default: {
            return [new Types_State(FormState_Form_validate((form_2) => State_validate(form_2), FormState_Form_withState((inputRecord = state.Form.State, new Types_FormState(inputRecord.Id, msg.fields[0], inputRecord.LastName, inputRecord.Email)), state.Form)), state.Saving), Cmd_none(), void 0];
        }
    }
}

export function View_render(state, dispatch) {
    const content = div(empty(), ofArray([react.createElement("div", {}, container(empty(), ofArray([div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("FirstName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(1))(state.Form)), new Option_1(13, (x) => {
        dispatch(new Types_Msg(0, Browser_Types_Event__Event_get_Value(x)));
    }), new Option_1(12, "First name"), new Option_1(11, state.Form.State.FirstName), new Option_1(15, singleton(new HTMLAttr(55, true)))])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(1))(state.Form)))]))))])), div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("LastName")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(2))(state.Form)), new Option_1(13, (x_1) => {
        dispatch(new Types_Msg(1, Browser_Types_Event__Event_get_Value(x_1)));
    }), new Option_1(12, "Last name"), new Option_1(11, state.Form.State.LastName)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(2))(state.Form)))]))))])), div_1(singleton(new Option(8)), ofArray([label(singleton(new Label_Option(1)), singleton("Email")), body(empty(), singleton(div_1(empty(), ofArray([input(ofArray([new Option_1(1, new IInputType(0)), new Option_1(13, (x_2) => {
        dispatch(new Types_Msg(2, Browser_Types_Event__Event_get_Value(x_2)));
    }), new Option_1(2, FormState_Form_getFieldColor(new Types_Field(3))(state.Form)), new Option_1(12, "Email"), new Option_1(11, state.Form.State.Email)])), help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFieldError(new Types_Field(3))(state.Form)))]))))])), div_1(singleton(new Option(8)), ofArray([label(empty(), empty()), body(empty(), singleton(help(singleton(new Option_2(2, new Color_IColor(8))), singleton(FormState_Form_getFormError(state.Form)))))]))]))), react.createElement("br", {})]));
    const onClose = (_arg1) => {
        dispatch(new Types_Msg(5));
    };
    return modal(singleton(new Option_3(1, true)), ofArray([background(singleton(new Common_GenericOption(1, singleton(new DOMAttr(40, onClose)))), empty()), Card_card(singleton(new Common_GenericOption(1, singleton(["style", {
        minWidth: "60%",
    }]))), singleton(Card_body(empty(), singleton(react.createElement("form", {}, p(empty(), singleton("Edit sub account")), content, level(empty(), ofArray([left(empty(), empty()), right(empty(), singleton(react.createElement("div", {
        className: "block",
    }, a(ofArray([new Option_4(0, new Color_IColor(8)), new Option_4(17, singleton(["style", {
        marginRight: "5px",
    }])), new Option_4(18, onClose)]), singleton("Cancel")), a(ofArray([new Option_4(0, new Color_IColor(4)), new Option_4(18, (_arg2) => {
        dispatch(new Types_Msg(6));
    }), new Option_4(13, state.Saving)]), singleton("Save")))))])))))))]));
}

